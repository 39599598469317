import React, { Component } from 'react'
import Chart from "chart.js";

export default class BarGraph extends Component<any, any> {
    chartRef: any = React.createRef();

    componentDidMount() {
        const myChartRef = this.chartRef.current.getContext("2d");

        new Chart(myChartRef, {
            type: "bar",
            data: {
                datasets: [
                    {
                        label: 'Target',
                        backgroundColor: '#3498db',
                        data: [10, 20, 5]
                    },
                    {
                        label: 'Achived',
                        backgroundColor: "#2ecc71",
                        data: [8, 12, 4],
                    }
                ],
                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: [
                    'Sr. Software Engineer',
                    'Manager Technology',
                    'CTO'
                ]
            },
            options: {
                responsive: true
            }
        });
    }
    
    render() {
        return (
            <div >
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        )
    }
}