import React, { useState } from 'react';

class HelpdeskModal extends React.Component<any, any>{

    constructor(props: any) {
        super(props);
        console.log("props.selectedStatus   ", props.selectedStatus)
        this.state = {
            time: { name: 'time', value: props.time ? props.time : '', error: '', isRequired: true },
            comment: { name: 'comment', value: '', error: '', isRequired: true },
        }
    }



    public render() {
        const { time, comment } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12 mb-2 px-4">
                        <div className="row">
                            <div className="col-lg-12 form-group">
                                <label>New Date</label>
                                <input
                                    className={time.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                    name={time.name}
                                    value={time.value}
                                    // onChange={this.onChange}
                                    placeholder=""
                                    type="date"
                                />
                            </div>
                            <div className="col-lg-12 form-group">
                                <textarea
                                    rows={5}
                                    className={comment.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    placeholder="Reason"
                                    name="comment"
                                    value={comment.value}
                                // onChange={this.onChange}
                                />
                            </div>


                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <button type="button" className="btn btn-secondary btn-block" onClick={this.cancelModal}>Cancel</button>
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                        <button type="button" className="btn btn-primary btn-block" onClick={this.cancelModal}>Update</button>
                    </div>
                </div>
            </div>
        );
    }

    cancelModal = () => {
        this.props.closeModal();
    }

}

export default HelpdeskModal;
