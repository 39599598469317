import * as React from 'react';
import { onChange, validateForm, setOptions } from './../../utils';
import { getLeaveStatusList } from '../../action/SettingsActions'
import CONSTANT from './../../constant';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            leaveId: detail ? detail.id : undefined,
            leaveStatusId: { name: 'leaveStatusId', value: detail && detail.leaveStatusId ? detail.leaveStatusId : '', error: '', isRequired: true, options: [] },
            comment: { name: 'comment', value: '', error: '', isRequired: true, },
        }
    }

    componentDidMount() {
        getLeaveStatusList(1, 100).then((res: any) => {
            setOptions(this, this.state.leaveStatusId.name, res.result);
        })
    }

    public render() {
        const { leaveStatusId, comment } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-lg-12 form-group">
                        <label>Leave status *</label>
                        <select name={leaveStatusId.name}
                            value={leaveStatusId.value}
                            onChange={this.onChange}
                            disabled={leaveStatusId.value === CONSTANT.leaveStatusId.rejected || leaveStatusId.value === CONSTANT.leaveStatusId.withdrawn}
                            className={leaveStatusId.error.length > 0 ? "form-control is-invalid" : "form-control"}>
                            <option value=''>Select status</option>
                            {
                                leaveStatusId.options.map((item: any, index: number) => {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })
                            }

                        </select>
                    </div>
                    <div className="col-lg-12 form-group">
                        <label>Comment *</label>
                        <textarea
                            rows={3}
                            className={comment.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Your comment"
                            name={comment.name}
                            value={comment.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-md-12"><small>* Indicates required fields</small></div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-3">
                        <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                        <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                    </div>
                </div>
                {/* </div>
                </div> */}
            </form>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        const isSure = window.confirm('Are you sure you want to update the status of Leave?');
        if (isSure && validateForm(this)) {
            const model: any = {
                leaveId: this.state.leaveId,
                leaveStatusId: this.state.leaveStatusId.value,
                comment: this.state.comment.value
            };
            this.props.onSubmit(model);
        }
    }
}

export default AddComponent;