import React from 'react';
import uniqid from 'uniqid';
import NumberFormat from 'react-number-format';

import { onChange, validateForm, setOptions } from './../../utils';
import { getReimbursementTypeList } from '../../action/ConfigAction';

class ComponentName extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        const detail = props.detail;

        this.state = {
            id: detail ? detail.id : '',
            uid: detail ? detail.uid : uniqid(),
            reimbursementTypeId: { name: 'reimbursementTypeId', value: '', error: '', isRequired: true, options: [] },
            startDate: { name: 'startDate', value: '', error: '', isRequired: true },
            endDate: { name: 'endDate', value: '', error: '', isRequired: true },
            comment: { name: 'comment', value: '', error: '', isRequired: false },
            billNumber: { name: 'billNumber', value: '', error: '', isRequired: false },
            amount: { name: 'amount', value: '', error: '', isRequired: true },
            bills: { name: 'bills', value: [], error: '', isRequired: false },
        }
    }

    componentDidMount() {
        getReimbursementTypeList().then((res: any) => {
            setOptions(this, this.state.reimbursementTypeId.name, res.result);
        });
    }

    render() {
        const {
            reimbursementTypeId, startDate, endDate, comment, billNumber, amount, bills } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <form onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Reimbursement Type <small>*</small></label>
                                        <select
                                            name={reimbursementTypeId.name}
                                            value={reimbursementTypeId.value}
                                            onChange={this.onChange}
                                            className={reimbursementTypeId.error.length > 0 ? "form-control is-invalid" : "form-control"}>
                                            <option value="">Select a type</option>
                                            {
                                                reimbursementTypeId.options.map((item: any, index: number) => {
                                                    return (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Start Date <small>*</small></label>
                                        <input
                                            type="date"
                                            className={startDate.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder=""
                                            name={startDate.name}
                                            value={startDate.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>End Date <small>*</small></label>
                                        <input
                                            type="date"
                                            className={endDate.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder=""
                                            name={endDate.name}
                                            value={endDate.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>



                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Bill Number</label>
                                        <input
                                            type="text"
                                            className={billNumber.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder=""
                                            name={billNumber.name}
                                            value={billNumber.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Amount <small>*</small></label>
                                        <NumberFormat
                                            decimalScale={2}
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            className={amount.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder=""
                                            name={amount.name}
                                            value={amount.value}
                                            onValueChange={(e: any) => { this.onChange({ target: { name: amount.name, value: e.value } }) }}
                                        />
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Bill(s)</label>
                                        <input
                                            name={bills.name}
                                            onChange={this.onChange}
                                            type="file"
                                            multiple={true}
                                            className="form-control"
                                            style={{ padding: 4 }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        {
                                            Array.from(bills.value).map(function (item: any, index: number) {
                                                return (
                                                    <span key={index} className="badge badge-primary mr-1">{item.name}</span>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Comments</label>
                                        <textarea
                                            rows={3}
                                            className={comment.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder=""
                                            name={comment.name}
                                            value={comment.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <button type="button" onClick={this.props.onCancel} className="btn btn-block btn-secondary btn-sm">Cancel</button>
                                </div>
                                <div className="col-md-6">
                                    &nbsp;
                                                </div>
                                <div className="col-md-3">
                                    <button type="submit" className="btn btn-block btn-primary btn-sm">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === this.state.bills.name) {
            value = e.target.files;
        }
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const reimbursementTypeId = parseFloat(st.reimbursementTypeId.value);
            const reimbursementType = st.reimbursementTypeId.options.filter((i: any) => i.id === reimbursementTypeId)[0];
            const model = {
                id: st.id,
                uid: st.uid,
                reimbursementTypeId,
                reimbursementTypeName: reimbursementType.name,
                startDate: st.startDate.value,
                endDate: st.endDate.value,
                comment: st.comment.value,
                billNumber: st.billNumber.value,
                amount: parseFloat(st.amount.value),
                bills: st.bills.value
            };
            if (st.id === "") {
                model.id = new Date().getTime();
            }
            this.props.onSubmit(model);
        }
    }
}

export default ComponentName;