import * as React from 'react';
import NumberFormat from 'react-number-format';
import { getLoginStatusList, getRoleList } from './../../action/ConfigAction';
import { onChange, validateForm, setOptions } from '../../utils';

class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;

        this.state = {
            userId: props.userId,

        }
    }

    componentDidMount() {

    }

    public render() {
        const { userId,
            ClaimNumber, ClaimDate, ClaimStatus, EmployeeNo, ReimbursementName, BillDate, BillNumber, ClaimedAmount,
            ApprovedAmount, Comment } = this.state;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                <table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1" role="grid" aria-describedby="kt_table_1_info">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '3%' }}>#</th>
                                            <th colSpan={2} className="text-center">Claim</th>
                                            <th colSpan={2} className="text-center">Bill</th>
                                            <th colSpan={2} className="text-center">Amount</th>
                                            <th>Comment</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th>Date</th>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>Number</th>
                                            <th>Claimed</th>
                                            <th>Approved</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>05-Mar-2020</td>
                                            <td>HMC Meeting</td>
                                            <td>28-Mar-2020</td>
                                            <td>1234</td>
                                            <td>1000</td>
                                            <td>1000</td>
                                            <td>Correct</td>

                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>05-Mar-2020</td>
                                            <td>HMC Meeting</td>
                                            <td>28-Mar-2020</td>
                                            <td>1234</td>
                                            <td>1000</td>
                                            <td>1000</td>
                                            <td>Correct</td>

                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>05-Mar-2020</td>
                                            <td>HMC Meeting</td>
                                            <td>28-Mar-2020</td>
                                            <td>1234</td>
                                            <td>1000</td>
                                            <td>1000</td>
                                            <td>Correct</td>

                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>05-Mar-2020</td>
                                            <td>HMC Meeting</td>
                                            <td>28-Mar-2020</td>
                                            <td>1234</td>
                                            <td>1000</td>
                                            <td>1000</td>
                                            <td>Correct</td>

                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>05-Mar-2020</td>
                                            <td>HMC Meeting</td>
                                            <td>28-Mar-2020</td>
                                            <td>1234</td>
                                            <td>1000</td>
                                            <td>1000</td>
                                            <td>Correct</td>

                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>05-Mar-2020</td>
                                            <td>HMC Meeting</td>
                                            <td>28-Mar-2020</td>
                                            <td>1234</td>
                                            <td>1000</td>
                                            <td>1000</td>
                                            <td>Correct</td>

                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>05-Mar-2020</td>
                                            <td>HMC Meeting</td>
                                            <td>28-Mar-2020</td>
                                            <td>1234</td>
                                            <td>1000</td>
                                            <td>1000</td>
                                            <td>Correct</td>

                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>05-Mar-2020</td>
                                            <td>HMC Meeting</td>
                                            <td>28-Mar-2020</td>
                                            <td>1234</td>
                                            <td>1000</td>
                                            <td>1000</td>
                                            <td>Correct</td>

                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>05-Mar-2020</td>
                                            <td>HMC Meeting</td>
                                            <td>28-Mar-2020</td>
                                            <td>1234</td>
                                            <td>1000</td>
                                            <td>1000</td>
                                            <td>Correct</td>

                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>05-Mar-2020</td>
                                            <td>HMC Meeting</td>
                                            <td>28-Mar-2020</td>
                                            <td>1234</td>
                                            <td>1000</td>
                                            <td>1000</td>
                                            <td>Correct</td>

                                        </tr>

                                    </tbody>

                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            // <form onSubmit={this.onSubmit}>
            //     <div className="card mt-3">
            //         <div className="card-header">
            //             Master detail
            //                         </div>
            //         <div className="card-body">
            //             <div className="row">
            //                 <div className="col-lg-12 form-group">
            //                     <label>Name *</label>
            //                     <input
            //                         type="text"
            //                         className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
            //                         placeholder="Name of the employee"
            //                         name={name.name}
            //                         value={name.value}
            //                         onChange={this.onChange}
            //                     />

            //                 </div>
            //             </div>
            //             <div className="row">
            //                 <div className="col-lg-6">
            //                     <div className=" form-group">
            //                         <label>Mobile number *</label>
            //                         <NumberFormat
            //                             maxLength={10}
            //                             allowNegative={false}
            //                             className={mobile.error.length > 0 ? "form-control is-invalid" : "form-control"}
            //                             placeholder="Enter mobile number"
            //                             name={mobile.name}
            //                             value={mobile.value}
            //                             onChange={this.onChange}
            //                         />
            //                         <small>* Will also be the password during inital login</small>
            //                     </div>
            //                 </div>
            //                 <div className="col-lg-6">
            //                     <div className="form-group">
            //                         <label>Email</label>
            //                         <input
            //                             type="email"
            //                             className={email.error.length > 0 ? "form-control is-invalid" : "form-control"}
            //                             placeholder="Login email of the employee"
            //                             name={email.name}
            //                             value={email.value}
            //                             onChange={this.onChange}
            //                         />
            //                     </div>
            //                 </div>
            //                 {/* <div className="col-lg-4">
            //                     <div className="form-group">
            //                         <label>Role*</label>
            //                         <select
            //                             className={roleId.error.length > 0 ? "form-control is-invalid" : "form-control"}
            //                             name={roleId.name}
            //                             value={roleId.value}
            //                             onChange={this.onChange}
            //                         >
            //                             <option value="">Select role</option>
            //                             {
            //                                 roleId.options.map((item: any, index: number) => {
            //                                     return (
            //                                         <option value={item.id} key={index}>{item.name}</option>
            //                                     )
            //                                 })
            //                             }
            //                         </select>
            //                     </div>
            //                 </div> */}
            //                 <div className="col-lg-8">
            //                     <div className="form-group">
            //                         <label>is onboard? *</label>
            //                         <select
            //                             className={isOnboard.error.length > 0 ? "form-control is-invalid" : "form-control"}
            //                             name={isOnboard.name}
            //                             value={isOnboard.value}
            //                             onChange={this.onChange}
            //                         >
            //                             <option value="true">Yes</option>
            //                             <option value="false">No</option>
            //                         </select>
            //                     </div>
            //                 </div>
            //                 <div className="col-lg-4">
            //                     <div className="form-group">
            //                         <label>login status*</label>
            //                         <select
            //                             className={loginStatusId.error.length > 0 ? "form-control is-invalid" : "form-control"}
            //                             name={loginStatusId.name}
            //                             value={loginStatusId.value}
            //                             onChange={this.onChange}
            //                         >
            //                             <option value="">Select login status</option>
            //                             {
            //                                 loginStatusId.options.map((item: any, index: number) => {
            //                                     return (
            //                                         <option value={item.id} key={index}>{item.name}</option>
            //                                     )
            //                                 })
            //                             }
            //                         </select>
            //                     </div>
            //                 </div>

            //             </div>
            //         </div>
            //     </div>
            //     <div className="col-lg-2 pull-right pr-0 pl-0">
            //         <div className="row">

            //             <div className="col-lg-12 pull-right mt-3 mb-3">
            //                 <button type="submit" className="btn btn-primary btn-sm">Save & Continue</button>
            //             </div>
            //         </div>
            //     </div>
            // </form>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    private _getJsonToPOST(state: any) {
        const stateData = JSON.parse(JSON.stringify(state));

    }

    onSubmit = (e: any) => {

    }
}

export default EmployeeMaster;
