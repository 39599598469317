import * as axios from 'axios';
import CONFIG from './../config';
import { getAuthHeader, getUploadFileHeader } from '../utils';

export const addReimbursementDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/reimbursement/add`;
    return axios.post(url, model, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const uploadBill = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/reimbursement/bill`;
    return axios.post(url, model, getUploadFileHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getReimbursementList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/reimbursement/list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getReimbursementDetail = (id: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/reimbursement/detail?id=${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getTeamReimbursementList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/reimbursement/team`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

// export const updateSalaryProfile: any = (model: any) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/v1/payroll/salary-profile`;
//     return axios.put(url, model, getAuthHeader())
//         .then((response: any) => {
//             return response.data;
//         });
// }

