import * as axios from 'axios';
import CONFIG from './../config';
import { getAuthHeader, getUploadFileHeader } from '../utils';


export const getCompanyCalenderList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/company-calendar/list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const addCompanyCalender = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/company-calendar/add`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateCompanyCalender = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/company-calendar/update`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getCompanyCalendarDetail = (id: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/company-calendar/detail?id=${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getHolidayType = (reqObj: any, page: any, limit: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/holiday-type/list?p=${page}&l=${limit}`;
    return axios.get(url, { reqObj, ...getAuthHeader() })
        .then((response: any) => {
            return response.data;
        });
}

export const getHolidayList = (year: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/company-calendar/holiday?y=${year}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
