import React, { Component } from 'react'
import Chart from "chart.js";
// import classes from "./LineGraph.module.css";

export default class StackedBarGraph extends Component<any, any> {
    chartRef: any = React.createRef();

    componentDidMount() {
        const myChartRef = this.chartRef.current.getContext("2d");

        new Chart(myChartRef, {
            type: "bar",
            data: {
                datasets: [
                    {
                        label: 'Available',
                        data: [25, 5],
                        backgroundColor: '#2ecc71' // green
                    },
                    {
                        label: 'Returned',
                        data: [20, 6],
                        backgroundColor: '#f1c40f' // yellow
                    },
                    {
                        label: 'Assigned',
                        data: [11, 3],
                        backgroundColor: '#e74c3c' // red
                    },
                ],
                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: [
                    'Mar 2019',
                    'Apr 2019',
                    'May 2019',
                    'Jun 2019',
                    'Jul 2019',
                    'Aug 2019',
                    'Sep 2019',
                    'Oct 2019',
                    'Nov 2019',
                    'Dec 2019',
                ],
                options: {
                    scales: {
                        xAxes: [{
                            stacked: true
                        }],
                        yAxes: [{
                            stacked: true
                        }]
                    }
                }
            },
        });
    }
    render() {
        return (
            <div >
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        )
    }
}