import * as React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import UserMenu from '../../component/employee/Menu';
import Footer from '../../component/common/Footer';
import CallDetail from './../../component/employee/CallDetail'
import Loader from '../../component/common/Loader';
import Shimmer from '../../component/common/Shimmer';
import CONSTANT from '../../constant';
import { getSessionHistory } from './../../action/EmployeeAction';
import { getCookie, getDateParts, getStorage, getLocalStorageItem } from '../../utils';
import moment from 'moment';


class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const isSelf = props.match.params.id ? false : true;
        const userId = props.match.params.id ? props.match.params.id : getStorage(CONSTANT.keys.userDetail).id;
        this.state = {
            page: 1,
            limit: 100,
            userId,
            isSelf,
            showLoader: false,
            list: undefined
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Session History
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { userId, isSelf, list, detail } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
                    <button className="kt-app__aside-close" id="kt_user_profile_aside_close">
                        <i className="la la-close"></i>
                    </button>
                    <UserMenu userId={userId} isSelf={isSelf} />

                    <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="kt-portlet">
                                    <form className="kt-form kt-form--label-right">
                                        <div className="kt-portlet__body">
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '3%' }} scope="col">#</th>
                                                        <th scope="col">Dated</th>
                                                        <th scope="col">IP</th>
                                                        <th scope="col">Attachment</th>
                                                        <th scope="col">URL</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        list === undefined && <tr>
                                                            <td className="text-center" colSpan={4}>Loading...</td>
                                                        </tr>
                                                    }
                                                    {
                                                        list !== undefined && list.length === 0 && <tr>
                                                            <td className="text-center" colSpan={4}>No records found</td>
                                                        </tr>
                                                    }
                                                    {
                                                        list !== undefined && list.map((item: any, ind: number) => {
                                                            const dt = getDateParts(item.createdOn);
                                                            const ud = getLocalStorageItem(CONSTANT.keys.userDetail);
                                                            const fileURL = `${process.env.REACT_APP_BASE_URL}/session/${ud.sc}/${item.createdBy}/${item.fileName}`;
                                                            return (
                                                                <tr key={ind}>
                                                                    <td>{ind + 1}</td>
                                                                    <td>{`${dt.date} ${dt.monthName} ${dt.year} ${dt.hour}:${dt.minute}:${dt.seconds} ${dt.amOrpm}`}</td>
                                                                    <td>{item.createdAt}</td>
                                                                    <td><a href={fileURL} target="_blank">Download</a></td>
                                                                    <td title={item.url} style={{ overflow: 'hidden' }}>{item.url}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }

    loadList = () => {
        const { userId, page, limit } = this.state;
        this.setState({ showLoader: true });
        getSessionHistory(userId, page, limit).then((res: any) => {
            this.setState({ showLoader: false, list: res.result.list });
        })
    }

    onPageChange = (e: any) => {
        const page = parseInt(e.target.dataset.page, 10);
        this.setState({ page }, () => {
            this.loadList();
        });
    }
}

export default EmployeeMaster;
