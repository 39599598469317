import * as React from 'react';
import NumberFormat from 'react-number-format';
import { CustomInput } from 'reactstrap';
// import { getCompanyBranchList } from '../../action/CompanyBranchAction';
import { getAllEmployeeList } from '../../action/EmployeeAction';
import { onChange, validateForm, setOptions } from '../../utils';

class AddITDetail extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            userId: props.userId,
            employeeId: { value: detail ? detail.employeeId : '', name: 'employeeId', error: '', isRequired: true },
            designation: { value: detail ? detail.designation : '', name: 'designation', error: '', isRequired: true },
            companyBranchId: { value: detail ? detail.companyBranchId : '', name: 'companyBranchId', error: '', isRequired: false, options: [] },
            dateOfJoining: { value: detail ? detail.dateOfJoining.substr(0, 10) : '', name: 'dateOfJoining', error: '', isRequired: true },
            workLocation: { value: detail ? detail.workLocation : '', name: 'workLocation', error: '', isRequired: true },
            department: { value: detail ? detail.department : '', name: 'department', error: '', isRequired: true },
            reportingManagerId: { value: detail ? detail.reportingManagerId : '', name: 'reportingManagerId', error: '', isRequired: true, options: [] },
        }
    }

    componentDidMount() {
        // getCompanyBranchList().then((res: any) => {
        //     const cbOptions = res.result.map((item: any) => {
        //         return {
        //             id: item.id,
        //             name: item.name,
        //             location: item.locationName
        //         }
        //     });
        //     setOptions(this, this.state.companyBranchId.name, cbOptions);
        // });

        getAllEmployeeList().then((res: any) => {
            const cbOptions = res.result.map((item: any) => {
                return {
                    id: item.id,
                    name: item.name,
                    email: item.email
                }
            });
            setOptions(this, this.state.reportingManagerId.name, cbOptions);
        });
    }

    public render() {
        const { employeeId, designation, companyBranchId, dateOfJoining, workLocation, department, reportingManagerId } = this.state;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="row">
                    <div className="col-xl-12">


                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        PARTICULARS
					                </h3>
                                </div>
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        DECLARED AMOUNT
					                </h3>
                                </div>
                            </div>

                        </div>

                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Is the employee staying in a rented house?
					                </h3>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <div className="kt-portlet__head-actions">
                                        {/* <CustomInput type="switch" checked={true} /> */}

                                        <div className="col-3">
                                            <span className="kt-switch kt-switch--info">
                                                <label>
                                                    <input type="checkbox" name="" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section kt-section--last">
                                    <div className="kt-section__title">House Rent Details</div>

                                    <div className="kt-section__content">

                                        <div id="kt_repeater_1">
                                            <div className="form-group form-group-last row" id="kt_repeater_1">

                                                <div data-repeater-list="" className="col-lg-12">
                                                    <div data-repeater-item="" className="form-group row align-items-center">
                                                        <div className="col-md-6">
                                                            <div className="kt-form__group--inline">
                                                                <label>Rental Period</label>
                                                                <div className="input-group">
                                                                    <input type="month" className="form-control" placeholder="From" aria-describedby="basic-addon1" />
                                                                    <input type="month" className="form-control" placeholder="To" aria-describedby="basic-addon1" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-3">
                                                            <div className="kt-form__group--inline">
                                                                <label>Amount</label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">₹</span>
                                                                    </div>
                                                                    <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                                </div>
                                                            </div>
                                                            <div className="d-md-none kt-margin-b-10"></div>
                                                        </div>
                                                        <div className="col-md-1 mt-4">
                                                            <a href="javascript:;" data-repeater-delete="" className="btn-sm btn btn-label-danger btn-bold">
                                                                <i className="la la-trash-o"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div data-repeater-list="" className="col-lg-12">
                                                    <div data-repeater-item="" className="form-group row align-items-center">
                                                        <div className="col-md-6">
                                                            <div className="kt-form__group--inline">
                                                                <label>Address</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Address" aria-describedby="basic-addon1" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div data-repeater-list="" className="col-lg-12">
                                                    <div data-repeater-item="" className="form-group row align-items-center">
                                                        <div className="col-md-3">
                                                            <div className="kt-form__group--inline">
                                                                <label>Metro / Non Metro</label>
                                                                <select className="form-control kt-selectpicker">
                                                                    <option>Metro</option>
                                                                    <option>Non Metro</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="kt-form__group--inline">
                                                                <label>Landloard Pan</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Landloard Pan" aria-describedby="basic-addon1" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group form-group-last row">
                                                <label className="col-lg-12 col-form-label"></label>
                                                <div className="col-lg-4">
                                                    <a href="javascript:;" data-repeater-create="" className="btn btn-bold btn-sm btn-label-brand">
                                                        <i className="la la-plus"></i> Add Rented House
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>

                        </div>

                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Is the employee repaying home loan for a self occupied house property?
					                </h3>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <div className="kt-portlet__head-actions">
                                        <div className="col-3">
                                            <span className="kt-switch kt-switch--info">
                                                <label>
                                                    <input type="checkbox" name="" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section kt-section--last">

                                    <div className="kt-section__content">
                                        <form className="kt-form">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Principal Paid on Home Loan</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="kt-form__group--inline">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Interest Paid on Home Loan</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="kt-form__group--inline">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Name of the Lender</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="kt-form__group--inline">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Lender PAN	</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="kt-form__group--inline">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>


                                </div>
                            </div>

                        </div>

                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Is the employee receiving rental income from let out property?
					                </h3>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <div className="kt-portlet__head-actions">
                                        <div className="col-3">
                                            <span className="kt-switch kt-switch--info">
                                                <label>
                                                    <input type="checkbox" name="" />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section kt-section--last">

                                    <div className="kt-section__content">
                                        <form className="kt-form">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Annual Rent Received</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="kt-form__group--inline">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Municipal Taxes Paid</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="kt-form__group--inline">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Net Annual Value</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="kt-form__group--inline">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Standard Deduction (@ 30% of Net Annual Value)</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="kt-form__group--inline">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Net Income/Loss from House Property</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="kt-form__group--inline">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group form-group-last row">
                                                <label className="col-lg-12 col-form-label"></label>
                                                <div className="col-lg-4">
                                                    <a href="javascript:;" data-repeater-create="" className="btn btn-bold btn-sm btn-label-brand">
                                                        <i className="la la-plus"></i> Add a Let Out Property
                                                    </a>
                                                </div>
                                            </div>

                                        </form>
                                    </div>


                                </div>
                            </div>

                        </div>

                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <span className="kt-portlet__head-icon">
                                        <i className="flaticon-statistics"></i>
                                    </span>
                                    <h3 className="kt-portlet__head-title">
                                        80C Investments
					                </h3>
                                </div>

                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section kt-section--last">
                                    <div className="form-group form-group-last">
                                        <div className="alert alert-secondary" role="alert">
                                            <div className="alert-icon"><i className="flaticon-warning kt-font-brand"></i></div>
                                            <div className="alert-text">
                                                <strong>Note: </strong> This section contains the list of investments including LIC schemes, mutual funds and PPF. The maximum limit for this section is ₹ 1,50,000.00
							                </div>
                                        </div>
                                    </div>

                                    <div className="kt-section__content">

                                        <div id="kt_repeater_1">
                                            <div className="form-group form-group-last row" id="kt_repeater_1">

                                                <div data-repeater-list="" className="col-lg-12">
                                                    <div data-repeater-item="" className="form-group row align-items-center">
                                                        <div className="col-md-8">
                                                            <div className="kt-form__group--inline">
                                                                <label>Select an Investment</label>
                                                                <select className="form-control kt-selectpicker">
                                                                    <option>Life Insurance Premium</option>
                                                                    <option>Public Provident Fund</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="kt-form__group--inline">
                                                                <label>Amount</label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">₹</span>
                                                                    </div>
                                                                    <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                                </div>
                                                            </div>
                                                            <div className="d-md-none kt-margin-b-10"></div>
                                                        </div>
                                                        <div className="col-md-1 mt-4">
                                                            <a href="javascript:;" data-repeater-delete="" className="btn-sm btn btn-label-danger btn-bold">
                                                                <i className="la la-trash-o"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="form-group form-group-last row">
                                                <label className="col-lg-12 col-form-label"></label>
                                                <div className="col-lg-4">
                                                    <a href="javascript:;" data-repeater-create="" className="btn btn-bold btn-sm btn-label-brand">
                                                        <i className="la la-plus"></i> Add an Investment
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>

                        </div>

                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <span className="kt-portlet__head-icon">
                                        <i className="flaticon-statistics"></i>
                                    </span>
                                    <h3 className="kt-portlet__head-title">
                                        80D Investments
					                </h3>
                                </div>

                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section kt-section--last">
                                    <div className="form-group form-group-last">
                                        <div className="alert alert-secondary" role="alert">
                                            <div className="alert-icon"><i className="flaticon-warning kt-font-brand"></i></div>
                                            <div className="alert-text">
                                                <strong>Note: </strong> This section contains Mediclaim policies for yourself, your children, spouse and parents. The maximum limit for this section is ₹ 1,00,000.00
							                </div>
                                        </div>
                                    </div>

                                    <div className="kt-section__content">

                                        <div id="kt_repeater_1">
                                            <div className="form-group form-group-last row" id="kt_repeater_1">

                                                <div data-repeater-list="" className="col-lg-12">
                                                    <div data-repeater-item="" className="form-group row align-items-center">
                                                        <div className="col-md-8">
                                                            <div className="kt-form__group--inline">
                                                                <label>Select an Investment</label>
                                                                <select className="form-control kt-selectpicker">
                                                                    <option>Life Insurance Premium</option>
                                                                    <option>Public Provident Fund</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="kt-form__group--inline">
                                                                <label>Amount</label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">₹</span>
                                                                    </div>
                                                                    <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                                </div>
                                                            </div>
                                                            <div className="d-md-none kt-margin-b-10"></div>
                                                        </div>
                                                        <div className="col-md-1 mt-4">
                                                            <a href="javascript:;" data-repeater-delete="" className="btn-sm btn btn-label-danger btn-bold">
                                                                <i className="la la-trash-o"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="form-group form-group-last row">
                                                <label className="col-lg-12 col-form-label"></label>
                                                <div className="col-lg-4">
                                                    <a href="javascript:;" data-repeater-create="" className="btn btn-bold btn-sm btn-label-brand">
                                                        <i className="la la-plus"></i> Add an Investment
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>

                        </div>

                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <span className="kt-portlet__head-icon">
                                        <i className="flaticon-statistics"></i>
                                    </span>
                                    <h3 className="kt-portlet__head-title">
                                        Other Investments
					                </h3>
                                </div>

                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section kt-section--last">
                                    <div className="form-group form-group-last">
                                        <div className="alert alert-secondary" role="alert">
                                            <div className="alert-icon"><i className="flaticon-warning kt-font-brand"></i></div>
                                            <div className="alert-text">
                                                <strong>Note: </strong> This section contains Mediclaim policies for yourself, your children, spouse and parents. The maximum limit for this section is ₹ 1,00,000.00
							                </div>
                                        </div>
                                    </div>

                                    <div className="kt-section__content">

                                        <div id="kt_repeater_1">
                                            <div className="form-group form-group-last row" id="kt_repeater_1">

                                                <div data-repeater-list="" className="col-lg-12">
                                                    <div data-repeater-item="" className="form-group row align-items-center">
                                                        <div className="col-md-8">
                                                            <div className="kt-form__group--inline">
                                                                <label>Select an Investment</label>
                                                                <select className="form-control kt-selectpicker">
                                                                    <option>Life Insurance Premium</option>
                                                                    <option>Public Provident Fund</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="kt-form__group--inline">
                                                                <label>Amount</label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">₹</span>
                                                                    </div>
                                                                    <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                                </div>
                                                            </div>
                                                            <div className="d-md-none kt-margin-b-10"></div>
                                                        </div>
                                                        <div className="col-md-1 mt-4">
                                                            <a href="javascript:;" data-repeater-delete="" className="btn-sm btn btn-label-danger btn-bold">
                                                                <i className="la la-trash-o"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="form-group form-group-last row">
                                                <label className="col-lg-12 col-form-label"></label>
                                                <div className="col-lg-4">
                                                    <a href="javascript:;" data-repeater-create="" className="btn btn-bold btn-sm btn-label-brand">
                                                        <i className="la la-plus"></i> Add an Investment
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>

                        </div>

                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <span className="kt-portlet__head-icon">
                                        <i className="flaticon-statistics"></i>
                                    </span>
                                    <h3 className="kt-portlet__head-title">
                                        Other Sources of Income
					                </h3>
                                </div>

                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section kt-section--last">

                                    <div className="kt-section__content">
                                        <form className="kt-form">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Income from other sources</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="kt-form__group--inline">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Interest Earned from Savings Deposit</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="kt-form__group--inline">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <label>Interest Earned from Fixed Deposit</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="kt-form__group--inline">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" className="form-control" aria-label="Text input with checkbox" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model = {
                userId: parseInt(st.userId, 10),
                employeeId: st.employeeId.value,
                designation: st.designation.value,
                department: st.department.value,
                dateOfJoining: st.dateOfJoining.value,
                companyBranchId: parseInt(st.companyBranchId.value, 10),
                workLocation: st.workLocation.value,
                reportingManagerId: parseInt(st.reportingManagerId.value, 10),
            };
            this.props.onSubmit(model);
        }
    }
}

export default AddITDetail;
