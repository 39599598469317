import React from 'react'
import moment from 'moment';
import Chart from "chart.js";

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import BarGraph from './../../component/chart/BarGraph';

import { getTeamLeaveList } from '../../action/LeaveActions'

export default class Leave extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            leaveList: undefined,
            showLoader: false,
            showManageLeaveModal: false,
            leaveDetail: undefined,
            comments: [],
            loadingComments: false,
            balance: null,
            date: moment()
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader, showManageLeaveModal, leaveDetail } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    {this.renderBody()}
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        const { date } = this.state;
        const currYear = date.format('YYYY');

        const prevDate = moment(date.format('YYYY-MM-DD')).subtract(1, 'year');
        const prevYear = prevDate.format('YYYY');

        const nextDate = moment(date.format('YYYY-MM-DD')).add(1, 'year');
        const nextYear = nextDate.format('YYYY');

        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Reimbursement
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {currYear} </span>
                        </div>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href="javascript:;" onClick={this.changeDate} data-year={prevYear} className="btn btn-label-brand btn-bold"><i className="fas fa-angle-double-left mr-1 pe-none" aria-hidden="true"></i>{prevYear}</a>
                        <a href="javascript:;" onClick={this.changeDate} data-year={nextYear} className="btn btn-label-brand btn-bold">{nextYear}<i className="fas fa-angle-double-right ml-1 pe-none" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div >
        )
    }

    renderBody = () => {
        const { leaveList, loadingComments } = this.state;
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body  kt-portlet__body--fit">
                            <div className="row row-no-padding row-col-separator-lg">
                                <div className="col-md-12 col-lg-6 col-xl-4">
                                    <div className="kt-widget24">
                                        <div className="kt-widget24__details">
                                            <div className="kt-widget24__info">
                                                <h4 className="kt-widget24__title">Claimed</h4>
                                            </div>
                                            <span className="kt-widget24__stats kt-font-brand">Rs. 23,000</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-4">
                                    <div className="kt-widget24">
                                        <div className="kt-widget24__details">
                                            <div className="kt-widget24__info">
                                                <h4 className="kt-widget24__title">Approved</h4>
                                            </div>
                                            <span className="kt-widget24__stats kt-font-warning">Rs. 23,000</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-4">
                                    <div className="kt-widget24">
                                        <div className="kt-widget24__details">
                                            <div className="kt-widget24__info">
                                                <h4 className="kt-widget24__title">Rejected</h4>
                                            </div>
                                            <span className="kt-widget24__stats kt-font-danger">Rs. 0</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__body">
                            <div id="kt_table_1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <table className="table table-bordered table-hover ">
                                            <thead>
                                                <tr><th style={{ width: "3%" }}>#</th><th colSpan={2} className="text-center">Claim</th><th colSpan={2} className="text-center">Bill</th><th colSpan={2} className="text-center">Amount</th><th>Comment</th></tr><tr><th></th><th>Date</th><th>Name</th><th>Date</th><th>Number</th><th>Claimed</th><th>Approved</th><th></th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td>1</td><td>05-Mar-2020</td><td>HMC Meeting</td><td>28-Mar-2020</td><td>1234</td><td>1000</td><td>1000</td><td>Correct</td></tr><tr><td>2</td><td>05-Mar-2020</td><td>HMC Meeting</td><td>28-Mar-2020</td><td>1234</td><td>1000</td><td>1000</td><td>Correct</td></tr><tr><td>3</td><td>05-Mar-2020</td><td>HMC Meeting</td><td>28-Mar-2020</td><td>1234</td><td>1000</td><td>1000</td><td>Correct</td></tr><tr><td>4</td><td>05-Mar-2020</td><td>HMC Meeting</td><td>28-Mar-2020</td><td>1234</td><td>1000</td><td>1000</td><td>Correct</td></tr><tr><td>5</td><td>05-Mar-2020</td><td>HMC Meeting</td><td>28-Mar-2020</td><td>1234</td><td>1000</td><td>1000</td><td>Correct</td></tr><tr><td>6</td><td>05-Mar-2020</td><td>HMC Meeting</td><td>28-Mar-2020</td><td>1234</td><td>1000</td><td>1000</td><td>Correct</td></tr><tr><td>7</td><td>05-Mar-2020</td><td>HMC Meeting</td><td>28-Mar-2020</td><td>1234</td><td>1000</td><td>1000</td><td>Correct</td></tr><tr><td>8</td><td>05-Mar-2020</td><td>HMC Meeting</td><td>28-Mar-2020</td><td>1234</td><td>1000</td><td>1000</td><td>Correct</td></tr><tr><td>9</td><td>05-Mar-2020</td><td>HMC Meeting</td><td>28-Mar-2020</td><td>1234</td><td>1000</td><td>1000</td><td>Correct</td></tr><tr><td>10</td><td>05-Mar-2020</td><td>HMC Meeting</td><td>28-Mar-2020</td><td>1234</td><td>1000</td><td>1000</td><td>Correct</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    loadList = () => {
        const { date } = this.state;
        const year = date.format('YYYY');
        getTeamLeaveList(year).then((res: any) => {
            this.setState({ leaveList: res.result });
        })
    }

    changeDate = (e: any) => {
        const year = e.target.dataset.year;
        this.setState({ date: moment(`${year}-01-01`) }, () => {
            this.loadList();
        });
    }
}
