import * as React from 'react';
import moment from 'moment';

import AddHolidayModal from './../../component/_holiday/Add';
import ModalWindow from '../../component/common/ModalWindow'
import { onChange, validateForm } from '../../utils';

class ListAttendance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const days = [];
        for (let i = 0; i <= 6; i++) {
            days.push({ id: i, name: moment.weekdays(i) });
        }

        const masterDetail = props.detail;
        let weekends = [];
        let holidayVal = [];
        let startDate = '';
        let endDate = '';
        let id = undefined;

        if (masterDetail) {
            const { detail, holidays } = masterDetail;
            id = detail.id;
            startDate = moment(detail.startDate).format('YYYY-MM');
            endDate = moment(detail.endDate).format('YYYY-MM');
            weekends = detail.weekends.split(',').map((i: any) => parseFloat(i));
            holidayVal = holidays;
        }

        this.state = {
            id,
            weekends: { name: 'weekends', value: weekends, error: '', isRequired: true, options: days },
            holidays: holidayVal,
            holidayStartDate: { name: 'holidayStartDate', value: startDate, error: '', isRequired: true },
            holidayEndDate: { name: 'holidayEndDate', value: endDate, error: '', isRequired: true },
            showModal: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    public render() {
        return (
            this.renderBody()
        )
    }

    renderBody = () => {
        const { weekends, holidays, showModal, holidayStartDate, holidayEndDate } = this.state;
        const cssClassName = weekends.error.length > 0 ? "card border-danger" : "card";
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className={cssClassName}>
                            <div className="card-header">Select Weekends</div>
                            <div className="card-body">
                                {
                                    weekends.options.map((item: any, index: number) => {
                                        const checked = weekends.value.indexOf(item.id) > -1;
                                        return (
                                            <div className="form-check d-inline-block mr-3" key={index}>
                                                <label className="form-check-label">
                                                    <input
                                                        name={weekends.name}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={item.id}
                                                        onChange={this.onChange}
                                                        checked={checked}
                                                    />
                                                    {item.name}
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="card">
                            <div className="card-header">
                                Holiday Calendar
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start *</label>
                                            <input
                                                type="month"
                                                className={holidayStartDate.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                name={holidayStartDate.name}
                                                value={holidayStartDate.value}
                                                onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>End *</label>
                                            <input
                                                type="month"
                                                className={holidayEndDate.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                name={holidayEndDate.name}
                                                value={holidayEndDate.value}
                                                onChange={this.onChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="card">
                            <div className="card-header">
                                Holidays
                            <a href="#" className="float-right" onClick={this.onShowModal}>Add Holiday</a>
                            </div>
                            <div className="card-body">
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '3%' }} scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Description</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            holidays === undefined && <tr>
                                                <td className="text-center" colSpan={5}>Loading...</td>
                                            </tr>
                                        }
                                        {
                                            holidays !== undefined && holidays.length === 0 && <tr>
                                                <td className="text-center" colSpan={5}>No records found</td>
                                            </tr>
                                        }
                                        {
                                            holidays !== undefined && holidays.map((item: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{moment(item.date).format('DD MMM YYYY')}</td>
                                                        <td>{item.description}</td>
                                                        <td><a href="#" data-id={item.id} onClick={this.deleteHoliday} className="txt-danger">Delete</a></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9"></div>
                    <div className="col-md-3"><button className="btn btn-primary btn-block" onClick={this.onSubmit}>Submit</button></div>
                </div>

                {/* <Pagination /> */}
                {
                    showModal &&
                    <ModalWindow
                        title="Add Holiday"
                        backdrop="static"
                        toggleModal={this.onHideModal}>
                        <AddHolidayModal
                            onSubmit={this.onHolidaySubmit}
                            onCancel={this.onHideModal}
                        />
                    </ModalWindow>
                }

            </React.Fragment>
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;
        const { weekends } = this.state;

        if (name === weekends.name) {
            const isChecked = e.target.checked;
            const parsedValArr = JSON.parse(JSON.stringify(weekends.value));
            const parsedVal = parseFloat(value);
            if (isChecked) {
                parsedValArr.push(parsedVal);
                ;
            } else {
                const valIndex = parsedValArr.indexOf(value);
                parsedValArr.splice(valIndex);
            }
            value = parsedValArr;
        }
        onChange(this, name, value);
    }

    onShowModal = () => {
        this.setState({ showModal: true })
    }

    onHideModal = () => {
        this.setState({ showModal: false })
    }

    onHolidaySubmit = (model: any) => {
        model.id = new Date().getTime();
        this.setState({ holidays: [...this.state.holidays, model] });
        this.onHideModal();
    }

    deleteHoliday = (e: any) => {
        const id = parseFloat(e.target.dataset.id);
        const holidays = this.state.holidays.filter((i: any) => i.id !== id);
        this.setState({ holidays });
    }

    onSubmit = (e: any) => {
        if (validateForm(this)) {
            const model: any = {
                weekend: this.state.weekends.value,
                holidays: this.state.holidays,
                holidayStartDate: this.state.holidayStartDate.value,
                holidayEndDate: this.state.holidayEndDate.value,
            }
            if (this.state.id) {
                model.id = this.state.id;
            }

            this.props.onSubmit(model);
        }
    }
}

export default ListAttendance;
