import * as React from 'react';
import $ from 'jquery';
import moment from 'moment';
import XLSX from 'xlsx';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import PieChart from './../../component/chart/PieChart';
import CONSTANT from './../../constant';

import { getTeamDailyHistory } from '../../action/AttendanceActions';
import { onChange } from '../../utils';

class EmployeeList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            date: moment(),
            present: undefined,
            absent: undefined,
            leave: undefined,
            uniqueRecords: undefined,
            showLoader: false,
            counter: { total: 0, absent: 0, present: 0, leave: 0 }
        }
    }

    componentDidMount() {
        this.loadDetail();
    }

    public render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { date } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Attendance
                        </h3>

                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {date.format('dddd DD MMM YYYY')}
                            </span>
                        </div>

                    </div>
                    <div className="kt-subheader__toolbar">
                        {/* <div className="kt-subheader__wrapper"> */}
                        <button
                            type="button"
                            className="btn btn-primary w-100 mr-3"
                            onClick={this.downloadExcel}>Download Report</button>

                        <input
                            type="date"
                            value={date.format('YYYY-MM-DD')}
                            onChange={this.onChange}
                            className="form-control"
                        />
                        {/* </div> */}
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { present, absent, leave, uniqueRecords, counter } = this.state;

        // let totalEmployeeCount = 0;
        // totalEmployeeCount = present !== undefined ? (present.length + absent.length + leave.length) : 0;

        // let totalEmployeeCount = 0;
        // totalEmployeeCount = present !== undefined ? (present.length + absent.length + leave.length) : 0;

        // let totalEmployeeCount = 0;
        // totalEmployeeCount = present !== undefined ? (present.length + absent.length + leave.length) : 0;

        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Present
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                {/* <!--begin::Section--> */}
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Employee #</th>
                                                    <th>Name</th>
                                                    <th># Check-in</th>
                                                    <th># Check out</th>
                                                    <th>Total hours</th>
                                                    <th>Status</th>
                                                    <th>View Detail</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    present === undefined && <tr>
                                                        <td colSpan={8} className="text-center">Loading...</td>
                                                    </tr>
                                                }
                                                {
                                                    present !== undefined && present.length === 0 && <tr>
                                                        <td colSpan={8} className="text-center">No records found</td>
                                                    </tr>
                                                }
                                                {
                                                    uniqueRecords !== undefined && uniqueRecords.map((userId: any, index: number) => {
                                                        const userAttendance = present.filter((i: any) => i.userId === userId);
                                                        // const checkInDate = moment.unix(userAttendance[0].inTimeUnix)
                                                        const checkIns = userAttendance.filter((i: any) => i.inTimeUnix !== null);
                                                        const checkOuts = userAttendance.filter((i: any) => i.outTimeUnix !== null);
                                                        let workingHoursUnix = 0;
                                                        for (let i = 0; i < userAttendance.length; i++) {
                                                            if (userAttendance[i].outTimeUnix !== null) {
                                                                workingHoursUnix += moment.unix(userAttendance[i].outTimeUnix).diff(moment.unix(userAttendance[i].inTimeUnix));
                                                            }
                                                        }
                                                        const workingHoursDate = moment.duration(workingHoursUnix);

                                                        const detailDivId = `hidden-tr-${index}`;
                                                        return (
                                                            <React.Fragment>
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{userAttendance[0].employeeId}</td>
                                                                    <td>{userAttendance[0].name}</td>
                                                                    <td>{checkIns.length}</td>
                                                                    <td>{checkOuts.length}</td>
                                                                    <td>{`${workingHoursDate.get('hours')}:${workingHoursDate.get('minutes')}:${workingHoursDate.get('seconds')}`} Hrs</td>
                                                                    {
                                                                        checkIns.length > 0 ? <td className="text-success">Present</td> : <td className="text-danger">Absent</td>
                                                                    }
                                                                    <td>
                                                                        <a onClick={(e) => this.expandDetail(e)} data-detail-id={`#${detailDivId}`} href="javascript:;">+</a>
                                                                    </td>
                                                                </tr>
                                                                <tr id={detailDivId} style={{ display: 'none' }}>
                                                                    <td colSpan={8}>
                                                                        <table style={{ width: '100%' }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Type</th>
                                                                                    <th>Source</th>
                                                                                    <th>In time</th>
                                                                                    <th>Out time</th>
                                                                                    <th>Total hours</th>
                                                                                    <th>Location</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    userAttendance.map((ci: any, ciIndex: number) => {
                                                                                        const inTimeUnix = moment.unix(ci.inTimeUnix);
                                                                                        const outTimeUnix = moment.unix(ci.outTimeUnix);
                                                                                        const duration = moment.duration(outTimeUnix.diff(inTimeUnix));
                                                                                        const locationURL = `https://www.google.com/maps/search/?api=1&query=${ci.lat},${ci.lng}`;
                                                                                        return (
                                                                                            <tr key={ciIndex}>
                                                                                                <td>{ci.attendanceTypeName}</td>
                                                                                                <td>{ci.source}</td>
                                                                                                <td>{inTimeUnix.format('hh:mm A')}</td>
                                                                                                <td>{ci.outTimeUnix !== null ? outTimeUnix.format('hh:mm A') : '-'}</td>
                                                                                                <td>{ci.outTimeUnix !== null ?
                                                                                                    `${duration.get('hours')}:${duration.get('minutes')}:${duration.get('seconds')} Hrs` : '-'}</td>
                                                                                                <td>
                                                                                                    <a target="blank" href={locationURL}> View Location </a>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end::Section--> */}
                            </div>
                        </div>

                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Absent
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                {/* <!--begin::Section--> */}
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Employee #</th>
                                                    <th>Name</th>
                                                    <th># Check-in</th>
                                                    <th># Check out</th>
                                                    <th>Total hours</th>
                                                    <th>Status</th>
                                                    <th>View Detail</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    absent === undefined && <tr>
                                                        <td colSpan={8} className="text-center">Loading...</td>
                                                    </tr>
                                                }
                                                {
                                                    absent !== undefined && absent.length === 0 && <tr>
                                                        <td colSpan={8} className="text-center">No records found</td>
                                                    </tr>
                                                }
                                                {
                                                    absent !== undefined && absent.map((item: any, index: number) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.employeeId}</td>
                                                                <td>{item.name}</td>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td className="text-danger">Absent</td>
                                                                <td><a className="float-right" href="javascript:;"></a></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end::Section--> */}
                            </div>
                        </div>

                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        On Leave
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                {/* <!--begin::Section--> */}
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Employee #</th>
                                                    <th>Name</th>
                                                    <th># Check-in</th>
                                                    <th># Check out</th>
                                                    <th>Total hours</th>
                                                    <th>Status</th>
                                                    <th>View Detail</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    leave === undefined && <tr>
                                                        <td colSpan={8} className="text-center">Loading...</td>
                                                    </tr>
                                                }
                                                {
                                                    leave !== undefined && leave.length === 0 && <tr>
                                                        <td colSpan={8} className="text-center">No records found</td>
                                                    </tr>
                                                }
                                                {
                                                    leave !== undefined && leave.map((item: any, index: number) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.employeeId}</td>
                                                                <td>{item.name}</td>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td className="text-danger">On Leave</td>
                                                                <td><a className="float-right" href="javascript:;"></a></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end::Section--> */}
                            </div>
                        </div>

                    </div>
                    <div className="col-md-4">
                        <div className="kt-portlet mb-3">
                            <div className="kt-portlet__body kt-portlet__body--fit">
                                <div className="row row-no-padding row-col-separator-xl">
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                        {/* <!--begin:: Widgets/Stats2-1 --> */}
                                        <div className="kt-widget1">
                                            <div className="kt-widget1__item">
                                                <div className="kt-widget1__info">
                                                    <h3 className="kt-widget1__title">Total Employee</h3>
                                                    <span className="kt-widget1__desc">Number of employees reporting you</span>
                                                </div>
                                                <span className="kt-widget1__number kt-font-brand">{counter.total}</span>
                                            </div>
                                            <div className="kt-widget1__item">
                                                <div className="kt-widget1__info">
                                                    <h3 className="kt-widget1__title">Present</h3>
                                                    <span className="kt-widget1__desc">Employee present on selected date</span>
                                                </div>
                                                <span className="kt-widget1__number kt-font-success">{counter.present}</span>
                                            </div>
                                            <div className="kt-widget1__item">
                                                <div className="kt-widget1__info">
                                                    <h3 className="kt-widget1__title">Absent</h3>
                                                    <span className="kt-widget1__desc">Employee absent on selected date</span>
                                                </div>
                                                <span className="kt-widget1__number kt-font-danger">{counter.absent}</span>
                                            </div>
                                            <div className="kt-widget1__item">
                                                <div className="kt-widget1__info">
                                                    <h3 className="kt-widget1__title">Planned leave</h3>
                                                    <span className="kt-widget1__desc">Employee on planned leave for selected date</span>
                                                </div>
                                                <span className="kt-widget1__number kt-font-warning">{counter.leave}</span>
                                            </div>
                                        </div>
                                        {/* <!--end:: Widgets/Stats2-1 -->             */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        In time statistics
                                    </h3>
                                </div>
                            </div>
                            <PieChart data={[
                                { name: '08:00 - 09:00', value: 10 },
                                { name: '09:00 - 10:00', value: 5 },
                                { name: '10:00 - 11:00', value: 2 }]} />
                        </div> */}
                    </div>
                </div>

                {/* <Pagination /> */}
            </div>
        )
    }

    onChange = (e: any) => {
        const date = moment(e.target.value);
        this.setState({ date }, () => {
            this.loadDetail();
        })
    }

    loadDetail = () => {
        const date = this.state.date.format('YYYY-MM-DD');
        this.setState({
            counter: {
                ...this.state.counter,
                total: 0, absent: 0, present: 0, leave: 0
            }
        });
        getTeamDailyHistory(date).then((res: any) => {

            let uniqueRecords = new Set(res.result.present.map((item: any) => item.userId));
            this.setState({
                uniqueRecords: Array.from(uniqueRecords),
                present: res.result.present,
                absent: res.result.absent,
                leave: res.result.leave,
            });

            const tCount = Array.from(uniqueRecords).length + res.result.absent.length + res.result.leave.length;
            const aCount = res.result.absent.length;
            const pCount = Array.from(uniqueRecords).length;
            const lCount = res.result.leave.length;

            this.setState({
                counter: {
                    ...this.state.counter,
                    total: tCount,
                    absent: aCount,
                    present: pCount,
                    leave: lCount
                }
            });
        })
    }

    changeMonth = (e: any) => {
        const month = e.target.dataset.month;
        const year = e.target.dataset.year;

        this.setState({
            date: moment(`${year}-${month}-01`)
        });
    }

    expandDetail = (e: any) => {
        const detailId = e.target.dataset.detailId;
        const innerText = e.target.innerText;
        $(e.target).html(innerText === '+' ? '-' : '+');
        $(detailId).slideToggle();
    }

    downloadExcel = () => {
        this.setState({ showLoader: true }, () => {

            const { uniqueRecords, absent, date } = this.state;
            let fileNameDT = new Date();
            let filename = `report-${fileNameDT.getTime()}.xlsx`;

            let dataToExport: any = [];
            var ws_name = "Sheet1";

            const present = uniqueRecords.map((userId: any, index: number) => {
                const userAttendance = present.filter((i: any) => i.userId === userId);
                const checkIns = userAttendance.filter((i: any) => i.inTimeUnix !== null);
                const checkOuts = userAttendance.filter((i: any) => i.outTimeUnix !== null);
                let workingHoursUnix = 0;
                for (let i = 0; i < userAttendance.length; i++) {
                    if (userAttendance[i].outTimeUnix !== null) {
                        workingHoursUnix += moment.unix(userAttendance[i].outTimeUnix).diff(moment.unix(userAttendance[i].inTimeUnix));
                    }
                }
                const workingHoursDate = moment.duration(workingHoursUnix);

                dataToExport.push({
                    employeeId: userAttendance[0].employeeId,
                    employeeName: userAttendance[0].name,
                    noOfCheckIns: checkIns.length,
                    noOfCheckOuts: checkOuts.length,
                    workingHours: `${workingHoursDate.get('hours')}:${workingHoursDate.get('minutes')}:${workingHoursDate.get('seconds')}`,
                    status: checkIns.length > 0 ? `Present` : `Absent`,
                    date: moment(date).format('YYYY-MM-DD')
                });
            });

            absent.map(function (item: any) {
                dataToExport.push({
                    employeeId: item.id,
                    employeeName: item.name,
                    noOfCheckIns: 0,
                    noOfCheckOuts: 0,
                    workingHours: `00:00:00`,
                    status: `Absent`,
                    date: moment(date).format('YYYY-MM-DD')
                });
            });

            var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(dataToExport);
            XLSX.utils.book_append_sheet(wb, ws, ws_name);
            XLSX.writeFile(wb, filename);
        });
    }
}

export default EmployeeList;
