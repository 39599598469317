import * as React from 'react';
import { onChange, validateForm, setOptions } from './../../utils';
import { getDesignationList } from '../../action/SettingsActions';
import ModalWindow from '../common/ModalWindow';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            cycleId: { name: 'cycleId', value: detail ? detail.cycleId : '', error: '', isRequired: true, options: [] },
            subCycleId: { name: 'subCycleId', value: detail ? detail.subCycleId : '', error: '', isRequired: true, options: [] },
            kra: [],
            showAddModal: false
        }
    }

    componentDidMount() {
        setOptions(this, this.state.cycleId.name, [{ id: 1, name: 'Appraisal 2020-21' }]);
        setOptions(this, this.state.subCycleId.name, [{ id: 1, name: 'Apr-Jun 2020-21' }])
    }

    public render() {
        const { cycleId, subCycleId, kra, showAddModal } = this.state;

        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-12 form-group">
                            <label>Cycle *</label>
                            <select
                                className={cycleId.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                name={cycleId.name}
                                value={cycleId.value}
                                onChange={this.onChange}
                            >
                                <option>Select a Appraisal cycle</option>
                                {
                                    cycleId.options.map(function (item: any, index: number) {
                                        return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-lg-12 form-group">
                            <label>Sub-Cycle *</label>
                            <select
                                className={subCycleId.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                name={subCycleId.name}
                                value={subCycleId.value}
                                onChange={this.onChange}
                            >
                                <option>Select a sub cycle</option>
                                {
                                    subCycleId.options.map(function (item: any, index: number) {
                                        return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-lg-12 form-group">
                            <label>KRA *</label>
                            <a href="javascript:;" onClick={this.onAddKRA} className="float-right">Add KRA</a>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>KRA</th>
                                        <th>Weightage %</th>
                                        <th>Notes</th>
                                        <th style={{ width: "3%" }} ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        kra.map((item: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.kra}</td>
                                                    <td>{item.weightage}</td>
                                                    <td>{item.notes}</td>
                                                    <td><a href="javascript:;" className="text-danger" onClick={this.onDelete}><i className="fas fa-times" /></a></td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        kra.length === 0 && <tr>
                                            <td colSpan={4} className="text-center">
                                                Click on the "Add KRA" button to add KRA
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-12"><small>* Indicates required fields</small></div>
                    </div >
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-3">
                            <button type="submit" className="btn btn-sm btn-primary btn-block">Submit for Review</button>
                        </div>
                    </div>
                </form >
                {
                    showAddModal && <ModalWindow
                        title="Competency detail"
                        backdrop="static"
                        toggleModal={this.onCancelCompetency}>
                        <CompetencyComponent
                            onSubmit={this.onSubmitCompetency}
                            onCancel={this.onCancelCompetency}
                        />
                    </ModalWindow>
                }
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const model: any = {
                name: this.state.name.value,
                description: this.state.description.value
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }

    onDelete = (e: any) => {

    }

    onAddKRA = () => {
        this.setState({ showAddModal: true });
    }

    onSubmitCompetency = (model: any) => {
        const { kra } = JSON.parse(JSON.stringify(this.state));
        kra.push(model);
        this.setState({ kra, showAddModal: false });
    }

    onCancelCompetency = (e: any) => {
        this.setState({ showAddModal: false });
    }
}

class CompetencyComponent extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            kra: { name: 'kra', value: detail ? detail.kra : '', error: '', isRequired: true },
            weightage: { name: 'weightage', value: detail ? detail.weightage : '', error: '', isRequired: true },
            notes: { name: 'notes', value: detail ? detail.notes : '', error: '', isRequired: false }
        }
    }

    public render() {
        const { kra, weightage, notes } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-12 form-group">
                            <label>KRA *</label>
                            <textarea
                                rows={3}
                                className={kra.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="KRA"
                                name={kra.name}
                                value={kra.value}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="col-lg-12 form-group">
                            <label>Weightage *</label>
                            <input
                                type="number"
                                className={weightage.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Weightage"
                                name={weightage.name}
                                value={weightage.value}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="col-lg-12 form-group">
                            <label>Notes</label>
                            <textarea
                                rows={3}
                                className={notes.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Notes (Optional)"
                                name={notes.name}
                                value={notes.value}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="col-md-12"><small>* Indicates required fields</small></div>
                    </div >
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-3">
                            <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                        </div>
                    </div>
                </form >
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const model: any = {
                kra: this.state.kra.value,
                weightage: this.state.weightage.value,
                notes: this.state.notes.value
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }

    onCancel = (e: any) => {
        this.setState({ showAddModal: false });
    }
}

export default AddComponent;
