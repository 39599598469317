import * as React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import CONSTANT from './../../constant';
import { getEmployeeList } from '../../action/EmployeeAction';
import StackedBarGraph from '../../component/chart/StackedBarChart';
import PieChart from './../../component/chart/PieChart';

class LeaveDashboard extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    public render() {

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Leave Dashboard
                        </h3>
                    </div>
                    {/* <div className="kt-subheader__toolbar">
                        <div className="kt-subheader__wrapper row">

                            <div className="kt-subheader__toolbar">
                                <button type="button" className="btn btn-primary btn-upper" onClick={this.onStarted}>
                                    Getting Started
                                </button>
                            </div>

                        </div>
                    </div> */}
                </div>
            </div>
        )
    }

    renderBody = () => {

        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                
            </div >
        )
    }

}

export default LeaveDashboard;
