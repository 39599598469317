import * as React from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import CONSTANT from '../../constant';

import { onChange, validateForm, setOptions, getLocalStorageItem } from './../../utils';
import { getLeaveTypeList } from '../../action/SettingsActions'
import { getAllEmployeeList } from '../../action/EmployeeAction';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            pendingWith: { name: 'pendingWith', value: '', error: '', isRequired: false, options: [] },
            fromDate: { name: 'fromDate', value: '', error: '', isRequired: true },
            toDate: { name: 'toDate', value: '', error: '', isRequired: true },
            leaveTypeId: { name: 'leaveTypeId', value: '', error: '', isRequired: true, options: [] },
            reason: { name: 'reason', value: '', error: '', isRequired: true, },
            numberOfDays: { name: 'numberOfDays', value: '', error: '', isRequired: true },
        }
    }

    componentDidMount() {
        getLeaveTypeList(1, 100).then((res: any) => {
            setOptions(this, this.state.leaveTypeId.name, res.result);
        });
        const ud = getLocalStorageItem(CONSTANT.keys.userDetail);
        getAllEmployeeList().then((res: any) => {
            setOptions(this, this.state.pendingWith.name, res.result);
        });
    }

    public render() {
        const { pendingWith, fromDate, toDate, leaveTypeId, reason, numberOfDays } = this.state;
        const durationSuggestion = this.leaveDuration();
        return (
            <form onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-lg-4 form-group">
                        <label>Leave type *</label>
                        <select name={leaveTypeId.name}
                            value={leaveTypeId.value}
                            onChange={this.onChange}
                            className={leaveTypeId.error.length > 0 ? "form-control is-invalid" : "form-control"}>
                            <option value=''>Select leave type</option>
                            {
                                leaveTypeId.options.map((item: any, index: number) => {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })
                            }

                        </select>
                    </div>
                    <div className="col-lg-8 form-group">
                        <label>Recipient *</label>
                        <select
                            className={pendingWith.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            name={pendingWith.name}
                            value={pendingWith.value}
                            onChange={this.onChange}
                        >
                            <option value="">Select</option>
                            {
                                pendingWith.options.map(function (item: any, index: number) {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className="col-lg-4 form-group">
                        <label>From *</label>
                        <input
                            type="date"
                            className={fromDate.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Name of the leave"
                            name={fromDate.name}
                            value={fromDate.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-lg-4 form-group">
                        <label>Till *</label>
                        <input
                            type="date"
                            className={toDate.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Name of the leave"
                            name={toDate.name}
                            value={toDate.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-lg-4 form-group">
                        <label>Number of Days *</label>
                        <NumberFormat
                            decimalScale={1}
                            maxLength={3}
                            allowNegative={false}
                            className={numberOfDays.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                            placeholder=""
                            name={numberOfDays.name}
                            value={numberOfDays.value}
                            onValueChange={(e: any) => { this.onChange({ target: { name: numberOfDays.name, value: e.value } }) }}
                        />
                        {
                            !isNaN(durationSuggestion) && <small>Duration turns out to be {durationSuggestion}</small>
                        }
                    </div>
                    <div className="col-lg-12 form-group">
                        <label>Description *</label>
                        <textarea
                            rows={3}
                            className={reason.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Write description"
                            name={reason.name}
                            value={reason.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-md-12"><small>* Indicates required fields</small></div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-3">
                        <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                        <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                    </div>
                </div>
            </form>
        )
    }

    leaveDuration = () => {
        const { fromDate, toDate } = this.state;
        const fdm = moment(fromDate.value);
        const tdm = moment(toDate.value);
        const diff = moment.duration(tdm.diff(fdm))
        const noOfDays = diff.asDays() + 1;
        return noOfDays;
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const model: any = {
                pendingWith: this.state.pendingWith.value,
                fromDate: this.state.fromDate.value,
                toDate: this.state.toDate.value,
                leaveTypeId: this.state.leaveTypeId.value,
                reason: this.state.reason.value,
                numberOfDays: this.state.numberOfDays.value
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }
}

export default AddComponent;