import * as React from 'react';
import NumberFormat from 'react-number-format';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { Map, Marker as BMarker, APILoader } from '@uiw/react-baidu-map';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { onChange, setOptions, validateForm } from './../../utils';
import { getAllEmployeeList } from './../../action/EmployeeAction';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;

        this.state = {
            id: detail ? detail.id : undefined,
            name: { name: 'name', value: detail ? detail.name : '', error: '', isRequired: true },
            radius: { name: 'radius', value: detail ? detail.radius : '', error: '', isRequired: true },
            employeeIds: { name: 'employeeIds', value: detail ? detail.members : [], error: '', isRequired: true, options: [] },

            map: {
                zoom: 12,
                lat: detail ? parseFloat(detail.lat) : 60.938043,
                lng: detail ? parseFloat(detail.lng) : 30.337157
            },
            marker: {
                lat: detail ? parseFloat(detail.lat) : 60.938043,
                lng: detail ? parseFloat(detail.lng) : 30.337157
            },
            mapType: { name: 'mapType', value: 'google', options: [{ id: 'google', name: 'Google' }, { id: 'baidu', name: 'Baidu' }], error: '', isRequired: false },
        }
    }

    componentDidMount() {
        getAllEmployeeList().then((res: any) => {
            setOptions(this, this.state.employeeIds.name, res.result);
        });
        this.getLocation();
    }

    public render() {
        const { name, radius, employeeIds, mapType } = this.state;
        return (
            <form onSubmit={this.onSubmit} className="row">
                <div className="col-md-10"></div>
                <div className="col-md-2">
                    <select
                        name={mapType.name}
                        value={mapType.value}
                        onChange={this.onChange}
                        className="form-control"
                    >
                        {
                            mapType.options.map((i: any) => {
                                return (<option value={i.id}>{i.name}</option>)
                            })
                        }
                    </select>
                </div>
                <div className="col-lg-12 form-group mt-1">
                    {
                        mapType.value === 'google' ? this.renderGoogleMap() : this.renderBaiduMap()
                    }
                </div>
                <div className="col-lg-12 form-group">
                    <label>Name *</label>
                    <input
                        type="text"
                        className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Name of the group"
                        name={name.name}
                        value={name.value}
                        onChange={this.onChange}
                    />
                </div>
                <div className="col-lg-12 form-group">
                    <label>Radius (in meters) *</label>
                    <NumberFormat
                        className={radius.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Radius to be coverd around the marker"
                        name={radius.name}
                        allowNegative={false}
                        value={radius.value}
                        onChange={this.onChange}
                        maxLength={5}
                    />
                </div>
                <div className="col-lg-12 form-group">
                    <label>Memberes of the group *</label>
                    <Typeahead
                        id="ta-employee-ids"
                        allowNew={false}
                        labelKey={(option: any) => `${option.name}`}
                        name={employeeIds.name}
                        selected={employeeIds.value}
                        multiple={true}
                        options={employeeIds.options}
                        onChange={(e: any) => this.typeaheadOnChange(employeeIds.name, e)}
                        placeholder="List of employees"
                        isInvalid={employeeIds.error.length > 0} />
                </div>

                <div className="col-md-12"><small>* Indicates required fields</small></div>
                <div className="col-md-3 mt-3">
                    <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                    <button type="submit" className="btn btn-sm btn-primary btn-block">Save Changes</button>
                </div>
            </form >
        )
    }

    renderBaiduMap = () => {
        const { map, marker } = this.state;
        return (
            <div style={{ width: '100%', height: '300px' }}>
                <APILoader akay="GTrnXa5hwXGwgQnTBG28SHBubErMKm3f">
                    <Map
                        zoom={map.zoom}
                        center={{
                            lat: map.lat,
                            lng: map.lng
                        }}
                        widget={['GeolocationControl', 'NavigationControl']}
                        onClick={this.baiduMapClick}
                    >
                        <BMarker
                            ref={this.baiduMarkerRef}
                            enableDragging={true}
                            position={{ lat: marker.lat, lng: marker.lng }}
                        />
                    </Map>
                </APILoader>
            </div>
        )
    }

    baiduMapClick = ({ type, target, point, pixel, overlay }: any) => {
        const { lat, lng } = point;
        this.setState({
            marker: { ...this.state.marker, lat, lng },
            map: { ...this.state.map, lat, lng },
        });
    }

    baiduMarkerRef = (props: any) => {
        if (props && props.marker) {
            props.marker.removeEventListener('dragend', this.baiduDragendHandle);
            props.marker.addEventListener('dragend', this.baiduDragendHandle);
        }
    }

    baiduDragendHandle = ({ type, target, pixel, point }: any) => {
        const { lat, lng } = point;
        this.setState({
            marker: { ...this.state.marker, lat, lng },
            map: { ...this.state.map, lat, lng },
        });
    }

    renderGoogleMap = () => {
        const { map, marker } = this.state;
        return (<LoadScript
            id="script-loader"
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API}
        >
            <GoogleMap mapContainerStyle={{
                height: "400px",
                width: "100%"
            }}
                zoom={map.zoom}
                center={{
                    lat: map.lat,
                    lng: map.lng
                }}
                onClick={this.googleMapClick}
            >
                <Marker
                    draggable={true}
                    onDragEnd={this.googleMarkerDragEnd}
                    position={{ lat: marker.lat, lng: marker.lng }}
                />
            </GoogleMap>
        </LoadScript>)
    }

    googleMapClick = (e: any) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();


        this.setState({
            marker: { ...this.state.marker, lat, lng },
            map: { ...this.state.map, lat, lng },
        });
    }

    googleMarkerDragEnd = (e: any) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();


        this.setState({
            marker: { ...this.state.marker, lat, lng },
            map: { ...this.state.map, lat, lng },
        });
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }



    getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.updateMarkerPosition);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }

    updateMarkerPosition = (e: any) => {
        const lat = e.coords.latitude;
        const lng = e.coords.longitude


        this.setState({
            marker: { ...this.state.marker, lat, lng },
            map: { ...this.state.map, lat, lng },
        });
    }

    // onMapChange = (e: any) => {
    //     this.setState({
    //         center: e.center,
    //         zoom: e.zoom,
    //     });
    // }

    // onCircleInteraction = (childKey, childProps, mouse) => {
    //     // function is just a stub to test callbacks
    //     this.setState({
    //         draggable: false,
    //         lat: mouse.lat,
    //         lng: mouse.lng
    //     });

    //     console.log('onCircleInteraction called with', childKey, childProps, mouse);
    // }

    // onCircleInteraction3 = (childKey, childProps, mouse) => {
    //     this.setState({ draggable: true });
    //     // function is just a stub to test callbacks
    //     console.log('onCircleInteraction called with', childKey, childProps, mouse);

    // }
    // _onChange = ({ center, zoom }) => {
    //     this.setState({
    //         center: center,
    //         zoom: zoom,
    //     });
    // }

    // onMapMouseMove = (childKey: any, childProps: any, mouse: any) => {
    //     // function is just a stub to test callbacks
    //     this.setState({
    //         draggable: false,
    //         lat: mouse.lat,
    //         lng: mouse.lng
    //     });

    //     console.log('onCircleInteraction called with', childKey, childProps, mouse);
    //     //
    // }

    // onMapMouseUp = (childKey: any, childProps: any, mouse: any) => {
    //     this.setState({ draggable: true });
    //     // function is just a stub to test callbacks  
    //     console.log('onCircleInteraction called with', childKey, childProps, mouse);
    // }

    typeaheadOnChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    }



    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const model: any = {
                name: this.state.name.value,
                radius: this.state.radius.value,
                employeeIds: this.state.employeeIds.value.map((e: any) => e.id),
                lat: this.state.marker.lat,
                lng: this.state.marker.lng
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }
}

export default AddComponent;
