import * as React from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import CONSTANT from './../../constant';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';

import { getLeaveTypeList } from '../../action/SettingsActions'
import { getDateParts } from '../../utils';

class ListLeave extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 20,
            count: 0
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadList()
    }

    quotaTypeFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        if (cell)
            return 'Accrued'
        else
            return 'Preassigned'
    }

    accrualCycleDaysFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        if (cell)
            return cell + ' Days'
        else
            return 'NA'
    }

    accrualRateFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        if (cell)
            return cell + ' Day(s)/cycle'
        else
            return 'NA'
    }

    modifiedOnFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        if (cell)
            return getDateParts(cell).relativeTime;
    }

    preassignedQuotaFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return cell + ' Days'
    }

    public render() {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Leave type
                        </h3>

                        {/* <span className="kt-subheader__separator kt-subheader__separator--v"></span> */}

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            {/* <span className="kt-subheader__desc" id="kt_subheader_total">
                                {`${currMonthName} ${currYear}`}
                            </span> */}
                        </div>

                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href={CONSTANT.url.addLeaveType} className="btn btn-label-brand btn-bold">Add leave type</a>
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { list, page, limit, count } = this.state;


        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            {/* <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Time log
					                                </h3>
                                </div>
                            </div> */}
                            <div className="kt-portlet__body">
                                {/* <!--begin::Section--> */}
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Modified by</th>
                                                    <th scope="col">Modified on</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list === undefined && <tr>
                                                        <td className="text-center" colSpan={4}>Loading...</td>
                                                    </tr>
                                                }
                                                {
                                                    list !== undefined && list.length === 0 && <tr>
                                                        <td className="text-center" colSpan={4}>No records found</td>
                                                    </tr>
                                                }
                                                {
                                                    list !== undefined && list.map((item: any, index: number) => {
                                                        const modOn = getDateParts(item.modifiedOn);
                                                        const url = CONSTANT.url.editLeaveType.replace(':id', item.id);
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.name}</td>
                                                                <td>{`${item.createdByName}`}</td>
                                                                <td>{`${modOn.relativeTime}`}</td>
                                                                <td>
                                                                    <a href={url}>
                                                                        <i className="fas fa-edit" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>

                                        {/* <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Day</th>
                                                    <th>Date</th>
                                                    <th># Check-in</th>
                                                    <th># Check out</th>
                                                    <th>Total hours</th>
                                                    <th>Status</th>
                                                    <th>View Detail</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Mon</td>
                                                    <td>01 Mar 2020</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>09:00 Hours</td>
                                                    <td className="text-success">Present</td>
                                                </tr>
                                                <tr id="detail-1" style={{ display: 'none' }}>
                                                    <td colSpan={8}>
                                                        <table style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Type</th>
                                                                    <th>Source</th>
                                                                    <th>In time</th>
                                                                    <th>Out time</th>
                                                                    <th>Total hours</th>
                                                                    <th>Location</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Attendance</td>
                                                                    <td>Mobile</td>
                                                                    <td>09:00</td>
                                                                    <td>18:00</td>
                                                                    <td>09:00</td>
                                                                    <td><a href="#" target="_blank">View Location</a></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Tue</td>
                                                    <td>02 Mar 2020</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>09:00 Hours</td>
                                                    <td className="text-success">Present</td>
                                                    <td>
                                                        <a href="javascript:;">+</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Wed</td>
                                                    <td>03 Mar 2020</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>09:00 Hours</td>
                                                    <td className="text-success">Present</td>
                                                    <td>
                                                        <a href="javascript:;">+</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Thu</td>
                                                    <td>03 Mar 2020</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>09:00 Hours</td>
                                                    <td className="text-success">Present</td>
                                                    <td>
                                                        <a href="javascript:;">+</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Fri</td>
                                                    <td>03 Mar 2020</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>09:00 Hours</td>
                                                    <td className="text-success">Present</td>
                                                    <td>
                                                        <a href="javascript:;">+</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>Sat</td>
                                                    <td>03 Mar 2020</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>09:00 Hours</td>
                                                    <td className="text-success">Present</td>
                                                    <td>
                                                        <a href="javascript:;">+</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>Sun</td>
                                                    <td>03 Mar 2020</td>
                                                    <td className="text-center" colSpan={5}>Week off</td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>Mon</td>
                                                    <td>03 Mar 2020</td>
                                                    <td className="text-center" colSpan={5}>Holiday (Holi)</td>
                                                </tr>
                                            </tbody>
                                        </table> */}
                                    </div>
                                </div>
                                {/* <!--end::Section--> */}
                            </div>
                            {/* <!--end::Form--> */}
                        </div>
                    </div>
                </div>

                {/* <Pagination /> */}
            </div>
        )
    }

    // public render() {
    //     const { list, page, limit, count } = this.state;

    //     const rowStyle = (row: any, rowIndex: any) => {
    //         if (rowIndex % 2 === 0) {
    //             return {
    //                 backgroundColor: '#efefef'
    //             };
    //         }
    //         return {
    //             backgroundColor: '#ffffff'
    //         };
    //     }
    //     const columns = [
    //         {
    //             dataField: 'name',
    //             text: 'Name',
    //             style: {
    //                 fontSize: '14px'
    //             }
    //         },
    //         {
    //             dataField: 'accrual',
    //             text: 'Quota Type',
    //             style: {
    //                 fontSize: '14px'
    //             },
    //             formatter: this.quotaTypeFormatter
    //         },
    //         {
    //             dataField: 'accrualRate',
    //             text: 'Accrual Rate',
    //             style: {
    //                 fontSize: '14px'
    //             },
    //             formatter: this.accrualRateFormatter
    //         },
    //         {
    //             dataField: 'accrualCycleDays',
    //             text: 'Accrual Cycle',
    //             style: {
    //                 fontSize: '14px'
    //             },
    //             formatter: this.accrualCycleDaysFormatter
    //         },
    //         {
    //             dataField: 'preassignedQuota',
    //             text: 'Preassigned Quota',
    //             style: {
    //                 fontSize: '14px'
    //             },
    //             formatter: this.preassignedQuotaFormatter
    //         },
    //         {
    //             dataField: 'createdByName',
    //             text: 'Modified By',
    //             style: {
    //                 fontSize: '14px'
    //             }
    //         },
    //         {
    //             dataField: 'modifiedOn',
    //             text: 'Modified On',
    //             style: {
    //                 fontSize: '14px'
    //             },
    //             formatter: this.modifiedOnFormatter
    //         },
    //     ]

    //     return (
    //         <React.Fragment>
    //             <Header />
    //             <div className="fluid-container px-0">
    //                 <div className="row">
    //                     <Sidebar />
    //                     <div className="col-lg-11 my-3">
    //                         <div className="card">
    //                             <div className="card-header">
    //                                 <div className="pull-left">
    //                                     Leave type
    //                                 </div>
    //                                 <div className="pull-right">
    //                                     <Link to={'CONSTANT.url.addLeaveType'} className="common-btn">
    //                                         <i className="fa fa-plus" />&nbsp;Add leave type
    //                                     </Link>
    //                                 </div>
    //                             </div>
    //                             <div className="card-body">
    //                                 {list !== undefined && <PaginationProvider
    //                                     pagination={
    //                                         paginationFactory({
    //                                             custom: true,
    //                                             page,
    //                                             sizePerPage: limit,
    //                                             totalSize: count,
    //                                             showTotal: true,
    //                                             pageStartIndex: 1,
    //                                             firstPageText: 'First',
    //                                             prePageText: 'Back',
    //                                             nextPageText: 'Next',
    //                                             lastPageText: 'Last',
    //                                             nextPageTitle: 'First page',
    //                                             prePageTitle: 'Pre page',
    //                                             firstPageTitle: 'Next page',
    //                                             lastPageTitle: 'Last page',
    //                                             withFirstAndLast: true,
    //                                             alwaysShowAllBtns: true
    //                                         })
    //                                     }
    //                                 >
    //                                     {
    //                                         ({
    //                                             paginationProps,
    //                                             paginationTableProps
    //                                         }: any) => (
    //                                                 <div>
    //                                                     <BootstrapTable
    //                                                         remote
    //                                                         keyField="id"
    //                                                         data={list}
    //                                                         columns={columns}
    //                                                         rowStyle={rowStyle}
    //                                                         onTableChange={this.onTableChange}
    //                                                         {...paginationTableProps}
    //                                                     />
    //                                                     <div>
    //                                                         <PaginationListStandalone
    //                                                             {...paginationProps}
    //                                                         />
    //                                                     </div>
    //                                                 </div>
    //                                             )
    //                                     }
    //                                 </PaginationProvider>}
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </React.Fragment >
    //     )
    // }

    loadList = () => {
        const { page, limit } = this.state;
        getLeaveTypeList(page, limit).then((res: any) => {
            this.setState({
                list: res.result
            })
        }).catch((err: any) => {
            console.log(err);
        })

        // getLeaveTypeCount().then((res: any) => {
        //     this.setState({
        //         count: res.result[0].count
        //     })
        // }).catch((err: any) => {
        //     console.log(err);
        // })
    }

    onTableChange = (type: any, { page, sizePerPage }: any) => {
        if (!isNaN(page))
            getLeaveTypeList(page, sizePerPage).then((response: any) => {
                window.scrollTo(0, 0)
                this.setState({
                    list: response.result,
                    page: page,
                    limit: sizePerPage
                });
            }, (error: any) => {
                alert(error.message);
            });

    }
}

export default ListLeave;
