import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class AddShiftModal extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            modal: true,
            message: props.message
        };
    }

    public render() {
        const { className } = this.props;
        const { modal } = this.state;
        const backdrop = this.props.backdrop ? this.props.backdrop : true;
        return (

            <React.Fragment>
                <div className="modal fade" id="kt_modal_1_2" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add Shift</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <label className="col-form-label col-lg-3 col-sm-12">Shift</label>
                                    <div className="col-lg-9 col-md-9 col-sm-12">
                                        <div className="input-group date">
                                            <input type="text" className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <label className="col-form-label col-lg-3 col-sm-12">From</label>
                                    <div className="col-lg-9 col-md-9 col-sm-12">
                                        <div className="input-group date">
                                            <input type="time" className="form-control" placeholder="Select date" />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <label className="col-form-label col-lg-3 col-sm-12">To</label>
                                    <div className="col-lg-9 col-md-9 col-sm-12">
                                        <div className="input-group date">
                                            <input type="time" className="form-control" placeholder="Select date" id="kt_datepicker_2" />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-form-label col-lg-3 col-sm-12">Shift Allowance</label>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="kt-radio-inline mt-3">
                                            <label className="kt-radio">
                                                <input type="radio" name="radio2" /> Enable
								                <span></span>
                                            </label>
                                            <label className="kt-radio">
                                                <input type="radio" name="radio2" /> Disable
								                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">Submit</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >


            // <div id="kt_modal_KTDatatable_local" className="modal fade" role="dialog" aria-hidden="true">
            //     <div className="modal-dialog modal-xl">
            //         <div className="modal-content" >
            //             <div className="modal-header">
            //                 {/* <h5 className="modal-title">
            //                     Datatable
            // 		            <small>local data source</small>
            //                 </h5> */}
            //                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            //                     <span aria-hidden="true">×</span>
            //                 </button>
            //             </div>
            //             <div className="modal-body">
            //                 <div className="kt-portlet kt-portlet--mobile">

            //                     <div className="kt-portlet__body">
            //                         <div id="kt_table_1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">

            //                             <div className="row"><div className="col-sm-12">
            //                                 <div className="dataTables_scroll">

            //                                     <div className="dataTables_scrollBody" >
            //                                         <table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer" id="kt_table_1" role="grid" aria-describedby="kt_table_1_info">
            //                                             <thead>
            //                                                 <tr role="row" >
            //                                                     <th className="sorting" aria-controls="kt_table_1" >
            //                                                         <div className="dataTables_sizing" >Particulars</div></th><th className="sorting" aria-controls="kt_table_1" >
            //                                                         <div className="dataTables_sizing">Actual</div></th><th className="sorting" aria-controls="kt_table_1" >
            //                                                         <div className="dataTables_sizing">Projection</div></th><th className="sorting" aria-controls="kt_table_1">
            //                                                         <div className="dataTables_sizing">Total</div></th><th className="sorting" aria-controls="kt_table_1">
            //                                                     </th>
            //                                                 </tr>
            //                                             </thead>

            //                                             <tbody>
            //                                                 <tr role="row" className="odd">
            //                                                     <td className="sorting_1">1</td>
            //                                                     <td>61715-075</td>
            //                                                     <td>China</td>
            //                                                     <td>Tieba</td>
            //                                                 </tr>
            //                                             </tbody>

            //                                         </table>
            //                                     </div>
            //                                 </div>
            //                             </div>
            //                             </div>

            //                         </div>

            //                     </div>
            //                 </div>

            //             </div>
            //             {/* <div className="modal-body modal-body-fit">

            //             </div> */}
            //             {/* <div className="modal-footer kt-hidden">
            //                 <button type="button" className="btn btn-clean btn-bold btn-upper btn-font-md" data-dismiss="modal">Close</button>
            //                 <button type="button" className="btn btn-default btn-bold btn-upper btn-font-md">Submit</button>
            //             </div> */}
            //         </div>
            //     </div>
            // </div>

        );
    }

    toggleModal = () => {
        this.setState((prevState: any) => ({ modal: !prevState.modal }));
        this.props.toggleModal();
    }
}

export default AddShiftModal;