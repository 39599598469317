
import * as React from 'react';
import { onChange, validateForm } from './../../utils';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            cycleId: { name: 'cycleId', value: detail ? detail.name : '', options: [{ id: 1, name: 'Yearly Cycle 20-21' }], error: '', isRequired: true },
            name: { name: 'name', value: detail ? detail.name : '', error: '', isRequired: true },
            startDate: { name: 'startDate', value: detail ? detail.startDate : '', error: '', isRequired: true },
            endDate: { name: 'endDate', value: detail ? detail.endDate : '', error: '', isRequired: true },
        }
    }

    public render() {
        const { name, cycleId, startDate, endDate } = this.state;

        return (
            <form onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-lg-12 form-group">
                        <label>Name *</label>
                        <input
                            type="text"
                            className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Name of the cycle"
                            name={name.name}
                            value={name.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-lg-12 form-group">
                        <label>Cycle *</label>
                        <select
                            className={cycleId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            name={cycleId.name}
                            value={cycleId.value}
                            onChange={this.onChange}
                        >
                            <option>Yearly Cycle 20-21</option>
                        </select>
                    </div>
                    <div className="col-lg-12 form-group">
                        <label>Start date *</label>
                        <input
                            type="date"
                            className={startDate.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Name of the leave"
                            name={startDate.name}
                            value={startDate.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-lg-12 form-group">
                        <label>End date *</label>
                        <input
                            type="date"
                            className={endDate.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Name of the leave"
                            name={endDate.name}
                            value={endDate.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-md-12"><small>* Indicates required fields</small></div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-3">
                        <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                        <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                    </div>
                </div>
            </form>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const model: any = {
                name: this.state.name.value,
                description: this.state.description.value
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }
}

export default AddComponent;
