import * as React from 'react';
import XLSX from 'xlsx'
import { UncontrolledTooltip } from 'reactstrap'

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Dropzone from '../../component/common/Dropzone'
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';

import { uploadEmployee } from './../../action/BulkAction';
import { isValidDateFormat } from './../../utils';
import { getEmploymentTypeList } from '../../action/ConfigAction';
import { getAllDesignationList, getAllDepartmentList, getAllGenderList, getAllMaritalStatusList, getAllLocationList } from '../../action/SettingsActions';

const sampleFile = require('./../../assets/excel/employee-bulk-sample.xlsx');

class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            employees: [],
            showLoader: false,
            isExcelValid: false,
            totalCount: 0,
            progressCount: 0,
            disableButton: true,

            employmentType: undefined,
            designationList: undefined,
            departmentList: undefined,

            locationList: undefined,
            genderList: undefined,
            maritalStatusList: undefined,
        }
    }

    componentDidMount() {
        getEmploymentTypeList().then((res: any) => {
            this.setState({ employmentType: res.result });
        });
        getAllDesignationList().then((res: any) => {
            this.setState({ designationList: res.result });
        });
        getAllDepartmentList().then((res: any) => {
            this.setState({ departmentList: res.result });
        });
        getAllGenderList().then((res: any) => {
            this.setState({ genderList: res.result });
        });
        getAllMaritalStatusList().then((res: any) => {
            this.setState({ maritalStatusList: res.result });
        });
        getAllLocationList().then((res: any) => {
            this.setState({ locationList: res.result });
        });

    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            {this.renderBody()}
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {/* {this.contentHeader()} */}

                                {/* {this.renderBody()} */}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>
        )
    }

    contentHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Users
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            {/* <span className="kt-subheader__desc" id="kt_subheader_total">
                                450 Total </span> */}
                            <form className="kt-margin-l-20" id="kt_subheader_search_form">
                                <div className="kt-input-icon kt-input-icon--right kt-subheader__search">
                                    {/* <input type="text" className="form-control" placeholder="Search..." id="generalSearch" /> */}
                                    <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                        <span>
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                    <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero" />
                                                </g>
                                            </svg> */}

                                            {/* <!--<i className="flaticon2-search-1"></i>--> */}
                                        </span>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="kt-subheader__group kt-hidden" id="kt_subheader_group_actions">
                            <div className="kt-subheader__desc"><span id="kt_subheader_group_selected_rows"></span> Selected:</div>
                            <div className="btn-toolbar kt-margin-l-20">
                                <div className="dropdown" id="kt_subheader_group_actions_status_change">
                                    <button type="button" className="btn btn-label-brand btn-bold btn-sm dropdown-toggle" data-toggle="dropdown">
                                        Update Status
                    </button>
                                    <div className="dropdown-menu">
                                        <ul className="kt-nav">
                                            <li className="kt-nav__section kt-nav__section--first">
                                                <span className="kt-nav__section-text">Change status to:</span>
                                            </li>
                                            <li className="kt-nav__item">
                                                <a href="#" className="kt-nav__link" data-toggle="status-change" data-status="1">
                                                    <span className="kt-nav__link-text"><span className="kt-badge kt-badge--unified-success kt-badge--inline kt-badge--bold">Approved</span></span>
                                                </a>
                                            </li>
                                            <li className="kt-nav__item">
                                                <a href="#" className="kt-nav__link" data-toggle="status-change" data-status="2">
                                                    <span className="kt-nav__link-text"><span className="kt-badge kt-badge--unified-danger kt-badge--inline kt-badge--bold">Rejected</span></span>
                                                </a>
                                            </li>
                                            <li className="kt-nav__item">
                                                <a href="#" className="kt-nav__link" data-toggle="status-change" data-status="3">
                                                    <span className="kt-nav__link-text"><span className="kt-badge kt-badge--unified-warning kt-badge--inline kt-badge--bold">Pending</span></span>
                                                </a>
                                            </li>
                                            <li className="kt-nav__item">
                                                <a href="#" className="kt-nav__link" data-toggle="status-change" data-status="4">
                                                    <span className="kt-nav__link-text"><span className="kt-badge kt-badge--unified-info kt-badge--inline kt-badge--bold">On Hold</span></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <button className="btn btn-label-success btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" data-toggle="modal" data-target="#kt_datatable_records_fetch_modal">
                                    Fetch Selected
                </button>
                                <button className="btn btn-label-danger btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_delete_all">
                                    Delete All
                </button>
                            </div>
                        </div>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href="#" className="">
                        </a>
                        <a href={sampleFile} className="btn btn-label-brand btn-bold">
                            Download Sample File </a>
                        {/* <div className="kt-subheader__wrapper">
                            <div className="dropdown dropdown-inline" data-toggle="kt-tooltip-" title="Quick actions" data-placement="left">
                                <a href="#" className="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--success kt-svg-icon--md">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                            <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                            <path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000" />
                                        </g>
                                    </svg>    
                                </a>
                                <div className="dropdown-menu dropdown-menu-fit dropdown-menu-md dropdown-menu-right">
                                    <ul className="kt-nav">
                                        <li className="kt-nav__head">
                                            Add New:
                            <i className="flaticon2-information" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Click to learn more..."></i>
                                        </li>
                                        <li className="kt-nav__separator"></li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-drop"></i>
                                                <span className="kt-nav__link-text">Orders</span>
                                            </a>
                                        </li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-new-email"></i>
                                                <span className="kt-nav__link-text">Members</span>
                                                <span className="kt-nav__link-badge">
                                                    <span className="kt-badge kt-badge--brand kt-badge--rounded">15</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-calendar-8"></i>
                                                <span className="kt-nav__link-text">Reports</span>
                                            </a>
                                        </li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-link"></i>
                                                <span className="kt-nav__link-text">Finance</span>
                                            </a>
                                        </li>
                                        <li className="kt-nav__separator"></li>
                                        <li className="kt-nav__foot">
                                            <a className="btn btn-label-brand btn-bold btn-sm" href="#">More options</a>
                                            <a className="btn btn-clean btn-bold btn-sm kt-hidden" href="#" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Click to learn more...">Learn more</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label"><h3 className="kt-portlet__head-title">Bulk upload Employees</h3></div>
                                {/* <div className="kt-portlet__head-toolbar"><a download={true} href={sampleFile} className="btn btn-clean btn-sm btn-icon btn-icon-md ng-star-inserted"><i className="lflaticon2-file-1"></i>Download Sample Excel File</a></div> */}
                                <div className="kt-portlet__head-toolbar">
                                    <div className="kt-portlet__head-wrapper">
                                        <a download={true} href={sampleFile} className="btn kt-subheader__btn-daterange" id="kt_dashboard_daterangepicker" data-toggle="kt-tooltip" title="" data-placement="left" data-original-title="Select dashboard daterange">
                                            <i className="flaticon-download-1"></i>
                                            <span className="kt-subheader__btn-daterange-title" id="kt_dashboard_daterangepicker_title">Download Sample Excel File</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-portlet__preview">
                                    <div className="kt-section">
                                        <Dropzone
                                            onFilesAdded={this.handleFileOpen}
                                            multiple={true}
                                            disabled={false}
                                        />
                                        <div className="kt-separator kt-separator--dashed"></div>
                                        {this.state.employees.length > 0 ? <table className="table table-striped table-bordered table-hover table-scrollable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Employee Code</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Email ID</th>
                                                    <th scope="col">Mobile No.</th>

                                                    <th scope="col">DOJ</th>
                                                    <th scope="col">Designation</th>
                                                    <th scope="col">Department</th>
                                                    <th scope="col">Work Location</th>
                                                    <th scope="col">Employment Type</th>

                                                    <th scope="col">Gender</th>
                                                    <th scope="col">DOB</th>

                                                    <th scope="col">Father Name</th>
                                                    <th scope="col">Mother Name</th>
                                                    <th scope="col">Nationality</th>
                                                    <th scope="col">Marital Status</th>
                                                    <th scope="col">Blood Group</th>
                                                    <th scope="col">Personal Email</th>

                                                    <th scope="col">Account Number</th>
                                                    <th scope="col">Account Type</th>
                                                    <th scope="col">Bank Name</th>
                                                    <th scope="col">Branch Name</th>
                                                    <th scope="col">Branch Code</th>

                                                    <th scope="col">Is Onboard</th>
                                                    <th scope="col">Login Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderEmployeeTable()}
                                            </tbody>
                                        </table> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="kt-portlet__foot">
                                <div className="kt-form__actions">
                                    <div className="row">
                                        <div className="col-md-9"></div>
                                        <div className="col-md-3">
                                            <button disabled={this.state.disableButton} type="submit" className="btn btn-brand btn-bold btn-block" onClick={this.onUploadEmployee} >Upload Data</button>&nbsp;
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    renderEmployeeTable = () => {
        return this.state.employees.map((item: any, index: number) => {
            const isEmpTypeValid = this.state.employmentType.map((i: any) => i.name.toLowerCase()).indexOf(item['employmentType'].toLowerCase());
            const isDesignationValid = this.state.designationList.map((i: any) => i.name.toLowerCase()).indexOf(item['designation'].toLowerCase());
            const isDepartmentValid = this.state.departmentList.map((i: any) => i.name.toLowerCase()).indexOf(item['department'].toLowerCase());
            const isWorkLocationValid = this.state.locationList.map((i: any) => i.name.toLowerCase()).indexOf(item['workLocation'].toLowerCase());
            const isGenderValid = this.state.genderList.map((i: any) => i.name.toLowerCase()).indexOf(item['gender'].toLowerCase());
            const isMaritalStatusValid = this.state.maritalStatusList.map((i: any) => i.name.toLowerCase()).indexOf(item['maritalStatus'].toLowerCase());

            const isOnboard = item['isOnboard'].toString();
            const loginStatus = item['loginStatus'].toString();
            const status = item['status'];
            const progress = status === 1 ? <span title="Uploaded successfully">👍</span> : <span title="User already exists">👎</span>;

            return (<tr key={index}>
                <td>{index + 1}</td>
                <td>{status !== undefined ? progress : '-'}</td>
                {/* work detail */}
                {item['employeeCode'] === '' || item['employeeCode'] === undefined ? <td id={`employeeCode_${index}`} className="table-danger">{item['employeeCode']} <UncontrolledTooltip target={`employeeCode_${index}`}>Check employee ID!</UncontrolledTooltip></td> : <td>{item['employeeCode']}</td>}
                {item['fullName'] === '' || item['fullName'] === undefined ? <td id={`fullName_${index}`} className="table-danger">{item['fullName']} <UncontrolledTooltip target={`fullName_${index}`}>Check employee name!</UncontrolledTooltip></td> : <td>{item['fullName']}</td>}
                {item['email'] === '' || item['email'] === undefined ? <td id={`emailId_${index}`} className="table-danger">{item['email']} <UncontrolledTooltip target={`emailId_${index}`}>Check employee email!</UncontrolledTooltip></td> : <td>{item['email']}</td>}
                {item['mobile'] === '' || item['mobile'] === undefined ? <td id={`mobileNo_${index}`} className="table-danger">{item['mobile']} <UncontrolledTooltip target={`mobileNo_${index}`}>Check employee mobile number!</UncontrolledTooltip></td> : <td>{item['mobile']}</td>}
                {item['doj(YYYY-MM-DD)'] === '' || item['doj(YYYY-MM-DD)'] === undefined || !isValidDateFormat(item['doj(YYYY-MM-DD)'].toString()) ? <td id={`doj_${index}`} className="table-danger">{item['doj(YYYY-MM-DD)']} <UncontrolledTooltip target={`doj_${index}`}>Check date of joining!</UncontrolledTooltip></td> : <td>{item['doj(YYYY-MM-DD)']}</td>}

                {item['designation'] === '' || item['designation'] === undefined || isDesignationValid === -1 ? <td id={`designation_${index}`} className="table-danger">{item['designation']} <UncontrolledTooltip target={`designation_${index}`}>Check designation!</UncontrolledTooltip></td> : <td>{item['designation']}</td>}
                {item['department'] === '' || item['department'] === undefined || isDepartmentValid === -1 ? <td id={`department_${index}`} className="table-danger">{item['department']} <UncontrolledTooltip target={`department_${index}`}>Check department!</UncontrolledTooltip></td> : <td>{item['department']}</td>}
                {item['workLocation'] === '' || item['workLocation'] === undefined || isWorkLocationValid === -1 ? <td id={`workLocation_${index}`} className="table-danger">{item['workLocation']} <UncontrolledTooltip target={`workLocation_${index}`}>Check work location!</UncontrolledTooltip></td> : <td>{item['workLocation']}</td>}
                {item['employmentType'] === '' || item['employmentType'] === undefined || isEmpTypeValid === -1 ? <td id={`employmentType_${index}`} className="table-danger">{item['employmentType']} <UncontrolledTooltip target={`employmentType_${index}`}>Check employment type!</UncontrolledTooltip></td> : <td>{item['employmentType']}</td>}

                {/* personal detail */}
                {item['gender'] === '' || item['gender'] === undefined || isGenderValid === -1 ? <td id={`gender_${index}`} className="table-danger">{item['gender']} <UncontrolledTooltip target={`gender_${index}`}>Check employee gender!</UncontrolledTooltip></td> : <td>{item['gender']}</td>}
                {item['dob(YYYY-MM-DD)'] === '' || item['dob(YYYY-MM-DD)'] === undefined || !isValidDateFormat(item['dob(YYYY-MM-DD)'].toString()) ? <td id={`dob_${index}`} className="table-danger">{item['dob(YYYY-MM-DD)']} <UncontrolledTooltip target={`dob_${index}`}>Check date of birth!</UncontrolledTooltip></td> : <td>{item['dob(YYYY-MM-DD)']}</td>}
                {item['fatherName'] === '' || item['fatherName'] === undefined ? <td id={`fatherName_${index}`} className="table-danger">{item['fatherName']} <UncontrolledTooltip target={`fatherName_${index}`}>Check father name!</UncontrolledTooltip></td> : <td>{item['fatherName']}</td>}
                {item['motherName'] === '' || item['motherName'] === undefined ? <td id={`motherName_${index}`} className="table-danger">{item['motherName']} <UncontrolledTooltip target={`motherName_${index}`}>Check mother name!</UncontrolledTooltip></td> : <td>{item['motherName']}</td>}
                {item['nationality'] === '' || item['nationality'] === undefined ? <td id={`nationality_${index}`} className="table-danger">{item['nationality']} <UncontrolledTooltip target={`nationality_${index}`}>Check nationality!</UncontrolledTooltip></td> : <td>{item['nationality']}</td>}
                {item['maritalStatus'] === '' || item['maritalStatus'] === undefined || isMaritalStatusValid === -1 ? <td id={`maritalStatus_${index}`} className="table-danger">{item['maritalStatus']} <UncontrolledTooltip target={`maritalStatus_${index}`}>Check marital status!</UncontrolledTooltip></td> : <td>{item['maritalStatus']}</td>}
                {item['bloodGroup'] === '' || item['bloodGroup'] === undefined ? <td id={`bloodGroup_${index}`} className="table-danger">{item['bloodGroup']} <UncontrolledTooltip target={`bloodGroup_${index}`}>Check blood group!</UncontrolledTooltip></td> : <td>{item['bloodGroup']}</td>}
                {item['personalEmail'] === '' || item['personalEmail'] === undefined ? <td id={`personalEmail_${index}`} className="table-danger">{item['personalEmail']} <UncontrolledTooltip target={`personalEmail_${index}`}>Check blood group!</UncontrolledTooltip></td> : <td>{item['personalEmail']}</td>}

                {/* bank detail */}
                {item['accountNo'] === '' || item['accountNo'] === undefined ? <td id={`accountNo_${index}`} className="table-danger">{item['accountNo']} <UncontrolledTooltip target={`accountNo_${index}`}>Check blood group!</UncontrolledTooltip></td> : <td>{item['accountNo']}</td>}
                {item['accountType'] === '' || item['accountType'] === undefined ? <td id={`accountType_${index}`} className="table-danger">{item['accountType']} <UncontrolledTooltip target={`accountType_${index}`}>Check blood group!</UncontrolledTooltip></td> : <td>{item['accountType']}</td>}
                {item['bankName'] === '' || item['bankName'] === undefined ? <td id={`bankName_${index}`} className="table-danger">{item['bankName']} <UncontrolledTooltip target={`bankName_${index}`}>Check blood group!</UncontrolledTooltip></td> : <td>{item['bankName']}</td>}
                {item['branchCode'] === '' || item['branchCode'] === undefined ? <td id={`branchCode_${index}`} className="table-danger">{item['branchCode']} <UncontrolledTooltip target={`branchCode_${index}`}>Check blood group!</UncontrolledTooltip></td> : <td>{item['branchCode']}</td>}
                {item['branchName'] === '' || item['branchName'] === undefined ? <td id={`branchName_${index}`} className="table-danger">{item['branchName']} <UncontrolledTooltip target={`branchName_${index}`}>Check blood group!</UncontrolledTooltip></td> : <td>{item['branchName']}</td>}

                {isOnboard.length === 0 ? <td id={`isOnboard_${index}`} className="table-danger">{item['isOnboard']} <UncontrolledTooltip target={`isOnboard_${index}`}>Check onboarding status!</UncontrolledTooltip></td> : <td>{item['isOnboard']}</td>}
                {loginStatus.length === 0 ? <td id={`loginStatus_${index}`} className="table-danger">{item['loginStatus']} <UncontrolledTooltip target={`loginStatus_${index}`}>Check login staus!</UncontrolledTooltip></td> : <td>{item['loginStatus']}</td>}
            </tr>)
        })
    }

    handleFileOpen = (files: any) => {
        var f = files[0];
        var reader = new FileReader();
        this.setState({ disableButton: false })
        reader.onload = (e: any) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array' });
            var json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
            this.setState({ employees: json, totalCount: json.length })
        }
        reader.readAsArrayBuffer(f);
    }

    onValidateExcelData = () => {
        let valid = true;
        const employeeList = JSON.parse(JSON.stringify(this.state.employees));
        for (var item of employeeList) {
            const isEmpTypeValid = this.state.employmentType.map((i: any) => i.name.toLowerCase()).indexOf(item['employmentType'].toLowerCase());
            const isDesignationValid = this.state.designationList.map((i: any) => i.name.toLowerCase()).indexOf(item['designation'].toLowerCase());
            const isDepartmentValid = this.state.departmentList.map((i: any) => i.name.toLowerCase()).indexOf(item['department'].toLowerCase());
            const isWorkLocationValid = this.state.locationList.map((i: any) => i.name.toLowerCase()).indexOf(item['workLocation'].toLowerCase());
            const isGenderValid = this.state.genderList.map((i: any) => i.name.toLowerCase()).indexOf(item['gender'].toLowerCase());
            const isMaritalStatusValid = this.state.maritalStatusList.map((i: any) => i.name.toLowerCase()).indexOf(item['maritalStatus'].toLowerCase());

            const isOnboard = item['isOnboard'].toString();
            const loginStatus = item['loginStatus'].toString();

            if (item['employeeCode'] === '' || item['employeeCode'] === undefined) {
                valid = false;
                break;
            }

            if (item['fullName'] === '' || item['fullName'] === undefined) {
                valid = false;
                break;
            }

            if (item['mobile'] === '' || item['mobile'] === undefined) {
                valid = false;
                break;
            }

            if (!isValidDateFormat(item['dob(YYYY-MM-DD)'])) {
                valid = false
                break;
            }

            if (!isValidDateFormat(item['doj(YYYY-MM-DD)'])) {
                valid = false
                break;
            }

            if (isEmpTypeValid === -1) {
                valid = false
                break;
            }

            if (isDesignationValid === -1) {
                valid = false
                break;
            }

            if (isDepartmentValid === -1) {
                valid = false
                break;
            }

            if (isWorkLocationValid === -1) {
                valid = false
                break;
            }

            if (isGenderValid === -1) {
                valid = false
                break;
            }

            if (isMaritalStatusValid === -1) {
                valid = false
                break;
            }

            if (isOnboard.length === 0) {
                valid = false;
                break;
            }

            if (loginStatus.length === 0) {
                valid = false;
                break;
            }
        }
        return valid;
    }

    onUploadEmployee = async () => {
        const employees = JSON.parse(JSON.stringify(this.state.employees));
        const isValid = this.onValidateExcelData();
        if (isValid) {
            this.setState({ showLoader: true });
            const xl = employees.map((item: any) => {
                const employmentTypeObj = this.state.employmentType.filter((i: any) => i.name.toLowerCase() === item['employmentType'].toLowerCase())[0];
                const designationObj = this.state.designationList.filter((i: any) => i.name.toLowerCase() === item['designation'].toLowerCase())[0];
                const departmentObj = this.state.departmentList.filter((i: any) => i.name.toLowerCase() === item['department'].toLowerCase())[0];
                const workLocationObj = this.state.locationList.filter((i: any) => i.name.toLowerCase() === item['workLocation'].toLowerCase())[0];
                const genderObj = this.state.genderList.filter((i: any) => i.name.toLowerCase() === item['gender'].toLowerCase())[0];
                const maritalStatusObj = this.state.maritalStatusList.filter((i: any) => i.name.toLowerCase() === item['maritalStatus'].toLowerCase())[0];
                
                return {
                    email: item['email'],
                    name: item['fullName'],
                    mobile: item['mobile'].toString(),
                    genderId: genderObj.id,
                    personalEmail: item['personalEmail'],
                    fatherName: item['fatherName'],
                    motherName: item['motherName'],
                    bloodGroup: item['bloodGroup'],
                    maritalStatusId: maritalStatusObj.id,
                    nationality: item['nationality'],
                    dateOfBirth: item['dob(YYYY-MM-DD)'].toString(),

                    accountNumber: item['accountNo'],
                    accountType: item['accountType'],
                    bankName: item['bankName'],
                    branchCode: item['branchCode'],
                    branchName: item['branchName'],

                    employeeId: item['employeeCode'].toString(),
                    designationId: designationObj.id,
                    departmentId: departmentObj.id,
                    workLocationId: workLocationObj.id,
                    employmentTypeId: employmentTypeObj.id,
                    dateOfJoining: item['doj(YYYY-MM-DD)'].toString(),

                    isOnboard: item['isOnboard'].toString() === '1',
                    loginStatus: item['loginStatus'].toString() === '1',
                }
            });
            this.callAPI(xl);
        } else {
            alert('Please fix the issues with excel before uploading.');
        }

    }

    sleep = (ms: any) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    callAPI = async (parsedEmployeeList: any) => {
        let self = this;
        let countPro = 0;

        for await (const data of parsedEmployeeList) {
            try {
                await self.sleep(1);
                uploadEmployee(data).then((response: any) => {
                    if (response.status) {
                        if (response.status) {
                            const employeeList = this.state.employees.map((item: any) => {
                                if (item.mobile.toString() === response.result.mobile) {
                                    item.status = response.result.status
                                }
                                return item;
                            });
                            this.setState({ employees: employeeList });
                        }
                    }
                    countPro += 1;
                    if (countPro === parsedEmployeeList.length) {
                        self.setState({ showLoader: false });
                    }
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
}

export default EmployeeMaster;
