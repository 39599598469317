import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import CONSTANT from '../../constant';
import BOT from './../../component/chat-bot/Index';
import { addTicket, updateTicket, addHelpdeskTicketStatus, uploadHelpdeskAttachment, getTicketDetail, getTicketTimeline } from '../../action/HelpdeskActions';
import HelpdeskModal from './../../component/helpdesk/HelpdeskUpdateModal';

class HelpdeskTimelineEmp extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            ticketId: '',
            statusId: '',
            pendingWith: '',
            comment: '',
            subject: '',
            description: '',
            timelineData: [],
            isModalOpen: false
        }
    }

    public componentDidMount() {
        const { match: { params } } = this.props;
        // console.log("params code => ", params.code);
        getTicketTimeline(params.code).then((response: any) => {
            if (response.result) {
                this.setState({
                    timelineData: response.result,
                    ticketId: params.code,
                })
            }
        });
    }

    public render() {
        const { timelineData, isModalOpen, statusId, pendingWith } = this.state;

        return (
            <React.Fragment>
                <BOT />
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {timelineData && timelineData.length > 0 &&
                    <HelpdeskModal isModalOpen={isModalOpen} closeModal={this.closeModal} selectedStatus={statusId} selectedAssignTo={pendingWith} updateTicket={this.updateTicket} />
                }
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Timeline
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { timelineData } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="kt-portlet kt-portlet--mobile">
                    {timelineData && timelineData.length == 0 && (
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-12 mt-4 mb-4 text-center">No record found</div>
                            </div>
                        </div>
                    )}

                    {timelineData && timelineData.length > 0 && (

                        <div className="col-lg-12">

                            <div className="card mt-2">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-lg-10">
                                            Ticket History
                                        </div>
                                        {/* <div className="col-lg-2 text-right">
                                            <button className="btn btn-label-brand btn-bold float-right" onClick={this.onTicket}>
                                                Update Ticket
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                        {timelineData && timelineData.length > 0 && timelineData.map((item: any, index: any) =>
                                            <div className="col-lg-12 mb-2">
                                                <div className="row">
                                                    <div className="col-lg-2 timeline-date mb-2">
                                                        {moment(item.modifiedOn).format('DD-MMM-YYYY')}
                                                    </div>
                                                    <span className="dot"></span>
                                                    <span className="line"></span>
                                                    <div className="col-lg-10 timeline-details card px-0, p-3">
                                                        {/* <b className="subject pl-3 mt-1">Attendance Timing Issue</b> */}
                                                        <p className="message pl-3">{item.comment}</p>
                                                        <span className="ticket-status pl-3 pb-2"><b>Status:</b>{` ${item.statusName ? item.statusName : 'Pending'}`}</span>
                                                        <div className="col-lg-12 ticket-details">
                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                    <b>Assign To:</b> {item.pendingWithName ? item.pendingWithName : 'None'}
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <b>Updated by:</b> {item.modifiedByName}
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <b>Updated on: </b>{moment(item.modifiedOn).format('DD-MMM-YYYY HH:mm:ss')}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        )}

                                    </div>
                                </div>
                            </div>


                        </div>

                    )}
                </div>

            </div >
        )
    }

    private onTicket = () => {
        this.setState({ isModalOpen: true });
    }

    private closeModal = () => {
        this.setState({ isModalOpen: false });
    }

    private createTicket = () => {
        addTicket({
            "categoryId": "2",
            "subCategoryId": "4",
            "description": "sdsd",
        }).then((response: any) => {
            // console.log("get sub cat  => ", response);
            if (response.result) {
                // this.setState({ data: response.result })
            }
        });
    }

    private getTicketDetail = () => {
        const { ticketId, } = this.state;
        getTicketDetail(ticketId).then((response: any) => {
            // console.log("update timeline  => ", response);
            if (response.result) {
                this.props.history.push(CONSTANT.url.helpdesk);
            }
        });
    }

    private updateTicket = async (reqObj: any) => {
        // console.log('reqObj update tcket   ', reqObj);
        updateTicket({
            ticketId: this.state.ticketId,
            statusId: reqObj.status,
            pendingWith: reqObj.assignedTo,
            comment: reqObj.comment,
        }).then(async (response: any) => {
            // console.log("update timeline  => ", response);
            if (response.result) {
                for (let i = 0; i < reqObj.attachments.length; i++) {
                    const item = reqObj.attachments[i];
                    const itemModel = { ticketId: this.state.ticketId, attachment: item }
                    await uploadHelpdeskAttachment(itemModel);
                }
                this.setState({ isModalOpen: false });
                this.props.history.push(CONSTANT.url.helpdesk);
            }
        });
    }

    private addTicketStatusList = () => {
        addHelpdeskTicketStatus({
            "name": "Pending",
            "description": ""
        }).then((response: any) => {
            // console.log("get status add  => ", response);
            if (response.result) {
                // this.props.history.push(CONSTANT.url.helpdesk);
            }
        });
    }

    private uploadAttachment = (item: any) => {
        // console.log("get status itteee  => ", item);

        uploadHelpdeskAttachment().then((response: any) => {
            // console.log("get status list  => ", response);
            let formData = new FormData();
            formData.append('ticketId', item.ticketId);
            formData.append('file', item.attachment);
            if (response.result) {
                console.log("file success")
            }
        });
    }
}

export default HelpdeskTimelineEmp;
