import * as React from 'react';
import { Link } from 'react-router-dom';
import CONSTANT from './../../constant';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';

import { getCompOffList } from '../../action/CompensatoryOffActions'
import { getDateParts } from '../../utils';

class ListAttendance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 20,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadList()
    }

    public render() {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Compensatory Off Request
                        </h3>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href={CONSTANT.url.addCompensatoryOff} className="btn btn-label-brand btn-bold">Add New</a>
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                {/* <!--begin::Section--> */}
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '3%' }} scope="col">#</th>

                                                    <th scope="col">Leave Type</th>
                                                    <th scope="col">Attendance Type</th>
                                                    <th scope="col">In Time</th>
                                                    <th scope="col">Out Time</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Modified by</th>
                                                    <th scope="col">Modified on</th>
                                                    <th style={{ width: '3%' }} scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list === undefined && <tr>
                                                        <td className="text-center" colSpan={8}>Loading...</td>
                                                    </tr>
                                                }
                                                {
                                                    list !== undefined && list.length === 0 && <tr>
                                                        <td className="text-center" colSpan={8}>No records found</td>
                                                    </tr>
                                                }
                                                {
                                                    list !== undefined && list.map((item: any, index: number) => {
                                                        const modOn = getDateParts(item.modifiedOn);
                                                        const inTime = getDateParts(item.inTime);
                                                        const outTime = getDateParts(item.outTime);
                                                        const url = CONSTANT.url.compensatoryOffDetail.replace(':id', item.id);
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.leaveTypeName}</td>
                                                                <td>{item.attendanceTypeName}</td>
                                                                <td>{`${inTime.date} ${inTime.monthName} ${inTime.year} ${inTime.hour}:${inTime.minute} ${inTime.amOrpm}`}</td>
                                                                <td>{`${outTime.date} ${outTime.monthName} ${outTime.year} ${outTime.hour}:${outTime.minute} ${outTime.amOrpm}`}</td>
                                                                <td>{item.statusName ? item.statusName : 'Pending'}</td>
                                                                <td>{item.modifiedByName ? item.modifiedByName : '-'}</td>
                                                                <td>{item.modifiedOn ? `${modOn.relativeTime}` : '-'}</td>
                                                                <td>
                                                                    <a href={url}>
                                                                        <i className="fas fa-edit" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end::Section--> */}
                            </div>
                            {/* <!--end::Form--> */}
                        </div>
                    </div>
                </div>

                {/* <Pagination /> */}
            </div>
        )
    }

    loadList = () => {
        const { page, limit } = this.state;
        getCompOffList(page, limit).then((res: any) => {
            this.setState({
                list: res.result
            })
        }).catch((err: any) => {
            console.log(err);
        })
    }
}

export default ListAttendance;
