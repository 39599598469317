import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import { getDateParts } from '../../utils';
import Paging from '../common/Paging';

class EmployeeMaster extends React.Component<any, any> {
    public render() {
        const { list, page, onPageChange } = this.props;
        const mapZoom = 20;
        let mapCenter = {
            lat: 40.854885,
            lng: -88.081807
        }
        const triangleCoords: any = list.map((i: any) => { return { lat: parseFloat(i.lat), lng: parseFloat(i.lng) } });
        if (list.length > 0) {
            mapCenter = {
                lat: parseFloat(list[list.length - 1].lat),
                lng: parseFloat(list[list.length - 1].lng)
            }
        }
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label"><h3 className="kt-portlet__head-title">Location History</h3></div>
                            </div>
                            <div className="kt-portlet__body">
                                <div style={{ height: '100vh', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={process.env.REACT_APP_GOOGLE_MAP_API}
                                        defaultCenter={mapCenter}
                                        defaultZoom={mapZoom}
                                    >
                                        {
                                            list.map((item: any) => {
                                                const dt = getDateParts(item.createdOn);
                                                const titleBody = `
                                                Date: ${dt.date} ${dt.monthName} ${dt.year}\nTime: ${dt.hour}:${dt.minute} ${dt.amOrpm}\n${dt.relativeTime}`;
                                                return (
                                                    <MapMarker
                                                        lat={item.lat}
                                                        lng={item.lng}
                                                        text={`${dt.hour}:${dt.minute} ${dt.amOrpm}`}
                                                        title={titleBody}
                                                    />
                                                )
                                            })
                                        }
                                    </GoogleMapReact>
                                </div>
                            </div>
                            <div className="kt-portlet__foot">
                                <div className="kt-form__actions">
                                    <Paging page={page} listItem={list} onPageChange={onPageChange} />
                                    {/* <div className="row">
                                        <div className="col-md-3">
                                            <button type="button" className="btn btn-brand btn-bold btn-block">Prev</button>
                                        </div>
                                        <div className="col-md-6"></div>
                                        <div className="col-md-3">
                                            <button type="button" className="btn btn-brand btn-bold btn-block">Next</button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default EmployeeMaster;

const MapMarker = (props: any) => {
    const style: any = props.$hover ? greatPlaceStyleHover : greatPlaceStyle;
    return (
        <div style={style} title={props.title}>
            {props.text}
        </div>
    );
}
// const MapMarker = ({ text }: any) => {
//     return (
//         <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
//             <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
//                 <rect x="0" y="0" width="24" height="24" />
//                 <path d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z" fill="#000000" fill-rule="nonzero" />
//             </g>
//         </svg>
//     )
// }



const K_SIZE = 40;

const greatPlaceStyle = {
    // initially any map object has left top corner at lat lng coordinates
    // it's on you to set object origin to 0,0 coordinates
    position: 'absolute',
    width: K_SIZE,
    height: K_SIZE,
    left: -K_SIZE / 2,
    top: -K_SIZE / 2,

    border: '5px solid #f44336',
    borderRadius: K_SIZE,
    backgroundColor: 'white',
    textAlign: 'center',
    color: '#3f51b5',
    fontSize: 12,
    fontWeight: '500',
    padding: 4,
    cursor: 'pointer'
};

const greatPlaceStyleHover = {
    ...greatPlaceStyle,
    border: '5px solid #3f51b5',
    color: '#f44336'
};
