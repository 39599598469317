import React from 'react'
import moment from 'moment';
import $ from 'jquery';

// import Doughnut from '../../component/common/Doughnut';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import ManageLeave from '../../component/leave/ManageLeave';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import BarGraph from './../../component/chart/BarGraph';
import ModalWindow from '../../component/common/ModalWindow';
import CONSTANT from './../../constant';

import { getTeamLeaveList, updateLeaveStatus, getLeaveComment } from '../../action/LeaveActions'
import { getDateParts } from '../../utils';

export default class Leave extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            leaveList: undefined,
            showLoader: false,
            showManageLeaveModal: false,
            leaveDetail: undefined,
            comments: [],
            loadingComments: false,
            balance: null,
            date: moment()
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader, showManageLeaveModal, leaveDetail } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    {this.renderBody()}
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                {
                    showManageLeaveModal && <ModalWindow
                        title="Leave detail"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <ManageLeave
                            detail={leaveDetail}
                            onCancel={this.onCancel}
                            onSubmit={this.onSubmit}
                        />
                    </ModalWindow>
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        const { date } = this.state;
        const currYear = date.format('YYYY');

        const prevDate = moment(date.format('YYYY-MM-DD')).subtract(1, 'year');
        const prevYear = prevDate.format('YYYY');

        const nextDate = moment(date.format('YYYY-MM-DD')).add(1, 'year');
        const nextYear = nextDate.format('YYYY');

        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Leave
                    </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {currYear} </span>
                        </div>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href="javascript:;" onClick={this.changeDate} data-year={prevYear} className="btn btn-label-brand btn-bold"><i className="fas fa-angle-double-left mr-1 pe-none" aria-hidden="true"></i>{prevYear}</a>
                        <a href="javascript:;" onClick={this.changeDate} data-year={nextYear} className="btn btn-label-brand btn-bold">{nextYear}<i className="fas fa-angle-double-right ml-1 pe-none" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div >
        )
    }

    renderBody = () => {
        const { leaveList, loadingComments } = this.state;
        return (
            <div className="row">
                {/* <div className="col-md-6"></div>
                <div className="col-md-6">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Leave Quota
					                                </h3>
                            </div>
                        </div>
                        <BarGraph title="Medical leave" />
                    </div>
                </div> */}
                <div className="col-md-12">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Leave Request
					                                </h3>
                            </div>
                        </div>
                        <div className="kt-portlet__body">
                            {/* <!--begin::Section--> */}
                            <div className="kt-section mb-0">
                                <div className="kt-section__content">
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '3%' }}>#</th>
                                                <th>Employee</th>
                                                <th>Leave type</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th># Days</th>
                                                <th>Requested on</th>
                                                <th>Status</th>
                                                <th>Assigned To</th>
                                                <th style={{ width: '3%' }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                leaveList === undefined && <tr>
                                                    <td colSpan={10} className="text-center">Loading...</td>
                                                </tr>
                                            }
                                            {
                                                leaveList !== undefined && leaveList.length === 0 && <tr>
                                                    <td colSpan={10} className="text-center">No leave request found</td>
                                                </tr>
                                            }
                                            {
                                                leaveList !== undefined && leaveList.map((item: any, index: number) => {
                                                    const fd = getDateParts(item.fromDate);
                                                    const td = getDateParts(item.toDate);
                                                    const md = getDateParts(item.modifiedOn);
                                                    const url = CONSTANT.url.leaveDetail.replace(':id', item.id);
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{item.createdByName}</td>
                                                                <td>{item.leaveTypeName}</td>
                                                                <td>{`${fd.date} ${fd.monthName} ${fd.year}`}</td>
                                                                <td>{`${td.date} ${td.monthName} ${td.year}`}</td>
                                                                <td>{item.numberOfDays}</td>
                                                                <td>{`${md.date} ${md.monthName} ${md.year}`}</td>
                                                                <td>{item.leaveStatusName ? item.leaveStatusName : 'Pending'}</td>
                                                                <td>{item.pendingWithName}</td>
                                                                <td>
                                                                    <a href={url}>
                                                                        <i className="fas fa-edit" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    expandDetail = (e: any) => {
        const id = parseInt(e.target.dataset.id, 10);
        const detailId = e.target.dataset.detailId;
        const innerText = e.target.innerText;

        $('.leave-timeline-detail').hide();
        $('.leave-detail-expander').html('+');

        $(e.target).html(innerText === '+' ? '-' : '+');
        if (innerText === '+') {
            $(detailId).toggle();
            const stComm = this.state.comments.filter((i: any) => i.leaveId != id);
            this.setState({ comments: stComm, loadingComments: true }, () => {
                getLeaveComment(id).then((res: any) => {
                    const comments = this.state.comments.concat(res.result);
                    this.setState({ comments, loadingComments: false });
                })
            });
        }
    }

    onShowModal = (e: any) => {
        const id = parseInt(e.target.dataset.id, 10);
        const leaveDetail = this.state.leaveList.filter((i: any) => i.id === id)[0];
        this.setState({
            showManageLeaveModal: true,
            leaveDetail
        });
    }

    onCancel = () => {
        this.setState({ showManageLeaveModal: false, leaveId: undefined });
    }

    loadList = () => {
        const { date } = this.state;
        const year = date.format('YYYY');
        getTeamLeaveList(year).then((res: any) => {
            this.setState({ leaveList: res.result });
        })
    }

    onSubmit = (model: any) => {
        updateLeaveStatus(model).then((res: any) => {
            if (res.status) {
                alert('Status updated successfully');
                this.onCancel();
                this.loadList();
            } else {
                alert(res.error);
            }
        });
    }

    changeDate = (e: any) => {
        const year = e.target.dataset.year;
        this.setState({ date: moment(`${year}-01-01`) }, () => {
            this.loadList();
        });
    }
}
