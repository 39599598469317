import React from 'react'
import moment from 'moment';
import Chart from "chart.js";

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';

import { getTeamLeaveList } from '../../action/LeaveActions'
import { getDateParts } from '../../utils';

export default class Leave extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            leaveList: undefined,
            showLoader: false,
            showManageLeaveModal: false,
            leaveDetail: undefined,
            comments: [],
            loadingComments: false,
            balance: null,
            date: moment()
        }
    }

    componentDidMount() {
        this.loadList();

        // headcount chart
        const hcChartRef = this.headCountChartRef.current.getContext("2d");
        new Chart(hcChartRef, {
            type: "line",
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Dec'],
                datasets: [
                    // {
                    //     // label: 'Narendra',
                    //     backgroundColor: '#F6301B',
                    //     fill: false,
                    //     data: [
                    //         0
                    //     ]
                    // },
                    {
                        backgroundColor: '#F1C40F',
                        fill: false,
                        data: [
                            0, 0.1, 0.3, 2
                        ]
                    },
                    // {
                    //     label: 'Ranjan',
                    //     backgroundColor: '#9B59B6',
                    //     data: [
                    //         2, 2, 2, 0, 0, 0, 0
                    //     ]
                    // },
                    // {
                    //     label: 'Rajeev',
                    //     backgroundColor: '#95A5A6',
                    //     data: [
                    //         2, 2, 2, 0, 0, 0, 0
                    //     ]
                    // },
                    // {
                    //     label: 'Dhruv',
                    //     backgroundColor: '#3498DB',
                    //     data: [
                    //         0, 1, 0, 3, 0, 0, 0
                    //     ]
                    // }
                ]
            },
            options: {
                // maintainAspectRatio: false,
                legend: {
                    display: false
                },
                // responsive: true,
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            beginAtZero: true,
                            display: false
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            beginAtZero: true,
                            display: false
                        }
                    }]
                }
            }
        });

        const gcChartRef = this.genderCountChartRef.current.getContext("2d");
        new Chart(gcChartRef, {
            type: "doughnut",
            data: {
                labels: ['Male', 'Female'],
                datasets: [{
                    data: [
                        100, 70
                    ],
                    backgroundColor: [
                        '#F6301B',
                        '#F1C40F'
                    ],
                    label: 'Dataset 1'
                }],
            },
            options: {
                // legend: {
                //     display: true
                // },
                // responsive: true,
                // scales: {
                //     xAxes: [{
                //         gridLines: {
                //             display: false,
                //             drawBorder: false,
                //         },
                //         ticks: {
                //             beginAtZero: true,
                //             display: false
                //         }
                //     }],
                //     yAxes: [{
                //         gridLines: {
                //             display: false,
                //             drawBorder: false,
                //         },
                //         ticks: {
                //             beginAtZero: true,
                //             display: false
                //         }
                //     }]
                // }
            }
        });

        // hiring
        const hicChartRef = this.hiringCountChartRef.current.getContext("2d");
        new Chart(hicChartRef, {
            type: "line",
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Dec'],
                datasets: [
                    // {
                    //     // label: 'Narendra',
                    //     backgroundColor: '#F6301B',
                    //     fill: false,
                    //     data: [
                    //         0
                    //     ]
                    // },
                    {
                        backgroundColor: '#F1C40F',
                        fill: false,
                        data: [
                            0, 0.1, 0.2, 0.1, 0.02
                        ]
                    },
                    // {
                    //     label: 'Ranjan',
                    //     backgroundColor: '#9B59B6',
                    //     data: [
                    //         2, 2, 2, 0, 0, 0, 0
                    //     ]
                    // },
                    // {
                    //     label: 'Rajeev',
                    //     backgroundColor: '#95A5A6',
                    //     data: [
                    //         2, 2, 2, 0, 0, 0, 0
                    //     ]
                    // },
                    // {
                    //     label: 'Dhruv',
                    //     backgroundColor: '#3498DB',
                    //     data: [
                    //         0, 1, 0, 3, 0, 0, 0
                    //     ]
                    // }
                ]
            },
            options: {
                // maintainAspectRatio: false,
                legend: {
                    display: false
                },
                // responsive: true,
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            beginAtZero: true,
                            display: false
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            beginAtZero: true,
                            display: false
                        }
                    }]
                }
            }
        });


        // tenure
        const tcChartRef = this.tenureRangeChartRef.current.getContext("2d");
        new Chart(tcChartRef, {
            type: 'bar',
            data: {
                labels: ['<1 yrs', '1-2 yrs', '2+ yrs'],
                datasets: [{
                    label: 'Age group',
                    data: [8, 2, 14],
                    backgroundColor: [
                        'rgba(255, 99, 132)',
                        'rgba(54, 162, 235)',
                        'rgba(255, 206, 86)',
                        'rgba(75, 192, 192)',
                        'rgba(153, 102, 255)',
                        'rgba(255, 159, 64)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        // scaleLabel: {
                        //     display: true,
                        //     labelString: 'Days'
                        // },
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });

        const chart1 = this.departmentExitChartRef.current.getContext("2d");
        new Chart(chart1, {
            type: 'bar',
            data: {
                labels: ['Recruitment', 'IT', 'Sales', 'Admin/Office'],
                datasets: [{
                    label: 'Age group',
                    data: [8, 5, 5, 1, 0],
                    backgroundColor: [
                        'rgba(255, 99, 132)',
                        'rgba(54, 162, 235)',
                        'rgba(255, 206, 86)',
                        'rgba(75, 192, 192)',
                        'rgba(153, 102, 255)',
                        'rgba(255, 159, 64)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        // scaleLabel: {
                        //     display: true,
                        //     labelString: 'Days'
                        // },
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    public render() {
        const { showLoader, showManageLeaveModal, leaveDetail } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    {this.renderBody()}
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        const { date } = this.state;
        const currYear = date.format('YYYY');

        const prevDate = moment(date.format('YYYY-MM-DD')).subtract(1, 'year');
        const prevYear = prevDate.format('YYYY');

        const nextDate = moment(date.format('YYYY-MM-DD')).add(1, 'year');
        const nextYear = nextDate.format('YYYY');

        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            HR Dashboard
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {currYear} </span>
                        </div>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href="javascript:;" onClick={this.changeDate} data-year={prevYear} className="btn btn-label-brand btn-bold"><i className="fas fa-angle-double-left mr-1 pe-none" aria-hidden="true"></i>{prevYear}</a>
                        <a href="javascript:;" onClick={this.changeDate} data-year={nextYear} className="btn btn-label-brand btn-bold">{nextYear}<i className="fas fa-angle-double-right ml-1 pe-none" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div >
        )
    }

    headCountChartRef: any = React.createRef();
    genderCountChartRef: any = React.createRef();

    hiringCountChartRef: any = React.createRef();

    tenureRangeChartRef: any = React.createRef();
    departmentExitChartRef: any = React.createRef();
    renderBody = () => {
        const { leaveList, loadingComments } = this.state;
        const dt = getDateParts(new Date().toString());
        return (
            <div className="row">
                <div className="col-md-4">
                    <div className="card mb-3">
                        <div className="card-header">
                            Headcount
                        </div>
                        <div className="card-body text-center">
                            <h4 className="card-title d-inline">202</h4><span>+10%</span>
                            <div className="p-3">
                                <canvas
                                    style={{ height: 80 }}
                                    ref={this.headCountChartRef}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header">
                            Headcount by Gender
                        </div>
                        <div className="card-body text-center">
                            {/* <h4 className="card-title d-inline">202</h4><span>+10%</span> */}
                            <div className="row">
                                <div className="col">
                                    <span className="" style={{ fontSize: '5rem' }}>
                                        <i className="fas fa-male" />
                                    </span>
                                </div>
                                <div className="col" style={{ fontSize: '5rem' }}>
                                    38%
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <span className="" style={{ fontSize: '5rem' }}>
                                        <i className="fas fa-female" />
                                    </span>
                                </div>
                                <div className="col" style={{ fontSize: '5rem' }}>
                                    62%
                                </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                                <div className="col" style={{ fontSize: '1.5rem' }}>
                                    Avg. Age
                                </div>
                                <div className="col" style={{ fontSize: '2rem' }}>
                                    38
                                </div>
                            </div>
                            <div className="p-3 d-none">
                                <canvas
                                    // style={{ height: 80 }}
                                    ref={this.genderCountChartRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card mb-3">
                        <div className="card-header">
                            Hiring
                        </div>
                        <div className="card-body text-center">
                            <h4 className="card-title d-inline">30</h4><span>+10%</span>
                            <div className="p-3">
                                <canvas
                                    style={{ height: 80 }}
                                    ref={this.hiringCountChartRef}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header">
                            Offer acceptance rate
                        </div>
                        <div className="card-body text-center">
                            <h1 className="card-title text-success">30%</h1>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header">
                            Avragae time to fill from request date
                        </div>
                        <div className="card-body text-center">
                            <h1 className="card-title text-warning">43</h1>
                        </div>
                    </div>

                </div>
                <div className="col-md-4">
                    <div className="card mb-3">
                        <div className="card-header">
                            Announcements
                            <a style={{ right: '20px', position: 'absolute' }} href="#">+</a>
                        </div>
                        <div className="card-body">
                            <ul>
                                <li>Office will remain closed till 3rd of May because of COVID-19.</li>
                            </ul>
                        </div>
                    </div>


                    <div className="card mb-3">
                        <div className="card-header">
                            Birthday this month
                        </div>
                        <div className="card-body">
                            <ul>
                                <li>1 {dt.monthName} - Navdeep Singh</li>
                                <li>13 {dt.monthName} - Aarav Tandon</li>
                                <li>15 {dt.monthName} - Kanika Kapoor</li>
                                <li>15 {dt.monthName} - Rajat Mangal</li>
                            </ul>
                        </div>
                    </div>

                    {/* <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">Announcements</h3>
                                <a style={{ right: '20px', position: 'absolute' }} href="#">+</a>
                            </div>
                        </div>
                        <div className="kt-portlet__body">
                            <p>Office will remain closed till 3rd of May because of COVID-19.</p>
                        </div>
                    </div> */}

                </div>

                <div className="col-md-4">
                    <div className="card mb-3">
                        <div className="card-header">
                            Exits
                        </div>
                        <div className="card-body text-center">
                            <h1 className="card-title text-warning">24</h1>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card mb-3">
                        <div className="card-header">
                            Exits by department
                        </div>
                        <div className="card-body text-center">
                            <canvas
                                ref={this.departmentExitChartRef}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card mb-3">
                        <div className="card-header">
                            Tenures of Exits
                        </div>
                        <div className="card-body text-center">
                            <canvas
                                ref={this.tenureRangeChartRef}
                            />
                        </div>
                    </div>
                </div>

                {/* <div className="col-md-12">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body  kt-portlet__body--fit">
                            <div className="row row-no-padding row-col-separator-lg">
                                <div className="col-md-12 col-lg-6 col-xl-4">
                                    <div className="kt-widget24">
                                        <div className="kt-widget24__details">
                                            <div className="kt-widget24__info">
                                                <h4 className="kt-widget24__title">Head Count</h4>
                                            </div>
                                            <span className="kt-widget24__stats kt-font-brand">310</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-4">
                                    <div className="kt-widget24">
                                        <div className="kt-widget24__details">
                                            <div className="kt-widget24__info">
                                                <h4 className="kt-widget24__title">Exists</h4>
                                            </div>
                                            <span className="kt-widget24__stats kt-font-danger">30</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-4">
                                    <div className="kt-widget24">
                                        <div className="kt-widget24__details">
                                            <div className="kt-widget24__info">
                                                <h4 className="kt-widget24__title">Joining</h4>
                                            </div>
                                            <span className="kt-widget24__stats kt-font-success">40</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">Announcements</h3>
                                <a style={{ right: '20px', position: 'absolute' }} href="#">+</a>
                            </div>
                        </div>
                        <div className="kt-portlet__body">
                            <p>Office will remain closed till 3rd of May because of COVID-19.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">Birthdays this month</h3>
                            </div>
                        </div>
                        <div className="kt-portlet__body">
                            <p>1 APR - Navdeep Singh</p>
                            <p>13 APR - Aarav Tandon</p>
                            <p>15 APR - Kanika Kapoor</p>
                            <p>15 APR - Rajat Mangal</p>
                        </div>
                    </div>
                </div> */}

            </div >
        )
    }

    loadList = () => {
        const { date } = this.state;
        const year = date.format('YYYY');
        getTeamLeaveList(year).then((res: any) => {
            this.setState({ leaveList: res.result });
        })
    }

    changeDate = (e: any) => {
        const year = e.target.dataset.year;
        this.setState({ date: moment(`${year}-01-01`) }, () => {
            this.loadList();
        });
    }
}
