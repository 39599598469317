import React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import CONSTANT from './../../constant';

class ComponentName extends React.Component<any, any> {
    public render() {
        return (
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <Sidebar />
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                        <Header />
                        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                            {this.renderHeader()}
                            {this.renderBody()}
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Candidate list
                            </h3>

                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                        {/* <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {count} Total
                            </span>
                            <SearchForm />
                        </div> */}

                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href={CONSTANT.url.atsAddCandidate} className="btn btn-label-brand btn-bold">
                            Add New Candidate
                        </a>

                        {/* <a href={CONSTANT.url.employeeBulkUpload} className="btn btn-label-brand btn-bold">
                            Bulk Upload
                            </a> */}
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                {
                    this.renderUser()
                }
                {
                    this.renderUser()
                }
                {
                    this.renderUser()
                }
            </div>
        )
    }

    renderUser = () => {
        return (
            <div className="kt-portlet">
                <div className="kt-portlet__body">
                    <div className="kt-widget kt-widget--user-profile-3">
                        <div className="kt-widget__top">
                            <div className="kt-widget__media">
                                <img src="https://keenthemes.com/metronic/themes/metronic/theme/html/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                            </div>
                            <div className="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-bolder kt-font-light kt-hidden">JM</div>
                            <div className="kt-widget__content">
                                <div className="kt-widget__head">
                                    <div className="kt-widget__user">
                                        <a href={CONSTANT.url.atsCandidateDetail.replace(':id', '1')} className="kt-widget__username">Candidate Name</a>
                                    </div>
                                </div>
                                <div className="kt-widget__subhead">
                                    <a href="#"><i className="flaticon2-new-email"></i>david.s@loop.com</a>
                                    <a href="#"><i className="flaticon2-calendar-3"></i>Designation/Role</a>
                                    <a href="#"><i className="flaticon2-phone"></i>7838 920 181</a>
                                    <a href="#"><i className="flaticon2-placeholder"></i>Pref. Location1, Pref. Location2</a>
                                </div>
                                <div className="kt-widget__info">
                                    <div className="kt-widget__desc">
                                        <span className="kt-badge kt-badge--primary kt-badge--inline mr-1">React.JS</span>
                                        <span className="kt-badge kt-badge--primary kt-badge--inline mr-1">HTML</span><span className="kt-badge kt-badge--primary kt-badge--inline mr-1">CSS</span>
                                        <span className="kt-badge kt-badge--primary kt-badge--inline mr-1">CSS3</span>
                                    </div>
                                </div>
                                <div className="kt-widget__info">
                                    <div className="kt-widget__desc">
                                        <span className="kt-badge kt-badge--warning kt-badge--inline mr-1">Last timeline status</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ComponentName;
