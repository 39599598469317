import * as React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import UserMenu from '../../component/employee/Menu';
import Footer from '../../component/common/Footer';
import ProfilePicture from './../../component/employee/ProfilePicture'
import Loader from '../../component/common/Loader';

import CONSTANT from '../../constant';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { updateProfilePicture, getBasicDetail } from "./../../action/EmployeeAction";
import { getCookie, getStorage } from '../../utils';

class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const isSelf = props.match.params.id ? false : true;
        const userId = props.match.params.id ? props.match.params.id : getStorage(CONSTANT.keys.userDetail).id;
        this.state = {
            userId,
            isSelf,
            showLoader: false,
            img_path: null,
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { userId, img_path, picture, showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Employee Detail
                        </h3>

                    </div>

                </div>
            </div>
        )
    }

    renderBody = () => {
        const { userId, isSelf, img_path, picture } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
                    <button className="kt-app__aside-close" id="kt_user_profile_aside_close">
                        <i className="la la-close"></i>
                    </button>
                    <UserMenu userId={userId} isSelf={isSelf} />
                    <ProfilePicture
                        userId={userId}
                        picture={picture}
                        onSubmit={this.onSubmit}
                    />
                </div>
            </div>
        )
    }

    loadList = () => {
        const { userId } = this.state;
        this.setState({ showLoader: true });
        getBasicDetail(userId).then((res: any) => {
            
            if (res.result && res.result.profilePicture) {
                this.setState({ showLoader: false, picture: res.result.profilePicture });
            } else {
                this.setState({ showLoader: false })
            }
        })
    };

    onSubmit = (e: any) => {

        this.setState({ showLoader: true }, () => {
            updateProfilePicture(e).then((response: any) => {
                window.location.reload()
            }, (error: any) => {
                alert(error.message);
            });
        });
    };

}

export default EmployeeMaster;
