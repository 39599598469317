import * as React from 'react';
// import $ from 'jquery';
import moment from 'moment';
import LogAttendance from './../../component/attendance/LogAttendance';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';

// import { getTeamDailyHistory } from '../../action/AttendanceActions';
// import { getDatePartsUnix, getDateParts } from '../../utils';

class EmployeeList extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            date: moment(),
            present: undefined,
            absent: undefined,
            leave: undefined,
            uniqueRecords: undefined,
            showLoader: false,
            counter: { total: 0, absent: 0, present: 0, leave: 0 }
        }
    }

    componentDidMount() {

    }

    public render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { date } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Dashboard
                        </h3>

                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {date.format('dddd DD MMM YYYY')}
                            </span>
                        </div>

                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">

                    <div className="col-md-4">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Attendance
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <LogAttendance />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Leaves
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <p>You've taken no leaves this month</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Announcements
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <p>-</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 d-none">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Open helpdesk tickets
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div>
                                    <h5>23 April attendance missing</h5>
                                    <p className="text-mute">Ticket # 120
                                    <br />
                                    Category Attendance
                                    <br />
                                    Dated 24 Apr 2020
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 d-none">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Reimbursement
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div>
                                    <p>Claimed: Rs. 5,000</p>
                                    <p>Approved: Rs. 3,000</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EmployeeList;
