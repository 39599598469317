import * as React from 'react';
import $ from 'jquery';
import moment from 'moment';
import XLSX from 'xlsx';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import PieChart from './../../component/chart/PieChart';
import CONSTANT from './../../constant';

import { getCompanyDailyHistory } from '../../action/AttendanceActions';
import { onChange } from '../../utils';

class EmployeeList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            date: moment(),
            present: undefined,
            absent: undefined,
            leave: undefined,
            uniqueRecords: undefined,
            showLoader: false,
            counter: { total: 0, absent: 0, present: 0, leave: 0 },

            map: {
                zoom: 14,
                lat: 28.579285,
                lng: 77.312911
            },
        }
    }

    componentDidMount() {
        this.loadDetail();
    }

    public render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { date } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Attendance
                        </h3>

                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {date.format('dddd DD MMM YYYY')}
                            </span>
                        </div>

                    </div>
                    <div className="kt-subheader__toolbar">
                        {/* <div className="kt-subheader__wrapper"> */}
                        <select
                            value={date.format('YYYY-MM-DD')}
                            onChange={this.onChange}
                            className="form-control mr-3">
                            <option>All Employees</option>
                            <option>Amit</option>
                            <option>Abhishek</option>
                            <option>Avinash</option>
                        </select>

                        <input
                            type="date"
                            value={date.format('YYYY-MM-DD')}
                            onChange={this.onChange}
                            className="form-control"
                        />
                        {/* </div> */}
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { map, marker, name, radius, employeeIds } = this.state;
        const employees = [
            ,
            { lng: 77.3120899, lat: 28.578864, name: 'Amit' },
            { lng: 77.312911, lat: 28.579285, name: 'Abhishek' },
            { lng: 77.3101164, lat: 28.5801183, name: 'Avinash' }
        ];
        // let totalEmployeeCount = 0;
        // totalEmployeeCount = present !== undefined ? (present.length + absent.length + leave.length) : 0;

        // let totalEmployeeCount = 0;
        // totalEmployeeCount = present !== undefined ? (present.length + absent.length + leave.length) : 0;

        // let totalEmployeeCount = 0;
        // totalEmployeeCount = present !== undefined ? (present.length + absent.length + leave.length) : 0;

        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 form-group">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Travel history of employee
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <LoadScript
                                            id="script-loader"
                                            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API}
                                        >
                                            <GoogleMap mapContainerStyle={{
                                                height: "600px",
                                                width: "100%"
                                            }}
                                                zoom={map.zoom}
                                                center={{
                                                    lat: map.lat,
                                                    lng: map.lng
                                                }}
                                            // onClick={this.onMapClick}
                                            >
                                                {
                                                    employees.map((item: any, index: number) => {
                                                        return (
                                                            <Marker
                                                                position={{ lat: item.lat, lng: item.lng }}
                                                            />
                                                        )
                                                    })
                                                }

                                            </GoogleMap>
                                        </LoadScript>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Pagination /> */}
            </div>
        )
    }

    onChange = (e: any) => {
        const date = moment(e.target.value);
        this.setState({ date }, () => {
            this.loadDetail();
        })
    }

    loadDetail = () => {
        const date = this.state.date.format('YYYY-MM-DD');
        this.setState({
            counter: {
                ...this.state.counter,
                total: 0, absent: 0, present: 0, leave: 0
            }
        });
        getCompanyDailyHistory(date).then((res: any) => {

            let uniqueRecords = new Set(res.result.present.map((item: any) => item.userId));
            this.setState({
                uniqueRecords: Array.from(uniqueRecords),
                present: res.result.present,
                absent: res.result.absent,
                leave: res.result.leave,
            });

            const tCount = Array.from(uniqueRecords).length + res.result.absent.length + res.result.leave.length;
            const aCount = res.result.absent.length;
            const pCount = Array.from(uniqueRecords).length;
            const lCount = res.result.leave.length;

            this.setState({
                counter: {
                    ...this.state.counter,
                    total: tCount,
                    absent: aCount,
                    present: pCount,
                    leave: lCount
                }
            });
        })
    }

    changeMonth = (e: any) => {
        const month = e.target.dataset.month;
        const year = e.target.dataset.year;

        this.setState({
            date: moment(`${year}-${month}-01`)
        });
    }

    expandDetail = (e: any) => {
        const detailId = e.target.dataset.detailId;
        const innerText = e.target.innerText;
        $(e.target).html(innerText === '+' ? '-' : '+');
        $(detailId).slideToggle();
    }

    downloadExcel = () => {
        this.setState({ showLoader: true }, () => {

            const { uniqueRecords, absent, date } = this.state;
            let fileNameDT = new Date();
            let filename = `report-${fileNameDT.getTime()}.xlsx`;

            let dataToExport: any = [];
            var ws_name = "Sheet1";

            const present = uniqueRecords.map((userId: any, index: number) => {
                const userAttendance = present.filter((i: any) => i.userId === userId);
                const checkIns = userAttendance.filter((i: any) => i.inTimeUnix !== null);
                const checkOuts = userAttendance.filter((i: any) => i.outTimeUnix !== null);
                let workingHoursUnix = 0;
                for (let i = 0; i < userAttendance.length; i++) {
                    if (userAttendance[i].outTimeUnix !== null) {
                        workingHoursUnix += moment.unix(userAttendance[i].outTimeUnix).diff(moment.unix(userAttendance[i].inTimeUnix));
                    }
                }
                const workingHoursDate = moment.duration(workingHoursUnix);

                dataToExport.push({
                    employeeId: userAttendance[0].employeeId,
                    employeeName: userAttendance[0].name,
                    noOfCheckIns: checkIns.length,
                    noOfCheckOuts: checkOuts.length,
                    workingHours: `${workingHoursDate.get('hours')}:${workingHoursDate.get('minutes')}:${workingHoursDate.get('seconds')}`,
                    status: checkIns.length > 0 ? `Present` : `Absent`,
                    date: moment(date).format('YYYY-MM-DD')
                });
            });

            absent.map(function (item: any) {
                dataToExport.push({
                    employeeId: item.id,
                    employeeName: item.name,
                    noOfCheckIns: 0,
                    noOfCheckOuts: 0,
                    workingHours: `00:00:00`,
                    status: `Absent`,
                    date: moment(date).format('YYYY-MM-DD')
                });
            });

            var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(dataToExport);
            XLSX.utils.book_append_sheet(wb, ws, ws_name);
            XLSX.writeFile(wb, filename);
        });
    }
}

export default EmployeeList;
