import * as axios from 'axios';
import CONSTANT from './../constant';
import { getAuthHeader, setCookie, setStorage } from './../utils';
import { POST } from './ServiceHelper';
import { getLocale } from './PublicActions';

export const registerCompany = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/auth/registration`;
    return axios.post(url, model)
        .then((res: any) => {
            if (res.data.status) {
                setCookie(CONSTANT.keys.token, res.data.result.token);
                setStorage(CONSTANT.keys.userDetail, res.data.result);
                setStorage(CONSTANT.keys.teamDetail, res.data.result.team);
                setStorage(CONSTANT.keys.configuration, res.data.result.con ? res.data.result.con : {});
            }
            return res.data;
        });
}

export const login: any = (param: any) => {
    const url = `/v1/login`;
    return POST(url, param, false, false).then((res: any) => {
        if (res.status) {
            setCookie(CONSTANT.keys.token, res.result.token);
            setStorage(CONSTANT.keys.userDetail, res.result);
            setStorage(CONSTANT.keys.teamDetail, res.result.team);
            setStorage(CONSTANT.keys.configuration, res.result.con);
        }
        return res;
    });
}

export const changePassword: any = (reqObj: any) => {
    const loginUrl = `${process.env.REACT_APP_BASE_URL}/v1/reset/password`;
    return axios.post(loginUrl, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response.data.status) {
                console.log("change pass response  ", response.data);
            }
            return response.data;
        });
}

export const setCompanyConfiguration = async (detail: any) => {
    debugger;
    setStorage(CONSTANT.keys.language, detail.languageFile);
    // setStorage(CONSTANT.keys.currency, detail.currencySymbol);
    // setStorage(CONSTANT.keys.timezoneOffset, detail.timezoneOffset);
    // setStorage(CONSTANT.keys.logo, detail.logoFileName);

    await getLocale(detail.languageFile).then((res: any) => {
        setStorage(CONSTANT.keys.locale, res);
    })
}