import * as React from 'react';
import CONSTANT from '../../constant';

import { onChange, setOptions, validateForm, getLocalStorageItem } from './../../utils';
import { getAvailableARStatusList } from '../../action/SettingsActions';
import { getContactList } from '../../action/EmployeeAction';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            arDetailId: props.arDetailId,
            pendingWith: { name: 'pendingWith', value: '', error: '', isRequired: true, options: [] },
            statusId: { name: 'statusId', value: '', error: '', isRequired: true, options: [] },
            comment: { name: 'comment', value: '', error: '', isRequired: true },
        }
    }

    componentDidMount() {
        const ud = getLocalStorageItem(CONSTANT.keys.userDetail);
        const { isSelf } = this.props;

        getAvailableARStatusList().then((res: any) => {
            debugger;
            let result = res.result;
            if (isSelf) {
                result = res.result.filter((i: any) => i.id !== 1);
            }
            setOptions(this, this.state.statusId.name, result);
        });
        getContactList(ud.id).then((res: any) => {
            setOptions(this, this.state.pendingWith.name, res.result);
        });
    }

    public render() {
        const { statusId, pendingWith, comment } = this.state;
        return (
            <form onSubmit={this.onSubmit} className="row">
                <div className="col-lg-12 form-group">
                    <label>Recipient *</label>
                    <select
                        className={pendingWith.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        name={pendingWith.name}
                        value={pendingWith.value}
                        onChange={this.onChange}
                    >
                        <option value="">Select</option>
                        {
                            pendingWith.options.map(function (item: any, index: number) {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col-lg-12 form-group">
                    <label>Status *</label>
                    <select
                        className={statusId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        name={statusId.name}
                        value={statusId.value}
                        onChange={this.onChange}
                    >
                        <option value="">Select</option>
                        {
                            statusId.options.map(function (item: any, index: number) {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col-lg-12 form-group">
                    <label>Comment *</label>
                    <textarea
                        rows={3}
                        className={comment.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Write comment"
                        name={comment.name}
                        value={comment.value}
                        onChange={this.onChange}
                    />
                </div>


                <div className="col-md-12"><small>* Indicates required fields</small></div>
                <div className="col-md-3">
                    <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                    <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                </div>
            </form>
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { arDetailId, pendingWith, statusId, comment } = this.state;
            const model: any = {
                arDetailId: parseFloat(arDetailId),
                statusId: parseFloat(statusId.value),
                pendingWith: parseFloat(pendingWith.value),
                comment: comment.value
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }
}

export default AddComponent;
