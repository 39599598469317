import * as React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from '../../component/common/Loader';
import CONSTANT from './../../constant';
import LeaveRequest from '../../component/leave/Add'
import { addLeave } from '../../action/LeaveActions';
import { getHolidayList } from '../../action/CompanyCalendarAction';
import { getDateParts } from '../../utils';

class AddAttendance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            holidayList: undefined
        }
    }

    componentDidMount() {
        const year = new Date().getFullYear();
        getHolidayList(year).then((res: any) => {
            this.setState({ holidayList: res.result });
        });
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Leave detail
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { holidayList } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                {/* <!--begin::Section--> */}
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <LeaveRequest
                                            onSubmit={this.onSubmit}
                                            onCancel={this.onCancel}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">Holidays in {new Date().getFullYear()}</div>
                            <div className="card-body">
                                {
                                    holidayList !== undefined && holidayList.map((item: any, index: number) => {
                                        const dt = getDateParts(item.date)
                                        return (
                                            <div className="row mb-3" key={index}>
                                                <div className="col-md-6">{`${dt.date} ${dt.monthName} ${dt.year}`}</div>
                                                <div className="col-md-6 text-right">{item.holidayTypeName}</div>
                                                <div className="col-md-12">{item.name}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        addLeave(model).then((res: any) => {
            this.setState({ showLoader: false });
            // this.props.history.push("CONSTANT.url.attendanceTypeList")
            window.location.href = CONSTANT.url.employeeLeave;
        }).catch((err: any) => {
            alert(err.message);
        })
    }

    onCancel = () => {
        // this.props.history.push("CONSTANT.url.attendanceTypeList")
        window.location.href = CONSTANT.url.employeeLeave;
    }
}

export default AddAttendance;
