import * as React from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { getLocalStorageItem } from '../../utils';
import CONSTANT from '../../constant';

class ProfilePicture extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
        }

        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {

    }

    public render() {
        const ud = getLocalStorageItem(CONSTANT.keys.userDetail);
        const { userId, picture } = this.props;
        const { img_path } = this.state
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Profile Picture
                                    </h3>
                                </div>
                            </div>

                            <form className="kt-form kt-form--label-right" onSubmit={this.onSubmit}>
                                <div className="kt-portlet__body">
                                    <div className="form-group">
                                        <label>Upload File</label>
                                        <input type="file" className="form-control p-1" onChange={this.onChange} />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12" style={{ width: '600px', height: '600px' }}>
                                            {!img_path && picture && <img style={{ width: '600px', height: '600px' }} src={`${process.env.REACT_APP_BASE_URL}/profile-picture/${ud.sc}/${userId}/${picture}`} />}
                                            {img_path && <Cropper
                                                ref={(ref: any) => { this.cropper = ref }}
                                                zoomOnWheel={false}
                                                style={{ height: 600, width: '100%' }}
                                                aspectRatio={1}
                                                crop={this._crop.bind(this)}
                                                src={this.state.img_path} />}
                                        </div>
                                    </div>

                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <div className="row">
                                            <div className="col-md-9"></div>
                                            <div className="col-md-3">
                                                <button type="submit" className="btn btn-brand btn-bold btn-block">Save & Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    async _crop() {

        await this.setState({ cropped: await this.getCroppedImg(this.cropper.getCroppedCanvas(), 'img.jpg') })
    }

    private cropper: any;

    getCroppedImg = (canvas: any, fileName: any) => {

        return new Promise((resolve, reject) => {
            canvas.toBlob((file: any) => {
                if (file)
                    file.name = fileName;
                var fil = new File([file], fileName, { type: 'image/jpeg', lastModified: Date.now() });
                resolve(fil);
            }, 'image/jpeg');
        });
    }

    onChange = (e: any) => {
        let files = e.target.files

        if (FileReader && files && files.length) {
            var fr = new FileReader();
            fr.onload = () => {
                this.setState({ img_path: fr.result, original: fr.result })
            }

            if (files[0].size <= 1000000)
                fr.readAsDataURL(files[0])
            else
                window.alert("File cannot be larger than 1 MB!")
        }
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (this.state.cropped) {
            let formData = new FormData()
            const { cropped } = this.state;

            formData.append('userId', this.props.userId)
            formData.append('file', cropped)
            this.props.onSubmit(formData);
        }
    };


}

export default ProfilePicture;
