import * as React from 'react';

import { onChange, validateForm, setError } from '../../utils';


class AddDocumentComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const documentDetail = props.documentDetail;
        const isMandOptions = [{ id: true, name: 'Yes' }, { id: false, name: 'No' }];
        this.state = {
            name: { name: 'name', value: documentDetail ? documentDetail.name : '', error: '', isRequired: true },
            isMandatory: { name: 'isMandatory', value: documentDetail ? documentDetail.isMandatory : true, error: '', options: isMandOptions, },
        }
    }

    public render() {
        const { name, isMandatory } = this.state;
        return (
            <form onSubmit={this.onSubmit} className="row">
                <div className="col-lg-12 form-group">
                    <label>Name *</label>
                    <input
                        type="text"
                        className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Name of the document"
                        name={name.name}
                        value={name.value}
                        onChange={this.onChange}
                    />
                </div>
                <div className="col-lg-12 form-group">
                    <label>Is Mandatory </label>
                    <select
                        className={isMandatory.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        name={isMandatory.name}
                        value={isMandatory.value}
                        onChange={this.onChange}>
                        {
                            isMandatory.options.map((item: any, index: number) => {
                                return (<option value={item.id} key={index}>{item.name}</option>)
                            })
                        }
                    </select>
                </div>
                <div className="col-md-12"><small>* Indicates required fields</small></div>
                <div className="col-md-3 mt-3">
                    <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                    <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                </div>
            </form>
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;


        if (name === this.state.isMandatory.name) {
            value = (value === 'true');
        }
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const stateData = JSON.parse(JSON.stringify(this.state));
            const jsonToPost = {
                name: stateData.name.value,
                isMandatory: stateData.isMandatory.value,

            };
            this.props.onSubmit(jsonToPost)
        }
    }
}

export default AddDocumentComponent