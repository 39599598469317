import * as React from 'react';

import Header from './../../component/common/Header';
import Footer from './../../component/common/Footer';
import Sidebar from '../../component/common/Sidebar';
import AddLocation from './../../component/location/Add';
import Loader from '../../component/common/Loader';
import Shimmer from '../../component/common/Loader';
import CONSTANT from './../../constant';
import { getLocationDetail, updateLocation } from './../../action/GeographyActions';

class ComponentName extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: props.match.params.id,
            detail: undefined,
            showLoader: false
        }
    }

    componentDidMount() {
        const { id } = this.state;
        getLocationDetail(id).then((res: any) => {
            this.setState({ detail: res.result });
        });
    }
    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Location detail
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { showLoader, detail } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                {/* <!--begin::Section--> */}
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        {
                                            detail === undefined && <Shimmer />
                                        }
                                        {
                                            detail !== undefined && <AddLocation
                                                detail={detail}
                                                onSubmit={this.onSubmit}
                                                onCancel={this.onCancel}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        updateLocation(model).then((res: any) => {
            this.setState({ showLoader: false });
            alert('Changes were saved successfully');
            window.location.href = CONSTANT.url.companyLocation;
        });
    }

    onCancel = () => {
        window.location.href = CONSTANT.url.companyLocation;
    }

}

export default ComponentName;
