import * as React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import UserDefaultPIC from './../../assets/media/users/default.jpg'
import CONSTANT from './../../constant';
import { getEmployeeList } from '../../action/EmployeeAction';

class PayrollSummary extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 20,
            sort: 1,
            count: 0,
            showLoader: false
        }
    }

    componentDidMount() {
        // this.loadList();
    }

    public render() {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                                {this.renderChatDiv()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                    </div>
                    <div className="kt-subheader__toolbar">
                        <div className="kt-subheader__wrapper row">

                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <div className="dropdown dropdown-inline">
                                            {/* <button type="button" className="btn btn-default btn-icon-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="flaticon2-calendar-1"></i> This Year
			                                </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <ul className="kt-nav">
                                                    <li className="kt-nav__section kt-nav__section--first">
                                                        <span className="kt-nav__section-text">This Year</span>
                                                    </li>
                                                    <li className="kt-nav__item">
                                                        <a href="#" className="kt-nav__link">
                                                            <span className="kt-nav__link-text">Previous Year</span>
                                                        </a>
                                                    </li>
                                                    
                                                </ul>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="dataTables_length" id="kt_table_1_length">
                                                        <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm form-control form-control-sm">
                                                            <option value="10">This Year</option>
                                                            <option value="25">Previous Year</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-6">
                                                    <div className="dataTables_length" id="kt_table_1_length">
                                                        <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm form-control form-control-sm">
                                                            <option value="10">Choose an option</option>
                                                            <option value="25">Printf</option>
                                                            <option value="25">Copy</option>
                                                            <option value="25">CSV</option>
                                                            <option value="25">PDF</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <button type="button" className="btn btn-default btn-icon-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export as
			                                </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <ul className="kt-nav">
                                                    <li className="kt-nav__section kt-nav__section--first">
                                                        <span className="kt-nav__section-text">Choose an option</span>
                                                    </li>
                                                    <li className="kt-nav__item">
                                                        <a href="#" className="kt-nav__link">
                                                            <i className="kt-nav__link-icon la la-print"></i>
                                                            <span className="kt-nav__link-text">Print</span>
                                                        </a>
                                                    </li>
                                                    <li className="kt-nav__item">
                                                        <a href="#" className="kt-nav__link">
                                                            <i className="kt-nav__link-icon la la-copy"></i>
                                                            <span className="kt-nav__link-text">Copy</span>
                                                        </a>
                                                    </li>
                                                    <li className="kt-nav__item">
                                                        <a href="#" className="kt-nav__link">
                                                            <i className="kt-nav__link-icon la la-file-excel-o"></i>
                                                            <span className="kt-nav__link-text">Excel</span>
                                                        </a>
                                                    </li>
                                                    <li className="kt-nav__item">
                                                        <a href="#" className="kt-nav__link">
                                                            <i className="kt-nav__link-icon la la-file-text-o"></i>
                                                            <span className="kt-nav__link-text">CSV</span>
                                                        </a>
                                                    </li>
                                                    <li className="kt-nav__item">
                                                        <a href="#" className="kt-nav__link">
                                                            <i className="kt-nav__link-icon la la-file-pdf-o"></i>
                                                            <span className="kt-nav__link-text">PDF</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="dropdown dropdown-inline" data-toggle="kt-tooltip" title="" data-placement="left" data-original-title="Quick actions">
                                <a href="#" className="btn btn-brand btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="flaticon2-plus"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <ul className="kt-nav">
                                        <li className="kt-nav__section kt-nav__section--first">
                                            <span className="kt-nav__section-text">Add new:</span>
                                        </li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-graph-1"></i>
                                                <span className="kt-nav__link-text">Order</span>
                                            </a>
                                        </li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-calendar-4"></i>
                                                <span className="kt-nav__link-text">Event</span>
                                            </a>
                                        </li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-layers-1"></i>
                                                <span className="kt-nav__link-text">Report</span>
                                            </a>
                                        </li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-calendar-4"></i>
                                                <span className="kt-nav__link-text">Post</span>
                                            </a>
                                        </li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-file-1"></i>
                                                <span className="kt-nav__link-text">File</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="col-md-12">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body">
                            {/* <div className="kt-portlet__preview">
                                <div className="kt-section text-center">
                                    <span>Mount</span>
                                    <h4>Payroll Summary</h4>
                                    <small>01/04/2019 to 31/03/2020</small>
                                    <div className="kt-separator kt-separator--dashed"></div>
                                </div>
                                <span>Earnings</span>
                                <div className="row">

                                </div>
                            </div>
                         */}

                            <div className="row">
                                <div className="col-xl-3"></div>
                                <div className="col-xl-6">
                                    <div className="kt-section kt-section--first">
                                        <div className="kt-section__body">
                                            <div className="kt-section text-center">
                                                <span>Mount</span>
                                                <h4 className="mt-3">Payroll Summary</h4>
                                                <small>01/04/2019 to 31/03/2020</small>
                                            </div>

                                            <div className="card-header row">
                                                <label className="col-3 col-form-label">PAY COMPONENTS</label>
                                                <div className="col-9 text-right">
                                                    <label className="col-form-label">AMOUNT (₹)</label>
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <span className="kt-bold">Earnings</span>
                                            </div>

                                            <div className="row">
                                                <label className="col-8 col-form-label">Basic</label>
                                                <div className="col-4 text-right">
                                                    <label className="col-form-label">₹65861.00</label>
                                                </div>
                                            </div>
                                            <hr className="MuiDivider-root"></hr>

                                            <div className="row">
                                                <label className="col-8 col-form-label">House Rent Allowance</label>
                                                <div className="col-4 text-right">
                                                    <label className="col-form-label">₹32931.00</label>
                                                </div>
                                            </div>
                                            <hr className="MuiDivider-root"></hr>

                                            <div className="row">
                                                <label className="col-3 col-form-label">Fixed Allowance</label>
                                                <div className="col-9 text-right">
                                                    <label className="col-form-label">₹32929.00</label>
                                                </div>
                                            </div>
                                            <hr className="MuiDivider-root"></hr>

                                            <div className="row">
                                                <label className="col-3 col-form-label"></label>
                                                <div className="col-3 text-right">
                                                    <label className="col-form-label">Total Gross Pay</label>
                                                    <hr className="MuiDivider-root"></hr>

                                                </div>
                                                <div className="col-6 text-right">
                                                    <label className="col-form-label">₹1,31,721.00</label>
                                                    <hr className="MuiDivider-root"></hr>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="kt-section">
                                        <div className="kt-section__body">
                                            <span className="kt-bold">Statutories</span>
                                            <div className="row">
                                                {/* <label className="col-3 col-form-label">Last Name</label>
                                                <div className="col-9 text-right">
                                                    <label className="col-form-label">Kumar</label>
                                                </div> */}
                                                <label className="col-12 col-form-label">No statutories were inclueded during this period</label>
                                            </div>
                                            <hr className="MuiDivider-root"></hr>
                                        </div>
                                    </div>

                                    <div className="kt-section">
                                        <div className="kt-section__body">
                                            <span className="kt-bold">Deductions</span>
                                            <div className="row">
                                                {/* <label className="col-3 col-form-label">Last Name</label>
                                                <div className="col-9 text-right">
                                                    <label className="col-form-label">Kumar</label>
                                                </div> */}
                                                <label className="col-12 col-form-label">No deductions were applied in this period</label>
                                            </div>
                                            <hr className="MuiDivider-root"></hr>
                                            {/* <div className="row">
                                                <label className="col-3 col-form-label"></label>
                                                <div className="col-3 text-right">
                                                    <label className="col-form-label">Total Taxes</label>
                                                    <hr className="MuiDivider-root"></hr>

                                                </div>
                                                <div className="col-6 text-right">
                                                    <label className="col-form-label">₹0.00</label>
                                                    <hr className="MuiDivider-root"></hr>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className="kt-section">
                                        <div className="kt-section__body">
                                            <span className="kt-bold">Taxes</span>
                                            <div className="row">
                                                <label className="col-3 col-form-label">Income Taxe</label>
                                                <div className="col-9 text-right">
                                                    <label className="col-form-label">₹0.00</label>
                                                </div>
                                            </div>
                                            <hr className="MuiDivider-root"></hr>
                                            <div className="row">
                                                <label className="col-3 col-form-label"></label>
                                                <div className="col-3 text-right">
                                                    <label className="col-form-label">Total Taxes</label>
                                                    <hr className="MuiDivider-root"></hr>

                                                </div>
                                                <div className="col-6 text-right">
                                                    <label className="col-form-label">₹0.00</label>
                                                    <hr className="MuiDivider-root"></hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="kt-section">
                                        <div className="kt-section__body">
                                            <span className="kt-bold">Reimbursements</span>
                                            <div className="row">
                                                {/* <label className="col-3 col-form-label">Last Name</label>
                                                <div className="col-9 text-right">
                                                    <label className="col-form-label">Kumar</label>
                                                </div> */}
                                                <label className="col-12 col-form-label">No data to display</label>
                                            </div>
                                            <hr className="MuiDivider-root"></hr>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                {/* <Pagination /> */}
            </div>
        )
    }

    renderChatDiv = () => {
        return (

            <div className="modal modal-hide fade- modal-sticky-bottom-right" id="kt_chat_modal" role="dialog" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="kt-chat">
                            <div className="kt-portlet kt-portlet--last">
                                <div className="kt-portlet__head">
                                    <div className="kt-chat__head ">
                                        <div className="kt-chat__left">
                                            <div className="kt-chat__label">
                                                <a href="#" className="kt-chat__title">Jason Muller</a>
                                                <span className="kt-chat__status">
                                                    <span className="kt-badge kt-badge--dot kt-badge--success"></span> Active
                                                </span>
                                            </div>
                                        </div>
                                        <div className="kt-chat__right">
                                            <div className="dropdown dropdown-inline">
                                                <button type="button" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="flaticon-more-1"></i>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-md">

                                                    <ul className="kt-nav">
                                                        <li className="kt-nav__head">
                                                            Messaging
                                                <i className="flaticon2-information" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more..."></i>
                                                        </li>
                                                        <li className="kt-nav__separator"></li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-group"></i>
                                                                <span className="kt-nav__link-text">New Group</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-open-text-book"></i>
                                                                <span className="kt-nav__link-text">Contacts</span>
                                                                <span className="kt-nav__link-badge">
                                                                    <span className="kt-badge kt-badge--brand  kt-badge--rounded-">5</span>
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-bell-2"></i>
                                                                <span className="kt-nav__link-text">Calls</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-dashboard"></i>
                                                                <span className="kt-nav__link-text">Settings</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-protected"></i>
                                                                <span className="kt-nav__link-text">Help</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__separator"></li>
                                                        <li className="kt-nav__foot">
                                                            <a className="btn btn-label-brand btn-bold btn-sm" href="#">Upgrade plan</a>
                                                            <a className="btn btn-clean btn-bold btn-sm" href="#" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more...">Learn more</a>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>

                                            <button type="button" className="btn btn-clean btn-sm btn-icon" data-dismiss="modal">
                                                <i className="flaticon2-cross"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__body">
                                    <div className="kt-scroll kt-scroll--pull" data-height="410" data-mobile-height="225">
                                        <div className="kt-chat__messages kt-chat__messages--solid">
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">2 Hours</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    How likely are you to recommend our company<br /> to your friends and family?
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Hey there, we’re just writing to let you know that you’ve<br /> been subscribed to a repository on GitHub.
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok, Understood!
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">Just Now</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    You’ll receive notifications for all issues, pull requests!
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">2 Hours</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    You were automatically <b className="kt-font-brand">subscribed</b> <br />because you’ve been given access to the repository
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>

                                                <div className="kt-chat__text">
                                                    You can unwatch this repository immediately <br />by clicking here: <a href="#" className="kt-font-bold kt-link"></a>
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok!
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">Just Now</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok!
                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-chat__input">
                                        <div className="kt-chat__editor">
                                            <textarea placeholder="Type here..." style={{ height: 50 }}></textarea>
                                        </div>
                                        <div className="kt-chat__toolbar">
                                            <div className="kt_chat__tools">
                                                <a href="#"><i className="flaticon2-link"></i></a>
                                                <a href="#"><i className="flaticon2-photograph"></i></a>
                                                <a href="#"><i className="flaticon2-photo-camera"></i></a>
                                            </div>
                                            <div className="kt_chat__actions">
                                                <button type="button" className="btn btn-brand btn-md  btn-font-sm btn-upper btn-bold kt-chat__reply">reply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { page, limit, sort } = this.state;
        getEmployeeList(page, limit, sort, "*").then((response: any) => {
            this.setState({
                list: response.result.list,
                count: response.result.count,
            });
        }, (error: any) => {
            alert(error.message);
        });
    }

}

export default PayrollSummary;
