import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getEmployeeList } from './../../action/EmployeeAction';

class AssignShiftModal extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            modal: true,
            message: props.message,
            list: undefined,
            page: 1,
            limit: 20,
            sort: 1,
            count: 0,
            showLoader: false
        };
    }
    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { className } = this.props;
        const { list, modal } = this.state;
        const backdrop = this.props.backdrop ? this.props.backdrop : true;
        return (

            <React.Fragment>
                <div className="modal fade" id="kt_modal_1_21" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Assign Shift</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <label className="col-form-label col-lg-4 col-sm-12">Applicable For</label>
                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                        <div className="input-group date">
                                            <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm form-control form-control-sm">
                                                <option value="10">Select Employees</option>
                                                {
                                                    list !== undefined && list.map((item: any, index: any) => {
                                                        return (
                                                            <option value={item.name}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-form-label col-lg-4 col-sm-12">Shift Name</label>
                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                        <div className="input-group date">
                                            <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm form-control form-control-sm">
                                                <option value="10">Select Shift</option>
                                                <option value="25">General</option>
                                                <option value="50">Night</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <label className="col-form-label col-lg-4 col-sm-12">From</label>
                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                        <div className="input-group date">
                                            <input type="date" className="form-control" placeholder="From" id="kt_datepicker_2" />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <label className="col-form-label col-lg-4 col-sm-12">To</label>
                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                        <div className="input-group date">
                                            <input type="date" className="form-control" placeholder="From" id="kt_datepicker_2" />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">Submit</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >


            // <div id="kt_modal_KTDatatable_local" className="modal fade" role="dialog" aria-hidden="true">
            //     <div className="modal-dialog modal-xl">
            //         <div className="modal-content" >
            //             <div className="modal-header">
            //                 {/* <h5 className="modal-title">
            //                     Datatable
            // 		            <small>local data source</small>
            //                 </h5> */}
            //                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            //                     <span aria-hidden="true">×</span>
            //                 </button>
            //             </div>
            //             <div className="modal-body">
            //                 <div className="kt-portlet kt-portlet--mobile">

            //                     <div className="kt-portlet__body">
            //                         <div id="kt_table_1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">

            //                             <div className="row"><div className="col-sm-12">
            //                                 <div className="dataTables_scroll">

            //                                     <div className="dataTables_scrollBody" >
            //                                         <table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer" id="kt_table_1" role="grid" aria-describedby="kt_table_1_info">
            //                                             <thead>
            //                                                 <tr role="row" >
            //                                                     <th className="sorting" aria-controls="kt_table_1" >
            //                                                         <div className="dataTables_sizing" >Particulars</div></th><th className="sorting" aria-controls="kt_table_1" >
            //                                                         <div className="dataTables_sizing">Actual</div></th><th className="sorting" aria-controls="kt_table_1" >
            //                                                         <div className="dataTables_sizing">Projection</div></th><th className="sorting" aria-controls="kt_table_1">
            //                                                         <div className="dataTables_sizing">Total</div></th><th className="sorting" aria-controls="kt_table_1">
            //                                                     </th>
            //                                                 </tr>
            //                                             </thead>

            //                                             <tbody>
            //                                                 <tr role="row" className="odd">
            //                                                     <td className="sorting_1">1</td>
            //                                                     <td>61715-075</td>
            //                                                     <td>China</td>
            //                                                     <td>Tieba</td>
            //                                                 </tr>
            //                                             </tbody>

            //                                         </table>
            //                                     </div>
            //                                 </div>
            //                             </div>
            //                             </div>

            //                         </div>

            //                     </div>
            //                 </div>

            //             </div>
            //             {/* <div className="modal-body modal-body-fit">

            //             </div> */}
            //             {/* <div className="modal-footer kt-hidden">
            //                 <button type="button" className="btn btn-clean btn-bold btn-upper btn-font-md" data-dismiss="modal">Close</button>
            //                 <button type="button" className="btn btn-default btn-bold btn-upper btn-font-md">Submit</button>
            //             </div> */}
            //         </div>
            //     </div>
            // </div>

        );
    }

    toggleModal = () => {
        this.setState((prevState: any) => ({ modal: !prevState.modal }));
        this.props.toggleModal();
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { page, limit, sort } = this.state;
        getEmployeeList(page, limit, sort, "*").then((response: any) => {
            this.setState({
                list: response.result.list,
                count: response.result.count,
            });
        }, (error: any) => {
            alert(error.message);
        });
    }
}

export default AssignShiftModal;