import * as axios from 'axios';
import CONSTANT from './../constant';
import { getAuthHeader, getUploadFileHeader, setStorage } from '../utils';

export const getCompanyConfiguration = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/company-configuration`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateCompanyConfiguration = (body: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/company-configuration`;
    return axios.post(url, body, getAuthHeader())
        .then((res: any) => {
            return res.data;
        });
}

export const updateCompanyLogo = (body: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/company-configuration/logo`;
    return axios.post(url, body, getUploadFileHeader())
        .then((res: any) => {
            return res.data;
        });
}