import * as React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import CONSTANT from '../../constant';
import { getTicketList } from '../../action/HelpdeskActions';
import BOT from './../../component/chat-bot/Index';

class AssetHome extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 20,
            sort: 1,
            count: 0,
            showLoader: false
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {

        return (
            <React.Fragment>
                {/* <BOT /> */}
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">

                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            My Queries
                        </h3>
                    </div>
                    {/* <div className="kt-subheader__toolbar">
                        <div className="kt-subheader__wrapper row">

                            <div className="kt-subheader__toolbar">
                                <a href={CONSTANT.url.assetAdd} className="btn btn-brand btn-elevate btn-icon-sm">
                                    <i className="la la-plus"></i>
                                    Add Asset
                                </a>
                            </div>

                        </div>
                    </div> */}
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { list } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="kt-portlet kt-portlet--mobile">
                    {/* <div className="kt-portlet__head kt-portlet__head--lg">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Asset
			                </h3>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <a href="#" className="btn btn-brand btn-elevate btn-icon-sm">
                                        <i className="la la-plus"></i>
                                        Add Record
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {list && list.length == 0 && (
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-12 mt-4 mb-4 text-center">No record found</div>
                            </div>
                        </div>
                    )}

                    {list && list.length > 0 && (
                        <div className="kt-portlet__body">
                            <div id="kt_table_1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {/* <table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1" role="grid" aria-describedby="kt_table_1_info"> */}
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Ticket Id</th>
                                                    <th scope="col">Subject</th>
                                                    <th scope="col">Category</th>
                                                    <th scope="col">Raised by</th>
                                                    <th scope="col">Assigned To</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list !== undefined && list.map((item: any, index: any) =>
                                                    <tr key={index}>
                                                        <td>{item.id}</td>
                                                        <td>{item.subject}</td>
                                                        <td>{item.categoryName}</td>
                                                        <td>{item.createdByName}</td>
                                                        <td>{item.pendingWithName ? item.pendingWithName : '-'}</td>
                                                        <td>{item.statusName ? item.statusName : '-'}</td>
                                                        <th scope="col">
                                                            <Link
                                                                to={{ pathname: CONSTANT.url.helpdeskTimeline.replace(':code', item.id), state: { detail: { id: item.id, subject: item.subject, description: item.description, statusId: item.statusId, pendingWith: item.pendingWith } } }}>
                                                                <a>
                                                                    <i className="la la-edit"></i>
                                                                </a>
                                                            </Link>
                                                        </th>
                                                    </tr>
                                                )}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                    
                </div>

            </div >
        )
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { page, limit, sort } = this.state;
        getTicketList().then((response: any) => {
            this.setState({ showLoader: false });
            // console.log("get getTicketList response  => ", response);
            if (response.result) {
                this.setState({ list: response.result })
            }
        });
    }
}

export default AssetHome;
