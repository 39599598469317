import * as React from 'react';
import ModalWindow from '../../component/common/ModalWindow'
import Header from './../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import CONSTANT from './../../constant';
import { getSalaryComponentList, toggleIsActiveStatus } from './../../action/PayrollTaxComponentActions';
import { getDateParts } from '../../utils';
import AddCycle from './../../component/pms/AddCycle';
import AddSubCycle from './../../component/pms/AddSubCycle';

class ComponentName extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 10,
            showLoader: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = CONSTANT.title.locationList;
    }

    public render() {
        const { showCycleModal, showSubCycleModal } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            PMS
                        </h3>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                        </div>

                    </div>
                    <div className="kt-subheader__toolbar">
                        <div className="kt-subheader__wrapper">
                            <a href="javascript:;" onClick={this.onAddCycle} className="btn btn-label-brand btn-bold">Process Appraisal</a>
                            <div className="dropdown dropdown-inline">
                                <select className="form-control">
                                    <option>Appraisal Cycle 20-21</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Ratings
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">KRA Rating %</th>
                                                    <th scope="col">Competency Rating %</th>
                                                    <th scope="col">Appraisal Cycle</th>
                                                    <th scope="col">Appraisal Sub-cycle</th>
                                                    <th scope="col">Rating by</th>
                                                    <th scope="col">Rated on</th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>Ranjan Sharma</td>
                                                    <td>70</td>
                                                    <td>80</td>
                                                    <td>Cycle 20-21</td>
                                                    <td>Apr-Jun 2020</td>
                                                    <td>Ranjan Sharma</td>
                                                    <td>2 Days ago</td>
                                                    <td className="text-right"><a href="#"><i className="fas fa-edit"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td>Amit Sharma</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>Cycle 20-21</td>
                                                    <td>Apr-Jun 2020</td>
                                                    <td>Ranjan Sharma</td>
                                                    <td>2 Days ago</td>
                                                    <td className="text-right"><a href="#"><i className="fas fa-edit"></i></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end::Section--> */}
                            </div>
                            {/* <!--end::Form--> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onAddCycle = () => {
        this.setState({
            showCycleModal: true
        });
    }

    onCycleSubmit = () => {
        this.setState({
            showCycleModal: false
        });
    }

    onCycleCancel = () => {
        this.setState({
            showCycleModal: false
        });
    }

    onAddSubCycle = () => {
        this.setState({
            showSubCycleModal: true
        });
    }

    onSubCycleSubmit = () => {
        this.setState({
            showSubCycleModal: false
        });
    }

    onSubCycleCancel = () => {
        this.setState({
            showSubCycleModal: false
        });
    }
}

export default ComponentName;
