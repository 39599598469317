import React from 'react';
import Chart from "chart.js";
import classes from "./PieChart.module.css";

class ComponentName extends React.Component<any, any> {
    private chartRef: any = React.createRef();
    constructor(props: any) {
        super(props);

        this.state = {
            data: props.data
        }
    }

    componentDidMount() {
        const myChartRef = this.chartRef.current.getContext("2d");
        const data = this.state.data;

        new Chart(myChartRef, {
            type: "pie",
            data: {
                datasets: [{
                    backgroundColor: [
                        "#2ecc71",
                        "#3498db",
                        "#95a5a6",
                        // "#9b59b6",
                        // "#f1c40f",
                        // "#e74c3c",
                        // "#34495e"
                    ],
                    data: data.map((i: any) => i.value)
                }],

                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: data.map((i: any) => i.name)
            },
            options: {
                //Customize chart options
            }
        });
    }

    render() {
        return (
            <div className="kt-widget12__chart mb-3">
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        )
    }
}

export default ComponentName;