import React from 'react';
import CSVIcon from './../../assets/media/files/csv.svg';

class Dropzone extends React.Component<any, any> {

    fileInputRef: any = null;

    constructor(props: any) {
        super(props)
        this.state = { hightlight: false, files: [] }
        this.fileInputRef = React.createRef()

        this.openFileDialog = this.openFileDialog.bind(this)
        this.onFilesAdded = this.onFilesAdded.bind(this)
        this.onDragOver = this.onDragOver.bind(this)
        this.onDragLeave = this.onDragLeave.bind(this)
        this.onDrop = this.onDrop.bind(this)
    }

    openFileDialog() {
        if (this.props.disabled)
            return
        this.fileInputRef.current.click()
    }

    onFilesAdded(evt: any) {
        if (this.props.disabled)
            return
        const files = evt.target.files
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files)
            this.setState({ files: array })
            this.props.onFilesAdded(array);
        }
    }

    onDragOver(evt: any) {
        evt.preventDefault()

        if (this.props.disabled)
            return

        this.setState({ hightlight: true })
    }

    onDragLeave() {
        this.setState({ hightlight: false })
    }

    onDrop(event: any) {
        event.preventDefault()

        if (this.props.disabled)
            return

        const files = event.dataTransfer.files
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files)
            this.props.onFilesAdded(array)
        }
        this.setState({ hightlight: false })
    }

    fileListToArray(list: any) {
        const array = []
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array
    }

    render() {
        const { files } = this.state;
        return (
            <React.Fragment>
                <div
                    className={`Dropzone ${this.state.hightlight ? 'Highlight' : ''}`}
                    onDragOver={this.onDragOver}
                    onDragLeave={this.onDragLeave}
                    onDrop={this.onDrop}
                    onClick={this.openFileDialog}
                    style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
                >
                    <input
                        ref={this.fileInputRef}
                        className="FileInput"
                        type="file"
                        multiple={this.props.multiple}
                        onChange={this.onFilesAdded}
                        accept=".csv,.xlsx,.xls"
                    />
                    <img
                        alt="upload"
                        className="Icon"
                        src={CSVIcon}
                    />
                    <span>Drag and drop or click here to upload file</span>

                </div>
                {
                    files.length > 0 && <ul className="FileList">
                        {
                            files.map((item: any, index: number) => {
                                return (
                                    <li key={index}>{item.name}</li>
                                )
                            })
                        }

                    </ul>
                }
            </React.Fragment>
        )
    }
}

export default Dropzone;