import * as React from 'react';
import $ from 'jquery';
import moment from 'moment';
import XLSX from 'xlsx';
import Chart from "chart.js";
import { Link } from 'react-router-dom';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import PieChart from './../../component/chart/PieChart';
import CONSTANT from './../../constant';

import { getCompanyDailyHistory } from '../../action/AttendanceActions';
import { onChange } from '../../utils';
import { getCategoryList } from '../../action/HelpdeskActions';

class CategoryList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: []
        }
    }

    public componentDidMount() {
        getCategoryList().then((response: any) => {
            if (response.status) {
                this.setState({ data: response.result });
            }
        });
    }

    public render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Helpdesk Category / Sub-Category
                        </h3>

                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                    </div>

                </div>
            </div>
        )
    }

    renderBody = () => {
        const { data } = this.state;

        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="col-lg-12">
                    <div className="col-lg-12 mt-4">
                        <div className="row">
                            <div className="col-lg-8 pl-0">
                                <h5 className="heading-h1">Category</h5>
                            </div>
                            <div className="col-lg-2 text-right pr-0">
                                <Link to={CONSTANT.url.addHelpdeskCategory}>
                                    <a className="common-btn"><i className="fa fa-plus"></i> &nbsp; Add Category</a>
                                </Link>
                            </div>
                            <div className="col-lg-2 text-right pr-0">
                                <Link to={CONSTANT.url.addHelpdeskSubCategory}>
                                    <a className="common-btn"><i className="fa fa-plus"></i> &nbsp; Add Sub Category</a>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-2">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-lg-2 role-head1">
                                    <b>Category</b>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Category Name</th>
                                            <th scope="col">Sub Category Name</th>
                                            <th scope="col">Owner</th>
                                            <th scope="col">Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.length > 0 && data.map((item: any, index: any) =>
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{item.subCategory}</td>
                                                <td>{item.ownerName}</td>
                                                <th scope="col">
                                                    <Link
                                                        to={{ pathname: CONSTANT.url.editHelpdeskCategory, state: { detail: { id: item.id, name: item.name, ownerId: item.ownerId } } }}>
                                                        <a>
                                                            <i className="la la-edit"></i>
                                                        </a>
                                                    </Link>
                                                </th>
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                
                </div>

            </div>
        )
    }

}

export default CategoryList;
