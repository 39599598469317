import * as React from 'react';
import CONSTANT from '../../constant';

import { onChange, setOptions, validateForm, getLocalStorageItem, getDateParts } from './../../utils';
import { getApprovalBasedLeaveType } from '../../action/SettingsActions';
import { getEmployeeMonthlyHistory } from '../../action/AttendanceActions';
import { getAllEmployeeList } from '../../action/EmployeeAction';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            pendingWith: { name: 'pendingWith', value: detail ? detail.pendingWith : '', error: '', isRequired: false, options: [] },
            leaveTypeId: { name: 'leaveTypeId', value: detail ? detail.leaveTypeId : '', error: '', isRequired: true, options: [] },
            attendanceId: { name: 'attendanceId', value: detail ? detail.attendanceId : '', error: '', isRequired: true, options: [] },
            comment: { name: 'comment', value: detail && detail.comment ? detail.comment : '', error: '', isRequired: true },
        }
    }

    componentDidMount() {
        const dt = new Date();
        const ud = getLocalStorageItem(CONSTANT.keys.userDetail);
        getEmployeeMonthlyHistory(ud.id, dt.getMonth() + 1, dt.getFullYear()).then((res: any) => {
            const result = res.result.filter((i: any) => i.outTime !== null);
            setOptions(this, this.state.attendanceId.name, result);
        });
        getApprovalBasedLeaveType().then((res: any) => {
            setOptions(this, this.state.leaveTypeId.name, res.result);
        })
        getAllEmployeeList().then((res: any) => {
            setOptions(this, this.state.pendingWith.name, res.result);
        });
    }

    public render() {
        const { pendingWith, leaveTypeId, attendanceId, comment } = this.state;
        return (
            <form onSubmit={this.onSubmit} className="row">
                <div className="col-lg-12 form-group">
                    <label>Recipient *</label>
                    <select
                        className={pendingWith.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        name={pendingWith.name}
                        value={pendingWith.value}
                        onChange={this.onChange}
                    >
                        <option value="">Select</option>
                        {
                            pendingWith.options.map(function (item: any, index: number) {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col-lg-12 form-group">
                    <label>Approval Type *</label>
                    <select
                        className={leaveTypeId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        name={leaveTypeId.name}
                        value={leaveTypeId.value}
                        onChange={this.onChange}
                    >
                        <option value="">Select Approval Type</option>
                        {
                            leaveTypeId.options.map(function (item: any, index: number) {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            })
                        }
                    </select>
                </div>

                <div className="col-lg-12 form-group">
                    <label>Attendance *</label>
                    <select
                        className={attendanceId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        name={attendanceId.name}
                        value={attendanceId.value}
                        onChange={this.onChange}
                    >
                        <option value="">Select attendance</option>
                        {
                            attendanceId.options.map(function (item: any, index: number) {
                                const inTime = getDateParts(item.inTime);
                                const outTime = getDateParts(item.outTime);
                                return (
                                    <option key={index} value={item.id}>
                                        {item.attendanceTypeName} {`[${inTime.date} ${inTime.monthName} ${inTime.year} ${inTime.hour}:${inTime.minute} ${inTime.amOrpm}-${outTime.date} ${outTime.monthName} ${outTime.year} ${outTime.hour}:${outTime.minute} ${outTime.amOrpm}]`}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>

                <div className="col-lg-12 form-group">
                    <label>comment *</label>
                    <textarea
                        rows={3}
                        className={comment.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Write comment"
                        name={comment.name}
                        value={comment.value}
                        onChange={this.onChange}
                    />
                </div>


                <div className="col-md-12"><small>* Indicates required fields</small></div>
                <div className="col-md-3">
                    <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                    <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                </div>
            </form>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { pendingWith, leaveTypeId, attendanceId, comment } = this.state;
            const model: any = {
                pendingWith: pendingWith.value,
                leaveTypeId: leaveTypeId.value,
                attendanceId: attendanceId.value,
                comment: comment.value
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }
}

export default AddComponent;
