
import * as React from 'react';
import { Link } from 'react-router-dom';
import CONSTANT from './../../constant';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import SearchForm from './../../component/project/SearchForm';
import ProjectSummary from './../../component/project/ProjectSummary';


import { getProjectList } from '../../action/ProjectAction';
import { getDateParts } from '../../utils';
import Shimmer from '../../component/common/Shimmer';

class ListAttendance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            detail: undefined,
            page: 1,
            limit: 20,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadList()
    }

    public render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">


                                {this.renderHeader()}

                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { detail } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            My Task
                        </h3>

                        {/* <span className="kt-subheader__separator kt-subheader__separator--v"></span> */}

                        {/* <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {detail && detail.count} Total
                            </span>

                            <SearchForm />
                        </div> */}

                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href={CONSTANT.url.addProjectDetail} className="btn btn-label-brand btn-bold">Add Task</a>
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { detail, count } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="kt-grid kt-grid--desktop kt-grid--ver-desktop  kt-todo" id="kt_todo">
                    <div className="kt-grid__item   kt-portlet kt-todo__aside" id="kt_todo_aside">

                        <div className="kt-todo__nav">
                            <ul className="kt-nav">
                                {/* <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link" data-action="list" data-type="inbox">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-nav__link-icon">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3"></path>
                                                <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000"></path>
                                            </g>
                                        </svg>              <span className="kt-nav__link-text">Home</span>

                                    </a>
                                </li>
                                <li className="kt-nav__item  kt-nav__item--active">
                                    <a href="#" className="kt-nav__link" data-action="list" data-type="marked">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-nav__link-icon">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"></path>
                                                <path d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z" fill="#000000"></path>
                                                <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"></path>
                                            </g>
                                        </svg>              <span className="kt-nav__link-text">My Tasks</span>
                                        <span className="kt-nav__link-badge">
                                            <span className="kt-badge kt-badge--unified-success kt-badge--md kt-badge--rounded kt-badge--boldest">3</span>
                                        </span>
                                    </a>
                                </li>
                                <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link" data-action="list" data-type="draft">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-nav__link-icon">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                                                <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"></path>
                                            </g>
                                        </svg>              <span className="kt-nav__link-text">Calendar</span>
                                    </a>
                                </li>
                                <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link" data-action="list" data-type="draft">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-nav__link-icon">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"></path>
                                                <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) " x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
                                            </g>
                                        </svg>              <span className="kt-nav__link-text">Reports</span>
                                        <span className="kt-nav__link-badge">
                                            <span className="kt-badge kt-badge--unified-warning kt-badge--md kt-badge--rounded kt-badge--boldest">1</span>
                                        </span>
                                    </a>
                                </li> */}

                                <li className="kt-nav__item kt-margin-t-20 kt-margin-b-20">
                                    <span className="kt-nav__link-title">Projects</span>
                                </li>

                                <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link" data-action="list" data-type="marked">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-nav__link-icon">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero"></path>
                                                <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "></path>
                                            </g>
                                        </svg>              <span className="kt-nav__link-text">AirAustraila Extranet</span>
                                    </a>
                                </li>
                                <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link" data-action="list" data-type="draft">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-nav__link-icon">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero"></path>
                                                <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "></path>
                                            </g>
                                        </svg>              <span className="kt-nav__link-text">Partnership</span>
                                    </a>
                                </li>
                                <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link" data-action="list" data-type="sent">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-nav__link-icon">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero"></path>
                                                <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "></path>
                                            </g>
                                        </svg>              <span className="kt-nav__link-text">In Progres</span>
                                    </a>
                                </li>
                                {/* <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link" data-action="list" data-type="trash">
                                        <i className="kt-nav__link-icon kt-nav__link-icon--size fa flaticon2-plus"></i>
                                        <span className="kt-nav__link-text">Add Project</span>
                                    </a>
                                </li> */}

                                <li className="kt-nav__item kt-margin-t-20 kt-margin-b-20">
                                    <span className="kt-nav__link-title">Tags</span>
                                </li>

                                <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link">
                                        <i className="kt-nav__link-icon fa fa-genderless kt-font-warning"></i>
                                        <span className="kt-nav__link-text">Custom Work</span>
                                    </a>
                                </li>
                                <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link">
                                        <i className="kt-nav__link-icon fa fa-genderless kt-font-success"></i>
                                        <span className="kt-nav__link-text">Partnership</span>
                                    </a>
                                </li>
                                <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link">
                                        <i className="kt-nav__link-icon fa fa-genderless kt-font-info"></i>
                                        <span className="kt-nav__link-text">In Progres</span>
                                    </a>
                                </li>
                                <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link">
                                        <i className="kt-nav__link-icon fa flaticon2-plus"></i>
                                        <span className="kt-nav__link-text">Add Tog</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="kt-grid__item kt-grid__item--fluid kt-todo__content" id="kt_todo_content">
                        <div className="kt-todo__tasks-bottom">
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="kt-grid__item kt-grid__item--fluid  kt-portlet kt-portlet--height-fluid kt-todo__list" id="kt_todo_list">
                                        <div className="kt-portlet__body kt-portlet__body--fit-x">
                                            {/* <div className="kt-todo__head">
                                                <div className="kt-todo__toolbar">
                                                    <div className="kt-todo__actions kt-todo__actions--expanded">
                                                        <div className="kt-todo__check">
                                                            <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>

                                                            <div className="btn-group">
                                                                <button type="button" className="kt-todo__icon kt-todo__icon--sm kt-todo__icon--light" data-toggle="dropdown">
                                                                    <i className="flaticon2-down"></i>
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu-left dropdown-menu-fit dropdown-menu-xs">
                                                                    <ul className="kt-nav">
                                                                        <li className="kt-nav__item kt-nav__item--active">
                                                                            <a href="#" className="kt-nav__link">
                                                                                <span className="kt-nav__link-text">All</span>
                                                                            </a>
                                                                        </li>
                                                                        <li className="kt-nav__item">
                                                                            <a href="#" className="kt-nav__link">
                                                                                <span className="kt-nav__link-text">Read</span>
                                                                            </a>
                                                                        </li>
                                                                        <li className="kt-nav__item">
                                                                            <a href="#" className="kt-nav__link">
                                                                                <span className="kt-nav__link-text">Unread</span>
                                                                            </a>
                                                                        </li>
                                                                        <li className="kt-nav__item">
                                                                            <a href="#" className="kt-nav__link">
                                                                                <span className="kt-nav__link-text">Starred</span>
                                                                            </a>
                                                                        </li>
                                                                        <li className="kt-nav__item">
                                                                            <a href="#" className="kt-nav__link">
                                                                                <span className="kt-nav__link-text">Unstarred</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" title="" data-original-title="Reload list">
                                                                <i className="flaticon2-refresh-arrow"></i>
                                                            </button>
                                                        </div>

                                                        <div className="kt-todo__panel">
                                                            <button className="kt-todo__icon" data-toggle="kt-tooltip" title="" data-original-title="Archive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                                        <path d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,12 C19,12.5522847 18.5522847,13 18,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M7.5,5 C7.22385763,5 7,5.22385763 7,5.5 C7,5.77614237 7.22385763,6 7.5,6 L13.5,6 C13.7761424,6 14,5.77614237 14,5.5 C14,5.22385763 13.7761424,5 13.5,5 L7.5,5 Z M7.5,7 C7.22385763,7 7,7.22385763 7,7.5 C7,7.77614237 7.22385763,8 7.5,8 L10.5,8 C10.7761424,8 11,7.77614237 11,7.5 C11,7.22385763 10.7761424,7 10.5,7 L7.5,7 Z" fill="#000000" opacity="0.3"></path>
                                                                        <path d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z" fill="#000000"></path>
                                                                    </g>
                                                                </svg>                                        </button>
                                                            <button className="kt-todo__icon" data-toggle="kt-tooltip" title="" data-original-title="Spam">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                                                                        <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"></rect>
                                                                        <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"></rect>
                                                                    </g>
                                                                </svg>                                        </button>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn btn-label-success btn-upper btn-sm btn-bold">new task</button>
                                                </div>
                                            </div> */}

                                            <div className="kt-todo__body">
                                                <div className="kt-todo__items" data-type="task">
                                                    <div className="kt-todo__item kt-todo__item--unread" data-id="1" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--on kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">Digital PPV Customer Confirmation</span>
                                                            </div>
                                                            <div className="kt-todo__labels">
                                                                <span className="kt-todo__label kt-badge kt-badge--unified-brand kt-badge--bold kt-badge--inline">inbox</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime" data-toggle="view">
                                                            8:30 PM
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Sean Paul">
                                                            <span className="kt-media kt-media--sm kt-media--danger"
                                                            // style="background-image: url('/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_13.jpg')"
                                                            >
                                                                <span></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="kt-todo__item kt-todo__item--unread" data-id="2" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">Your iBuy.com grocery shopping confirmation</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime" data-toggle="view">
                                                            day ago
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Oliver	Jake">
                                                            <span className="kt-media kt-media--sm kt-media--danger">
                                                                <span>OJ</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="kt-todo__item" data-id="3" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">Your Order #224820998666029 has been Confirmed</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime" data-toggle="view">
                                                            11:20PM
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Enrico Fermi">
                                                            <span className="kt-media kt-media--sm kt-media--brand">
                                                                <span>EF</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="kt-todo__item" data-id="4" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--on kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--on kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">Payment Notification DLOP2329KD</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime" data-toggle="view">
                                                            2 days ago
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Jane Goodall">
                                                            <span className="kt-media kt-media--sm kt-media--brand"
                                                            // style="background-image: url('/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_11.jpg')"
                                                            >
                                                                <span></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="kt-todo__item" data-id="5" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">Congratulations on your iRun Coach subscription</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime" data-toggle="view">
                                                            Jul 25
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Matt Peares">
                                                            <span className="kt-media kt-media--sm kt-media--success">
                                                                <span>MP</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="kt-todo__item" data-id="6" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">Pay bills &amp; win up to 600$ Cashback!</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime" data-toggle="view">
                                                            July 24
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Rita Levi-Montalcini">
                                                            <span className="kt-media kt-media--sm kt-media--brand"
                                                            // style="background-image: url('/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_7.jpg')"
                                                            >
                                                                <span></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="kt-todo__item kt-todo__item--unread" data-id="7" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--on kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">Activate your LIPO Account today</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime" data-toggle="view">
                                                            Jun 13
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Stephen Hawking">
                                                            <span className="kt-media kt-media--sm kt-media--brand"
                                                            // style="background-image: url('/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_8.jpg')"
                                                            >
                                                                <span></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="kt-todo__item" data-id="8" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--on kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">About your request for PalmLake</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime" data-toggle="view">
                                                            25 May
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Wolfgang Ernst Pauli">
                                                            <span className="kt-media kt-media--sm kt-media--dark">
                                                                <span>WE</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="kt-todo__item" data-id="9" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">Verification of your card transaction</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime" data-toggle="view">
                                                            May 23
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Sarah Boysen">
                                                            <span className="kt-media kt-media--sm"
                                                            // style="background-image: url('/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg')"
                                                            >
                                                                <span></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="kt-todo__item" data-id="10" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">Payment Notification (DE223232034)</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime" data-toggle="view">
                                                            Apr 12
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Max Born">
                                                            <span className="kt-media kt-media--sm kt-media--brand"
                                                            // style="background-image: url('/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_14.jpg')"
                                                            >
                                                                <span></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="kt-todo__item" data-id="11" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">Welcome, Patty</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime" data-toggle="view">
                                                            Mar 1
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Patty Jo Watson">
                                                            <span className="kt-media kt-media--sm kt-media--brand"
                                                            // style="background-image: url('/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_5.jpg')"
                                                            >
                                                                <span></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="kt-todo__item" data-id="12" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">Optimize with Recommendations, now used by most advertisers</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime" data-toggle="view">
                                                            Feb 11
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Roberts O'Neill Wilson">
                                                            <span className="kt-media kt-media--sm kt-media--warning">
                                                                <span>RW</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="kt-todo__item" data-id="13" data-type="task">
                                                        <div className="kt-todo__info">
                                                            <div className="kt-todo__actions">
                                                                <label className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand d-none">
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Star">
                                                                    <i className="flaticon-star"></i>
                                                                </span>
                                                                <span className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Mark as important">
                                                                    <i className="flaticon-add-label-button"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__details" data-toggle="view">
                                                            <div className="kt-todo__message">
                                                                <span className="kt-todo__subject">Free Video Marketing Guide</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__datetime">
                                                            Jan 24
                                        </div>
                                                        <div className="kt-todo__sender" data-toggle="kt-tooltip" data-placement="top" title="" data-original-title="Blaise Pascal">
                                                            <span className="kt-media kt-media--sm kt-media--brand"
                                                            // style="background-image: url('/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg')"
                                                            >
                                                                <span></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="kt-todo__foot">
                                                <div className="kt-todo__toolbar">
                                                    <div className="kt-todo__controls">
                                                        <div className="kt-todo__pages" data-toggle="kt-tooltip" title="" data-original-title="Records per page">
                                                            <span className="kt-todo__perpage" data-toggle="dropdown">1 - 50 of 235</span>
                                                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-fit dropdown-menu-xs">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <span className="kt-nav__link-text">20 per page</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item kt-nav__item--active">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <span className="kt-nav__link-text">50 par page</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <span className="kt-nav__link-text">100 per page</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <button className="kt-todo__icon" data-toggle="kt-tooltip" title="" data-original-title="Previose page">
                                                            <i className="flaticon2-left-arrow"></i>
                                                        </button>

                                                        <button className="kt-todo__icon" data-toggle="kt-tooltip" title="" data-original-title="Next page">
                                                            <i className="flaticon2-right-arrow"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6">

                                    <div className="kt-grid__item kt-grid__item--fluid  kt-portlet kt-portlet--height-fluid kt-todo__view" id="kt_todo_view">

                                        <div className="kt-portlet__body kt-portlet__body--fit-y">

                                            <div className="kt-todo__wrapper">

                                                <div className="kt-todo__head">
                                                    <div className="kt-todo__toolbar">
                                                        <div className="kt-todo__info">
                                                            <span className="kt-media kt-media--sm" data-toggle="expand"
                                                            // style="background-image: url('/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_13.jpg')"
                                                            >
                                                                <span></span>
                                                            </span>
                                                            <a href="#" className="kt-todo__name">
                                                                Dave Stone
                                            </a>
                                                        </div>
                                                        <div className="kt-todo__details">
                                                            <a href="#" className="kt-todo__icon kt-todo__icon--back">
                                                                <i className="flaticon2-left-arrow-1"></i>
                                                            </a>

                                                            <a href="#" className="kt-todo__icon" data-toggle="kt-tooltip" title="" data-original-title="Archive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                                        <path d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,12 C19,12.5522847 18.5522847,13 18,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M7.5,5 C7.22385763,5 7,5.22385763 7,5.5 C7,5.77614237 7.22385763,6 7.5,6 L13.5,6 C13.7761424,6 14,5.77614237 14,5.5 C14,5.22385763 13.7761424,5 13.5,5 L7.5,5 Z M7.5,7 C7.22385763,7 7,7.22385763 7,7.5 C7,7.77614237 7.22385763,8 7.5,8 L10.5,8 C10.7761424,8 11,7.77614237 11,7.5 C11,7.22385763 10.7761424,7 10.5,7 L7.5,7 Z" fill="#000000" opacity="0.3"></path>
                                                                        <path d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z" fill="#000000"></path>
                                                                    </g>
                                                                </svg>                                            </a>
                                                            <a href="#" className="kt-todo__icon" data-toggle="kt-tooltip" title="" data-original-title="Spam">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                                                                        <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"></rect>
                                                                        <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"></rect>
                                                                    </g>
                                                                </svg>                                            </a>
                                                            <a href="#" className="kt-todo__icon" data-toggle="kt-tooltip" title="" data-original-title="Delete">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                                        <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"></path>
                                                                        <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
                                                                    </g>
                                                                </svg>                                            </a>
                                                            <button type="button" className="btn btn-label-danger btn-upper btn-sm btn-bold">may 07</button>
                                                            <button type="button" className="btn btn-label-success btn-upper btn-sm btn-bold">complete</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="kt-todo__body">
                                                    <div className="kt-todo__title">
                                                        <a href="#" className="kt-todo__text">Company Meeting Agenda</a>
                                                        <div className="kt-todo__labels">
                                                            <span className="kt-todo__label">
                                                                <i className="kt-todo__dot fa fa-genderless kt-font-info"></i>
                                                                <span className="kt-todo__text">In Progres</span>
                                                            </span>
                                                            <span className="kt-todo__label">
                                                                <i className="kt-todo__dot fa fa-genderless kt-font-danger"></i>
                                                                <span className="kt-todo__text">Urgent</span>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="kt-todo__desc">
                                                        With respect, imust disagree with Mr.Zinsser.We all know the
                                                        most part important part of any article is the title.Without a compell
                                    </div>

                                                    <div className="kt-todo__files">
                                                        <span className="kt-todo__file">
                                                            <i className="flaticon2-clip-symbol kt-font-warning"></i>
                                                            <a href="#">Agreement Samle.pdf</a>
                                                        </span>
                                                        <span className="kt-todo__file">
                                                            <i className="flaticon2-clip-symbol kt-font-warning"></i>
                                                            <a href="#">Requirements.docx</a>
                                                        </span>
                                                    </div>

                                                    <div className="kt-todo__comments">
                                                        <div className="kt-todo__comment">
                                                            <div className="kt-todo__box">
                                                                <span className="kt-media kt-media--sm" data-toggle="expand"
                                                                // style="background-image: url('/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_1.jpg')"
                                                                >
                                                                    <span></span>
                                                                </span>
                                                                <a href="#" className="kt-todo__username">
                                                                    Matt Peares
                                                </a>
                                                                <span className="kt-todo__date">
                                                                    Yestarday
                                                </span>
                                                            </div>
                                                            <span className="kt-todo__text">
                                                                With respect, imust disagree with Mr.Zinsser.We all know the
                                                                most part important part of any article is the title.
                                            </span>
                                                        </div>
                                                        <div className="kt-todo__comment">
                                                            <div className="kt-todo__box">
                                                                <span className="kt-media kt-media--sm" data-toggle="expand"
                                                                // style="background-image: url('/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_4.jpg')"
                                                                >
                                                                    <span></span>
                                                                </span>
                                                                <a href="#" className="kt-todo__username">
                                                                    Jane Miller
                                                </a>
                                                                <span className="kt-todo__date">
                                                                    3 Days Ago
                                                </span>
                                                            </div>
                                                            <span className="kt-todo__text">
                                                                With respect, imust disagree with Mr.Zinsser.We all know the
                                                                most part important part of any article is the title.
                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="kt-todo__foot">
                                                <div className="kt-todo__form" id="kt_todo_post_form">
                                                    <div className="kt-todo__body">
                                                        <div className="ql-toolbar ql-snow">
                                                            <span className="ql-formats">
                                                                <span className="ql-header ql-picker">
                                                                    <span className="ql-picker-label" tabIndex={0} role="button" >
                                                                        <svg viewBox="0 0 18 18">
                                                                            <polygon className="ql-stroke" points="7 11 9 13 11 11 7 11"></polygon>
                                                                            <polygon className="ql-stroke" points="7 7 9 5 11 7 7 7"></polygon>
                                                                        </svg>
                                                                    </span>
                                                                    <span className="ql-picker-options" aria-hidden="true" tabindex="-1" id="ql-picker-options-0">
                                                                        <span tabindex="0" role="button" className="ql-picker-item" data-value="1"></span>
                                                                        <span tabindex="0" role="button" className="ql-picker-item" data-value="2"></span>
                                                                        <span tabindex="0" role="button" className="ql-picker-item" data-value="3"></span>
                                                                        <span tabindex="0" role="button" className="ql-picker-item ql-selected"></span>
                                                                    </span>
                                                                </span>
                                                                <select className="ql-header" style="display: none;"><option value="1"></option><option value="2"></option><option value="3"></option><option selected="selected"></option></select></span><span className="ql-formats"><button type="button" className="ql-bold"><svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path> <path className="ql-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path> </svg></button><button type="button" className="ql-italic"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line> <line className="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line> <line className="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line> </svg></button><button type="button" className="ql-underline"><svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect> </svg></button><button type="button" className="ql-link"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="11" y1="7" y2="11"></line> <path className="ql-even ql-stroke" d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"></path> <path className="ql-even ql-stroke" d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"></path> </svg>
                                                                </button></span><span className="ql-formats">
                                                                <button type="button" className="ql-list" value="ordered"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> </svg></button><button type="button" className="ql-list" value="bullet"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg></button></span><span className="ql-formats"><button type="button" className="ql-clean"><svg className="" viewBox="0 0 18 18"> <line className="ql-stroke" x1="5" x2="13" y1="3" y2="3"></line> <line className="ql-stroke" x1="6" x2="9.35" y1="12" y2="3"></line> <line className="ql-stroke" x1="11" x2="15" y1="11" y2="15"></line> <line className="ql-stroke" x1="15" x2="11" y1="11" y2="15"></line> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="7" x="2" y="14"></rect> </svg>

                                                                </button></span></div><div className="kt-todo__editor ql-container ql-snow" id="kt_todo_post_editor" style="height: 175px;"><div className="ql-editor ql-blank" data-gramm="false" contenteditable="true" data-placeholder="Type message..."><p><br /></p></div><div className="ql-clipboard" contentEditable={true} tabIndex={0}></div><div className="ql-tooltip ql-hidden"><a className="ql-preview" rel="noopener noreferrer" target="_blank" href="about:blank"></a><input type="text" data-formula="e=mc^2" data-link="https://quilljs.com" data-video="Embed URL"><a className="ql-action"></a>
                                                                    <a className="ql-remove"></a>
                                                                    </div>
                                                                </div>
                                                            <div className="kt-todo__attachments">
                                                                <div className="dropzone dropzone-multi" id="kt_todo_post_attachments">
                                                                    <div className="dropzone-items">

                                                                    </div>
                                                                    <div className="dz-default dz-message"><button className="dz-button" type="button">Drop files here to upload</button></div></div>
                                                            </div>
                                                        </div>
                                                        <div className="kt-todo__foot">
                                                            <div className="kt-todo__primary">
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn btn-brand btn-bold">
                                                                        Commment
                                                    </button>
                                                                    <button type="button" className="btn btn-brand btn-bold dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    </button>

                                                                    <div className="dropdown-menu dropup dropdown-menu-fit dropdown-menu-right">
                                                                        <ul className="kt-nav">
                                                                            <li className="kt-nav__item">
                                                                                <a href="#" className="kt-nav__link">
                                                                                    <i className="kt-nav__link-icon flaticon2-writing"></i>
                                                                                    <span className="kt-nav__link-text">Schedule Send</span>
                                                                                </a>
                                                                            </li>
                                                                            <li className="kt-nav__item">
                                                                                <a href="#" className="kt-nav__link">
                                                                                    <i className="kt-nav__link-icon flaticon2-medical-records"></i>
                                                                                    <span className="kt-nav__link-text">Save &amp; archive</span>
                                                                                </a>
                                                                            </li>
                                                                            <li className="kt-nav__item">
                                                                                <a href="#" className="kt-nav__link">
                                                                                    <i className="kt-nav__link-icon flaticon2-hourglass-1"></i>
                                                                                    <span className="kt-nav__link-text">Cancel</span>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="kt-todo__panel">
                                                                    <button className="kt-todo__icon kt-todo__icon--light dz-clickable" id="kt_todo_post_attachments_select">
                                                                        <i className="flaticon2-clip-symbol"></i>
                                                                    </button>
                                                                    <button className="kt-todo__icon kt-todo__icon--light">
                                                                        <i className="flaticon2-pin"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="kt-todo__secondary">
                                                                <button className="kt-todo__icon kt-todo__icon--light" data-toggle="kt-tooltip" title="" data-original-title="More actions">
                                                                    <i className="flaticon2-settings"></i>
                                                                </button>
                                                                <button className="kt-todo__icon kt-todo__icon--remove kt-todo__icon--light" data-toggle="kt-tooltip" title="" data-original-title="Dismiss reply">
                                                                    <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div> */}

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div >
            </div>
        )
    }

    loadList = () => {
        const { page, limit } = this.state;
        getProjectList(page, limit).then((res: any) => {
            this.setState({
                detail: res
            })
        }).catch((err: any) => {
            console.log(err);
        })
    }
}

export default ListAttendance;
