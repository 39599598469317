import * as React from 'react';
import { onChange, validateForm, setOptions } from './../../utils';
import { getDesignationList } from '../../action/SettingsActions';
import ModalWindow from '../common/ModalWindow';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            roleId: { name: 'roleId', value: detail ? detail.roleId : '', error: '', isRequired: true, options: [] },
            // competency: { name: 'competency', value: detail ? detail.competency : [], error: '', isRequired: false },
            competency: [],
            showAddModal: false
        }
    }

    componentDidMount() {
        getDesignationList().then((res: any) => {
            setOptions(this, this.state.roleId.name, res.result);
        });
    }

    public render() {
        const { roleId, competency, showAddModal } = this.state;

        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-12 form-group">
                            <label>Role *</label>
                            <select
                                className={roleId.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                name={roleId.name}
                                value={roleId.value}
                                onChange={this.onChange}
                            >
                                <option>Select a Role</option>
                                {
                                    roleId.options.map(function (item: any, index: number) {
                                        return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-lg-12 form-group">
                            <label>Competency *</label>
                            <a href="javascript:;" onClick={this.onAddCompetency} className="float-right">Add Competency</a>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Competency</th>
                                        <th>Weightage %</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        competency.map((item: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.competency}</td>
                                                    <td>{item.weightage}</td>
                                                    <td className="text-right"><a href="javascript:;" className="text-danger" onClick={this.onDelete}><i className="fas fa-times" /></a></td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        competency.length === 0 && <tr>
                                            <td colSpan={4} className="text-center">
                                                Click on the "Add Competency" button to add competency
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-12"><small>* Indicates required fields</small></div>
                    </div >
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-3">
                            <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                        </div>
                    </div>
                </form >
                {
                    showAddModal && <ModalWindow
                        title="Competency detail"
                        backdrop="static"
                        toggleModal={this.onCancelCompetency}>
                        <CompetencyComponent
                            onSubmit={this.onSubmitCompetency}
                            onCancel={this.onCancelCompetency}
                        />
                    </ModalWindow>
                }
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const model: any = {
                name: this.state.name.value,
                description: this.state.description.value
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }

    onDelete = (e: any) => {

    }

    onAddCompetency = () => {
        this.setState({ showAddModal: true });
    }

    onSubmitCompetency = (model: any) => {
        const { competency } = JSON.parse(JSON.stringify(this.state));
        competency.push(model);
        this.setState({ competency, showAddModal: false });
    }

    onCancelCompetency = (e: any) => {
        this.setState({ showAddModal: false });
    }
}

class CompetencyComponent extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            competency: { name: 'competency', value: detail ? detail.competency : '', error: '', isRequired: true },
            weightage: { name: 'weightage', value: detail ? detail.weightage : '', error: '', isRequired: true },
        }
    }

    public render() {
        const { competency, weightage } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-12 form-group">
                            <label>Competency *</label>
                            <textarea
                                rows={3}
                                className={competency.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Competency"
                                name={competency.name}
                                value={competency.value}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="col-lg-12 form-group">
                            <label>Weightage *</label>
                            <input
                                type="number"
                                className={weightage.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Weightage"
                                name={weightage.name}
                                value={weightage.value}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="col-md-12"><small>* Indicates required fields</small></div>
                    </div >
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-3">
                            <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                        </div>
                    </div>
                </form >
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const model: any = {
                competency: this.state.competency.value,
                weightage: this.state.weightage.value
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }

    onCancel = (e: any) => {
        this.setState({ showAddModal: false });
    }
}

export default AddComponent;
