import * as React from 'react';
import NumberFormat from 'react-number-format';
import { getLoginStatusList, getRoleList } from './../../action/ConfigAction';
import { onChange, validateForm, setOptions } from '../../utils';

class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            name: { value: detail ? detail.name : '', name: 'name', error: '', isRequired: true },
            mobile: { value: detail ? detail.mobile : '', name: 'mobile', error: '', isRequired: true },
            email: { value: detail ? detail.email : '', name: 'email', error: '', isRequired: false },
            loginStatusId: { value: detail ? detail.loginStatusId : '', name: 'loginStatusId', error: '', isRequired: true, options: [] },
            // roleId: { value: detail ? detail.roleId : '', name: 'roleId', error: '', isRequired: true, options: [] },
            isOnboard: { value: detail ? detail.isOnboard : '', name: 'isOnboard', error: '', isRequired: true }
        }
    }

    componentDidMount() {
        getLoginStatusList().then((res: any) => {
            setOptions(this, this.state.loginStatusId.name, res.result);
        });

        // getRoleList().then((res: any) => {
        //     setOptions(this, this.state.roleId.name, res.result);
        // });
    }

    public render() {
        const { name, email, mobile, loginStatusId, roleId, isOnboard } = this.state;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Master Detail
                                    </h3>
                                </div>
                            </div>

                            <form className="kt-form kt-form--label-right" onSubmit={this.onSubmit}>
                                <div className="kt-portlet__body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Name *</label>
                                                <input
                                                    type="text"
                                                    className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    placeholder="Name of the employee"
                                                    name={name.name}
                                                    value={name.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Mobile *</label>
                                                <NumberFormat
                                                    maxLength={10}
                                                    allowNegative={false}
                                                    className={mobile.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    placeholder="Enter mobile number"
                                                    name={mobile.name}
                                                    value={mobile.value}
                                                    onChange={this.onChange}
                                                />
                                                <small>* Will also be the password during inital login</small>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    className={email.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    placeholder="Email of the employee"
                                                    name={email.name}
                                                    value={email.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Is onboard? *</label>
                                                <select
                                                    className={isOnboard.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    name={isOnboard.name}
                                                    value={isOnboard.value}
                                                    onChange={this.onChange}
                                                >
                                                    <option value="">Select onboarding status</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>login status*</label>
                                                <select
                                                    className={loginStatusId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    name={loginStatusId.name}
                                                    value={loginStatusId.value}
                                                    onChange={this.onChange}
                                                >
                                                    <option value="">Select login status</option>
                                                    {
                                                        loginStatusId.options.map((item: any, index: number) => {
                                                            return (
                                                                <option value={item.id} key={index}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {
                                    !this.props.isSelf && <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-md-9"></div>
                                                <div className="col-md-3">
                                                    <button type="submit" className="btn btn-brand btn-bold btn-block">Save Changes</button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            // <form onSubmit={this.onSubmit}>
            //     <div className="card mt-3">
            //         <div className="card-header">
            //             Master detail
            //                         </div>
            //         <div className="card-body">
            //             <div className="row">
            //                 <div className="col-lg-12 form-group">
            //                     <label>Name *</label>
            //                     <input
            //                         type="text"
            //                         className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
            //                         placeholder="Name of the employee"
            //                         name={name.name}
            //                         value={name.value}
            //                         onChange={this.onChange}
            //                     />

            //                 </div>
            //             </div>
            //             <div className="row">
            //                 <div className="col-lg-6">
            //                     <div className=" form-group">
            //                         <label>Mobile number *</label>
            //                         <NumberFormat
            //                             maxLength={10}
            //                             allowNegative={false}
            //                             className={mobile.error.length > 0 ? "form-control is-invalid" : "form-control"}
            //                             placeholder="Enter mobile number"
            //                             name={mobile.name}
            //                             value={mobile.value}
            //                             onChange={this.onChange}
            //                         />
            //                         <small>* Will also be the password during inital login</small>
            //                     </div>
            //                 </div>
            //                 <div className="col-lg-6">
            //                     <div className="form-group">
            //                         <label>Email</label>
            //                         <input
            //                             type="email"
            //                             className={email.error.length > 0 ? "form-control is-invalid" : "form-control"}
            //                             placeholder="Login email of the employee"
            //                             name={email.name}
            //                             value={email.value}
            //                             onChange={this.onChange}
            //                         />
            //                     </div>
            //                 </div>
            //                 {/* <div className="col-lg-4">
            //                     <div className="form-group">
            //                         <label>Role*</label>
            //                         <select
            //                             className={roleId.error.length > 0 ? "form-control is-invalid" : "form-control"}
            //                             name={roleId.name}
            //                             value={roleId.value}
            //                             onChange={this.onChange}
            //                         >
            //                             <option value="">Select role</option>
            //                             {
            //                                 roleId.options.map((item: any, index: number) => {
            //                                     return (
            //                                         <option value={item.id} key={index}>{item.name}</option>
            //                                     )
            //                                 })
            //                             }
            //                         </select>
            //                     </div>
            //                 </div> */}
            //                 <div className="col-lg-8">
            //                     <div className="form-group">
            //                         <label>is onboard? *</label>
            //                         <select
            //                             className={isOnboard.error.length > 0 ? "form-control is-invalid" : "form-control"}
            //                             name={isOnboard.name}
            //                             value={isOnboard.value}
            //                             onChange={this.onChange}
            //                         >
            //                             <option value="true">Yes</option>
            //                             <option value="false">No</option>
            //                         </select>
            //                     </div>
            //                 </div>
            //                 <div className="col-lg-4">
            //                     <div className="form-group">
            //                         <label>login status*</label>
            //                         <select
            //                             className={loginStatusId.error.length > 0 ? "form-control is-invalid" : "form-control"}
            //                             name={loginStatusId.name}
            //                             value={loginStatusId.value}
            //                             onChange={this.onChange}
            //                         >
            //                             <option value="">Select login status</option>
            //                             {
            //                                 loginStatusId.options.map((item: any, index: number) => {
            //                                     return (
            //                                         <option value={item.id} key={index}>{item.name}</option>
            //                                     )
            //                                 })
            //                             }
            //                         </select>
            //                     </div>
            //                 </div>

            //             </div>
            //         </div>
            //     </div>
            //     <div className="col-lg-2 pull-right pr-0 pl-0">
            //         <div className="row">

            //             <div className="col-lg-12 pull-right mt-3 mb-3">
            //                 <button type="submit" className="btn btn-primary btn-sm">Save & Continue</button>
            //             </div>
            //         </div>
            //     </div>
            // </form>
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === this.state.isOnboard.name) {
            value = (value === 'true');
        }
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model: any = {
                name: st.name.value,
                mobile: st.mobile.value,
                email: st.email.value,
                isOnboard: st.isOnboard.value,
                loginStatusId: parseInt(st.loginStatusId.value, 10),
                // roleId: parseInt(st.roleId.value, 10),
            };
            if (st.id !== undefined) {
                model.id = st.id;
            }

            this.props.onSubmit(model);
        }
    }
}

export default EmployeeMaster;
