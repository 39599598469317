import * as React from 'react';

import AddCalendar from '../../component/company-calendar/Add';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Shimmer from '../../component/common/Shimmer';

import CONSTANT from '../../constant';
import { updateCompanyCalender, getCompanyCalendarDetail } from './../../action/CompanyCalendarAction';

class ListAttendance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const id = props.match.params.id;
        this.state = {
            id,
            detail: undefined
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const { id } = this.state;
        getCompanyCalendarDetail(id).then((res: any) => {
            this.setState({ detail: res.result });
        })
    }

    public render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Company calendar
                        </h3>

                        {/* <span className="kt-subheader__separator kt-subheader__separator--v"></span> */}

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            {/* <span className="kt-subheader__desc" id="kt_subheader_total">
                                {`${currMonthName} ${currYear}`}
                            </span> */}
                        </div>

                    </div>
                    {/* <div className="kt-subheader__toolbar">
                        <a href={CONSTANT.url.addCompanyLocation} className="btn btn-label-brand btn-bold">Add company location</a>
                    </div> */}
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { detail } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                {
                    detail === undefined ? <Shimmer /> :
                        <AddCalendar
                            detail={detail}
                            onSubmit={this.onSubmit}
                            onCancel={this.onCancel}
                        />
                }

            </div>
        )
    }

    onSubmit = (model: any) => {
        updateCompanyCalender(model).then((res: any) => {
            if (res.result) {
                window.location.href = CONSTANT.url.companyCalendar;
            } else {
                alert(res.error);
            }
        })
    }

    onCancel = () => {

    }
}

export default ListAttendance;
