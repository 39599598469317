import React from 'react';

import CONSTANT from './../../constant';
import { getEmployeeSummary } from '../../action/EmployeeAction';
import { getLocalStorageItem } from '../../utils';

interface IPropType {
    userId: string,
    isSelf: boolean
}

class ComponentName extends React.Component<IPropType, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            userId: props.userId,
            summary: undefined
        }
    }

    componentDidMount() {
        const { userId } = this.state;
        getEmployeeSummary(userId).then((res: any) => {
            this.setState({ summary: res.result });
        });
    }

    render() {
        const { summary } = this.state;
        const userId = this.props.userId;
        const isSelf = this.props.isSelf;
        const ud = getLocalStorageItem(CONSTANT.keys.userDetail);
        
        let overviewLink, masterLink, basicLink, addressLink, educationLink, experienceLink, bankLink,
            documentLink, familyLink, workLink, salaryLink, leaveLink, notificationLink, profilePictureLink,
            changePasswordLink, taxDeclarationLink, locationDetailLink, callDetailLink, assetDetailLink, reimbursementLink, reimbursementlistLink;
        const ppURL = summary && summary.profilePicture ? `${process.env.REACT_APP_BASE_URL}/profile-picture/${ud.sc}/${summary.id}/${summary.profilePicture}` : '';
        if (userId !== undefined) {
            overviewLink = CONSTANT.url.employeeOverview.replace(':id', userId);
            masterLink = CONSTANT.url.employeeMasterDetail.replace(':id', userId);
            basicLink = CONSTANT.url.employeeBasicDetail.replace(':id', userId);
            addressLink = CONSTANT.url.employeeAddressDetail.replace(':id', userId);
            educationLink = CONSTANT.url.employeeEducationDetail.replace(':id', userId);
            experienceLink = CONSTANT.url.employeeExperienceDetail.replace(':id', userId);
            bankLink = CONSTANT.url.employeeBankDetail.replace(':id', userId);
            documentLink = CONSTANT.url.employeeDocumentDetail.replace(':id', userId);
            familyLink = CONSTANT.url.employeeFamilyDetail.replace(':id', userId);
            workLink = CONSTANT.url.employeeWorkDetail.replace(':id', userId);
            salaryLink = CONSTANT.url.employeeSalaryDetail.replace(':id', userId);
            leaveLink = CONSTANT.url.employeeLeaveBalance.replace(':id', userId);
            profilePictureLink = CONSTANT.url.employeeProfilePicture.replace(':id', userId);
            notificationLink = CONSTANT.url.employeeNotificationDetail.replace(':id', userId);
            changePasswordLink = CONSTANT.url.employeeChangePassword.replace(':id', userId);
            taxDeclarationLink = CONSTANT.url.employeeTaxDeclarationDetail.replace(':id', userId);
            locationDetailLink = CONSTANT.url.employeeLocationDetail.replace(':id', userId);
            callDetailLink = CONSTANT.url.employeeCallDetail.replace(':id', userId);
            // assetDetailLink = CONSTANT.url.employeeAssetDetail.replace(':id', userId);
            reimbursementLink = CONSTANT.url.employeeReimbursementDetail.replace(':id', userId);
            reimbursementlistLink = CONSTANT.url.employeeReimbursementListDetail.replace(':id', userId);
        }
        if (isSelf) {
            overviewLink = CONSTANT.url.profileOverview;
            masterLink = CONSTANT.url.profileMasterDetail;
            basicLink = CONSTANT.url.profileBasicDetail;
            addressLink = CONSTANT.url.profileAddressDetail;
            educationLink = CONSTANT.url.profileEducationDetail;
            experienceLink = CONSTANT.url.profileExperienceDetail;
            bankLink = CONSTANT.url.profileBankDetail;
            documentLink = CONSTANT.url.profileDocumentDetail;
            familyLink = CONSTANT.url.profileFamilyDetail;
            workLink = CONSTANT.url.profileWorkDetail;
            salaryLink = CONSTANT.url.profileSalaryDetail;
            leaveLink = CONSTANT.url.profileLeaveBalance;
            profilePictureLink = CONSTANT.url.profileProfilePicture;
            notificationLink = CONSTANT.url.profileNotificationDetail;
            changePasswordLink = CONSTANT.url.profileChangePassword;
            taxDeclarationLink = CONSTANT.url.profileTaxDeclaration;
            // assetDetailLink = CONSTANT.url.profileAssetDetail;
            reimbursementLink = CONSTANT.url.profileReimbursementDetail;
            reimbursementlistLink = CONSTANT.url.profileReimbursementListDetail;
        }

        return (
            <div className="kt-grid__item kt-app__toggle kt-app__aside" id="kt_user_profile_aside">
                <div className="kt-portlet kt-portlet--height-fluid-">
                    <div className="kt-portlet__head  kt-portlet__head--noborder">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                            </h3>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit-y">
                        <div className="kt-widget kt-widget--user-profile-1">
                            {
                                summary && <div className="kt-widget__head">
                                    {
                                        summary.profilePicture !== null ? <div className="kt-widget__media">
                                            <img src={ppURL} alt="image" />
                                        </div> : <div style={{
                                            width: 90, height: 90,
                                            backgroundColor: '#eee',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            borderRadius: 3,
                                            alignItems: 'center'
                                        }} className="kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest kt-hidden-">
                                                {summary.name.split(' ').map((i: any) => i.substring(0, 1))}
                                            </div>
                                    }


                                    <div className="kt-widget__content">
                                        <div className="kt-widget__section">
                                            <a href="#" className="kt-widget__username">
                                                {summary.name}
                                                {/* <i className="flaticon2-correct kt-font-success" title="Onboarding complete"></i> */}
                                            </a>
                                            <span className="kt-widget__subtitle">
                                                {summary.designation}
                                            </span>
                                        </div>
                                        <div className="kt-widget__action">
                                            {/* <button type="button" className="btn btn-info btn-sm">chat</button> */}
                                            {/* <button type="button" className="btn btn-success btn-sm">follow</button> */}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="kt-widget__body">
                                {
                                    summary && <div className="kt-widget__content">
                                        <div className="kt-widget__info">
                                            <span className="kt-widget__label">Email:</span>
                                            <a href="#" className="kt-widget__data">{summary.email}</a>
                                        </div>
                                        <div className="kt-widget__info">
                                            <span className="kt-widget__label">Phone:</span>
                                            <a href="#" className="kt-widget__data">{summary.mobile}</a>
                                        </div>
                                        <div className="kt-widget__info">
                                            <span className="kt-widget__label">Employee #:</span>
                                            <a href="#" className="kt-widget__data">{summary.employeeId}</a>
                                        </div>
                                    </div>
                                }
                                <div className="kt-widget__items">
                                    {
                                        userId !== undefined && <a href={overviewLink} className={window.location.href.indexOf(`${overviewLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                            <span className="kt-widget__section">
                                                <span className="kt-widget__icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero"></path>
                                                            <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span className="kt-widget__desc">
                                                    Overview
                                        </span>
                                            </span>
                                        </a>
                                    }
                                    <a href={masterLink} className={window.location.href.indexOf(`${masterLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <polygon fill="#000000" opacity="0.3" points="6 3 18 3 20 6.5 4 6.5" />
                                                        <path d="M6,5 L18,5 C19.1045695,5 20,5.8954305 20,7 L20,19 C20,20.1045695 19.1045695,21 18,21 L6,21 C4.8954305,21 4,20.1045695 4,19 L4,7 C4,5.8954305 4.8954305,5 6,5 Z M9,9 C8.44771525,9 8,9.44771525 8,10 C8,10.5522847 8.44771525,11 9,11 L15,11 C15.5522847,11 16,10.5522847 16,10 C16,9.44771525 15.5522847,9 15,9 L9,9 Z" fill="#000000" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Master Detail
                                        </span>
                                        </span>
                                    </a>
                                    <a href={basicLink} className={window.location.href.indexOf(`${basicLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Basic Detail
                                        </span>
                                        </span>
                                    </a>
                                    <a href={addressLink} className={window.location.href.indexOf(`${addressLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M3.95709826,8.41510662 L11.47855,3.81866389 C11.7986624,3.62303967 12.2013376,3.62303967 12.52145,3.81866389 L20.0429,8.41510557 C20.6374094,8.77841684 21,9.42493654 21,10.1216692 L21,19.0000642 C21,20.1046337 20.1045695,21.0000642 19,21.0000642 L4.99998155,21.0000673 C3.89541205,21.0000673 2.99998155,20.1046368 2.99998155,19.0000673 L2.99999828,10.1216672 C2.99999935,9.42493561 3.36258984,8.77841732 3.95709826,8.41510662 Z M10,13 C9.44771525,13 9,13.4477153 9,14 L9,17 C9,17.5522847 9.44771525,18 10,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,14 C15,13.4477153 14.5522847,13 14,13 L10,13 Z" fill="#000000" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Address Detail
                                        </span>
                                        </span>
                                    </a>
                                    <a href={familyLink} className={window.location.href.indexOf(`${familyLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                        <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                        <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Family Detail
                                        </span>
                                        </span>
                                    </a>
                                    <a href={bankLink} className={window.location.href.indexOf(`${bankLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z" fill="#000000" opacity="0.3" transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) " />
                                                        <path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z" fill="#000000" />
                                                    </g>
                                                </svg>


                                            </span>
                                            <span className="kt-widget__desc">
                                                Bank Detail
                                        </span>
                                        </span>
                                    </a>
                                    <a href={educationLink} className={window.location.href.indexOf(`${educationLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M13.6855025,18.7082217 C15.9113859,17.8189707 18.682885,17.2495635 22,17 C22,16.9325178 22,13.1012863 22,5.50630526 L21.9999762,5.50630526 C21.9999762,5.23017604 21.7761292,5.00632908 21.5,5.00632908 C21.4957817,5.00632908 21.4915635,5.00638247 21.4873465,5.00648922 C18.658231,5.07811173 15.8291155,5.74261533 13,7 C13,7.04449645 13,10.79246 13,18.2438906 L12.9999854,18.2438906 C12.9999854,18.520041 13.2238496,18.7439052 13.5,18.7439052 C13.5635398,18.7439052 13.6264972,18.7317946 13.6855025,18.7082217 Z" fill="#000000" />
                                                        <path d="M10.3144829,18.7082217 C8.08859955,17.8189707 5.31710038,17.2495635 1.99998542,17 C1.99998542,16.9325178 1.99998542,13.1012863 1.99998542,5.50630526 L2.00000925,5.50630526 C2.00000925,5.23017604 2.22385621,5.00632908 2.49998542,5.00632908 C2.50420375,5.00632908 2.5084219,5.00638247 2.51263888,5.00648922 C5.34175439,5.07811173 8.17086991,5.74261533 10.9999854,7 C10.9999854,7.04449645 10.9999854,10.79246 10.9999854,18.2438906 L11,18.2438906 C11,18.520041 10.7761358,18.7439052 10.4999854,18.7439052 C10.4364457,18.7439052 10.3734882,18.7317946 10.3144829,18.7082217 Z" fill="#000000" opacity="0.3" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Education Detail
                                        </span>
                                        </span>
                                    </a>
                                    <a href={experienceLink} className={window.location.href.indexOf(`${experienceLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z" fill="#000000" />
                                                        <path d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Experience Detail
                                        </span>
                                        </span>
                                    </a>
                                    <a href={documentLink} className={window.location.href.indexOf(`${documentLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><polygon points="0 0 24 0 24 24 0 24"></polygon><path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path><rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"></rect><rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"></rect></g></svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Document Detail
                                        </span>
                                        </span>
                                    </a>
                                    <a href={workLink} className={window.location.href.indexOf(`${workLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <polygon fill="#000000" opacity="0.3" transform="translate(8.885842, 16.114158) rotate(-315.000000) translate(-8.885842, -16.114158) " points="6.89784488 10.6187476 6.76452164 19.4882481 8.88584198 21.6095684 11.0071623 19.4882481 9.59294876 18.0740345 10.9659914 16.7009919 9.55177787 15.2867783 11.0071623 13.8313939 10.8837471 10.6187476" />
                                                        <path d="M15.9852814,14.9852814 C12.6715729,14.9852814 9.98528137,12.2989899 9.98528137,8.98528137 C9.98528137,5.67157288 12.6715729,2.98528137 15.9852814,2.98528137 C19.2989899,2.98528137 21.9852814,5.67157288 21.9852814,8.98528137 C21.9852814,12.2989899 19.2989899,14.9852814 15.9852814,14.9852814 Z M16.1776695,9.07106781 C17.0060967,9.07106781 17.6776695,8.39949494 17.6776695,7.57106781 C17.6776695,6.74264069 17.0060967,6.07106781 16.1776695,6.07106781 C15.3492424,6.07106781 14.6776695,6.74264069 14.6776695,7.57106781 C14.6776695,8.39949494 15.3492424,9.07106781 16.1776695,9.07106781 Z" fill="#000000" transform="translate(15.985281, 8.985281) rotate(-315.000000) translate(-15.985281, -8.985281) " />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Work Detail
                                        </span>
                                        </span>
                                    </a>
                                    <a href={salaryLink} className={window.location.href.indexOf(`${salaryLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <circle fill="#000000" opacity="0.3" cx="20.5" cy="12.5" r="1.5" />
                                                        <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 6.500000) rotate(-15.000000) translate(-12.000000, -6.500000) " x="3" y="3" width="18" height="7" rx="1" />
                                                        <path d="M22,9.33681558 C21.5453723,9.12084552 21.0367986,9 20.5,9 C18.5670034,9 17,10.5670034 17,12.5 C17,14.4329966 18.5670034,16 20.5,16 C21.0367986,16 21.5453723,15.8791545 22,15.6631844 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,9.33681558 Z" fill="#000000" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Salary Detail
                                        </span>
                                        </span>
                                    </a>
                                    <a href={leaveLink} className={window.location.href.indexOf(`${leaveLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                        <path d="M6.5,24 C5.67157288,24 5,23.3284271 5,22.5 C5,21.9477153 5.5,21.1143819 6.5,20 C7.5,21.1143819 8,21.9477153 8,22.5 C8,23.3284271 7.32842712,24 6.5,24 Z M11.5,24 C10.6715729,24 10,23.3284271 10,22.5 C10,21.9477153 10.5,21.1143819 11.5,20 C12.5,21.1143819 13,21.9477153 13,22.5 C13,23.3284271 12.3284271,24 11.5,24 Z M16.5,24 C15.6715729,24 15,23.3284271 15,22.5 C15,21.9477153 15.5,21.1143819 16.5,20 C17.5,21.1143819 18,21.9477153 18,22.5 C18,23.3284271 17.3284271,24 16.5,24 Z M13.5,13 C11.5670034,13 10,11.4329966 10,9.5 C10,7.56700338 11.5670034,6 13.5,6 C15.4329966,6 17,7.56700338 17,9.5 C17,11.4329966 15.4329966,13 13.5,13 Z M19.7419575,8.875 L21.0544575,8.875 C21.7793313,8.875 22.3669575,9.46262627 22.3669575,10.1875 C22.3669575,10.9123737 21.7793313,11.5 21.0544575,11.5 L19.7419575,11.5 C19.0170838,11.5 18.4294575,10.9123737 18.4294575,10.1875 C18.4294575,9.46262627 19.0170838,8.875 19.7419575,8.875 Z M16.7325352,4.8249053 L17.6606128,3.89682765 C18.173176,3.38426452 19.004205,3.38426452 19.5167681,3.89682765 C20.0293313,4.40939078 20.0293313,5.24041982 19.5167681,5.75298295 L18.5886905,6.6810606 C18.0761273,7.19362373 17.2450983,7.19362373 16.7325352,6.6810606 C16.219972,6.16849747 16.219972,5.33746843 16.7325352,4.8249053 Z M13.1794575,1 C13.9043313,1 14.4919575,1.58762627 14.4919575,2.3125 L14.4919575,3.625 C14.4919575,4.34987373 13.9043313,4.9375 13.1794575,4.9375 C12.4545838,4.9375 11.8669575,4.34987373 11.8669575,3.625 L11.8669575,2.3125 C11.8669575,1.58762627 12.4545838,1 13.1794575,1 Z M6.88878517,3.89682765 C7.4013483,3.38426452 8.23237734,3.38426452 8.74494047,3.89682765 L9.67301812,4.8249053 C10.1855813,5.33746843 10.1855813,6.16849747 9.67301812,6.6810606 C9.16045499,7.19362373 8.32942596,7.19362373 7.81686282,6.6810606 L6.88878517,5.75298295 C6.37622204,5.24041982 6.37622204,4.40939078 6.88878517,3.89682765 Z" fill="#000000" opacity="0.3" />
                                                        <path d="M4.74714567,18.0425758 C3.09410362,16.9740356 2,15.1147886 2,13 C2,9.6862915 4.6862915,7 8,7 C10.7957591,7 13.1449096,8.91215918 13.8109738,11.5 L16.25,11.5 C18.3210678,11.5 20,13.1789322 20,15.25 C20,17.3210678 18.3210678,19 16.25,19 L7.25,19 C6.28817895,19 5.41093178,18.6378962 4.74714567,18.0425758 Z" fill="#000000" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Leave Balance
                                        </span>
                                        </span>
                                    </a>
                                    {/* <a href={reimbursementlistLink} className={window.location.href.indexOf(`${reimbursementlistLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Reimbursement
                                        </span>
                                        </span>
                                    </a> */}

                                    {/* <a href={reimbursementLink} className={window.location.href.indexOf(`${reimbursementLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Add Reimbursement
                                        </span>
                                        </span>
                                    </a> */}


                                    <a href={profilePictureLink} className={window.location.href.indexOf(`${profilePictureLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M5,7 L19,7 C20.1045695,7 21,7.8954305 21,9 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,9 C3,7.8954305 3.8954305,7 5,7 Z M12,17 C14.209139,17 16,15.209139 16,13 C16,10.790861 14.209139,9 12,9 C9.790861,9 8,10.790861 8,13 C8,15.209139 9.790861,17 12,17 Z" fill="#000000" />
                                                        <rect fill="#000000" opacity="0.3" x="9" y="4" width="6" height="2" rx="1" />
                                                        <circle fill="#000000" opacity="0.3" cx="12" cy="13" r="2" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Profile Picture
                                        </span>
                                        </span>
                                    </a>
                                    <a href={changePasswordLink} className={window.location.href.indexOf(`${changePasswordLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <polygon fill="#000000" opacity="0.3" transform="translate(8.885842, 16.114158) rotate(-315.000000) translate(-8.885842, -16.114158) " points="6.89784488 10.6187476 6.76452164 19.4882481 8.88584198 21.6095684 11.0071623 19.4882481 9.59294876 18.0740345 10.9659914 16.7009919 9.55177787 15.2867783 11.0071623 13.8313939 10.8837471 10.6187476" />
                                                        <path d="M15.9852814,14.9852814 C12.6715729,14.9852814 9.98528137,12.2989899 9.98528137,8.98528137 C9.98528137,5.67157288 12.6715729,2.98528137 15.9852814,2.98528137 C19.2989899,2.98528137 21.9852814,5.67157288 21.9852814,8.98528137 C21.9852814,12.2989899 19.2989899,14.9852814 15.9852814,14.9852814 Z M16.1776695,9.07106781 C17.0060967,9.07106781 17.6776695,8.39949494 17.6776695,7.57106781 C17.6776695,6.74264069 17.0060967,6.07106781 16.1776695,6.07106781 C15.3492424,6.07106781 14.6776695,6.74264069 14.6776695,7.57106781 C14.6776695,8.39949494 15.3492424,9.07106781 16.1776695,9.07106781 Z" fill="#000000" transform="translate(15.985281, 8.985281) rotate(-315.000000) translate(-15.985281, -8.985281) " />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Change password
                                        </span>
                                        </span>
                                    </a>

                                    {/* <a href={taxDeclarationLink} className={window.location.href.indexOf(`${taxDeclarationLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                        <span className="kt-widget__section">
                                            <span className="kt-widget__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M16.0322024,5.68722152 L5.75790403,15.945742 C5.12139076,16.5812778 5.12059836,17.6124773 5.75613416,18.2489906 C5.75642891,18.2492858 5.75672377,18.2495809 5.75701875,18.2498759 L5.75701875,18.2498759 C6.39304347,18.8859006 7.42424328,18.8859006 8.060268,18.2498759 C8.06056298,18.2495809 8.06085784,18.2492858 8.0611526,18.2489906 L18.3196731,7.9746922 C18.9505124,7.34288268 18.9501191,6.31942463 18.3187946,5.68810005 L18.3187946,5.68810005 C17.68747,5.05677547 16.6640119,5.05638225 16.0322024,5.68722152 Z" fill="#000000" fill-rule="nonzero" />
                                                        <path d="M9.85714286,6.92857143 C9.85714286,8.54730513 8.5469533,9.85714286 6.93006028,9.85714286 C5.31316726,9.85714286 4,8.54730513 4,6.92857143 C4,5.30983773 5.31316726,4 6.93006028,4 C8.5469533,4 9.85714286,5.30983773 9.85714286,6.92857143 Z M20,17.0714286 C20,18.6901623 18.6898104,20 17.0729174,20 C15.4560244,20 14.1428571,18.6901623 14.1428571,17.0714286 C14.1428571,15.4497247 15.4560244,14.1428571 17.0729174,14.1428571 C18.6898104,14.1428571 20,15.4497247 20,17.0714286 Z" fill="#000000" opacity="0.3" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="kt-widget__desc">
                                                Tax Declaration
                                        </span>
                                        </span>
                                    </a> */}
                                    {
                                        !isSelf && <React.Fragment>
                                            <a href={locationDetailLink} className={window.location.href.indexOf(`${locationDetailLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                                <span className="kt-widget__section">
                                                    <span className="kt-widget__icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <path d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z" fill="#000000" fill-rule="nonzero" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span className="kt-widget__desc">
                                                        Location History
                                                    </span>
                                                </span>
                                            </a>
                                            <a href={callDetailLink} className={window.location.href.indexOf(`${callDetailLink}`) > -1 ? 'kt-widget__item kt-widget__item--active' : 'kt-widget__item'}>
                                                <span className="kt-widget__section">
                                                    <span className="kt-widget__icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <path d="M11.914857,14.1427403 L14.1188827,11.9387145 C14.7276032,11.329994 14.8785122,10.4000511 14.4935235,9.63007378 L14.3686433,9.38031323 C13.9836546,8.61033591 14.1345636,7.680393 14.7432841,7.07167248 L17.4760882,4.33886839 C17.6713503,4.14360624 17.9879328,4.14360624 18.183195,4.33886839 C18.2211956,4.37686904 18.2528214,4.42074752 18.2768552,4.46881498 L19.3808309,6.67676638 C20.2253855,8.3658756 19.8943345,10.4059034 18.5589765,11.7412615 L12.560151,17.740087 C11.1066115,19.1936265 8.95659008,19.7011777 7.00646221,19.0511351 L4.5919826,18.2463085 C4.33001094,18.1589846 4.18843095,17.8758246 4.27575484,17.613853 C4.30030124,17.5402138 4.34165566,17.4733009 4.39654309,17.4184135 L7.04781491,14.7671417 C7.65653544,14.1584211 8.58647835,14.0075122 9.35645567,14.3925008 L9.60621621,14.5173811 C10.3761935,14.9023698 11.3061364,14.7514608 11.914857,14.1427403 Z" fill="#000000" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span className="kt-widget__desc">
                                                        Call History
                                                    </span>
                                                </span>
                                            </a>

                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ComponentName;