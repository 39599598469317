import *as React from 'react';
import { getEmploymentTypeList } from '../../action/ConfigAction';
import { getAllEmployeeList } from '../../action/EmployeeAction';
import { onChange, validateForm, setOptions } from '../../utils';
import { getAllLocationList, getAllDesignationList, getAllDepartmentList } from '../../action/SettingsActions';

class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            userId: props.userId,
            employeeId: { value: detail ? detail.employeeId : '', name: 'employeeId', error: '', isRequired: true },
            employmentTypeId: { value: detail && detail.employmentTypeId ? detail.employmentTypeId : '', name: 'employmentTypeId', error: '', isRequired: true, options: [] },
            designationId: { value: detail && detail.designationId ? detail.designationId : '', name: 'designationId', error: '', isRequired: true, options: [] },
            dateOfJoining: { value: detail ? detail.dateOfJoining.substr(0, 10) : '', name: 'dateOfJoining', error: '', isRequired: true },
            workLocationId: { value: detail && detail.workLocationId ? detail.workLocationId : '', name: 'workLocationId', error: '', isRequired: true, options: [] },
            departmentId: { value: detail && detail.departmentId ? detail.departmentId : '', name: 'departmentId', error: '', isRequired: true, options: [] },
            reportingManagerId: { value: detail ? detail.reportingManagerId : '', name: 'reportingManagerId', error: '', isRequired: true, options: [] },
        }
    }


    componentDidMount() {
        console.log(this.state)
        getEmploymentTypeList().then((res: any) => {
            setOptions(this, this.state.employmentTypeId.name, res.result);
        });

        getAllEmployeeList().then((res: any) => {
            const cbOptions = res.result.map((item: any) => {
                return {
                    id: item.id,
                    name: item.name,
                    email: item.email
                }
            });
            setOptions(this, this.state.reportingManagerId.name, cbOptions);
        });

        getAllDepartmentList().then((res: any) => {
            setOptions(this, this.state.departmentId.name, res.result);
        });

        getAllDesignationList().then((res: any) => {
            setOptions(this, this.state.designationId.name, res.result);
        });

        getAllLocationList().then((res: any) => {
            setOptions(this, this.state.workLocationId.name, res.result);
        });
    }

    public render() {
        const { employeeId, employmentTypeId, designationId, dateOfJoining, workLocationId, departmentId, reportingManagerId } = this.state;
        console.log(this.state)
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Work Detail
                                    </h3>
                                </div>
                            </div>

                            <form className="kt-form kt-form--label-right" onSubmit={this.onSubmit}>
                                <div className="kt-portlet__body">
                                    <div className="row">
                                        <div className="col-lg-4 form-group">
                                            <label>Employee Code *</label>

                                            <input
                                                type="text"
                                                className={employeeId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter employee Id"
                                                name={employeeId.name}
                                                value={employeeId.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="col-lg-4 form-group">
                                            <label>Designation *</label>
                                            <select
                                                className={designationId.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                name={designationId.name}
                                                value={designationId.value}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    designationId.options.map(function (item: any, index: number) {
                                                        return (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {/* <input
                                                type="text"
                                                className={designationId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter designationId"
                                                name={designationId.name}
                                                value={designationId.value}
                                                onChange={this.onChange}
                                            /> */}
                                        </div>
                                        <div className="col-lg-4 form-group">
                                            <label>Department *</label>
                                            <select
                                                className={departmentId.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                name={departmentId.name}
                                                value={departmentId.value}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    departmentId.options.map(function (item: any, index: number) {
                                                        return (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {/* <input
                                                type="text"
                                                className={departmentId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter departmentId"
                                                name={departmentId.name}
                                                value={departmentId.value}
                                                onChange={this.onChange}
                                            /> */}

                                        </div>
                                        {/* <div className="col-lg-4 form-group">
                                            <label>Company Branch *</label>
                                            <select
                                                className={companyBranchId.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                name={companyBranchId.name}
                                                value={companyBranchId.value}
                                                onChange={this.onChange}
                                            >
                                                <option>Select a branch</option>
                                                {
                                                    companyBranchId.options.map(function (item: any, index: number) {
                                                        return (
                                                            <option key={index} value={item.id}>{item.name} ({item.location})</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div> */}
                                        <div className="col-lg-4 form-group">
                                            <label>Work Location *</label>
                                            {/* <input
                                                type="text"
                                                className={workLocationId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter work location"
                                                name={workLocationId.name}
                                                value={workLocationId.value}
                                                onChange={this.onChange}
                                            /> */}
                                            <select
                                                className={workLocationId.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                name={workLocationId.name}
                                                value={workLocationId.value}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    workLocationId.options.map(function (item: any, index: number) {
                                                        return (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-lg-4 form-group">
                                            <label>Employment type *</label>
                                            <select
                                                className={employmentTypeId.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                name={employmentTypeId.name}
                                                value={employmentTypeId.value}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select employement Type</option>
                                                {
                                                    employmentTypeId.options.map(function (item: any, index: number) {
                                                        return (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <label>Date Of Joining *</label>


                                            <input
                                                type="date"
                                                className={dateOfJoining.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter date of joining"
                                                name={dateOfJoining.name}
                                                value={dateOfJoining.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <label>Reporting Manager *</label>

                                            <select
                                                className={reportingManagerId.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                name={reportingManagerId.name}
                                                value={reportingManagerId.value}
                                                onChange={this.onChange}
                                            >
                                                <option>Select a reporting manager</option>
                                                {
                                                    reportingManagerId.options.map(function (item: any, index: number) {
                                                        return (
                                                            <option key={index} value={item.id}>{item.name} {item.designationId ? `- ${item.designationId}` : ''}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                {
                                    !this.props.isSelf && <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-md-9"></div>
                                                <div className="col-md-3">
                                                    <button type="submit" className="btn btn-brand btn-bold btn-block">Save Changes</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model = {
                userId: parseInt(st.userId, 10),
                employeeId: st.employeeId.value,
                designationId: parseFloat(st.designationId.value),
                departmentId: parseFloat(st.departmentId.value),
                dateOfJoining: st.dateOfJoining.value,
                workLocationId: parseFloat(st.workLocationId.value),
                employmentTypeId: parseFloat(st.employmentTypeId.value),
                reportingManagerId: parseFloat(st.reportingManagerId.value),
            };
            this.props.onSubmit(model);
        }
    }
}

export default EmployeeMaster;
