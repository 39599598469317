import * as React from 'react';
import ModalWindow from '../../component/common/ModalWindow';
import AddClaim from './AddClaim';

import { addReimbursementDetail, uploadBill } from './../../action/ReimbursementAction';
import { onChange, validateForm, setOptions, getDateParts } from '../../utils';
import CONSTANT from '../../constant';

class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;

        this.state = {
            name: { name: 'name', value: '', error: '', isRequired: true },
            claim: { name: 'claim', value: [], error: '', isRequired: true },
            showLoader: false,
            showModal: false
        }
    }

    public render() {
        const { name, claim, showModal, showLoader } = this.state;
        let totalAmount = claim.value.reduce(function (accumulator: any, currentValue: any) {
            return accumulator + parseFloat(currentValue.amount);
        }, 0);
        return (
            <React.Fragment>
                <form className="kt-form kt-form--label-right" onSubmit={this.onSubmit}>
                    <div className="kt-portlet__body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className=" form-group">
                                    <label>Name *</label>
                                    <input
                                        type="text"
                                        className={name.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                        placeholder="Name which describes the reimbursement claim"
                                        name={name.name}
                                        value={name.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 text-right">
                                <button type="button" onClick={this.onAddClaim} className="btn btn-link">+ Add Claim</button>
                            </div>
                            <div className="col-md-12">
                                <table style={{ width: '100%' }} className={claim.error.length > 0 ? "table table-bordered table-hover table-danger" : "table table-bordered table-hover"}>
                                    <tbody>
                                        <tr className="active">
                                            <th style={{ width: '3%' }}>#</th>
                                            <th>Type</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Bill Number</th>
                                            <th>Comments</th>
                                            <th>Bills</th>
                                            <th>Amount</th>
                                            <th style={{ width: '3%' }}></th>
                                        </tr>
                                        {
                                            claim.value.length === 0 && <tr>
                                                <td colSpan={9} className="text-center">No records found</td>
                                            </tr>
                                        }
                                        {
                                            claim.value.map((item: any, index: number) => {
                                                const fdt = getDateParts(item.startDate);
                                                const tdt = getDateParts(item.endDate);
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.reimbursementTypeName}</td>
                                                        <td>{`${fdt.date} ${fdt.monthName} ${fdt.year}`}</td>
                                                        <td>{`${tdt.date} ${tdt.monthName} ${tdt.year}`}</td>
                                                        <td>{item.billNumber}</td>
                                                        <td>{item.comment}</td>
                                                        <td>{item.bills.length}<i className="fa fa-info-circle ml-1" title={Array.from(item.bills).map((i: any) => i.name).join(', ')} /></td>
                                                        <td>{parseFloat(item.amount)}</td>
                                                        <td>
                                                            <button type="button" className="text-danger border-0 bg-white" onClick={this.onDeleteClaim} data-id={item.id}>
                                                                <i className="far fa-trash-alt pe-none" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr>
                                            <td colSpan={7}>Total</td>
                                            <td colSpan={1} className="font-weight-bold">{parseFloat(totalAmount)}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__foot">
                        <div className="kt-form__actions">
                            <div className="row">
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <button type="submit" className="btn btn-brand btn-bold btn-block">Save & Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {
                    showModal && <ModalWindow
                        title="Claim Detail"
                        backdrop={false}
                        toggleModal={this.onCancelClaim}>
                        <AddClaim
                            onSubmit={this.onSubmitClaim}
                            onCancel={this.onCancelClaim}
                        />
                    </ModalWindow>
                }
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {

        e.preventDefault();
        if (validateForm(this)) {
            this.setState({ showLoader: true });
            const st = this.state;
            const totalAmount = st.claim.value.reduce(function (accumulator: any, currentValue: any) {
                return accumulator + parseFloat(currentValue.amount);
            }, 0);
            const model = {
                name: st.name.value,
                totalAmount,
                claim: st.claim.value
            };

            addReimbursementDetail(model).then(async (res: any) => {
                if (res.status) {
                    for (let i = 0; i < st.claim.value.length; i++) {
                        const reimbursementId = res.result.detail.id;
                        const uid = st.claim.value[i].uid;
                        const bills = st.claim.value[i].bills;
                        for (let j = 0; j < bills.length; j++) {
                            const formData = new FormData();
                            formData.append('reimbursementId', reimbursementId);
                            formData.append('uid', uid);
                            formData.append('attachment', bills[j]);

                            await uploadBill(formData)
                        }
                    }

                    // this.props.history.push(CONSTANT.url.userETourDetail.replace(':code', st.code));
                    window.location.href = CONSTANT.url.reimbursementList;
                } else {
                    alert(res.error);
                }
                this.setState({ showLoader: false });
            });
        }
    }

    onSubmitClaim = (model: any) => {
        onChange(this, this.state.claim.name, [...this.state.claim.value, model]);
        this.onCancelClaim();
    }

    onAddClaim = () => {
        this.setState({ showModal: true });
    }

    onCancelClaim = () => {
        this.setState({ showModal: false });
    }

    onDeleteClaim = (e: any) => {
        const id = parseInt(e.target.dataset.id, 10);
        const isSure = window.confirm('Are you sure, you want to delete this record?');
        if (isSure) {
            const roadMilage = this.state.claim.value.filter((i: any) => i.id !== id);
            onChange(this, this.state.claim.name, roadMilage);
        }
    }


}

export default EmployeeMaster;
