import * as React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import UserDefaultPIC from './../../assets/media/users/default.jpg'
import CONSTANT from './../../constant';
import { getEmployeeList } from '../../action/EmployeeAction';
import TDSModal from './../../component/payroll/TDSModal';

class PayrollSubmit extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 20,
            sort: 1,
            count: 0,
            showLoader: false
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                                {this.renderChatDiv()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <TDSModal />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Regular Payroll
                        </h3>
                    </div>

                    <div className="kt-subheader__toolbar">

                        <button type="button" className="btn btn-label-brand btn-bold" onClick={this.onSubmit}>Submit and Approve</button>

                        <div className="kt-subheader__wrapper">
                            <div className="dropdown dropdown-inline" data-toggle="kt-tooltip-" title="Quick actions" data-placement="left">
                                <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <i className="flaticon-more-1"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-fit dropdown-menu-md dropdown-menu-right">
                                    <ul className="kt-nav">

                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <span className="kt-nav__link-text">Delete Payrun</span>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>
            </div>

        )
    }

    renderBody = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="row">
                    <div className="col-xl-4 col-lg-4 order-lg-1 order-xl-1">
                        <div className="kt-portlet kt-portlet--fit kt-portlet--height-fluid">
                            <div className="kt-portlet__head kt-portlet__space-x">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Period: March 2020 | 31 Payable Days
			                        </h3>
                                </div>

                            </div>
                            <div className="kt-portlet__body kt-portlet__body--fluid">
                                <div className="kt-widget12">
                                    <div className="kt-widget12__content kt-portlet__space-x kt-portlet__space-y mt-4">
                                        <div className="kt-widget12__item">
                                            <div className="kt-widget12__info">
                                                <span className="kt-widget12__value">41,31,721</span>
                                                <span className="kt-widget12__desc">PAYROLL COST</span>
                                            </div>

                                            <div className="kt-widget12__info">
                                                <span className="kt-widget12__value">38,31,721</span>
                                                <span className="kt-widget12__desc">EMPLOYEES' NET PAY</span>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-2 order-lg-1 order-xl-1">
                        <div className="kt-portlet kt-portlet--fit kt-portlet--height-fluid">

                            <div className="kt-portlet__body kt-portlet__body--fluid">
                                <div className="kt-widget12 text-center">
                                    <div className="kt-widget12__content kt-portlet__space-x kt-portlet__space-y">
                                        <div className="kt-widget12__item">
                                            <div className="kt-widget12__info">
                                                <h1 className="kt-bold mt-2">23</h1>
                                                <span className="kt-widget12__desc mt-3">PAY DAY</span>
                                                <span className="kt-widget12__desc mt-3">APR, 2020</span>
                                                <span className="kt-widget12__desc mt-3">50 Employees</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 order-lg-1 order-xl-1">
                        <div className="kt-portlet kt-portlet--height-fluid">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">Taxes & Deductions</h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-widget kt-widget--user-profile-2">
                                    <div className="kt-widget__body">
                                        <div className="kt-widget__item">
                                            <div className="kt-widget__contact"><span className="kt-widget__label">Taxes</span><a href="#" className="kt-widget__data"><h5>998,332</h5></a></div>
                                            <div className="kt-widget__contact"><span className="kt-widget__label">Provident Fund Deductions</span><a href="#" className="kt-widget__data"><h5>618,962</h5></a></div>
                                            <div className="kt-widget__contact"><span className="kt-widget__label">ESIC Deductions</span><span className="kt-widget__data"><h5>234,789</h5></span></div>
                                            <div className="kt-widget__contact"><span className="kt-widget__label">PT Deductions</span><span className="kt-widget__data"><h5>156,789</h5></span></div>
                                            <div className="kt-widget__contact"><span className="kt-widget__label">LWF Deductions</span><span className="kt-widget__data"><h5>110,998</h5></span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            {/* <h3 className="kt-portlet__head-title">
                                Tasks
			                </h3> */}
                        </div>
                        <div className="kt-portlet__head-toolbar col-lg-12 float-left">
                            <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-brand" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#kt_widget2_tab1_content" role="tab" aria-selected="true">
                                        Employee Summary
					                </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#kt_widget2_tab2_content" role="tab" aria-selected="false">
                                        Taxes & Deductions
					                </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="kt-portlet__body">
                        <div className="tab-content">

                            <div className="tab-pane active" id="kt_widget2_tab1_content">
                                <div className="row mb-3">
                                    <div className="col-md-2">
                                        <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm form-control form-control-sm">
                                            <option value="10">All Employees</option>
                                            <option value="25">Active Employees</option>
                                            <option value="50">Employees with LOP</option>
                                            <option value="100">Tax Overhidden Employees</option>
                                            <option value="100">Skipped Employees</option>
                                            <option value="100">Yet to Pay Employees</option>
                                            <option value="100">Paid Employees</option>
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm form-control form-control-sm">
                                            <option value="10">Select Employee</option>
                                            {
                                                list !== undefined && list.map((item: any, index: any) => {
                                                    return (
                                                        <option value={item.name}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-4">

                                    </div>
                                    <div className="col-md-2">

                                    </div>
                                    <div className="col-md-2">
                                        <button className="btn btn-primary btn-block">Download Report</button>
                                    </div>
                                </div>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '3%' }}>
                                                <input type="checkbox" />
                                            </th>
                                            <th colSpan={2}>
                                                Employee
                                                                    </th>
                                            <th colSpan={6}>
                                                Pay Days
                                                                    </th>
                                            <th colSpan={5}>
                                                Earnings(+)
                                                                    </th>
                                            <th colSpan={5}>
                                                Deductions(-)
                                                                    </th>
                                            
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th>Code</th>
                                            <th>Name</th>
                                            <th>Calendar</th>
                                            <th>Working</th>
                                            <th>Present</th>
                                            <th>Absent</th>
                                            <th>Leaves</th>
                                            <th>Paid days</th>
                                            <th>Basic</th>
                                            <th>HRA</th>
                                            <th>SA</th>
                                            <th>TA</th>
                                            <th>Gross Salary</th>
                                            <th>PF</th>
                                            <th>ESIC</th>
                                            <th>PT</th>
                                            <th>TDS</th>
                                            <th>Total</th>

                                            <th>Net pay</th>
                                            <th>Pay slip</th>
                                            <th>Pay mode</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input type="checkbox" /></td>
                                            <td>E001</td>
                                            <td>Abhishek Tripathi</td>
                                            <td>31</td>
                                            <td>23</td>
                                            <td>20</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td className="bg-success text-light">23</td>
                                            <td>40,000</td>
                                            <td>16,000</td>
                                            <td>20,000</td>
                                            <td>8,000</td>
                                            <td className="bg-success text-light">84,000</td>
                                            <td>3,200</td>
                                            <td>800</td>
                                            <td>750</td>
                                            <td>16,800</td>
                                            <td className="bg-warning text-light">21,550</td>
                                            <td>62,450</td>
                                            <td>View</td>
                                            <td>NEFT</td>
                                            <td>Pending</td>
                                        </tr>
                                        <tr>
                                            <td><input type="checkbox" /></td>
                                            <td>E002</td>
                                            <td>Neeraj Kumar</td>
                                            <td>31</td>
                                            <td>23</td>
                                            <td>20</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td className="bg-success text-light">23</td>
                                            <td>40,000</td>
                                            <td>16,000</td>
                                            <td>20,000</td>
                                            <td>8,000</td>
                                            <td className="bg-success text-light">84,000</td>
                                            <td>3,200</td>
                                            <td>800</td>
                                            <td>750</td>
                                            <td>16,800</td>
                                            <td className="bg-warning text-light">21,550</td>
                                            <td>62,450</td>
                                            <td>View</td>
                                            <td>NEFT</td>
                                            <td>Pending</td>
                                        </tr>
                                        <tr>
                                            <td><input type="checkbox" /></td>
                                            <td>E003</td>
                                            <td>Amit Kumar</td>
                                            <td>31</td>
                                            <td>23</td>
                                            <td>20</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td className="bg-success text-light">23</td>
                                            <td>40,000</td>
                                            <td>16,000</td>
                                            <td>20,000</td>
                                            <td>8,000</td>
                                            <td className="bg-success text-light">84,000</td>
                                            <td>3,200</td>
                                            <td>800</td>
                                            <td>750</td>
                                            <td>16,800</td>
                                            <td className="bg-warning text-light">21,550</td>
                                            <td>62,450</td>
                                            <td>View</td>
                                            <td>NEFT</td>
                                            <td>Pending</td>
                                        </tr>
                                        <tr>
                                            <td><input type="checkbox" /></td>
                                            <td>E004</td>
                                            <td>Arun Kumar</td>
                                            <td>31</td>
                                            <td>23</td>
                                            <td>20</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td className="bg-success text-light">23</td>
                                            <td>40,000</td>
                                            <td>16,000</td>
                                            <td>20,000</td>
                                            <td>8,000</td>
                                            <td className="bg-success text-light">84,000</td>
                                            <td>3,200</td>
                                            <td>800</td>
                                            <td>750</td>
                                            <td>16,800</td>
                                            <td className="bg-warning text-light">21,550</td>
                                            <td>62,450</td>
                                            <td>View</td>
                                            <td>NEFT</td>
                                            <td>Pending</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="tab-pane" id="kt_widget2_tab2_content">
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet kt-portlet--mobile">
                                        <div className="kt-portlet__head kt-portlet__head--lg">
                                            <div className="kt-portlet__head-label">
                                                <h3 className="kt-portlet__head-title">
                                                    Tax Details
			                                    </h3>
                                            </div>
                                        </div>

                                        <div className="kt-portlet__body">
                                            <div id="kt_table_1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                {/* <div className="row">
                                                    <div className="col-sm-12 col-md-3">
                                                        <div className="dataTables_length" id="kt_table_1_length"><label>Show <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm form-control form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label></div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-3">
                                                        <div className="dataTables_length" id="kt_table_1_length"><label>Show <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm form-control form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label></div>
                                                    </div>

                                                </div> */}
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1" role="grid" aria-describedby="kt_table_1_info">
                                                            <thead>
                                                                <tr role="row">
                                                                    <th className="sorting_asc" aria-controls="kt_table_1" aria-sort="ascending" aria-label="Record ID: activate to sort column descending">TAX NAME</th>
                                                                    <th className="sorting" aria-controls="kt_table_1" aria-label="Order ID: activate to sort column ascending">PAID BY EMPLOYER</th>
                                                                    <th className="sorting" aria-controls="kt_table_1" aria-label="Country: activate to sort column ascending">PAID BY EMPLOYEE</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr role="row">
                                                                    <td className="sorting_1">Income Tax</td>
                                                                    <td>0</td>
                                                                    <td>998,332</td>
                                                                </tr>
                                                                <tr role="row">
                                                                    <td className="sorting_1">Total</td>
                                                                    <td>0</td>
                                                                    <td>998,332</td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-sm-12 col-md-5">
                                                        <div className="dataTables_info" id="kt_table_1_info" role="status" aria-live="polite">Showing 1 to 10 of 40 entries</div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-7">
                                                        <div className="dataTables_paginate paging_full_numbers" id="kt_table_1_paginate"><ul className="pagination"><li className="paginate_button page-item first disabled" id="kt_table_1_first"><a href="#" aria-controls="kt_table_1" data-dt-idx="0" className="page-link"><i className="la la-angle-double-left"></i></a></li><li className="paginate_button page-item previous disabled" id="kt_table_1_previous"><a href="#" aria-controls="kt_table_1" data-dt-idx="1" className="page-link"><i className="la la-angle-left"></i></a></li><li className="paginate_button page-item active"><a href="#" aria-controls="kt_table_1" data-dt-idx="2" className="page-link">1</a></li><li className="paginate_button page-item "><a href="#" aria-controls="kt_table_1" data-dt-idx="3" className="page-link">2</a></li><li className="paginate_button page-item "><a href="#" aria-controls="kt_table_1" data-dt-idx="4" className="page-link">3</a></li><li className="paginate_button page-item "><a href="#" aria-controls="kt_table_1" data-dt-idx="5" className="page-link">4</a></li><li className="paginate_button page-item next" id="kt_table_1_next"><a href="#" aria-controls="kt_table_1" data-dt-idx="6" className="page-link"><i className="la la-angle-right"></i></a></li><li className="paginate_button page-item last" id="kt_table_1_last"><a href="#" aria-controls="kt_table_1" data-dt-idx="7" className="page-link"><i className="la la-angle-double-right"></i></a></li></ul>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                {/* <Pagination /> */}
            </div>
        )
    }

    renderChatDiv = () => {
        return (

            <div className="modal modal-hide fade- modal-sticky-bottom-right" id="kt_chat_modal" role="dialog" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="kt-chat">
                            <div className="kt-portlet kt-portlet--last">
                                <div className="kt-portlet__head">
                                    <div className="kt-chat__head ">
                                        <div className="kt-chat__left">
                                            <div className="kt-chat__label">
                                                <a href="#" className="kt-chat__title">Jason Muller</a>
                                                <span className="kt-chat__status">
                                                    <span className="kt-badge kt-badge--dot kt-badge--success"></span> Active
                                                </span>
                                            </div>
                                        </div>
                                        <div className="kt-chat__right">
                                            <div className="dropdown dropdown-inline">
                                                <button type="button" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="flaticon-more-1"></i>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-md">

                                                    <ul className="kt-nav">
                                                        <li className="kt-nav__head">
                                                            Messaging
                                                <i className="flaticon2-information" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more..."></i>
                                                        </li>
                                                        <li className="kt-nav__separator"></li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-group"></i>
                                                                <span className="kt-nav__link-text">New Group</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-open-text-book"></i>
                                                                <span className="kt-nav__link-text">Contacts</span>
                                                                <span className="kt-nav__link-badge">
                                                                    <span className="kt-badge kt-badge--brand  kt-badge--rounded-">5</span>
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-bell-2"></i>
                                                                <span className="kt-nav__link-text">Calls</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-dashboard"></i>
                                                                <span className="kt-nav__link-text">Settings</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-protected"></i>
                                                                <span className="kt-nav__link-text">Help</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__separator"></li>
                                                        <li className="kt-nav__foot">
                                                            <a className="btn btn-label-brand btn-bold btn-sm" href="#">Upgrade plan</a>
                                                            <a className="btn btn-clean btn-bold btn-sm" href="#" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more...">Learn more</a>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>

                                            <button type="button" className="btn btn-clean btn-sm btn-icon" data-dismiss="modal">
                                                <i className="flaticon2-cross"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__body">
                                    <div className="kt-scroll kt-scroll--pull" data-height="410" data-mobile-height="225">
                                        <div className="kt-chat__messages kt-chat__messages--solid">
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">2 Hours</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    How likely are you to recommend our company<br /> to your friends and family?
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Hey there, we’re just writing to let you know that you’ve<br /> been subscribed to a repository on GitHub.
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok, Understood!
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">Just Now</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    You’ll receive notifications for all issues, pull requests!
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">2 Hours</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    You were automatically <b className="kt-font-brand">subscribed</b> <br />because you’ve been given access to the repository
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>

                                                <div className="kt-chat__text">
                                                    You can unwatch this repository immediately <br />by clicking here: <a href="#" className="kt-font-bold kt-link"></a>
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok!
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">Just Now</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok!
                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-chat__input">
                                        <div className="kt-chat__editor">
                                            <textarea placeholder="Type here..." style={{ height: 50 }}></textarea>
                                        </div>
                                        <div className="kt-chat__toolbar">
                                            <div className="kt_chat__tools">
                                                <a href="#"><i className="flaticon2-link"></i></a>
                                                <a href="#"><i className="flaticon2-photograph"></i></a>
                                                <a href="#"><i className="flaticon2-photo-camera"></i></a>
                                            </div>
                                            <div className="kt_chat__actions">
                                                <button type="button" className="btn btn-brand btn-md  btn-font-sm btn-upper btn-bold kt-chat__reply">reply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { page, limit, sort } = this.state;
        getEmployeeList(page, limit, sort, "*").then((response: any) => {
            this.setState({
                list: response.result.list,
                count: response.result.count,
            });
        }, (error: any) => {
            alert(error.message);
        });
    }

    onSubmit = () => {
        window.location.href = CONSTANT.url.payRuns;
    }

}

export default PayrollSubmit;
