import * as React from 'react';
import $ from 'jquery';
import moment from 'moment';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import PieChart from './../../component/chart/PieChart';
import CONSTANT from './../../constant';
import LogAttendance from './../../component/attendance/LogAttendance';

import { getEmployeeMonthlyHistory } from '../../action/AttendanceActions';
import { getCookie, getStorage } from '../../utils';

class EmployeeList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            date: moment(),
            list: undefined,
            uniqueRecords: undefined,
            showLoader: false
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { date } = this.state;
        const currMonthName = date.format('MMM');
        const currMonth = date.format('MM');
        const currYear = date.format('YYYY');

        const prevDate = moment(date.format('YYYY-MM-DD')).subtract(1, 'month');
        const prevMonthName = prevDate.format('MMM');
        const prevMonth = prevDate.format('MM');
        const prevYear = prevDate.format('YYYY');

        const nextDate = moment(date.format('YYYY-MM-DD')).add(1, 'month');
        const nextMonthName = nextDate.format('MMM');
        const nextMonth = nextDate.format('MM');
        const nextYear = nextDate.format('YYYY');

        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Attendance
                        </h3>

                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {`${currMonthName} ${currYear}`}
                            </span>
                        </div>

                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href="javascript:;" onClick={this.changeDate} data-month={prevMonth} data-year={prevYear} className="btn btn-label-brand btn-bold"><i className="fas fa-angle-double-left mr-1 pe-none" aria-hidden="true"></i>{`${prevMonthName} ${prevYear}`}</a>
                        <a href="javascript:;" onClick={this.changeDate} data-month={nextMonth} data-year={nextYear} className="btn btn-label-brand btn-bold">{`${nextMonthName} ${nextYear}`}<i className="fas fa-angle-double-right ml-1 pe-none" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { list, uniqueRecords } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Attendance log
                                    </h3>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <a href={CONSTANT.url.attendanceRegularizationList} className="btn btn-label-brand btn-sm btn-bold mr-3">Attendance Regularization</a>
                                    <a href={CONSTANT.url.compensatoryOffList} className="btn btn-label-brand btn-sm btn-bold">Compensatory Off</a>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Day</th>
                                                    <th>Date</th>
                                                    <th># Check-in</th>
                                                    <th># Check out</th>
                                                    <th>Total hours</th>
                                                    <th>Status</th>
                                                    <th>View Detail</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list === undefined && <tr>
                                                        <td colSpan={8}>Loading...</td>
                                                    </tr>
                                                }
                                                {
                                                    list !== undefined && list.length === 0 && <tr>
                                                        <td colSpan={8}>No records found</td>
                                                    </tr>
                                                }
                                                {
                                                    uniqueRecords !== undefined && uniqueRecords.map((date: any, index: number) => {
                                                        const dateAttendance = list.filter((i: any) => i.inTimeDay === date);
                                                        const checkInDate = moment.unix(dateAttendance[0].inTimeUnix)
                                                        const checkIns = dateAttendance.filter((i: any) => i.inTimeUnix !== null);
                                                        const checkOuts = dateAttendance.filter((i: any) => i.outTimeUnix !== null);
                                                        let workingHoursUnix = 0;
                                                        for (let i = 0; i < dateAttendance.length; i++) {
                                                            if (dateAttendance[i].outTimeUnix !== null) {
                                                                workingHoursUnix += moment.unix(dateAttendance[i].outTimeUnix).diff(moment.unix(dateAttendance[i].inTimeUnix));
                                                            }
                                                        }
                                                        const workingHoursDate = moment.duration(workingHoursUnix);

                                                        const detailDivId = `hidden-tr-${index}`;
                                                        return (
                                                            <React.Fragment>
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{checkInDate.format('ddd')}</td>
                                                                    <td>{checkInDate.format('DD MMM YYYY')}</td>
                                                                    <td>{checkIns.length}</td>
                                                                    <td>{checkOuts.length}</td>
                                                                    <td>{`${workingHoursDate.get('hours')}:${workingHoursDate.get('minutes')}:${workingHoursDate.get('seconds')}`} Hrs</td>
                                                                    {
                                                                        checkIns.length > 0 ? <td className="text-success">Present</td> : <td className="text-danger">Absent</td>
                                                                    }
                                                                    <td>
                                                                        <a onClick={(e) => this.expandDetail(e)} data-detail-id={`#${detailDivId}`} href="javascript:;">+</a>
                                                                    </td>
                                                                </tr>
                                                                <tr id={detailDivId} style={{ display: 'none' }}>
                                                                    <td colSpan={8}>
                                                                        <table style={{ width: '100%' }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Type</th>
                                                                                    <th>Source</th>
                                                                                    <th>In time</th>
                                                                                    <th>Out time</th>
                                                                                    <th>Total hours</th>
                                                                                    <th>Location</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    dateAttendance.map((ci: any, ciIndex: number) => {
                                                                                        const inTimeUnix = moment.unix(ci.inTimeUnix);
                                                                                        const outTimeUnix = moment.unix(ci.outTimeUnix);
                                                                                        const duration = moment.duration(outTimeUnix.diff(inTimeUnix));
                                                                                        const locationURL = `https://www.google.com/maps/search/?api=1&query=${ci.lat},${ci.lng}`;
                                                                                        return (
                                                                                            <tr key={ciIndex}>
                                                                                                <td>{ci.attendanceTypeName}</td>
                                                                                                <td>{ci.source}</td>
                                                                                                <td>{inTimeUnix.format('hh:mm A')}</td>
                                                                                                <td>{ci.outTimeUnix !== null ? outTimeUnix.format('hh:mm A') : '-'}</td>
                                                                                                <td>{ci.outTimeUnix !== null ?
                                                                                                    `${duration.get('hours')}:${duration.get('minutes')}:${duration.get('seconds')} Hrs` : '-'}</td>
                                                                                                <td>
                                                                                                    <a target="blank" href={locationURL}> View Location </a>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end::Section--> */}
                            </div>
                            {/* <!--end::Form--> */}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="kt-portlet mb-3">
                            <div className="kt-portlet__body kt-portlet__body--fit">
                                <div className="row row-no-padding row-col-separator-xl">
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                        <div className="kt-widget1">
                                            <LogAttendance />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="kt-portlet mb-3">
                            <div className="kt-portlet__body kt-portlet__body--fit">
                                <div className="row row-no-padding row-col-separator-xl">
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                        {/* <!--begin:: Widgets/Stats2-1 --> */}
                                        <div className="kt-widget1">
                                            <div className="kt-widget1__item">
                                                <div className="kt-widget1__info">
                                                    <h3 className="kt-widget1__title">Calendar Day</h3>
                                                    <span className="kt-widget1__desc">Total no of days in the selected date range</span>
                                                </div>
                                                <span className="kt-widget1__number kt-font-brand">30</span>
                                            </div>

                                            <div className="kt-widget1__item">
                                                <div className="kt-widget1__info">
                                                    <h3 className="kt-widget1__title">Working Days</h3>
                                                    <span className="kt-widget1__desc">Total no of working days in selected date range</span>
                                                </div>
                                                <span className="kt-widget1__number kt-font-warning">22</span>
                                            </div>

                                            <div className="kt-widget1__item">
                                                <div className="kt-widget1__info">
                                                    <h3 className="kt-widget1__title">Present</h3>
                                                    <span className="kt-widget1__desc">Total no of days you've worked</span>
                                                </div>
                                                <span className="kt-widget1__number kt-font-success">18</span>
                                            </div>

                                            <div className="kt-widget1__item">
                                                <div className="kt-widget1__info">
                                                    <h3 className="kt-widget1__title">Absent</h3>
                                                    <span className="kt-widget1__desc">Total no of days your were absent</span>
                                                </div>
                                                <span className="kt-widget1__number kt-font-danger">2</span>
                                            </div>

                                            <div className="kt-widget1__item">
                                                <div className="kt-widget1__info">
                                                    <h3 className="kt-widget1__title">Leave</h3>
                                                    <span className="kt-widget1__desc">Total no of days you were on leave</span>
                                                </div>
                                                <span className="kt-widget1__number kt-font-primary">1</span>
                                            </div>

                                        </div>
                                        {/* <!--end:: Widgets/Stats2-1 -->             */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="kt-portlet d-none">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        In time statistics
                                    </h3>
                                </div>
                            </div>
                            <PieChart data={[
                                { name: '8:00 - 9:00', value: 10 },
                                { name: '9:00 - 10:00', value: 5 },
                                { name: '10:00 - 11:00', value: 2 }]} />
                        </div>
                    </div>
                </div>

                {/* <Pagination /> */}
            </div>
        )
    }

    loadList = () => {
        const ud = getStorage(CONSTANT.keys.userDetail);
        const date = this.state.date;
        const monthName = date.format('MMMM');
        const month = date.format('MM');
        const year = date.format('YYYY');
        const userId = ud.id;
        getEmployeeMonthlyHistory(userId, month, year).then((res: any) => {
            let uniqueRecords = new Set(res.result.map((item: any) => item.inTimeDay));
            this.setState({
                uniqueRecords: Array.from(uniqueRecords),
                list: res.result
            });
        })
    }

    changeDate = (e: any) => {
        const month = e.target.dataset.month;
        const year = e.target.dataset.year;

        this.setState({ date: moment(`${year}-${month}-01`) }, () => {
            this.loadList();
        });
    }

    expandDetail = (e: any) => {
        const detailId = e.target.dataset.detailId;
        const innerText = e.target.innerText;
        $(e.target).html(innerText === '+' ? '-' : '+');
        $(detailId).slideToggle();
    }

}

export default EmployeeList;
