import * as React from 'react';
import ModalWindow from '../../component/common/ModalWindow'
import Header from './../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import CONSTANT from './../../constant';
import { getSalaryComponentList, toggleIsActiveStatus } from './../../action/PayrollTaxComponentActions';
import { getDateParts } from '../../utils';
import AddCycle from './../../component/pms/AddCycle';
import AddSubCycle from './../../component/pms/AddSubCycle';

class ComponentName extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 10,
            showLoader: false,
            showCycleModal: false,
            showSubCycleModal: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = CONSTANT.title.locationList;
    }

    public render() {
        const { showCycleModal, showSubCycleModal } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Role Competency
                        </h3>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                        </div>

                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href={CONSTANT.url.addPMSRoleCompetency} className="btn btn-label-brand btn-bold">Add Competency</a>
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { list, showLoader, page, limit } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            {/* <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Role C
                                    </h3>
                                </div>
                            </div> */}
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{width:"3%"}}>#</th>
                                                    <th scope="col">Role</th>
                                                    <th scope="col">Competency</th>
                                                    <th scope="col">Modified by</th>
                                                    <th scope="col">Modified on</th>
                                                    <th scope="col" style={{width:"3%"}}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Jr. Software Engineer</td>
                                                    <td>Node.js, React.js, Javascript, Database</td>
                                                    <td>Ranjan Sharma</td>
                                                    <td>2 Days ago</td>
                                                    <td className="text-right"><a href="#"><i className="fas fa-edit"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Sr. Software Engineer</td>
                                                    <td>Node.js, React.js, Javascript, Database</td>
                                                    <td>Ranjan Sharma</td>
                                                    <td>2 Days ago</td>
                                                    <td className="text-right"><a href="#"><i className="fas fa-edit"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Team Lead</td>
                                                    <td>Ranjan Sharma</td>
                                                    <td>Node.js, React.js, Javascript, Database</td>
                                                    <td>2 Days ago</td>
                                                    <td className="text-right"><a href="#"><i className="fas fa-edit"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Manager Technology</td>
                                                    <td>Node.js, React.js, Javascript, Database</td>
                                                    <td>Ranjan Sharma</td>
                                                    <td>2 Days ago</td>
                                                    <td className="text-right"><a href="#"><i className="fas fa-edit"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Manager Delivery</td>
                                                    <td>Node.js, React.js, Javascript, Database</td>
                                                    <td>Ranjan Sharma</td>
                                                    <td>2 Days ago</td>
                                                    <td className="text-right"><a href="#"><i className="fas fa-edit"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>CTO</td>
                                                    <td>Ranjan Sharma</td>
                                                    <td>Node.js, React.js, Javascript, Database</td>
                                                    <td>2 Days ago</td>
                                                    <td className="text-right"><a href="#"><i className="fas fa-edit"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>CEO</td>
                                                    <td>Ranjan Sharma</td>
                                                    <td>Node.js, React.js, Javascript, Database</td>
                                                    <td>2 Days ago</td>
                                                    <td className="text-right"><a href="#"><i className="fas fa-edit"></i></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end::Section--> */}
                            </div>
                            {/* <!--end::Form--> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onAddCycle = () => {
        this.setState({
            showCycleModal: true
        });
    }

    onCycleSubmit = () => {
        this.setState({
            showCycleModal: false
        });
    }

    onCycleCancel = () => {
        this.setState({
            showCycleModal: false
        });
    }

    onAddSubCycle = () => {
        this.setState({
            showSubCycleModal: true
        });
    }

    onSubCycleSubmit = () => {
        this.setState({
            showSubCycleModal: false
        });
    }

    onSubCycleCancel = () => {
        this.setState({
            showSubCycleModal: false
        });
    }
}

export default ComponentName;
