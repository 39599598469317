import * as React from 'react';
import moment from 'moment';
import CONSTANT from './../../constant';
import AddComment from '../../component/compensatory-off/AddComment';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from '../../component/common/Loader';

import { getCompOffDetail, addComment } from '../../action/CompensatoryOffActions'
import Shimmer from '../../component/common/Shimmer';
import { getDateParts, getLocalStorageItem } from '../../utils';
import ModalWindow from '../../component/common/ModalWindow';

class AddAttendance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: props.match.params.id,
            detail: undefined,
            attendanceDetail: undefined,
            comments: undefined,
            showModal: false,
        }
    }

    componentDidMount() {
        const { id } = this.state;
        getCompOffDetail(id).then((res: any) => {
            this.setState({
                detail: res.result.detail,
                comments: res.result.comments
            });
        });
    }

    public render() {
        const { showLoader, showModal, id } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                {
                    showModal && <ModalWindow
                        title="Add Comment"
                        backdrop="static"
                        toggleModal={this.hideCommentModal}>
                        <AddComment
                            compOffDetailId={id}
                            onSubmit={this.onSubmit}
                            onCancel={this.hideCommentModal}
                        />
                    </ModalWindow>
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        const { detail } = this.state;
        const ud = getLocalStorageItem(CONSTANT.keys.userDetail);
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Compensatory Off Detail
                        </h3>
                    </div>
                    {
                        detail !== undefined && detail.pendingWith === ud.id && <div className="kt-subheader__toolbar">
                            <button className="btn btn-label-brand btn-bold" onClick={this.showCommentModal}>Add Comment</button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    public renderBody() {
        const { detail, comments } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet mb-3">
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        {
                                            detail === undefined && <Shimmer />
                                        }
                                        {
                                            detail !== undefined && this.renderDetail()
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            comments === undefined && <Shimmer />
                        }
                        {
                            comments !== undefined && this.renderComment()
                        }
                    </div>
                </div>
            </div>
        )
    }

    renderDetail = () => {
        const { detail, attendanceDetail } = this.state;
        const inTime = getDateParts(detail.inTime);
        const outTime = getDateParts(detail.outTime);
        const con = getDateParts(detail.createdOn);
        return (
            <div className="row">
                <div className="col-md-12">
                    Leave type: <u className="ml-1">{detail.leaveTypeName}</u><br />
                    Attendace type: <u className="ml-1">{detail.attendanceTypeName}</u><br />
                    Duration: <u className="ml-1">{`${inTime.date} ${inTime.monthName} ${inTime.year} ${inTime.hour}:${inTime.minute} ${inTime.amOrpm} - ${outTime.date} ${outTime.monthName} ${outTime.year} ${outTime.hour}:${outTime.minute} ${outTime.amOrpm}`}</u><br />
                    {detail.comment}
                </div>
                <div className="col-md-12">
                    {
                        this.renderAttendanceDetail()
                    }
                </div>
                <div className="col-md-12 text-right">
                    {detail.createdByName}<br />
                    {`${con.date} ${con.monthName} ${con.year} ${con.hour}:${con.minute} ${con.amOrpm}`}
                </div>
            </div>
        )
    }

    renderAttendanceDetail = () => {
        const attendanceDetail = this.state.detail;
        const inTimeUnix = moment.unix(attendanceDetail.inTimeUnix);
        const outTimeUnix = moment.unix(attendanceDetail.outTimeUnix);
        const duration = moment.duration(outTimeUnix.diff(inTimeUnix));
        const locationURL = `https://www.google.com/maps/search/?api=1&query=${attendanceDetail.lat},${attendanceDetail.lng}`;
        return (
            <div className="row mt-3">
                <div className="col-md-12">
                    <b>Attendance Detail</b>
                    <table className="table table-bordered table-hover" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Source</th>
                                <th>In time</th>
                                <th>Out time</th>
                                <th>Total hours</th>
                                <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{attendanceDetail.attendanceTypeName}</td>
                                <td>{attendanceDetail.source}</td>
                                <td>{inTimeUnix.format('hh:mm A')}</td>
                                <td>{attendanceDetail.outTimeUnix !== null ? outTimeUnix.format('hh:mm A') : '-'}</td>
                                <td>{attendanceDetail.outTimeUnix !== null ?
                                    `${duration.get('hours')}:${duration.get('minutes')}:${duration.get('seconds')} Hrs` : '-'}</td>
                                <td>
                                    <a target="blank" href={locationURL}> View Location </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    renderComment = () => {
        const { comments } = this.state;
        return (
            <React.Fragment>
                {
                    comments.length === 0 && <div className="kt-portlet">
                        <div className="kt-portlet__body">
                            <div className="kt-section mb-0">
                                <div className="kt-section__content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="text-center">
                                                Request is still pending with the Assigned user.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    comments.map((item: any, index: number) => {
                        const con = getDateParts(item.createdOn);
                        return (
                            <div className="kt-portlet" key={index}>
                                <div className="kt-portlet__body">
                                    <div className="kt-section mb-0">
                                        <div className="kt-section__content">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <b>{item.statusName}</b>
                                                </div>
                                                <div className="col-md-12">
                                                    {item.comment}
                                                </div>
                                                <div className="col-md-12 text-right">
                                                    {item.createdByName}<br />
                                                    {`${con.date} ${con.monthName} ${con.year} ${con.hour}:${con.minute} ${con.amOrpm}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </React.Fragment>
        )
    }

    showCommentModal = () => {
        this.setState({ showModal: true });
    }

    hideCommentModal = () => {
        this.setState({ showModal: true });
    }

    onSubmit = (model: any) => {
        addComment(model).then((res: any) => {
            window.location.href = CONSTANT.url.compensatoryOffList;
        }).catch((err: any) => {
            alert(err)
        })
    }

    onCancel = () => {
        window.location.href = CONSTANT.url.compensatoryOffList;
    }
}

export default AddAttendance;
