import React, { useState } from 'react';
import { isEmpty, setOptions, validateForm, onChange } from '../../utils';
import { getHolidayTypeList } from './../../action/SettingsActions';
// import { getCompanyBranchList } from '../../action/CompanyBranchAction';
import moment from 'moment';
class ShiftsModal extends React.Component<any, any>{

    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            name: { name: 'name', value: detail ? detail.name : '', error: '', isRequired: true },
            description: { name: 'description', value: detail ? detail.description : '', error: '', isRequired: true },
            date: { name: 'date', value: detail ? moment(detail.date).format('YYYY-MM-DD') : '', error: '', isRequired: true },
            holidayTypeId: { name: 'holidayTypeId', value: detail ? detail.holidayTypeId : '', options: [], error: '', isRequired: true },
            companyBranchId: { name: 'companyBranchId', value: detail ? detail.companyBranchId : '', options: [], error: '', isRequired: false }
        }
    }

    componentDidMount() {
        getHolidayTypeList(1, 100).then((res: any) => {
            setOptions(this, this.state.holidayTypeId.name, res.result);
        });
        // getCompanyBranchList().then((res: any) => {
        //     setOptions(this, this.state.companyBranchId.name, res.result);
        // });
    }

    public render() {
        const { name, holidayTypeId, companyBranchId, description, date } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-lg-6 form-group">
                        <label>Name *</label>
                        <input
                            type="text"
                            className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Name of the Holiday"
                            name={name.name}
                            value={name.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-lg-6 form-group">
                        <label>Date *</label>
                        <input
                            type="date"
                            className={date.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder=""
                            name={date.name}
                            value={date.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-lg-6 form-group">
                        <label>Holiday type *</label>
                        <select
                            className={holidayTypeId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            name={holidayTypeId.name}
                            value={holidayTypeId.value}
                            onChange={this.onChange}>
                            <React.Fragment>
                                <option value=''>Select holiday type</option>
                                {
                                    holidayTypeId.options.map((dep: any, dIndex: number) => {
                                        return (<option key={dIndex} value={dep.id}>{dep.name}</option>)
                                    })
                                }
                            </React.Fragment>
                        </select>
                    </div>
                    <div className="col-lg-6 form-group d-none">
                        <label>Company branch *</label>
                        <select
                            className={companyBranchId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            name={companyBranchId.name}
                            value={companyBranchId.value}
                            onChange={this.onChange}>
                            <React.Fragment>
                                <option value=''>Select company branch</option>
                                {
                                    companyBranchId.options.map((dep: any, dIndex: number) => {
                                        return (<option key={dIndex} value={dep.id}>{dep.name}</option>)
                                    })
                                }
                            </React.Fragment>
                        </select>
                    </div>

                    <div className="col-lg-12 form-group">
                        <label>Description</label>
                        <textarea
                            rows={3}
                            className={description.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Write description"
                            name={description.name}
                            value={description.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-md-12"><small>* Indicates required fields</small></div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-3">
                        <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                        <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                    </div>
                </div>
                {/* </div>
                </div> */}
            </form>

            // <React.Fragment>
            //     <React.Fragment>
            //         <div className="company-model">
            //             <div className="modal-body">
            //                 <div>
            //                     <div className="row">

            //                         <div className="col-lg-12 mb-2">
            //                             <div>
            //                                 <form>
            //                                     <div className="mb-2">
            //                                         <div className="row">
            //                                             <div className="col-lg-12 form-group">
            //                                                 <label>Name</label>
            //                                                 <input onChange={this._onChange} type="text" className={isEmpty(name.error) ? "form-control" : "form-control is-invalid"} placeholder="Name" name="name" />
            //                                             </div>
            //                                             <div className="col-lg-12 form-group">
            //                                                 <label>Date</label>
            //                                                 <input onChange={this._onChange} type="date" className={isEmpty(date.error) ? "form-control" : "form-control is-invalid"} placeholder="" name="date" />
            //                                             </div>
            //                                             <div className="col-lg-12 form-group">
            //                                                 <label>Type</label>
            //                                                 <select
            //                                                     className={isEmpty(holidayTypeId.error) ? "form-control" : "form-control is-invalid"}
            //                                                     name={holidayTypeId.name}
            //                                                     onChange={this._onChange}>
            //                                                     <React.Fragment>
            //                                                         <option>--Select--</option>
            //                                                         {
            //                                                             holidayTypeId.options.map((dep: any, dIndex: number) => {
            //                                                                 return (<option key={dIndex} value={dep.id}>{dep.name}</option>)
            //                                                             })
            //                                                         }
            //                                                     </React.Fragment>
            //                                                 </select>
            // )
            //                                             </div>
            //                                         </div>
            //                                     </div>
            //                                     <div className="mb-2">
            //                                         <div className="row">
            //                                             <div className="col-lg-12 form-group">
            //                                                 <label>Description</label>
            //                                                 <textarea onChange={this._onChange} className={isEmpty(description.error) ? "form-control" : "form-control is-invalid"} id="comment" name="description" placeholder="Type Here"></textarea>
            //                                             </div>
            //                                         </div>
            //                                     </div>
            //                                 </form>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </React.Fragment>
            //     <React.Fragment>
            //         <button type="button" onClick={this._submitFormModal} className="col-lg-12 btn btn-primary">Save</button>
            //     </React.Fragment>
            // </React.Fragment>
        );
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        const isFormValid = validateForm(this);
        if (isFormValid) {
            const model: any = {
                name: this.state.name.value,
                date: this.state.date.value,
                holidayTypeId: this.state.holidayTypeId.value,
                companyBranchId: this.state.companyBranchId.value,
                description: this.state.description.value,
            };
            if (this.state.id) {
                model.id = this.state.id
            }

            this.props.onSubmit(model);
        }
    }
}

export default ShiftsModal;
