import React from 'react';
import Logo from './../../assets/images/logo/logo-light.png';
import CONSTANT from './../../constant';
import { getStorage } from '../../utils';

const Sidebar = () => {
    const ud = getStorage(CONSTANT.keys.userDetail);
    const td = getStorage(CONSTANT.keys.teamDetail);
    const con = getStorage(CONSTANT.keys.configuration);
    const hasTeam = td.length > 0;
    const logoFilePath = con.logoFileName ? `${process.env.REACT_APP_BASE_URL}/logo/${con.logoFileName}` : Logo

    return (
        <div
            className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
            <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand" kt-hidden-height="65" >
                <div className="kt-aside__brand-logo">
                    <a href="javascript:;">
                        <img alt="Logo" src={Logo} style={{ height: 30 }} />
                    </a>
                </div>
                <div className="kt-aside__brand-tools">
                    <button className="kt-aside__brand-aside-toggler" id="kt_aside_toggler">
                        <span><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z" fill="#000000" fill-rule="nonzero" transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) " />
                                <path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) " />
                            </g>
                        </svg></span>
                        <span><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
                                <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) " />
                            </g>
                        </svg></span>
                    </button>
                </div>
            </div>
            <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                <div id="kt_aside_menu" className="kt-aside-menu kt-scroll ps ps--active-y" data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
                    <ul className="kt-menu__nav ">
                        {
                            ud.iob && renderEmployeeOptions()
                        }
                        {
                            renderCommonOptions(ud)
                        }
                        {
                            ud.iob && hasTeam && renderTeamOptions()
                        }
                        {
                            ud.isu && renderCompanyOptions()
                        }
                        {
                            ud.isu && renderConsolidatedOptions()
                        }
                        {
                            ud.isu && renderSystemOptions()
                        }
                    </ul>
                    <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                        <div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }}></div>
                    </div>
                    <div className="ps__rail-y" style={{ top: 0, height: 219, right: 3 }}>
                        <div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 40 }}></div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const renderSystemOptions = () => {
    return (
        <React.Fragment>
            <li className="kt-menu__section ">
                <h4 className="kt-menu__section-text">Admin Options</h4>
                <i className="kt-menu__section-icon flaticon-more-v2"></i>
            </li>
            {/* employee */}
            <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Employee</span>
                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                    <ul className="kt-menu__subnav">
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.employeeList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Employee List</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.addEmployeeMaster} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Add Employee</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.employeeBulkUpload} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Bulk Upload</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.employeeExitList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Exit Process</span></a></li>
                    </ul>
                </div>
            </li>
            {/* settings */}
            <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z" fill="#000000" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Settings</span>
                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                    <ul className="kt-menu__subnav">
                        {/* <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.settings} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Dashboard</span></a></li> */}


                        <li className="kt-menu__item kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                            <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                <span className="kt-menu__link-text">Attendance &amp; Leaves</span>
                                <i className="kt-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="kt-menu__submenu " kt-hidden-height="160">
                                <span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.attendanceType} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Attendance type</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.geofenceGroup} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Geofence group</span></a></li>

                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.leaveType} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Leave type</span></a></li>
                                    {/* <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.holidayTypeList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Holiday type</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.holidayList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Holiday list</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.companyCalendar} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Calendar</span></a></li> */}

                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.attendenceShift} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Shift(s)</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.attendenceShifCalendar} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Shift Mapping</span></a></li>
                                </ul>
                            </div>
                        </li>

                        <li className="kt-menu__item kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                            <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                <span className="kt-menu__link-text">Calendar</span>
                                <i className="kt-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="kt-menu__submenu " kt-hidden-height="160">
                                <span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.holidayTypeList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Holiday type</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.companyCalendar} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Calendar</span></a></li>
                                    {/* <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.holidayList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Holiday list</span></a></li> */}
                                </ul>
                            </div>
                        </li>

                        <li className="kt-menu__item kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                            <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                <span className="kt-menu__link-text">Company</span>
                                <i className="kt-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="kt-menu__submenu " kt-hidden-height="160">
                                <span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.companyLocation} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Company location</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.companyBranch} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Company branch</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.companyConfiguration} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Company Configuration</span></a></li>
                                </ul>
                            </div>
                        </li>


                        <li className="kt-menu__item kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                            <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                <span className="kt-menu__link-text">PMS</span>
                                <i className="kt-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="kt-menu__submenu " kt-hidden-height="160">
                                <span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.pmsCycle} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">PMS Cycle</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.pmsRoleCompetency} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">PMS Role Competency</span></a></li>
                                </ul>
                            </div>
                        </li>

                        <li className="kt-menu__item kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                            <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                <span className="kt-menu__link-text">Employee</span>
                                <i className="kt-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="kt-menu__submenu " kt-hidden-height="160">
                                <span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.documentType} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Document type</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.designationList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Designation</span></a></li>
                                </ul>
                            </div>
                        </li>




                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.payrollTaxComponent} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Payroll Tax component</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.helpdeskCategoryList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Helpdesk Category</span></a></li>


                    </ul>
                </div>
            </li>
        </React.Fragment>

    )
}

const renderConsolidatedOptions = () => {
    return (
        <React.Fragment>
            <li className="kt-menu__section ">
                <h4 className="kt-menu__section-text">Consolidated View</h4>
                <i className="kt-menu__section-icon flaticon-more-v2"></i>
            </li>
            <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3" />
                                <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Consolidated</span>
                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                    <ul className="kt-menu__subnav">
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.consolidatedAttendanceDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Attendance</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.consolidatedLeaveDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Leave</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.consolidatedReimbursementDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Reimbursement</span></a></li>
                    </ul>
                </div>
            </li>
        </React.Fragment>
    )
}

const renderCompanyOptions = () => {
    return (
        <React.Fragment>
            <li className="kt-menu__section ">
                <h4 className="kt-menu__section-text">Company Options</h4>
                <i className="kt-menu__section-icon flaticon-more-v2"></i>
            </li>
            <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3" />
                                <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Company</span>
                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                    <ul className="kt-menu__subnav">
                        <li className="kt-menu__item kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                            <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                <span className="kt-menu__link-text">Attendance</span>
                                <i className="kt-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="kt-menu__submenu " kt-hidden-height="160">
                                <span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.companyAttendanceDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Dashboard</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.companyAttendanceList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">List</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.companyAttendanceMap} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Map</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.companyProxyAttendance} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Proxy</span></a></li>
                                </ul>
                            </div>
                        </li>
                        <li className="kt-menu__item kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                            <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                <span className="kt-menu__link-text">Leave</span>
                                <i className="kt-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="kt-menu__submenu " kt-hidden-height="160">
                                <span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.companyLeaveDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Dashboard</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.companyLeaveList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">List</span></a></li>
                                </ul>
                            </div>
                        </li>
                        <li className="kt-menu__item" aria-haspopup="true">
                            <a href={CONSTANT.url.companyDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">HR Manager</span></a>
                        </li>
                        <li className="kt-menu__item" aria-haspopup="true">
                            <a href={CONSTANT.url.reimbursementDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Reimbursement</span></a>
                        </li>
                        <li className="kt-menu__item" aria-haspopup="true">
                            <a href={CONSTANT.url.helpdeskDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Helpdesk</span></a>
                        </li>
                        <li className="kt-menu__item kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                            <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                <span className="kt-menu__link-text">Assets</span>
                                <i className="kt-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="kt-menu__submenu " kt-hidden-height="160">
                                <span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.assetDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Dashboard</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.assetList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">List</span></a></li>
                                </ul>
                            </div>
                        </li>

                        <li className="kt-menu__item kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                            <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                <span className="kt-menu__link-text">Payroll</span>
                                <i className="kt-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="kt-menu__submenu " kt-hidden-height="160">
                                <span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.payrollDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Dashboard</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.payRuns} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Pay runs</span></a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
        </React.Fragment>
    )
}

const renderTeamOptions = () => {
    return (
        <React.Fragment>
            <li className="kt-menu__section ">
                <h4 className="kt-menu__section-text">Team Options</h4>
                <i className="kt-menu__section-icon flaticon-more-v2"></i>
            </li>
            <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <rect fill="#000000" opacity="0.3" x="4" y="4" width="4" height="4" rx="1" />
                                <path d="M5,10 L7,10 C7.55228475,10 8,10.4477153 8,11 L8,13 C8,13.5522847 7.55228475,14 7,14 L5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,7 C14,7.55228475 13.5522847,8 13,8 L11,8 C10.4477153,8 10,7.55228475 10,7 L10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,13 C14,13.5522847 13.5522847,14 13,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,11 C10,10.4477153 10.4477153,10 11,10 Z M17,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,7 C20,7.55228475 19.5522847,8 19,8 L17,8 C16.4477153,8 16,7.55228475 16,7 L16,5 C16,4.44771525 16.4477153,4 17,4 Z M17,10 L19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 L17,14 C16.4477153,14 16,13.5522847 16,13 L16,11 C16,10.4477153 16.4477153,10 17,10 Z M5,16 L7,16 C7.55228475,16 8,16.4477153 8,17 L8,19 C8,19.5522847 7.55228475,20 7,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,17 C4,16.4477153 4.44771525,16 5,16 Z M11,16 L13,16 C13.5522847,16 14,16.4477153 14,17 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,17 C10,16.4477153 10.4477153,16 11,16 Z M17,16 L19,16 C19.5522847,16 20,16.4477153 20,17 L20,19 C20,19.5522847 19.5522847,20 19,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,17 C16,16.4477153 16.4477153,16 17,16 Z" fill="#000000" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Team</span>
                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                    <ul className="kt-menu__subnav">
                        <li className="kt-menu__item kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                            <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                <span className="kt-menu__link-text">Attendance</span>
                                <i className="kt-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="kt-menu__submenu " kt-hidden-height="160">
                                <span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.teamAttendanceDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Dashboard</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.teamAttendance} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">List</span></a></li>
                                </ul>
                            </div>
                        </li>
                        <li className="kt-menu__item kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                            <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                <span className="kt-menu__link-text">Leave</span>
                                <i className="kt-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="kt-menu__submenu " kt-hidden-height="160">
                                <span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.teamLeaveDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Dashboard</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.teamLeave} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">List</span></a></li>
                                </ul>
                            </div>
                        </li>
                        <li className="kt-menu__item" aria-haspopup="true">
                            <a href={CONSTANT.url.projectList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Projects</span></a>
                        </li>
                        <li className="kt-menu__item" aria-haspopup="true">
                            <a href={CONSTANT.url.teamReimbursement} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Reimbursement</span></a>
                        </li>
                        <li className="kt-menu__item" aria-haspopup="true">
                            <a href={CONSTANT.url.teamAttendanceRegularizationRequest} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Regularization Request</span></a>
                        </li>
                        <li className="kt-menu__item" aria-haspopup="true">
                            <a href={CONSTANT.url.teamCompensatoryOffRequest} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Compensatory Off</span></a>
                        </li>
                        <li className="kt-menu__item kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                            <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i>
                                <span className="kt-menu__link-text">LMS Reports</span>
                                <i className="kt-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="kt-menu__submenu " kt-hidden-height="160">
                                <span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.lmsIndividualReport} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Individual Report</span></a></li>
                                    <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.lmsTaskReport} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Task Report</span></a></li>
                                    {/* <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.lmsTeamReport} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Team Report</span></a></li> */}
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
        </React.Fragment>
    )
}

const renderEmployeeOptions = () => {
    return (
        <React.Fragment>
            <li className="kt-menu__section ">
                <h4 className="kt-menu__section-text">Personal Options</h4>
                <i className="kt-menu__section-icon flaticon-more-v2"></i>
            </li>
            {/* dashboard */}
            <li className="kt-menu__item" aria-haspopup="true">
                <a href={CONSTANT.url.dashboard} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <rect fill="#000000" opacity="0.3" x="4" y="4" width="8" height="16"></rect>
                                <path d="M6,18 L9,18 C9.66666667,18.1143819 10,18.4477153 10,19 C10,19.5522847 9.66666667,19.8856181 9,20 L4,20 L4,15 C4,14.3333333 4.33333333,14 5,14 C5.66666667,14 6,14.3333333 6,15 L6,18 Z M18,18 L18,15 C18.1143819,14.3333333 18.4477153,14 19,14 C19.5522847,14 19.8856181,14.3333333 20,15 L20,20 L15,20 C14.3333333,20 14,19.6666667 14,19 C14,18.3333333 14.3333333,18 15,18 L18,18 Z M18,6 L15,6 C14.3333333,5.88561808 14,5.55228475 14,5 C14,4.44771525 14.3333333,4.11438192 15,4 L20,4 L20,9 C20,9.66666667 19.6666667,10 19,10 C18.3333333,10 18,9.66666667 18,9 L18,6 Z M6,6 L6,9 C5.88561808,9.66666667 5.55228475,10 5,10 C4.44771525,10 4.11438192,9.66666667 4,9 L4,4 L9,4 C9.66666667,4 10,4.33333333 10,5 C10,5.66666667 9.66666667,6 9,6 L6,6 Z" fill="#000000" fill-rule="nonzero"></path>
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Dashboard</span></a>
            </li>
            {/* attendance */}
            <li className="kt-menu__item" aria-haspopup="true">
                <a href={CONSTANT.url.employeeAttendance} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path d="M7.14319965,19.3575259 C7.67122143,19.7615175 8.25104409,20.1012165 8.87097532,20.3649307 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 L7.14319965,19.3575259 Z M15.1367085,20.3616573 C15.756345,20.0972995 16.3358198,19.7569961 16.8634386,19.3524415 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C16.9877332,22.6722956 16.3761428,22.5084204 16.1000004,22.0301278 L15.1367085,20.3616573 Z" fill="#000000" />
                                <path d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L5.29862906,2.88207799 Z" fill="#000000" opacity="0.3" />
                                <path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Attendance</span></a>
            </li>
            {/* leaves */}
            <li className="kt-menu__item" aria-haspopup="true">
                <a href={CONSTANT.url.employeeLeave} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path d="M11.2928932,5.63603897 L18.363961,12.7071068 L12,19.0710678 L4.92893219,12 L11.2928932,5.63603897 Z M8.46446609,11.2928932 C8.0739418,11.6834175 8.0739418,12.3165825 8.46446609,12.7071068 C8.85499039,13.0976311 9.48815536,13.0976311 9.87867966,12.7071068 C10.2692039,12.3165825 10.2692039,11.6834175 9.87867966,11.2928932 C9.48815536,10.9023689 8.85499039,10.9023689 8.46446609,11.2928932 Z M11.2928932,8.46446609 C10.9023689,8.85499039 10.9023689,9.48815536 11.2928932,9.87867966 C11.6834175,10.2692039 12.3165825,10.2692039 12.7071068,9.87867966 C13.0976311,9.48815536 13.0976311,8.85499039 12.7071068,8.46446609 C12.3165825,8.0739418 11.6834175,8.0739418 11.2928932,8.46446609 Z M11.2928932,14.1213203 C10.9023689,14.5118446 10.9023689,15.1450096 11.2928932,15.5355339 C11.6834175,15.9260582 12.3165825,15.9260582 12.7071068,15.5355339 C13.0976311,15.1450096 13.0976311,14.5118446 12.7071068,14.1213203 C12.3165825,13.7307961 11.6834175,13.7307961 11.2928932,14.1213203 Z M14.1213203,11.2928932 C13.7307961,11.6834175 13.7307961,12.3165825 14.1213203,12.7071068 C14.5118446,13.0976311 15.1450096,13.0976311 15.5355339,12.7071068 C15.9260582,12.3165825 15.9260582,11.6834175 15.5355339,11.2928932 C15.1450096,10.9023689 14.5118446,10.9023689 14.1213203,11.2928932 Z" fill="#000000" />
                                <path d="M10.5150629,20.4145579 C8.57369375,21.7007639 5.93228695,21.4886361 4.22182541,19.7781746 C2.51136387,18.0677131 2.2992361,15.4263063 3.58544211,13.4849371 L10.5150629,20.4145579 Z" fill="#000000" opacity="0.3" />
                                <path d="M12.7778303,4.29254889 C14.7191995,3.00634288 17.3606063,3.21847065 19.0710678,4.92893219 C20.7815294,6.63939373 20.9936571,9.28080053 19.7074511,11.2221697 L12.7778303,4.29254889 Z" fill="#000000" opacity="0.3" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Leaves</span></a>
            </li>
            {/* payroll */}
            <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" opacity="0.3" cx="20.5" cy="12.5" r="1.5" />
                                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 6.500000) rotate(-15.000000) translate(-12.000000, -6.500000) " x="3" y="3" width="18" height="7" rx="1" />
                                <path d="M22,9.33681558 C21.5453723,9.12084552 21.0367986,9 20.5,9 C18.5670034,9 17,10.5670034 17,12.5 C17,14.4329966 18.5670034,16 20.5,16 C21.0367986,16 21.5453723,15.8791545 22,15.6631844 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,9.33681558 Z" fill="#000000" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Payroll</span>
                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                    <ul className="kt-menu__subnav">
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.employeeInvestmentDeclaration} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Investment Declarations</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.employeeSalarySlips} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Salary Slips</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.employeeForm16} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Form 16</span></a></li>
                    </ul>
                </div>
            </li>
            {/* expense and reimbursement */}
            <li className="kt-menu__item" aria-haspopup="true" >
                <a href={CONSTANT.url.reimbursementList} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path d="M4,7 L20,7 L20,19.5 C20,20.3284271 19.3284271,21 18.5,21 L5.5,21 C4.67157288,21 4,20.3284271 4,19.5 L4,7 Z M10,10 C9.44771525,10 9,10.4477153 9,11 C9,11.5522847 9.44771525,12 10,12 L14,12 C14.5522847,12 15,11.5522847 15,11 C15,10.4477153 14.5522847,10 14,10 L10,10 Z" fill="#000000" />
                                <rect fill="#000000" opacity="0.3" x="2" y="3" width="20" height="4" rx="1" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Expense &amp; Reimbursement</span></a>
            </li>
            {/* task management */}
            <li className="kt-menu__item" aria-haspopup="true" >
                <a href={CONSTANT.url.taskList} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"></path>
                                <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) " x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Task Management</span></a>
            </li>
            {/* assets */}
            <li className="kt-menu__item" aria-haspopup="true" >
                <a href={CONSTANT.url.employeeAssets} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000"></path>
                                <path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3"></path>
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">My Assets</span></a>
            </li>
            {/* pms */}
            <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover" >
                <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <rect fill="#000000" opacity="0.3" x="12" y="4" width="3" height="13" rx="1.5" />
                                <rect fill="#000000" opacity="0.3" x="7" y="9" width="3" height="8" rx="1.5" />
                                <path d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z" fill="#000000" fill-rule="nonzero" />
                                <rect fill="#000000" opacity="0.3" x="17" y="11" width="3" height="6" rx="1.5" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">PMS</span>
                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                    <ul className="kt-menu__subnav">
                        {/* <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.pmsDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Dashboard</span></a></li> */}
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.kraList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">My KRA</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.kraList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Self Appraisal</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.pmsRating} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Team Appraisal</span></a></li>
                    </ul>
                </div>
            </li>
            {/* ats */}
            <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                <a href="javascript:;" className="kt-menu__link kt-menu__toggle">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><polygon points="0 0 24 0 24 24 0 24"></polygon><path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path><path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"></path></g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">ATS</span>
                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                    <ul className="kt-menu__subnav">
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.atsDashboard} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Dashboard</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.atsPositionList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Position</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.atsCandidate} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Candidates</span></a></li>
                    </ul>
                </div>
            </li>

        </React.Fragment>
    )
}

const renderCommonOptions = (ud: any) => {
    return (
        <React.Fragment>
            {/* profile */}
            <li className="kt-menu__item" aria-haspopup="true">
                <a href={CONSTANT.url.profileOverview} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero"></path>
                                <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"></path>
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Profile</span></a>
            </li>
            {/* helpdesk */}

            <li className="kt-menu__item" aria-haspopup="true">
                {ud.isu ?
                    <a href={CONSTANT.url.helpdeskDashboard} className="kt-menu__link ">
                        <span className="kt-menu__link-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M3,10.0500091 L3,8 C3,7.44771525 3.44771525,7 4,7 L9,7 L9,9 C9,9.55228475 9.44771525,10 10,10 C10.5522847,10 11,9.55228475 11,9 L11,7 L21,7 C21.5522847,7 22,7.44771525 22,8 L22,10.0500091 C20.8588798,10.2816442 20,11.290521 20,12.5 C20,13.709479 20.8588798,14.7183558 22,14.9499909 L22,17 C22,17.5522847 21.5522847,18 21,18 L11,18 L11,16 C11,15.4477153 10.5522847,15 10,15 C9.44771525,15 9,15.4477153 9,16 L9,18 L4,18 C3.44771525,18 3,17.5522847 3,17 L3,14.9499909 C4.14112016,14.7183558 5,13.709479 5,12.5 C5,11.290521 4.14112016,10.2816442 3,10.0500091 Z M10,11 C9.44771525,11 9,11.4477153 9,12 L9,13 C9,13.5522847 9.44771525,14 10,14 C10.5522847,14 11,13.5522847 11,13 L11,12 C11,11.4477153 10.5522847,11 10,11 Z" fill="#000000" opacity="0.3" transform="translate(12.500000, 12.500000) rotate(-45.000000) translate(-12.500000, -12.500000) " />
                                </g>
                            </svg>
                        </span>
                        <span className="kt-menu__link-text">Helpdesk</span>
                    </a>
                    : <a href={CONSTANT.url.helpdeskEmp} className="kt-menu__link ">
                        <span className="kt-menu__link-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M3,10.0500091 L3,8 C3,7.44771525 3.44771525,7 4,7 L9,7 L9,9 C9,9.55228475 9.44771525,10 10,10 C10.5522847,10 11,9.55228475 11,9 L11,7 L21,7 C21.5522847,7 22,7.44771525 22,8 L22,10.0500091 C20.8588798,10.2816442 20,11.290521 20,12.5 C20,13.709479 20.8588798,14.7183558 22,14.9499909 L22,17 C22,17.5522847 21.5522847,18 21,18 L11,18 L11,16 C11,15.4477153 10.5522847,15 10,15 C9.44771525,15 9,15.4477153 9,16 L9,18 L4,18 C3.44771525,18 3,17.5522847 3,17 L3,14.9499909 C4.14112016,14.7183558 5,13.709479 5,12.5 C5,11.290521 4.14112016,10.2816442 3,10.0500091 Z M10,11 C9.44771525,11 9,11.4477153 9,12 L9,13 C9,13.5522847 9.44771525,14 10,14 C10.5522847,14 11,13.5522847 11,13 L11,12 C11,11.4477153 10.5522847,11 10,11 Z" fill="#000000" opacity="0.3" transform="translate(12.500000, 12.500000) rotate(-45.000000) translate(-12.500000, -12.500000) " />
                                </g>
                            </svg>
                        </span>
                        <span className="kt-menu__link-text">Helpdesk</span>
                    </a>
                }
            </li>

            {/* faqs */}
            <li className="kt-menu__item" aria-haspopup="true">
                <a href={CONSTANT.url.faq} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                <path d="M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M10.591,14.868 L10.591,13.209 L11.851,13.209 C13.447,13.209 14.602,11.991 14.602,10.395 C14.602,8.799 13.447,7.581 11.851,7.581 C10.234,7.581 9.121,8.799 9.121,10.395 L7.336,10.395 C7.336,7.875 9.31,5.922 11.851,5.922 C14.392,5.922 16.387,7.875 16.387,10.395 C16.387,12.915 14.392,14.868 11.851,14.868 L10.591,14.868 Z" fill="#000000" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">FAQs</span></a>
            </li>
            {/* exit process */}
            <li className="kt-menu__item" aria-haspopup="true" >
                <a href={CONSTANT.url.exit} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path d="M9.35321926,16.3736278 L16.3544311,10.3706602 C16.5640654,10.1909158 16.5882961,9.87526197 16.4085517,9.66562759 C16.3922584,9.64662485 16.3745611,9.62887247 16.3556091,9.6125202 L9.35439731,3.57169798 C9.14532254,3.39130299 8.82959492,3.41455255 8.64919993,3.62362732 C8.5708616,3.71442013 8.52776329,3.83034375 8.52776329,3.95026134 L8.52776329,15.9940512 C8.52776329,16.2701936 8.75162092,16.4940512 9.02776329,16.4940512 C9.14714624,16.4940512 9.2625893,16.4513356 9.35321926,16.3736278 Z" fill="#000000" transform="translate(12.398118, 9.870355) rotate(-450.000000) translate(-12.398118, -9.870355) " />
                                <rect fill="#000000" opacity="0.3" transform="translate(12.500000, 17.500000) scale(-1, 1) rotate(-270.000000) translate(-12.500000, -17.500000) " x="11" y="11" width="3" height="13" rx="0.5" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Exit</span></a>
            </li>
            <li className="kt-menu__item" aria-haspopup="true" >
                <a href={CONSTANT.url.lms} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path d="M9.35321926,16.3736278 L16.3544311,10.3706602 C16.5640654,10.1909158 16.5882961,9.87526197 16.4085517,9.66562759 C16.3922584,9.64662485 16.3745611,9.62887247 16.3556091,9.6125202 L9.35439731,3.57169798 C9.14532254,3.39130299 8.82959492,3.41455255 8.64919993,3.62362732 C8.5708616,3.71442013 8.52776329,3.83034375 8.52776329,3.95026134 L8.52776329,15.9940512 C8.52776329,16.2701936 8.75162092,16.4940512 9.02776329,16.4940512 C9.14714624,16.4940512 9.2625893,16.4513356 9.35321926,16.3736278 Z" fill="#000000" transform="translate(12.398118, 9.870355) rotate(-450.000000) translate(-12.398118, -9.870355) " />
                                <rect fill="#000000" opacity="0.3" transform="translate(12.500000, 17.500000) scale(-1, 1) rotate(-270.000000) translate(-12.500000, -17.500000) " x="11" y="11" width="3" height="13" rx="0.5" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">LMS</span></a>
            </li>
        </React.Fragment>
    )
}

export default Sidebar;