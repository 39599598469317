import * as React from 'react';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from './../../component/common/Loader';
import Shimmer from './../../component/common/Loader';
import CONSTANT from './../../constant';

import { addSubCategory } from './../../action/HelpdeskActions';
import AddSubCategoryCard from './../../component/setting/helpdesk-category/AddSubCategory';

class AddSubCategory extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false
        }
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Add Helpdesk Sub-Category
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="col-lg-12">
                    <AddSubCategoryCard
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel}
                    />
                </div>
            </div>
        )
    }

    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        addSubCategory(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.result) {
                window.location.href = CONSTANT.url.helpdeskCategoryList
            }
        });
    }

    onCancel = () => {
        window.location.href = CONSTANT.url.helpdeskCategoryList
    }
}

export default AddSubCategory;
