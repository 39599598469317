import * as React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from '../../component/common/Loader';
import CONSTANT from './../../constant';
import AddDocumentType from '../../component/document-type/Add'
// import AddDocumentComponent from '../../component/document/Add'
import { addDocumentType } from '../../action/SettingsActions';

class AddAttendance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false
        }
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Document type
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                {/* <!--begin::Section--> */}
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <AddDocumentType
                                            onSubmit={this.onSubmit}
                                            onCancel={this.onCancel}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        addDocumentType(model).then((res: any) => {
            this.setState({ showLoader: false });
            window.location.href = CONSTANT.url.documentType;
        }).catch((err: any) => {
            alert(err.message);
        })
    }

    onCancel = () => {
        window.location.href = CONSTANT.url.documentType;
    }
}

export default AddAttendance;


// import * as React from 'react';

// import CONSTANT from '../../constant';
// import Header from '../../component/common/Header';
// import Sidebar from '../../component/common/Sidebar';
// import { addDocumentType } from '../../action/SettingsActions';
// import AddDocumentComponent from '../../component/document/Add'

// class AddDocument extends React.Component<any, any> {
//     constructor(props: any) {
//         super(props);
//     }

//     public render() {

//         return (
//             <React.Fragment>
//                 <Header />
//                 <div className="col-lg-12 main-container">
//                     <div className="fluid-container pl-0">
//                         <div className="row">
//                             <Sidebar />
//                             <AddDocumentComponent
//                                 onSubmit={this.onSubmit}
//                             />
//                         </div>
//                     </div>
//                 </div>

//             </React.Fragment >
//         )
//     }


//     onSubmit = (model: any) => {
//         addDocumentType(model)
//             .then((res: any) => {
//                 console.log(res);
//                 // this.props.history.push("CONSTANT.url.settingsOption.document")
//                 window.location.href = CONSTANT.url.documentType;
//             })
//             .catch((err: any) => {
//                 alert(err)

//             })
//     }


// }

// export default AddDocument;
