import React from 'react';

const Pagination = (props: any) => {
    let { page, total, limit, onPageChange } = props;

    let currentPage = page;
    let lastPage = Math.ceil(total / limit);
    const delta = 3;

    const range = [];
    for (let i = Math.max(2, (currentPage - delta)); i <= Math.min((lastPage - 1), (currentPage + delta)); i += 1) {
        range.push(i);
    }

    if ((currentPage - delta) > 2) {
        range.unshift('...');
    }
    if ((currentPage + delta) < (lastPage - 1)) {
        range.push('...');
    }

    range.unshift(1);
    if (lastPage !== 1) range.push(lastPage);

    return (
        <div className="row">
            <div className="col-xl-12">
                <div className="kt-portlet">
                    <div className="kt-portlet__body">
                        <div className="kt-pagination kt-pagination--brand">
                            <ul className="kt-pagination__links">
                                {
                                    page !== 1 && <React.Fragment>
                                        <li className="kt-pagination__link--first">
                                            <a href="javascript:;" onClick={onPageChange} data-page={1}><i className="fa fa-angle-double-left kt-font-brand pe-none"></i></a>
                                        </li>
                                        <li className="kt-pagination__link--next">
                                            <a href="javascript:;" onClick={onPageChange} data-page={page - 1}><i className="fa fa-angle-left kt-font-brand pe-none"></i></a>
                                        </li>
                                    </React.Fragment>
                                }
                                {
                                    range.map(function (item: any, index: number) {
                                        return (
                                            <li key={index} className={item === page ? "kt-pagination__link--active" : ""}>
                                                {
                                                    item === '...' ? <a>{item}</a> : <a href="javascript:;" data-page={item} onClick={onPageChange}>{item}</a>
                                                }
                                            </li>
                                        )
                                    })
                                }
                                {/* <li>
                                    <a href="javascript:;" onClick={onPageChange}>...</a>
                                </li> */}
                                {/* {
                                    total > limit && <React.Fragment>
                                        <li>
                                            <a href="javascript:;" onClick={onPageChange}>29</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;" onClick={onPageChange}>30</a>
                                        </li>
                                        <li className="kt-pagination__link--active">
                                            <a href="javascript:;" onClick={onPageChange}>31</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;" onClick={onPageChange}>32</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;" onClick={onPageChange}>33</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;" onClick={onPageChange}>34</a>
                                        </li>
                                    </React.Fragment>
                                } */}

                                {/* <li>
                                    <a href="javascript:;" onClick={onPageChange}>...</a>
                                </li> */}
                                {
                                    page !== total && <React.Fragment>
                                        <li className="kt-pagination__link--prev">
                                            <a href="javascript:;" onClick={onPageChange} data-page={page + 1}><i className="fa fa-angle-right kt-font-brand pe-none"></i></a>
                                        </li>
                                        <li className="kt-pagination__link--last">
                                            <a href="javascript:;" onClick={onPageChange} data-page={total / limit}><i className="fa fa-angle-double-right kt-font-brand pe-none"></i></a>
                                        </li>
                                    </React.Fragment>
                                }

                            </ul>

                            {/* <div className="kt-pagination__toolbar">
                                <select className="form-control kt-font-brand"
                                // style="width: 60px"
                                >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                <span className="pagination__desc">
                                    Displaying 10 of 230 records
                    </span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default Pagination;
