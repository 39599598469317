import * as React from 'react';
import { onChange, validateForm, setOptions } from '../../utils';
import { getAllGenderList, getAllMaritalStatusList } from '../../action/SettingsActions';

class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;

        this.state = {
            userId: props.userId,
            genderId: { value: detail ? detail.genderId : '', name: 'genderId', error: '', isRequired: true, options: [] },
            maritalStatusId: { value: detail ? detail.maritalStatusId : '', name: 'maritalStatusId', error: '', isRequired: true, options: [] },

            fatherName: { value: detail ? detail.fatherName : '', name: 'fatherName', error: '', isRequired: true },
            motherName: { value: detail ? detail.motherName : '', name: 'motherName', error: '', isRequired: true },
            personalEmail: { value: detail ? detail.personalEmail : '', name: 'personalEmail', error: '', isRequired: true },
            nationality: { value: detail ? detail.nationality : '', name: 'nationality', error: '', isRequired: true },
            dateOfBirth: { value: detail && detail.dateOfBirth ? detail.dateOfBirth.substr(0, 10) : '', name: 'dateOfBirth', error: '', isRequired: true },
            bloodGroup: { value: detail ? detail.bloodGroup : '', name: 'bloodGroup', error: '', isRequired: true },
        }
    }

    componentDidMount() {
        getAllGenderList().then((res: any) => {
            setOptions(this, this.state.genderId.name, res.result);
        });
        getAllMaritalStatusList().then((res: any) => {
            setOptions(this, this.state.maritalStatusId.name, res.result);
        });
    }

    public render() {
        const { userId,
            genderId, fatherName, motherName, nationality, personalEmail,
            dateOfBirth, maritalStatusId, bloodGroup } = this.state;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Basic Detail
                                    </h3>
                                </div>
                            </div>

                            <form className="kt-form kt-form--label-right" onSubmit={this.onSubmit}>
                                <div className="kt-portlet__body">
                                    <div className="row">
                                        <div className="col-lg-4 form-group">
                                            <label>Father name *</label>
                                            <input
                                                type="text"
                                                className={fatherName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter father name"
                                                name={fatherName.name}
                                                value={fatherName.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="col-lg-4 form-group">
                                            <label>Mother name *</label>
                                            <input
                                                type="text"
                                                className={motherName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter mother name"
                                                name={motherName.name}
                                                value={motherName.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="col-lg-4 form-group">
                                            <label>Nationality *</label>
                                            <input
                                                type="text"
                                                className={nationality.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter nationality"
                                                name={nationality.name}
                                                value={nationality.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div className="col-lg-4 form-group">
                                            <label>Date of birth *</label>
                                            <input
                                                type="date"
                                                className={dateOfBirth.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter date of birth"
                                                name={dateOfBirth.name}
                                                value={dateOfBirth.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div className="col-lg-4 form-group">
                                            <label>Gender *</label>
                                            <select
                                                className={genderId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                name={genderId.name}
                                                value={genderId.value}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    genderId.options.map((item: any, index: number) => {
                                                        return (
                                                            <option value={item.id} key={index}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div className="col-lg-4 form-group">
                                            <label>Martial status *</label>
                                            <select
                                                className={maritalStatusId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                name={maritalStatusId.name}
                                                value={maritalStatusId.value}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    maritalStatusId.options.map((item: any, index: number) => {
                                                        return (
                                                            <option value={item.id} key={index}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div className="col-lg-4 form-group">
                                            <label>Blood group *</label>
                                            <input
                                                type="text"
                                                className={bloodGroup.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter blood group"
                                                name={bloodGroup.name}
                                                value={bloodGroup.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div className="col-lg-8 form-group">
                                            <label>Personal email *</label>
                                            <input
                                                type="text"
                                                className={personalEmail.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter personal email id"
                                                name={personalEmail.name}
                                                value={personalEmail.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <div className="row">
                                            <div className="col-md-9"></div>
                                            <div className="col-md-3">
                                                <button type="submit" className="btn btn-brand btn-bold btn-block">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    private _getJsonToPOST(state: any) {
        const stateData = JSON.parse(JSON.stringify(state));
        const jsonToReturn = {
            userId: parseFloat(stateData.userId),
            genderId: parseFloat(stateData.genderId.value),
            fatherName: stateData.fatherName.value,
            dateOfBirth: stateData.dateOfBirth.value,
            motherName: stateData.motherName.value,
            nationality: stateData.nationality.value,
            personalEmail: stateData.personalEmail.value,
            maritalStatusId: parseFloat(stateData.maritalStatusId.value),
            bloodGroup: stateData.bloodGroup.value,
        };
        return jsonToReturn;
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const model = this._getJsonToPOST(this.state);
            this.props.onSubmit(model);
        }
    }
}

export default EmployeeMaster;
