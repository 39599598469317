import React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import BOT from '../../component/chat-bot/Index';
import PieChart from '../../component/chart/DoughnutChart';
import ModalWindow from './../../component/common/ModalWindow';
import ExtendTimeModal from './../../component/lms/ExtendTimeModal';

class ComponentName extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            questions: undefined,
            cateogories: undefined,
            activeCategoryId: undefined,
            isModalOpen: false
        }
    }


    componentDidMount() {
    }

    render() {
        const { isModalOpen } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {isModalOpen &&
                    <ModalWindow
                        title="Extend Timeline"
                        backdrop="static"
                        toggleModal={this.closeModal}>
                        <ExtendTimeModal
                            isModalOpen={isModalOpen}
                            closeModal={this.closeModal}
                        />
                    </ModalWindow>
                }
            </React.Fragment >
        );
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Course Detail
                        </h3>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <div className="kt-subheader__wrapper row">
                            <div className="kt-subheader__toolbar">
                                <button type="button" className="btn btn-primary" onClick={this.openAddTimeline}>
                                    Extend Timeline
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { questions, categories, activeCategoryId } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <BOT />
                <div className="row">
                    <div className="col-xl-8">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="font-weight-bolder text-dark">Anushka Singh’s Learning Journey to Excellence: BPCL Batch 1</span>
                                    </h3>
                                    <span className="label label-light-success label-inline font-weight-bolder mr-1 mb-2 ml-2">BPCL</span>

                                </div>
                            </div>
                            {/* <div className="kt-portlet__body"> */}
                            <iframe height="400" src="https://www.youtube.com/embed/eIrMbAQSU34" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                            {/* </div> */}

                        </div>
                    </div>

                    <div className="col-xl-4">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Projects
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{ width: '35%' }} aria-valuenow={35} aria-valuemin={0} aria-valuemax={100}>35%</div>
                                </div>
                                <div className="progress mt-3">
                                    <div className="progress-bar" role="progressbar" style={{ width: '60%' }} aria-valuenow={60} aria-valuemin={0} aria-valuemax={100}>60%</div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-xl-6 text-center mt-1">
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                            Start Date
                                        </span>
                                        <span className="text-muted font-weight-bold">
                                            May 20, 2020
                                        </span>
                                    </div>

                                    <div className="col-xl-6 text-center mt-1">
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                            End Date
                                        </span>
                                        <span className="text-muted font-weight-bold">
                                            July 20, 2020
                                        </span>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-xl-6">
                                        <PieChart
                                            data={[
                                                { name: '', value: 30 },
                                                { name: '', value: 70 }
                                            ]}
                                        />
                                        <div className="text-center mt-1">
                                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                APPROVED <br /> DOCUMENTS
                                            </span>
                                            {/* <span className="text-muted font-weight-bold">
                                                Paid
                                            </span> */}
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <PieChart
                                            data={[
                                                { name: '', value: 35 },
                                                { name: '', value: 65 },
                                            ]}
                                            colors={['#2ecc71']}
                                        />
                                        <div className="text-center mt-1">
                                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                COMPLETE <br /> MILESTONES
                                            </span>
                                            {/* <span className="text-muted font-weight-bold">
                                                Paid
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-xl-6">
                                        <PieChart
                                            data={[
                                                { name: '', value: 20 },
                                                { name: '', value: 80 },
                                            ]}
                                            colors={['#95a5a6']}
                                        />
                                        <div className="text-center mt-1">
                                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                COMPLETE PHASES
                                            </span>
                                            {/* <span className="text-muted font-weight-bold">
                                                Paid
                                            </span> */}
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <PieChart
                                            data={[
                                                { name: '', value: 40 },
                                                { name: '', value: 60 },
                                            ]}
                                            colors={['#9b59b6']}
                                        />
                                        <div className="text-center mt-1">
                                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                COMPLETE TASKS
                                            </span>
                                            {/* <span className="text-muted font-weight-bold">
                                                Paid
                                            </span> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="card gutter-b">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3 className="card-label">
                                        Project Content
					                </h3>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="accordion accordion-toggle-arrow" id="accordionExample1">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-title" data-toggle="collapse" data-target="#collapseOne1">
                                                Tasks
							                </div>
                                        </div>
                                        <div id="collapseOne1" className="collapse show" data-parent="#accordionExample1">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                            <div className="mr-3">
                                                                <div className="d-flex align-items-center mr-3">
                                                                    <h6 className="card-title align-items-start flex-column">
                                                                        <span className="font-weight-bolder text-dark">Bella Fernandes Learning Journey to Excellence: BPCL Batch 1</span>
                                                                    </h6>
                                                                </div>

                                                                <div className="d-flex flex-wrap my-2">
                                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                                <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000"></path>
                                                                                <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5"></circle>
                                                                            </g>
                                                                        </svg></span>david.s@loop.com
                                                                    </a>
                                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <mask fill="white">
                                                                                    <use ></use>
                                                                                </mask>
                                                                                <g></g>
                                                                                <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000"></path>
                                                                            </g>
                                                                        </svg></span>PR Manager
                                                                    </a>
                                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold">
                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                                <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000"></path>
                                                                            </g>
                                                                        </svg></span>Melbourne
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="progress mt-3">
                                                            <div className="progress-bar" role="progressbar" style={{ width: '100%' }} aria-valuenow={60} aria-valuemin={0} aria-valuemax={100}>60%</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseTwo1">
                                                Phase Documents
							                </div>
                                        </div>
                                        <div id="collapseTwo1" className="collapse" data-parent="#accordionExample1">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                            <div className="mr-3">
                                                                <div className="d-flex align-items-center mr-3">
                                                                    <h6 className="card-title align-items-start flex-column">
                                                                        <span className="font-weight-bolder text-dark">Bella Fernandes Learning Journey to Excellence: BPCL Batch 1</span>
                                                                    </h6>
                                                                </div>

                                                                <div className="d-flex flex-wrap my-2">
                                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                                <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000"></path>
                                                                                <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5"></circle>
                                                                            </g>
                                                                        </svg></span>david.s@loop.com
                                                                    </a>
                                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <mask fill="white">
                                                                                    <use ></use>
                                                                                </mask>
                                                                                <g></g>
                                                                                <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000"></path>
                                                                            </g>
                                                                        </svg></span>PR Manager
                                                                    </a>
                                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold">
                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                                <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000"></path>
                                                                            </g>
                                                                        </svg></span>Melbourne
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="progress mt-3">
                                                            <div className="progress-bar" role="progressbar" style={{ width: '100%' }} aria-valuenow={60} aria-valuemin={0} aria-valuemax={100}>60%</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseThree1">
                                                Pending Items
							                </div>
                                        </div>
                                        <div id="collapseThree1" className="collapse" data-parent="#accordionExample1">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                            <div className="mr-3">
                                                                <div className="d-flex align-items-center mr-3">
                                                                    <h6 className="card-title align-items-start flex-column">
                                                                        <span className="font-weight-bolder text-dark">Bella Fernandes Learning Journey to Excellence: BPCL Batch 1</span>
                                                                    </h6>
                                                                </div>

                                                                <div className="d-flex flex-wrap my-2">
                                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                                <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000"></path>
                                                                                <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5"></circle>
                                                                            </g>
                                                                        </svg></span>david.s@loop.com
                                                                    </a>
                                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <mask fill="white">
                                                                                    <use ></use>
                                                                                </mask>
                                                                                <g></g>
                                                                                <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000"></path>
                                                                            </g>
                                                                        </svg></span>PR Manager
                                                                    </a>
                                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold">
                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                                <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000"></path>
                                                                            </g>
                                                                        </svg></span>Melbourne
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="progress mt-3">
                                                            <div className="progress-bar" role="progressbar" style={{ width: '100%' }} aria-valuenow={60} aria-valuemin={0} aria-valuemax={100}>60%</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6">

                        <div className="card card-custom gutter-b example example-compact">
                            <div className="card-header">
                                <h3 className="card-title">
                                    MCQ's
				                </h3>

                            </div>

                            <form className="form">
                                <div className="card-body">

                                    <div className="form-group validated">
                                        <label className="form-control-label">1. Which of the following option leads to the portability and security of Java?</label>
                                        <div className="checkbox-inline">
                                            <div className="row">
                                                <span className="col-xl-6 col-md-6">
                                                    A) Bytecode is executed by JVM
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    B) The applet makes the Java code secure and portable
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) Use of exception handling
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) Dynamic binding between objects
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group validated">
                                        <label className="form-control-label">2. Which of the following is not a Java features?</label>
                                        <div className="checkbox-inline">
                                            <div className="row">
                                                <span className="col-xl-6 col-md-6">
                                                    A) Dynamic
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    B) Architecture Neutral
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) Use of pointers
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) Object-oriented
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group validated">
                                        <label className="form-control-label">3. _____ is used to find and fix bugs in the Java programs.</label>
                                        <div className="checkbox-inline">
                                            <div className="row">
                                                <span className="col-xl-6 col-md-6">
                                                    A) JVM
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    B) JRE
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) JDK
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) JDB
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group validated">
                                        <label className="form-control-label">4. Which of the following is a valid declaration of a char?</label>
                                        <div className="checkbox-inline">
                                            <div className="row">
                                                <span className="col-xl-6 col-md-6">
                                                    A) char ch = '\utea';
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    B) char ca = 'tea';
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) char cr = \u0223;
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) char cc = '\itea';
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group validated">
                                        <label className="form-control-label">5. What is the return type of the hashCode() method in the Object class?</label>
                                        <div className="checkbox-inline">
                                            <div className="row">
                                                <span className="col-xl-6 col-md-6">
                                                    A) Object
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    B) int
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) long
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) void
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group validated">
                                        <label className="form-control-label">6. Which of the following tool is used to generate API documentation in HTML format from doc comments in source code?</label>
                                        <div className="checkbox-inline">
                                            <div className="row">
                                                <span className="col-xl-6 col-md-6">
                                                    A) javap tool
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    B) javaw commandint
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) Javadoc tool
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) javah command
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group validated">
                                        <label className="form-control-label">7. An interface with no fields or methods is known as a ______.</label>
                                        <div className="checkbox-inline">
                                            <div className="row">
                                                <span className="col-xl-6 col-md-6">
                                                    A) Runnable Interface
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    B) Marker Interface
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) Abstract Interface
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) CharSequence Interface
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group validated">
                                        <label className="form-control-label">8. Which method of the Class.class is used to determine the name of a class represented by the class object as a String?</label>
                                        <div className="checkbox-inline">
                                            <div className="row">
                                                <span className="col-xl-6 col-md-6">
                                                    A) getClass()
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    B) intern()
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) getName()
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) toString()
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group validated">
                                        <label className="form-control-label">9. In which process, a local variable has the same name as one of the instance variables?</label>
                                        <div className="checkbox-inline">
                                            <div className="row">
                                                <span className="col-xl-6 col-md-6">
                                                    A) Serialization
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    B) Variable Shadowing
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) Abstraction
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) Multi-threading
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group validated">
                                        <label className="form-control-label">10. Which package contains the Random class?</label>
                                        <div className="checkbox-inline">
                                            <div className="row">
                                                <span className="col-xl-6 col-md-6">
                                                    A) java.util package
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    B) java.lang package
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) java.awt package
                                                </span>
                                                <span className="col-xl-6 col-md-6">
                                                    C) java.io package
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="card-footer">
                                    <button type="submit" className="btn btn-success font-weight-bold mr-2">Submit</button>
                                    <button type="submit" className="btn btn-light-success font-weight-bold">Cancel</button>
                                </div> */}
                            </form>

                        </div>

                    </div>

                </div>
            </div>
        )
    }

    onSubmit = (e: any) => {
        const isSure = window.confirm('Are you sure, you want to initate the exit process?');
    }

    openAddTimeline = () => {
        this.setState({ isModalOpen: true });
    }

    closeModal = () => {
        this.setState({ isModalOpen: false });
    }
}

export default ComponentName;
