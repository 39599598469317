import * as React from 'react';
import $ from 'jquery';
import moment from 'moment';
import XLSX from 'xlsx';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import PieChart from './../../component/chart/PieChart';
import CONSTANT from './../../constant';

import { getTeamDailyHistory } from '../../action/AttendanceActions';
import { onChange } from '../../utils';

class EmployeeList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            date: moment(),
            present: undefined,
            absent: undefined,
            leave: undefined,
            uniqueRecords: undefined,
            showLoader: false,
            counter: { total: 0, absent: 0, present: 0, leave: 0 }
        }
    }

    componentDidMount() {
    }

    public render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { date } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Task Report
                        </h3>



                    </div>

                </div>
            </div>

        )
    }

    renderBody = () => {
        const { present, absent, leave, uniqueRecords, counter } = this.state;

        // let totalEmployeeCount = 0;
        // totalEmployeeCount = present !== undefined ? (present.length + absent.length + leave.length) : 0;

        // let totalEmployeeCount = 0;
        // totalEmployeeCount = present !== undefined ? (present.length + absent.length + leave.length) : 0;

        // let totalEmployeeCount = 0;
        // totalEmployeeCount = present !== undefined ? (present.length + absent.length + leave.length) : 0;

        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            {/* <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        <a href={CONSTANT.url.lms}>
                                            Nitesh Kumar
                                        </a>
                                    </h3>
                                </div>
                            </div> */}
                            <div className="kt-portlet__body">
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '3%' }}>#</th>
                                            <th>Name</th>
                                            <th>Employee Enrolled</th>
                                            <th>Completed</th>
                                            <th>In Progress</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Anushka Singh’s Learning Journey to Excellence: BPCL Batch 7</td>
                                            <td>4</td>
                                            <td>0</td>
                                            <td>4</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Anushka Singh’s Learning Journey to Excellence: BPCL Batch 6</td>
                                            <td>4</td>
                                            <td>0</td>
                                            <td>4</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Anushka Singh’s Learning Journey to Excellence: BPCL Batch 5</td>
                                            <td>4</td>
                                            <td>4</td>
                                            <td>0</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>



                </div>

                {/* <Pagination /> */}
            </div>
        )
    }
}

export default EmployeeList;
