import * as React from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import Loader from '../../component/common/Loader';
import CONSTANT from './../../constant';

import LoginBG from './../../assets/media/bg/bg-4.jpg';
import Logo from './../../assets/images/logo/logo-light.png';
import queryString from 'query-string';

import { onChange, validateForm, getStorage, setCookie, removeCookie, removeStorage } from './../../utils';
import { login } from './../../action/AuthAction';

class Login extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            mobile: { name: 'mobile', value: '', error: '', isRequired: true },
            password: { name: 'password', value: '', error: '', isRequired: true },
            showLoader: false,
            locale: getStorage(CONSTANT.keys.locale),
        }

        const language = getStorage(CONSTANT.keys.language);
        if (language === undefined || language === null) {
            this.props.history.push(CONSTANT.url.language);
        }

        const qParam = queryString.parse(props.location.search);
        if (qParam.mock === 'true') {
            setCookie(CONSTANT.keys.useMockEnv, true);
        } else {
            removeCookie(CONSTANT.keys.useMockEnv);
        }

        // remove all storage keys except language
        removeStorage(CONSTANT.keys.configuration);
        removeStorage(CONSTANT.keys.userDetail);
        removeStorage(CONSTANT.keys.teamDetail);
    }

    componentDidMount() {
        document.title = "Login - HRMS";
    }

    public render() {
        const { mobile, password, showLoader, locale } = this.state;
        return (
            <div className="kt-grid kt-grid--ver kt-grid--root">
                <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside" style={{ backgroundImage: `url(${LoginBG})` }}>
                            <div className="kt-grid__item">
                                <a href="#" className="kt-login__logo">
                                    <img src={Logo} style={{ height: 30 }} />

                                </a>
                            </div>
                            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                                <div className="kt-grid__item kt-grid__item--middle">
                                    <h3 className="kt-login__title">Welcome to HRMS</h3>
                                    <div>
                                        <h4 className="kt-login__subtitle mb-0">Centralized and secure employee data</h4>
                                        <p className="text-light">Manage all your HR administrative actions from a central location. Search employees, set favorites, view organization trees, and analyze attrition reports--all from a single dashboard.</p>
                                    </div>
                                    <div>
                                        <h4 className="kt-login__subtitle mb-0">Track time, leave, and attendance in a snap</h4>
                                        <p className="text-light">Eliminate errors due to manual entries, consolidate attendance data from multiple devices, customize leave types, schedule jobs, and create timesheets with ease.</p>
                                    </div>
                                    <div>
                                        <h4 className="kt-login__subtitle mb-0">Simplify employee performance reviews.</h4>
                                        <p className="text-light">Identify your employees' strengths and weaknesses. Set goals, gather 360-degree feedback, and review performance. Bridge the gap between where employees are and where they need to be.</p>
                                    </div>
                                    <div>
                                        <h4 className="kt-login__subtitle mb-0">Answer your employees' questions.</h4>
                                        <p className="text-light">Too many questions from employees? With our HR case-management tool, create a category for every type of question, assign an agent, organize, and discuss the case through comments.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-grid__item">
                                <div className="kt-login__info">
                                    <div className="kt-login__copyright">
                                        © {new Date().getFullYear()} rozgar.com
								</div>
                                    <div className="kt-login__menu">
                                        <a href="#" className="kt-link">Privacy</a>
                                        <a href="#" className="kt-link">Legal</a>
                                        <a href="#" className="kt-link">Contact</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">

                            <div className="kt-login__head">
                                <span className="kt-login__signup-label">Don't have an account yet?</span>&nbsp;&nbsp;
							    <a href={CONSTANT.url.register} className="kt-link kt-login__signup-link">Sign Up!</a>
                            </div>

                            <div className="kt-login__body">

                                <div className="kt-login__form">
                                    <div className="kt-login__title">
                                        <h3>Sign In</h3>
                                    </div>

                                    <form className="kt-form" onSubmit={this.submitForm}>
                                        <div className="form-group">
                                            <NumberFormat
                                                className={mobile.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Mobile number"
                                                name={mobile.name}
                                                maxLength={10}
                                                allowNegative={false}
                                                value={mobile.value}
                                                allowLeadingZeros={true}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            {/* <input className="form-control" type="password" placeholder="Password" name="password" autoComplete="off" /> */}
                                            <input
                                                type="password"
                                                className={password.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Password"
                                                name={password.name}
                                                value={password.value}
                                                onChange={this.onChange} />
                                        </div>

                                        <div className="kt-login__actions">
                                            <a href={CONSTANT.url.forgotPassword} className="kt-link kt-login__link-forgot">
                                                Forgot Password ?
										</a>
                                            <button type="submit" className="btn btn-primary btn-elevate kt-login__btn-primary">
                                                Sign In
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {
                    showLoader && <Loader />
                }
            </div>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    private submitForm = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            this.setState({ showLoader: true });
            const st = this.state;
            const model = {
                mobile: st.mobile.value,
                password: st.password.value
            };
            login(model).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.status) {
                    if (res.result.isu && res.result.iob) {
                        window.location.href = CONSTANT.url.dashboard;
                    } else if (!res.result.isu && res.result.iob) {
                        window.location.href = CONSTANT.url.dashboard;
                    } else if (!res.result.isu && !res.result.iob) {
                        window.location.href = CONSTANT.url.profile;
                    }
                } else {
                    alert(this.state.locale[res.error]);
                }
            });
        }
    }
}

export default Login;