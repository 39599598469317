import * as React from 'react';
import CONSTANT from './../../constant';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';

import { getLanguageList, getLocale } from './../../action/PublicActions';
import { setOptions, onChange, setStorage, getStorage } from '../../utils';

class ListLeave extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const selLang = getStorage(CONSTANT.keys.language);
        this.state = {
            language: { name: 'language', value: selLang, error: '', isRequired: true, options: [] },
            showLoader: false,
            locale: undefined
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Language - HRMS";
        getLanguageList().then((res: any) => {
            setOptions(this, this.state.language.name, res.result);
        })
    }

    public render() {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { language, showLoader, locale } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            {locale !== undefined ? locale.lbl_changeLanguage : 'Change language'}
                        </h3>

                        {/* <span className="kt-subheader__separator kt-subheader__separator--v"></span> */}

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            {/* <span className="kt-subheader__desc" id="kt_subheader_total">
                                {`${currMonthName} ${currYear}`}
                            </span> */}
                        </div>

                    </div>
                    {/* <div className="kt-subheader__toolbar">
                        <a href={CONSTANT.url.addLeaveType} className="btn btn-label-brand btn-bold">Add leave type</a>
                    </div> */}
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { language, showLoader, locale } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card m-3">
                            <div className="card-body">
                                <p>{locale !== undefined ? locale.lbl_changeLanguage_desc : 'Below is the list of languages we support right now. Please let us know if your language is missing from the list at hrms@rozgar.com'}</p>
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            language.options.map((item: any, index: number) => {
                                                return (
                                                    <div className="form-check" key={index}>
                                                        <label className="form-check-label">
                                                            <input type="radio" onChange={this.onChange} className="form-check-input" name={language.name} value={item.fileName} checked={item.fileName === language.value} />
                                                            {item.name}
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
        this.setState({ showLoader: true });
        getLocale(value).then((res: any) => {
            setStorage(CONSTANT.keys.language, value);
            setStorage(CONSTANT.keys.locale, res.data);
            this.setState({ showLoader: false, locale: res.data });
        });
    }
}

export default ListLeave;
