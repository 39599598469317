import * as React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import UserDefaultPIC from './../../assets/media/users/default.jpg'
import CONSTANT from './../../constant';
import { getEmployeeList } from '../../action/EmployeeAction';
import { sendOnboardingEmail } from './../../action/MailerAction';
import SearchForm from './../../component/employee/SearchForm';
import Shimmer from '../../component/common/Shimmer';
import Paging from '../../component/common/Paging';
import Pagination from '../../component/common/Pagination';
import { getLocalStorageItem } from '../../utils';

class EmployeeList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 16,
            sort: 1,
            count: 0,
            showLoader: false
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        return (
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <Sidebar />
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                        <Header />
                        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                            {this.renderHeader()}
                            {this.renderBody()}
                            {this.renderChatDiv()}
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }

    renderHeader = () => {
        const { count } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Users
                            </h3>

                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {count} Total
                            </span>
                            <SearchForm />
                        </div>

                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href={CONSTANT.url.addEmployeeMaster} className="btn btn-label-brand btn-bold">
                            Add User
                            </a>

                        <a href={CONSTANT.url.employeeBulkUpload} className="btn btn-label-brand btn-bold">
                            Bulk Upload
                            </a>
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { page, list, limit, count } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    {
                        list === undefined && <div className="col-md-12">
                            <Shimmer />
                        </div>
                    }
                    {
                        list !== undefined && list.map((item: any, index: any) => {
                            const ud = getLocalStorageItem(CONSTANT.keys.userDetail);
                            const ppURL = item.profilePicture ? `${process.env.REACT_APP_BASE_URL}/profile-picture/${ud.sc}/${item.id}/${item.profilePicture}` : UserDefaultPIC;
                            const userURL = CONSTANT.url.employeeOverview.replace(':id', item.id);
                            const nameInitals = item.name.split(' ');
                            const locationDetailURL = CONSTANT.url.employeeLocationDetail.replace(':id', item.id);
                            return (
                                <div className="col-md-3" key={index}>
                                    {/* <!--Begin::Portlet--> */}
                                    <div className="kt-portlet kt-portlet--height-fluid">
                                        <div className="kt-portlet__head kt-portlet__head--noborder">
                                            <div className="kt-portlet__head-label">
                                                <h3 className="kt-portlet__head-title"></h3>
                                            </div>
                                            {
                                                !item.isOnboard && <div className="kt-portlet__head-toolbar">
                                                    <a href="#" className="btn btn-icon" data-toggle="dropdown"><i className="flaticon-more-1 kt-font-brand"></i></a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <ul className="kt-nav">
                                                            <li className="kt-nav__item">
                                                                <a href="javascript:;" onClick={this.sendOnboardingEmail} data-user-id={item.id} className="kt-nav__link">
                                                                    <span className="kt-nav__link-text pe-none">Send onboarding mail</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                        </div>


                                        <div className="kt-portlet__body">
                                            {/* <!--begin::Widget --> */}
                                            <div className="kt-widget kt-widget--user-profile-2">
                                                <div className="kt-widget__head">
                                                    <div className="kt-widget__media">
                                                        {
                                                            item.profilePicture !== null ?
                                                                <img className="kt-widget__img kt-hidden-" src={ppURL} alt="image" /> :
                                                                <div className={`kt-widget__pic kt-font-boldest  kt-hidden- ${this.getRandomColorCSS()}`}>
                                                                    {nameInitals.map((i: any) => i.substring(0, 1))}
                                                                </div>
                                                        }
                                                    </div>

                                                    <div className="kt-widget__info">
                                                        <a href={userURL} className="kt-widget__username">
                                                            {item.name}
                                                        </a>

                                                        <span className="kt-widget__desc">
                                                            {item.designation ? item.designation : '-'}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="kt-widget__body">
                                                    {/* <div className="kt-widget__section">
                                                            I distinguish three <a href="#" className="kt-font-brand kt-link kt-font-transform-u kt-font-bold">#xrs-54pq</a> objectsves First
                                                esetablished and nice coocked rice
                                                        </div> */}

                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__contact">
                                                            <span className="kt-widget__label">Email:</span>
                                                            <a href={`mailto:${item.email}`} className="kt-widget__data">{item.email}</a>
                                                        </div>
                                                        <div className="kt-widget__contact">
                                                            <span className="kt-widget__label">Phone:</span>
                                                            <a href={`tel:${item.mobile}`} className="kt-widget__data">{item.mobile}</a>
                                                        </div>
                                                        <div className="kt-widget__contact">
                                                            <span className="kt-widget__label">Is onboard:</span>
                                                            <span className="kt-widget__data">{item.isOnboard ? <span className="text-success font-weight-bold">Yes</span> : <span className="text-warning font-weight-bold">No</span>}</span>
                                                        </div>
                                                        <div className="kt-widget__contact">
                                                            <span className="kt-widget__label">Login status:</span>
                                                            <span className="kt-widget__data font-weight-bold text-danger">{item.loginStatusName}</span>
                                                        </div>
                                                        {/* <div className="kt-widget__contact">
                                                                <span className="kt-widget__label">Administrator:</span>
                                                                <span className="kt-widget__data">{item.isSystemUser ? 'Yes' : 'No'}</span>
                                                            </div> */}
                                                    </div>
                                                </div>

                                                <div className="kt-widget__footer">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <a href="#" className="btn btn-block btn-label-brand">
                                                                Call
                                                            </a>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <a href="#" className="btn btn-block btn-label-brand">
                                                                Message
                                                            </a>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <a href={locationDetailURL} className="btn btn-block btn-label-brand">
                                                                Locate
                                                            </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>

                <Pagination page={page} limit={limit} total={count} onPageChange={this.onPageChange} />
                {/* {
                    list !== undefined && <div className="row">
                        <div className="col-xl-12">
                            <div className="kt-portlet">
                                <div className="kt-portlet__body">
                                    <Paging page={page} listItem={list} onPageChange={this.onPageChange} />
                                </div>
                            </div>

                        </div>
                    </div>
                } */}

            </div>
        )
    }

    renderChatDiv = () => {
        return (

            <div className="modal modal-hide fade- modal-sticky-bottom-right" id="kt_chat_modal" role="dialog" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="kt-chat">
                            <div className="kt-portlet kt-portlet--last">
                                <div className="kt-portlet__head">
                                    <div className="kt-chat__head ">
                                        <div className="kt-chat__left">
                                            <div className="kt-chat__label">
                                                <a href="#" className="kt-chat__title">Jason Muller</a>
                                                <span className="kt-chat__status">
                                                    <span className="kt-badge kt-badge--dot kt-badge--success"></span> Active
                                    </span>
                                            </div>
                                        </div>
                                        <div className="kt-chat__right">
                                            <div className="dropdown dropdown-inline">
                                                <button type="button" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="flaticon-more-1"></i>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-md">

                                                    <ul className="kt-nav">
                                                        <li className="kt-nav__head">
                                                            Messaging
                                                <i className="flaticon2-information" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more..."></i>
                                                        </li>
                                                        <li className="kt-nav__separator"></li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-group"></i>
                                                                <span className="kt-nav__link-text">New Group</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-open-text-book"></i>
                                                                <span className="kt-nav__link-text">Contacts</span>
                                                                <span className="kt-nav__link-badge">
                                                                    <span className="kt-badge kt-badge--brand  kt-badge--rounded-">5</span>
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-bell-2"></i>
                                                                <span className="kt-nav__link-text">Calls</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-dashboard"></i>
                                                                <span className="kt-nav__link-text">Settings</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-protected"></i>
                                                                <span className="kt-nav__link-text">Help</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__separator"></li>
                                                        <li className="kt-nav__foot">
                                                            <a className="btn btn-label-brand btn-bold btn-sm" href="#">Upgrade plan</a>
                                                            <a className="btn btn-clean btn-bold btn-sm" href="#" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more...">Learn more</a>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>

                                            <button type="button" className="btn btn-clean btn-sm btn-icon" data-dismiss="modal">
                                                <i className="flaticon2-cross"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__body">
                                    <div className="kt-scroll kt-scroll--pull" data-height="410" data-mobile-height="225">
                                        <div className="kt-chat__messages kt-chat__messages--solid">
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">2 Hours</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    How likely are you to recommend our company<br /> to your friends and family?
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Hey there, we’re just writing to let you know that you’ve<br /> been subscribed to a repository on GitHub.
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok, Understood!
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">Just Now</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    You’ll receive notifications for all issues, pull requests!
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">2 Hours</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    You were automatically <b className="kt-font-brand">subscribed</b> <br />because you’ve been given access to the repository
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>

                                                <div className="kt-chat__text">
                                                    You can unwatch this repository immediately <br />by clicking here: <a href="#" className="kt-font-bold kt-link"></a>
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok!
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">Just Now</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok!
                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-chat__input">
                                        <div className="kt-chat__editor">
                                            <textarea placeholder="Type here..." style={{ height: 50 }}></textarea>
                                        </div>
                                        <div className="kt-chat__toolbar">
                                            <div className="kt_chat__tools">
                                                <a href="#"><i className="flaticon2-link"></i></a>
                                                <a href="#"><i className="flaticon2-photograph"></i></a>
                                                <a href="#"><i className="flaticon2-photo-camera"></i></a>
                                            </div>
                                            <div className="kt_chat__actions">
                                                <button type="button" className="btn btn-brand btn-md  btn-font-sm btn-upper btn-bold kt-chat__reply">reply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onMessageClick = () => {
        // window.location.href = CONSTANT.url.messageHome;
    }

    getRandomColorCSS = () => {
        const colorsCSS = [
            "kt-widget__pic--info kt-font-info",
            "kt-widget__pic--warning kt-font-warning",
            "kt-widget__pic--danger kt-font-danger",
            "kt-widget__pic--brand kt-font-brand",

        ]
        return colorsCSS[Math.floor(Math.random() * colorsCSS.length) + 1];
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { page, limit, sort } = this.state;
        getEmployeeList(page, limit, sort, "*").then((res: any) => {
            if (res.status) {
                this.setState({
                    list: res.result.list,
                    count: res.result.count,
                });
            } else {
                alert(res.error);
            }
        }, (error: any) => {
            alert(error.message);
        });
    }

    sendOnboardingEmail = (e: any) => {
        const userId = e.target.dataset.userId;
        this.setState({ showLoader: true });
        sendOnboardingEmail({ userId }).then((response: any) => {
            window.alert('A mail was sent to the employee on the given email id.');
            this.setState({
                showLoader: false
            });
        }, (error: any) => {
            alert(error.message);
        });
    }

    onPageChange = (e: any) => {
        const page = parseInt(e.target.dataset.page, 10);
        this.setState({ page }, () => {
            window.scrollTo(0, 0);
            this.loadList();
        });
    }
}

export default EmployeeList;
