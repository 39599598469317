import * as React from 'react';
import $ from 'jquery';
import moment from 'moment';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import PieChart from './../../component/chart/PieChart';
import CONSTANT from './../../constant';
import StackedBarGraph from '../../component/chart/StackedBarChart';

class AttendanceDashboard extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            date: moment(),
            list: undefined,
            uniqueRecords: undefined,
            showLoader: false
        }
    }

    componentDidMount() {

    }

    public render() {

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {



        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Attendance
                        </h3>

                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {

        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">

                    <div className="col-xl-3">

                        <div className="kt-portlet kt-portlet--height-fluid">

                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Mark Attendance
                                    </h3>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <span className="kt-font-brand">Fri, Apr 03, 2020</span>
                                </div>
                            </div>

                            <div className="kt-portlet__body kt-portlet__body--fit">
                                <div className="kt-widget kt-widget--project-1 ">
                                    <div className="kt-widget__body ">
                                        <div className="kt-widget__stats ">
                                            <div className="kt-widget__item col-lg-12 text-center">
                                                <div className="kt-widget__label">
                                                    <h4 className="kt-font-bold">09: 00: 00</h4>
                                                </div>
                                                <div className="kt-widget__label mt-3">
                                                    <button type="button" className="btn btn-success">Check In</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-3">

                        <div className="kt-portlet kt-portlet--height-fluid">

                            {/* <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Employee Summary
			                        </h3>
                                </div>

                            </div> */}

                            <div className="kt-portlet__body kt-portlet__body--fit text">

                                <div className="kt-widget kt-widget--project-1 ">

                                    <div className="kt-widget__body ">

                                        <div className="kt-widget__stats ">

                                            <div className="kt-widget__item col-lg-12 text-center">

                                                {/* <div className="kt-widget__label">
                                                    <h4 className="kt-font-bold">ACTIVE EMPLOYEES</h4>
                                                </div> */}
                                                <div className="kt-widget__label mt-4">
                                                    <h2 className="kt-font-bold kt-font-success">129</h2>
                                                </div>
                                                <div className="kt-widget__label mt-4">
                                                    <span className="kt-widget__value kt-font-brand">On Time</span>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-3">

                        <div className="kt-portlet kt-portlet--height-fluid">

                            {/* <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Employee Summary
			                        </h3>
                                </div>

                            </div> */}

                            <div className="kt-portlet__body kt-portlet__body--fit text">

                                <div className="kt-widget kt-widget--project-1 ">

                                    <div className="kt-widget__body ">

                                        <div className="kt-widget__stats ">

                                            <div className="kt-widget__item col-lg-12 text-center">

                                                {/* <div className="kt-widget__label">
                                                    <h4 className="kt-font-bold">ACTIVE EMPLOYEES</h4>
                                                </div> */}
                                                <div className="kt-widget__label mt-4">
                                                    <h2 className="kt-font-bold kt-font-danger">12</h2>
                                                </div>
                                                <div className="kt-widget__label mt-4">
                                                    <span className="kt-widget__value kt-font-brand">Late In</span>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3">

                        <div className="kt-portlet kt-portlet--height-fluid">

                            {/* <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Employee Summary
			                        </h3>
                                </div>

                            </div> */}

                            <div className="kt-portlet__body kt-portlet__body--fit text">

                                <div className="kt-widget kt-widget--project-1 ">

                                    <div className="kt-widget__body ">

                                        <div className="kt-widget__stats ">
                                            <div className="kt-widget__item col-lg-12 text-center">
                                                {/* <div className="kt-widget__label">
                                                    <h4 className="kt-font-bold">ACTIVE EMPLOYEES</h4>
                                                </div> */}
                                                <div className="kt-widget__label mt-4">
                                                    <h1 className="kt-fontsize-40 kt-font-bold kt-font-warning">14</h1>
                                                </div>
                                                <div className="kt-widget__label mt-4">
                                                    <span className="kt-widget__value kt-font-brand">Absent</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <div className="kt-portlet kt-portlet--height-fluid">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Recent Timelogs
                                    </h3>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <a href="#" className="btn btn-label-brand btn-bold btn-sm" data-toggle="dropdown" aria-expanded="false">
                                        View All
			                        </a>
                                </div>
                                {/* <div className="kt-portlet__head-toolbar">
                                    <div className="dropdown dropdown-inline">
                                        <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="flaticon-more-1"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <ul className="kt-nav">
                                                <li className="kt-nav__item">
                                                    <a href="#" className="kt-nav__link">
                                                        <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                        <span className="kt-nav__link-text">Today</span>
                                                    </a>
                                                </li>
                                                <li className="kt-nav__item">
                                                    <a href="#" className="kt-nav__link">
                                                        <i className="kt-nav__link-icon flaticon2-send"></i>
                                                        <span className="kt-nav__link-text">Monthly</span>
                                                    </a>
                                                </li>
                                                <li className="kt-nav__item">
                                                    <a href="#" className="kt-nav__link">
                                                        <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                        <span className="kt-nav__link-text">Yearly</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                             */}
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-widget6">
                                    <div className="kt-widget6__head">
                                        <div className="kt-widget6__item">
                                            <span>Emp Id</span>
                                            <span>Emp Name</span>
                                            <span>Date</span>
                                            <span>Check in</span>
                                            <span>Check out</span>
                                        </div>
                                    </div>
                                    <div className="kt-widget6__body">
                                        <div className="kt-widget6__item">
                                            <span>MTC101</span>
                                            <span>Nitesh</span>
                                            <span>01 April 2020</span>
                                            <span className="kt-font-info kt-font-bold">9:11 AM</span>
                                            <span className="kt-font-info kt-font-bold">06:40 PM</span>
                                        </div>
                                        <div className="kt-widget6__item">
                                            <span>MTC101</span>
                                            <span>Nitesh</span>
                                            <span>01 April 2020</span>
                                            <span className="kt-font-info kt-font-bold">9:11 AM</span>
                                            <span className="kt-font-info kt-font-bold">06:40 PM</span>
                                        </div>
                                        <div className="kt-widget6__item">
                                            <span>MTC101</span>
                                            <span>Nitesh</span>
                                            <span>01 April 2020</span>
                                            <span className="kt-font-info kt-font-bold">9:11 AM</span>
                                            <span className="kt-font-info kt-font-bold">06:40 PM</span>
                                        </div>
                                        <div className="kt-widget6__item">
                                            <span>MTC101</span>
                                            <span>Nitesh</span>
                                            <span>01 April 2020</span>
                                            <span className="kt-font-info kt-font-bold">9:11 AM</span>
                                            <span className="kt-font-info kt-font-bold">06:40 PM</span>
                                        </div>
                                        <div className="kt-widget6__item">
                                            <span>MTC101</span>
                                            <span>Nitesh</span>
                                            <span>01 April 2020</span>
                                            <span className="kt-font-info kt-font-bold">9:11 AM</span>
                                            <span className="kt-font-info kt-font-bold">06:40 PM</span>
                                        </div>

                                    </div>
                                    {/* <div className="kt-widget6__foot">
                                        <div className="kt-widget6__action kt-align-right">
                                            <a href="#" className="btn btn-label-brand btn-sm btn-bold">View All</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Shift Wise Headcounts Statistics
                                    </h3>
                                </div>
                            </div>
                            <PieChart data={[
                                { name: 'General', value: 102 },
                                { name: 'Night', value: 52 },
                                { name: 'Noon', value: 23 }]} />
                        </div>
                    </div>
                </div>


                <div className="row">

                    <div className="col-xl-6">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <span className="kt-portlet__head-icon kt-hidden">
                                        <i className="la la-gear"></i>
                                    </span>
                                    <h3 className="kt-portlet__head-title">
                                        Attendance Summary
                                    </h3>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <div className="kt-portlet__head-wrapper">
                                        <div className="kt-portlet__head-actions">
                                            <div className="dropdown dropdown-inline">
                                                <button type="button" className="btn btn-default btn-icon-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    This Month
			                                    </button>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <ul className="kt-nav">
                                                        <li className="kt-nav__section kt-nav__section--first">
                                                            <a href="#" className="kt-nav__link">
                                                                <span className="kt-nav__section-text">This Month</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <span className="kt-nav__link-text">Last Month</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <span className="kt-nav__link-text">This Year</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="kt-portlet__body">
                                <StackedBarGraph />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default AttendanceDashboard;
