import axios from 'axios';
import { getAuthHeader, getUploadFileHeader, getCookie } from './../utils';
import CONSTANT from './../constant';
import mapper from './../mock-environment/mapper';

export const POST = (url: string, param: any, includeHeader: boolean, includeMultipartHeader: boolean) => {
    const useMockEnv = getCookie(CONSTANT.keys.useMockEnv);
    if (useMockEnv === 'true') {
        const fileMapper: any = mapper;
        const mockFile = fileMapper[url];
        const fileDetail = require(`./../mock-environment/${mockFile}`);
        return Promise.resolve(fileDetail.result);
    } else {
        return axios.post(`${process.env.REACT_APP_BASE_URL}${url}`, param, includeHeader ? getAuthHeader() : (includeMultipartHeader ? getUploadFileHeader : null))
            .then((response: any) => {
                return response.data;
            }, (err: any) => {
                return err;
            });
    }
}

export const PUT = (url: string, param: any, includeHeader: boolean, includeMultipartHeader: boolean) => {
    return axios.put(url, param, includeHeader ? getAuthHeader() : (includeMultipartHeader ? getUploadFileHeader : null))
        .then((response: any) => {
            return response.data;
        }, (err: any) => {
            return err;
        });
}

export const DELETE = (url: string, param: any, includeHeader: boolean, includeMultipartHeader: boolean) => {
    return axios.delete(url, param, includeHeader ? getAuthHeader() : (includeMultipartHeader ? getUploadFileHeader : null))
        .then((response: any) => {
            return response.data;
        }, (err: any) => {
            return err;
        });
}

export const GET = (url: string, includeHeader: boolean, forceMockEnv: boolean) => {
    const useMockEnv = getCookie(CONSTANT.keys.useMockEnv);
    if (useMockEnv === 'true' || forceMockEnv === true) {
        const fileMapper: any = mapper;
        const mockFile = fileMapper[url.split('?')[0]];
        const fileDetail = require(`./../mock-environment/${mockFile}`);
        return Promise.resolve(fileDetail.result);
    } else {
        return axios.get(url, includeHeader ? getAuthHeader() : null)
            .then((response: any) => {
                return response.data;
            }, (err: any) => {
                return err;
            });
    }

}