import * as React from 'react';
import { onChange, setOptions, validateForm, getBrowserDetail, getOSDetail } from './../../utils';
import { getAllAttendanceType } from '../../action/SettingsActions';
import { getCheckinDetail, postCheckInDetail, postCheckOutDetail } from './../../action/AttendanceActions';
import Shimmer from '../common/Shimmer';
import moment from 'moment';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            attendanceTypeId: { name: 'attendanceTypeId', value: detail ? detail.attendanceTypeId : '', error: '', isRequired: true, options: [] },
            remark: { name: 'remark', value: detail ? detail.remark : '', error: '', isRequired: false },
            detail: undefined,
            elapsedTime: undefined
        }
    }

    componentDidMount() {
        getCheckinDetail().then((res: any) => {
            this.setState({ detail: res.result }, () => {
                if (this.state.detail !== null) {
                    let counter = setInterval(this.attendanceTimer, 1000);
                }
            });
        });
        getAllAttendanceType().then((res: any) => {
            setOptions(this, this.state.attendanceTypeId.name, res.result.filter((i: any) => i.isGeofenced === false));
        });
    }

    public render() {
        const { detail } = this.state;
        return (
            <React.Fragment>
                {
                    detail === undefined && <Shimmer />
                }
                {
                    detail !== undefined && detail !== null && this.renderTimeElapsed()
                }
                {
                    detail !== undefined && detail === null && this.renderCheckInButton()
                }
            </React.Fragment>

        )
    }

    renderCheckInButton = () => {
        const { attendanceTypeId, remark } = this.state;
        return (
            <form onSubmit={this.onCheckin} className="row">
                <div className="col-lg-12 form-group">
                    <label>Attendance Type *</label>
                    <select
                        className={attendanceTypeId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        name={attendanceTypeId.name}
                        value={attendanceTypeId.value}
                        onChange={this.onChange}
                    >
                        <option value="">Select Type</option>
                        {
                            attendanceTypeId.options.map(function (item: any, index: number) {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col-lg-12 form-group">
                    <label>Remarks</label>
                    <textarea
                        rows={3}
                        className={remark.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Any remarks"
                        name={remark.name}
                        value={remark.value}
                        onChange={this.onChange}
                    />
                </div>
                <div className="col-md-12"><small>* Indicates required fields</small></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                    <button type="submit" className="btn btn-sm btn-primary btn-block">Check In</button>
                </div>
            </form>
        )
    }

    renderTimeElapsed = () => {
        const { detail, elapsedTime } = this.state;
        return (
            <form onSubmit={this.onCheckout} className="row">
                <div className="col-lg-12 form-group">
                    <label>{detail.attendanceTypeName}</label>
                    <h3>{elapsedTime ? elapsedTime : '--:--:--'} Hrs</h3>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                    <button type="submit" className="btn btn-sm btn-primary btn-block">Check Out</button>
                </div>
            </form>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onCheckin = (e: any) => {
        e.preventDefault();
        const isSure = window.confirm('Are you sure you want to check-in?');
        if (isSure && validateForm(this)) {
            const browserDetail: any = getBrowserDetail();
            const OSName = getOSDetail();
            const model = {
                attendanceTypeId: this.state.attendanceTypeId.value,
                remark: this.state.remark.value,
                source: OSName,
                deviceId: `${browserDetail.browserName}-${browserDetail.fullVersion}`,
                lat: `0`,
                lng: `0`,
            };
            postCheckInDetail(model).then((res: any) => {
                if (res.status) {
                    this.setState({ detail: res.result }, () => {
                        let counter = setInterval(this.attendanceTimer, 1000);
                    });
                } else {
                    alert(res.error);
                }
            })
        }
    }

    onCheckout = (e: any) => {
        e.preventDefault();
        const isSure = window.confirm('Are you sure you want to check-out?');
        if (isSure) {
            postCheckOutDetail().then((res: any) => {
                if (res.status) {
                    this.setState({ detail: null, elapsedTime: undefined });
                    window.location.reload();
                } else {
                    alert(res.error);
                }
            })
        }
    }

    attendanceTimer = () => {
        const { detail } = this.state;
        const inTimeUnix = moment.unix(detail.inTimeUnix);
        const outTimeUnix = moment.unix(new Date().getTime() / 1000);
        const elapsedTime = moment.duration(outTimeUnix.diff(inTimeUnix));

        const formattedTime = `${elapsedTime.get('hours')}:${elapsedTime.get('minutes')}:${elapsedTime.get('seconds')}`;
        this.setState({
            elapsedTime: formattedTime
        });
    }
}

export default AddComponent;
