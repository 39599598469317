import * as React from 'react';
import NumberFormat from 'react-number-format';
import { onChange, validateForm } from './../../utils';

class AddEducation extends React.Component<any, any>{

    constructor(props: any) {
        super(props);
        const detail = props.detail
        this.state = {
            userId: props.userId,
            id: detail ? detail.id : undefined,
            course: { value: detail ? detail.course : '', name: 'course', error: '', isRequired: true },
            institute: { value: detail ? detail.institute : '', name: 'institute', error: '', isRequired: true },
            marks: { value: detail ? detail.marks : '', name: 'marks', error: '', isRequired: true },
            startYear: { value: detail ? detail.startYear : '', name: 'startYear', error: '', isRequired: true },
            endYear: { value: detail ? detail.endYear : '', name: 'endYear', error: '', isRequired: false }
        }
    }

    render() {
        const { id, course, institute, marks, startYear, endYear } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-lg-6 form-group">
                        <label>Degree/Diploma </label>
                        <input
                            type="text"
                            className={course.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Name of the Degree/Diploma"
                            name={course.name}
                            value={course.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-lg-6 form-group">
                        <label>Institute Name</label>
                        <input
                            type="text"
                            className={institute.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Name of the Institute"
                            name={institute.name}
                            value={institute.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-lg-4 form-group">
                        <label>Percentage/Grade</label>
                        <input
                            type="text"
                            className={marks.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Percentage/Grade"
                            name={marks.name}
                            value={marks.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-lg-4 form-group">
                        <label>Start Year</label>
                        <NumberFormat
                            className={startYear.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Enter start year"
                            name={startYear.name}
                            maxLength={4}
                            value={startYear.value}
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="col-lg-4 form-group">
                        <label>End Year</label>
                        <NumberFormat
                            className={endYear.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            placeholder="Enter end year"
                            name={endYear.name}
                            maxLength={4}
                            value={endYear.value}
                            onChange={this.onChange}
                        />
                        <small>Note: Leave empty if pursuing</small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <button onClick={this.props.onCancel} type="button" className="btn btn-secondary btn-sm btn-block">Cancel</button>
                    </div>
                    <div className="col-md-3" />
                    <div className="col-md-3">
                        {
                            id !== undefined && <button type="button" onClick={this.onDelete} data-id={id} className="btn btn-danger btn-sm btn-sm btn-block">Delete</button>
                        }
                    </div>
                    <div className="col-md-3">
                        <button type="submit" className="btn btn-primary btn-sm btn-sm btn-block">Save Changes</button>
                    </div>
                </div>
            </form>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    private _getJsonToPOST = () => {
        const stateData = JSON.parse(JSON.stringify(this.state));
        let jsonToReturn = {
            userId: stateData.userId,
            course: stateData.course.value,
            institute: stateData.institute.value,
            marks: stateData.marks.value,
            startYear: stateData.startYear.value,
            endYear: stateData.endYear.value,
        };
        if (this.props.detail)
            return { ...jsonToReturn, id: this.props.detail.id }

        return jsonToReturn;
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const model = this._getJsonToPOST();
            this.props.onSubmit(model);
        }
    }

    onDelete = (e: any) => {
        const result = window.confirm('Are you sure, you want to delete this record?')
        if (result) {
            const id = e.target.dataset.id;
            this.props.onDelete(id);
        }
    }
}

export default AddEducation;

