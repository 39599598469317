import React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import BOT from './../../component/chat-bot/Index';
const questionsJSON = require('./data.json');

class ComponentName extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            questions: undefined,
            cateogories: undefined,
            activeCategoryId: undefined
        }
    }


    componentDidMount() {
        const questions = questionsJSON;
        const categories = Array.from(new Set([...questions.map((item: any) => item.categoryId)])).
            map((categoryId: any) => {
                const categoryName = questions.find((item: any) => (item.categoryId === categoryId))?.categoryName;
                return {
                    categoryId: categoryId,
                    categoryName: categoryName
                }
            });
        const activeCategoryId = categories[0].categoryId;
        this.setState({ questions, categories, activeCategoryId });
    }

    render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            FAQs
                    </h3>
                    </div>
                    <div className="kt-subheader__toolbar">
                        {/* <div className="kt-subheader__wrapper row">

                        <div className="kt-subheader__toolbar">
                            <button type="button" className="btn btn-primary btn-upper">
                                Getting Started
                            </button>
                        </div>

                    </div> */}
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { questions, categories, activeCategoryId } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <BOT />
                <div className="kt-portlet kt-faq-v1">
                    <div className="kt-portlet__body">
                        <div className="row">
                            <div className="col-xl-3">
                                <ul className="kt-nav" id="kt_nav" role="tablist">
                                    {
                                        categories !== undefined && categories.map((i: any, index: number) => {
                                            return (
                                                <li className={i.categoryId === activeCategoryId ? "kt-nav__item kt-nav__item--active" : "kt-nav__item"} key={index}>
                                                    <a className="kt-nav__link collapsed" onClick={this.onChangeCategroy} data-id={i.categoryId} role="tab" id="kt_nav_link_1" data-toggle="collapse" href="#kt_nav_sub_1" aria-expanded="false">
                                                        <span className="kt-nav__link-text pe-none">{i.categoryName}</span>
                                                        <span className="kt-nav__link-arrow pe-none"></span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="col-xl-9">
                                <div className="accordion accordion-solid accordion-toggle-plus" id="accordionExample1">
                                    {
                                        questions !== undefined && questions.filter((q: any) => q.categoryId === activeCategoryId).map((q: any, index: number) => {
                                            return (
                                                <div className="card" key={index}>
                                                    <div className="card-header" id="headingOne">
                                                        <div className="card-title collapsed" data-toggle="collapse" data-target={`#collapseOne${index}`}>
                                                            {q.question}
                                                        </div>
                                                    </div>
                                                    <div id={`collapseOne${index}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample1">
                                                        <div className="card-body">
                                                            <p dangerouslySetInnerHTML={{ __html: q.answer }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {/* <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne1">
                                                Where can admin see the attendance information?
                            </div>
                                        </div>
                                        <div id="collapseOne1" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample1">
                                            <div className="card-body">
                                                <p>
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                </p>
                                                <p>
                                                    Type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                </p>
                                                <p>
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                </p>
                                                <p>
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <div className="card-title" data-toggle="collapse" data-target="#collapseTwo1" aria-expanded="true" aria-controls="collapseTwo1">
                                                Impact on buyers who bought before the new item support policy.
                            </div>
                                        </div>
                                        <div id="collapseTwo1" className="collapse show" aria-labelledby="headingTwo1" data-parent="#accordionExample1">
                                            <div className="card-body">
                                                <p>
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                </p>
                                                <p>
                                                    Type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                </p>
                                                <p>
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                </p>
                                                <p>
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree1">
                                            <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                                                Extending and Renewing Item Support.
                            </div>
                                        </div>
                                        <div id="collapseThree1" className="collapse" aria-labelledby="headingThree1" data-parent="#accordionExample1">
                                            <div className="card-body">
                                                <p>
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                </p>
                                                <p>
                                                    Type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                </p>
                                                <p>
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                </p>
                                                <p>
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    onChangeCategroy = (e: any) => {
        const id = parseInt(e.target.dataset.id, 10);
        this.setState({ activeCategoryId: id })
    }
}

export default ComponentName;
