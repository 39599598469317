import React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import CONSTANT from './../../constant';

class ComponentName extends React.Component<any, any> {
    public render() {
        return (
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <Sidebar />
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                        <Header />
                        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                            {this.renderHeader()}
                            {this.renderBody()}
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Position list
                            </h3>

                        {/* <span className="kt-subheader__separator kt-subheader__separator--v"></span> */}

                        {/* <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {count} Total
                            </span>
                            <SearchForm />
                        </div> */}

                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href={CONSTANT.url.atsAddPosition} className="btn btn-label-brand btn-bold">
                            Add New Position
                        </a>

                        {/* <a href={CONSTANT.url.employeeBulkUpload} className="btn btn-label-brand btn-bold">
                            Bulk Upload
                            </a> */}
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                {/* <!--begin::Section--> */}
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Role</th>
                                                    <th scope="col">Industry</th>
                                                    <th scope="col">Skill(s)</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Modified by</th>
                                                    <th scope="col">Modified on</th>
                                                    <th scope="col">Candidate(s)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td><a href="/position/bvufzfk9i5k693avhf">SQL DBA Pune</a></td><td>Senior Software Devloper</td><td>IT Services</td><td>sql, dba, admin</td><td>Pune</td>
                                                    <td>Recruter Name</td>
                                                    <td>2 Days ago</td>

                                                    <td><a href={CONSTANT.url.atsCandidate}>View</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end::Section--> */}
                            </div>
                            {/* <!--end::Form--> */}
                        </div>
                    </div>
                </div>

                {/* <Pagination /> */}
            </div>
        )
    }
}

export default ComponentName;
