import React from 'react'
import moment from 'moment';
import Chart from "chart.js";

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import BarGraph from './../../component/chart/BarGraph';

import { getTeamLeaveList } from '../../action/LeaveActions'

export default class Leave extends React.Component<any, any> {
    teamLeaveChartRef: any = React.createRef();
    leaveBalanceChartRef: any = React.createRef();

    constructor(props: any) {
        super(props);
        this.state = {
            leaveList: undefined,
            showLoader: false,
            showManageLeaveModal: false,
            leaveDetail: undefined,
            comments: [],
            loadingComments: false,
            balance: null,
            date: moment()
        }
    }

    componentDidMount() {
        this.loadList();
        this.bindTeamLeaveChart();
        this.bindLeaveBalanceChart();
    }

    public render() {
        const { showLoader, showManageLeaveModal, leaveDetail } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    {this.renderBody()}
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        const { date } = this.state;
        const currYear = date.format('YYYY');

        const prevDate = moment(date.format('YYYY-MM-DD')).subtract(1, 'year');
        const prevYear = prevDate.format('YYYY');

        const nextDate = moment(date.format('YYYY-MM-DD')).add(1, 'year');
        const nextYear = nextDate.format('YYYY');

        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Leave
                    </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {currYear} </span>
                        </div>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href="javascript:;" onClick={this.changeDate} data-year={prevYear} className="btn btn-label-brand btn-bold"><i className="fas fa-angle-double-left mr-1 pe-none" aria-hidden="true"></i>{prevYear}</a>
                        <a href="javascript:;" onClick={this.changeDate} data-year={nextYear} className="btn btn-label-brand btn-bold">{nextYear}<i className="fas fa-angle-double-right ml-1 pe-none" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div >
        )
    }

    renderBody = () => {
        const { leaveList, loadingComments } = this.state;
        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Leaves this year
                                </h3>
                            </div>
                        </div>
                        <div className="kt-portlet__body">
                            {this.renderTeamLeaveChart()}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Leave Balance
					                                </h3>
                            </div>
                        </div>
                        <div className="kt-portlet__body">
                            {this.renderLeaveBalanceChart()}
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    bindTeamLeaveChart = () => {
        const myChartRef = this.teamLeaveChartRef.current.getContext("2d");

        new Chart(myChartRef, {
            type: "bar",
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        label: 'Narendra',
                        backgroundColor: '#F6301B',
                        data: [
                            4, 0, 0, 0, 0, 0, 0
                        ]
                    },
                    {
                        label: 'Manoj',
                        backgroundColor: '#F1C40F',
                        data: [
                            1, 1, 0, 0, 0, 0, 0
                        ]
                    },
                    {
                        label: 'Ranjan',
                        backgroundColor: '#9B59B6',
                        data: [
                            2, 2, 2, 0, 0, 0, 0
                        ]
                    },
                    {
                        label: 'Rajeev',
                        backgroundColor: '#95A5A6',
                        data: [
                            2, 2, 2, 0, 0, 0, 0
                        ]
                    },
                    {
                        label: 'Dhruv',
                        backgroundColor: '#3498DB',
                        data: [
                            0, 1, 0, 3, 0, 0, 0
                        ]
                    }
                ]
            },
            options: {
                responsive: true,
                scales: {
                    xAxes: [{
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    renderTeamLeaveChart = () => {
        return (
            <canvas
                id="myChart"
                ref={this.teamLeaveChartRef}
            />
        )
    }


    bindLeaveBalanceChart = () => {
        const myChartRef = this.leaveBalanceChartRef.current.getContext("2d");

        new Chart(myChartRef, {
            type: "bar",
            data: {
                labels: ['Narendra', 'Manoj', 'Ranjan', 'Rajeev', 'Dhruv'],
                datasets: [
                    {
                        label: 'Consumed',
                        backgroundColor: '#F6301B',
                        data: [
                            4, 2, 6, 6, 4
                        ]
                    },
                    {
                        label: 'Available',
                        backgroundColor: '#F1C40F',
                        data: [
                            8, 10, 6, 6, 8
                        ]
                    },
                ]
            },
            options: {
                responsive: true,
                scales: {
                    xAxes: [{
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    renderLeaveBalanceChart = () => {
        return (
            <canvas
                id="myChart"
                ref={this.leaveBalanceChartRef}
            />
        )
    }

    loadList = () => {
        const { date } = this.state;
        const year = date.format('YYYY');
        getTeamLeaveList(year).then((res: any) => {
            this.setState({ leaveList: res.result });
        })
    }

    changeDate = (e: any) => {
        const year = e.target.dataset.year;
        this.setState({ date: moment(`${year}-01-01`) }, () => {
            this.loadList();
        });
    }
}
