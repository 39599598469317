import React from "react";
import ChatBot from "react-simple-chatbot";
import defaultSteps from "./Steps";
const UserIcon = require("./../../assets/svg/use_bot.svg");
const BotIcon = require("./../../assets/svg/bot.svg");

class MobChatbot extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpened: true,
            categories: [],
            config: {
                width: "350px",
                height: "500px",
                floating: true
            },
            steps: defaultSteps
        };
    }

    onBotEventClick = (e: any) => {
        this.setState({ isOpened: false });
    };

    onRefreshEventClick = (e: any) => {
        localStorage.removeItem("rsc_cache");
        window.location.reload();
    };

    mytoggleFunction = (e: any) => {
        this.setState({ isOpened: false });
    };

    render() {
        const { isOpened, config, steps } = this.state;
        const bubbleStyleOptions = {
            fontSize: "1rem",
            fontFamily: "Poppins",
            backgroundColor: "white",
            border: "1px solid #1a237e",
            color: "#1a237e"
        };
        return (
            <ChatBot
                eventHandler={this.onBotEventClick}
                userAvatar={UserIcon}
                botAvatar={BotIcon}
                cache={true}
                submitButtonStyle={{}}
                headerComponent={
                    <div className="contact-profile">
                        <img src={BotIcon} alt="" />
                        <span>HR Helpdesk</span>
                        <div className="social-media">
                            <i
                                className="fas fa-sync"
                                aria-hidden="true"
                                onClick={this.onRefreshEventClick}
                            />
                        </div>
                    </div>
                }
                bubbleOptionStyle={bubbleStyleOptions}
                floating={true}
                opened={isOpened}
                recognitionEnable={true}
                recognitionLang={"en"}
                placeholder={"Send Text..."}
                recognitionPlaceholder={"Listening"}
                steps={steps}
                {...config}
            />
        );
    }
}

export default MobChatbot;
