export default {
    url: {
        login: '/',
        logout: '/logout',
        forgotPassword: '/forgot-password',
        changePassword: '/change-password',
        register: '/register',
        language: '/language',

        dashboard: '/dashboard',
        companyDashboard: '/company',

        // attendance route
        attendanceDashboard: '/attendance/dashboard',
        employeeAttendance: '/attendance/employee',
        teamAttendance: '/attendance/team',
        teamAttendanceDashboard: '/attendance/team/dashboard',

        consolidatedAttendanceDashboard: '/attendance/consolidated',
        companyAttendanceDashboard: '/attendance/company',
        companyAttendanceList: '/attendance/company/list',
        companyAttendanceMap: '/attendance/company/map',
        companyProxyAttendance: '/attendance/company/proxy',
        // attendance route

        // attendance regularization
        attendanceRegularizationList: '/attendance-regularization',
        addAttendanceRegularization: '/attendance-regularization/add',
        attendanceRegularizationDetail: '/attendance-regularization/detail/:id',
        teamAttendanceRegularizationRequest: '/attendance-regularization/team',
        // attendance regularization

        // attendance regularization
        compensatoryOffList: '/compensatory-off',
        addCompensatoryOff: '/compensatory-off/add',
        compensatoryOffDetail: '/compensatory-off/detail/:id',
        teamCompensatoryOffRequest: '/compensatory-off/team',
        // attendance regularization

        // attendace shift route
        attendenceShift: '/settings/shift',
        attendenceShifCalendar: '/settings/shift-listview',
        // attendace shift route

        // leave route
        leave: '/leave',
        leaveDashboard: '/leave/dashboard',
        leaveRequest: '/leave/request',
        employeeLeave: '/leave/employee',
        leaveDetail: '/leave/detail/:id',
        teamLeave: '/leave/team',
        teamLeaveDashboard: '/leave/team/dashboard',
        companyLeaveDashboard: '/leave/company',
        consolidatedLeaveDashboard: '/leave/consolidated',
        companyLeaveList: '/leave/company/dashboard',
        // leave route

        // settings route
        settings: '/settings',
        // settings route

        // exit route
        exit: '/exit',
        // settings route

        // attendance type route
        attendanceType: '/settings/attendance-type',
        addAttendanceType: '/settings/attendance-type/add',
        editAttendanceType: '/settings/attendance-type/:id',
        // attendance type route

        // designation route
        designationList: '/settings/designation',
        addDesignation: '/settings/designation/add',
        editDesignation: '/settings/designation/:id',
        // designation route

        // leave type route
        leaveType: '/settings/leave-type',
        addLeaveType: '/settings/leave-type/add',
        editLeaveType: '/settings/leave-type/:id',
        // leave type route

        // document type route
        documentType: '/settings/document-type',
        addDocumentType: '/settings/document-type/add',
        editDocumentType: '/settings/document-type/:id',
        // document type route

        // company branch route
        companyBranch: '/settings/company-branch',
        addCompanyBranch: '/settings/company-branch/add',
        editCompanyBranch: '/settings/company-branch/:id',
        // company branch route

        // company location route
        companyLocation: '/settings/company-location',
        addCompanyLocation: '/settings/company-location/add',
        editCompanyLocation: '/settings/company-location/:id',
        // company location route

        // company configuration
        companyConfiguration: '/settings/company-configuration',
        // company configuration

        // company calendar route
        companyCalendar: '/settings/company-calendar',
        addCompanyCalendar: '/settings/company-calendar/add',
        editCompanyCalendar: '/settings/company-calendar/:id',
        // company calendar route

        // geofencing group route
        geofenceGroup: '/settings/geofence-group',
        addGeofenceGroup: '/settings/geofence-group/add',
        editGeofenceGroup: '/settings/geofence-group/:id',
        // geofencing group route

        // payroll component route
        payrollTaxComponent: '/settings/payroll-tax-component',
        addPayrollTaxComponent: '/settings/payroll-tax-component/add',
        editPayrollTaxComponent: '/settings/payroll-tax-component/:id',
        // payroll component route

        // employee route
        employee: '/employee',
        employeeList: '/employee/list',
        employeeSearch: '/employee/search',
        employeeBulkUpload: '/employee/bulk',
        addEmployeeMaster: '/employee/add',
        employeeExitList: '/employee/exit',
        addEmployeeExit: '/employee/exit-add',

        employeeOverview: '/employee/overview/:id',
        employeeMasterDetail: '/employee/master-detail/:id',

        employeeBasicDetail: '/employee/basic-detail/:id',
        employeeReimbursementDetail: '/employee/reimbursement-detail/:id',
        employeeReimbursementListDetail: '/employee/reimbursement-list-detail/:id',

        employeeAddressDetail: '/employee/address-detail/:id',
        employeeFamilyDetail: '/employee/family-detail/:id',
        employeeBankDetail: '/employee/bank-detail/:id',
        employeeEducationDetail: '/employee/education-detail/:id',
        employeeExperienceDetail: '/employee/experience-detail/:id',
        employeeDocumentDetail: '/employee/document-detail/:id',
        employeeWorkDetail: '/employee/work-detail/:id',
        employeeSalaryDetail: '/employee/salary-detail/:id',
        employeeLeaveBalance: '/employee/leave-balance/:id',
        employeeProfilePicture: '/employee/profile-picture/:id',
        employeeTaxDeclarationDetail: '/employee/tax-declaration/:id',
        employeeNotificationDetail: '/employee/notification-detail/:id',
        employeeChangePassword: '/employee/change-password/:id',
        employeeLocationDetail: '/employee/location-detail/:id',
        employeeCallDetail: '/employee/call-detail/:id',
        employeeAssetDetail: '/employee/asset-detail/:id',
        employeeExitDetail: '/employee/exit-detail/:id',
        employeeSessionHistory: '/employee/session/:id',

        profile: '/profile',
        profileOverview: '/profile/overview',
        profileMasterDetail: '/profile/master-detail',
        profileBasicDetail: '/profile/basic-detail',

        profileReimbursementDetail: '/profile/reimbursement-detail',
        profileReimbursementListDetail: '/profile/reimbursement-list-detail',

        profileAddressDetail: '/profile/address-detail',
        profileFamilyDetail: '/profile/family-detail',
        profileBankDetail: '/profile/bank-detail',
        profileEducationDetail: '/profile/education-detail',
        profileExperienceDetail: '/profile/experience-detail',
        profileDocumentDetail: '/profile/document-detail',
        profileWorkDetail: '/profile/work-detail',
        profileSalaryDetail: '/profile/salary-detail',
        profileLeaveBalance: '/profile/leave-balance',
        profileProfilePicture: '/profile/profile-picture',
        profileNotificationDetail: '/profile/notification-detail',
        profileChangePassword: '/profile/change-password',
        profileTaxDeclaration: '/profile/tax-declaration-detail',
        profileAssetDetail: '/profile/asset-detail',
        // employee route

        // project route
        projectList: '/project',
        projectSearch: '/project/search',
        addProjectDetail: '/project/add',
        editProjectDetail: '/project/edit/:id',
        projectDetail: '/project/detail/:id',
        // project route

        // task route
        taskList: '/task',
        addTaskDetail: '/task/add',
        editTaskDetail: '/task/edit/:id',
        // task route

        // ats route
        atsDashboard: '/ats',
        atsPositionDashboard: '/ats/position',
        atsPositionList: '/ats/position/list',
        atsAddPosition: '/ats/position/add',
        atsEditPosition: '/ats/position/edit/:id',
        atsPositionDetail: '/ats/position/detail/:id',

        atsCandidate: '/ats/candidate',
        atsAddCandidate: '/ats/candidate/add',
        atsEditCandidate: '/ats/candidate/edit/:id',
        atsCandidateDetail: '/ats/candidate/detail/:id',
        // ats route

        // payroll route
        payrollSummary: '/payroll/summary',
        payrollDetail: '/payroll/detail/:id',
        payRuns: '/payroll/pay-runs',
        paySchedule: '/payroll/pay-schedule',
        payRunSubmit: '/payroll/pay-submit',
        payrollDashboard: '/payroll/dashboard',
        payrollExpense: '/payroll/expense',
        employeeInvestmentDeclaration: '/payroll/investment-declaration',
        employeeSalarySlips: '/payroll/salary-slips',
        employeeForm16: '/payroll/form16',
        // payroll route

        // asset route
        assetDashboard: '/asset/dashboard',
        assetList: '/asset/list',
        assetAdd: '/asset/add',
        employeeAssets: '/asset/employee',
        // asset route

        // reimbursement route
        consolidatedReimbursementDashboard: '/reimbursement/consolidated',
        reimbursementDashboard: '/reimbursement/dashboard',
        reimbursementList: '/reimbursement/list',
        addReimbursement: '/reimbursement/add',
        reimbursementDetail: '/reimbursement/detail/:id',
        teamReimbursement: '/reimbursement/team',
        // reimbursement route

        // pms route
        pmsDashboard: '/pms',
        pmsRating: '/pms/rating',
        pmsRatingDetail: '/pms/rating/:id',
        pmsCycle: '/settings/pms-cycle',
        pmsRoleCompetency: '/settings/pms-role-competency',
        addPMSRoleCompetency: '/settings/pms-role-competency/add',
        editPMSRoleCompetency: '/settings/pms-role-competency/edit/:id',
        // pms route

        // pms kra route
        kraDashboard: '/kra',
        kraList: '/kra/list',
        addKRA: '/kra/add',
        editKRA: '/kra/:id',
        // pms kra route

        // faq
        faq: '/faq',
        // faq

        // chat bot
        chatBot: '/bot',
        // chat bot

        // change language
        changeLanguage: '/change-language',
        // change language

        // holiday route
        holidayList: '/holiday',
        addHoliday: '/holiday/add',
        editHoliday: '/holiday/edit/:id',
        // holiday route

        // holiday type route
        holidayTypeList: '/settings/holiday-type',
        addHolidayType: '/settings/holiday-type/add',
        editHolidayType: '/settings/holiday-type/edit/:id',
        // holiday type route

        // helpdesk category
        helpdesk: '/helpdesk',
        helpdeskDashboard: '/helpdesk/dashboard',
        helpdeskTimeline: '/helpdesk/timeline/:code',
        helpdeskCategoryList: '/helpdesk-category',
        addHelpdeskCategory: '/helpdesk-category/add',
        editHelpdeskCategory: '/helpdesk-category/edit',
        addHelpdeskSubCategory: '/helpdesk-category/add-sub-category',
        helpdeskEmp: '/helpdesk-emp',
        helpdeskTimelineEmp: '/helpdesk/timeline-emp/:code',
        // helpdesk category

        //LMS Route
        lms: '/lms',
        lmsDetail: '/lms/detail',
        lmsTeamReport: '/lms/team/report',
        lmsTaskReport: '/lms/task/report',
        lmsIndividualReport: '/lms/team/individual-report',
        //LMS Route
    },
    title: {
        locationList: 'Location list - HRMS',
        addLocation: 'Add location - HRMS',
        editLocation: 'Edit location - HRMS',
    },
    keys: {
        token: 'tk.hrms.com',
        userDetail: 'ud.hrms.com',
        teamDetail: 'tm.hrms.com',
        useMockEnv: 'mk.hrms.com',
        configuration: 'co.hrms.com',
        language: 'lg.hrms.com',
        locale: 'lo.hrms.com'
    },
    leaveStatusId: {
        approved: 1,
        rejected: 2,
        withdrawn: 3
    },
}