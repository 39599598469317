import * as axios from 'axios';
import CONFIG from './../config';
import { getAuthHeader } from '../utils';

export const addAR = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/ar/add`;
    return axios.post(url, model, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getARList = (page: number, limit: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/ar/list?p=${page}&l=${limit}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getTeamAR = (page: number, limit: number, sort: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/ar/team?p=${page}&l=${limit}&s=${sort}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}


export const getARDetail = (id: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/ar/detail?id=${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const addComment = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/ar/comment`;
    return axios.post(url, model, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}