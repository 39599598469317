import * as React from 'react';
import { CustomInput } from 'reactstrap';
import Header from './../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import CONSTANT from './../../constant';
import { getSalaryComponentList, toggleIsActiveStatus } from './../../action/PayrollTaxComponentActions';
import { getDateParts } from '../../utils';

class ComponentName extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 10,
            showLoader: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = CONSTANT.title.locationList;

        this.loadList();
    }

    public render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Payroll Tax Component
                        </h3>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                        </div>

                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href={CONSTANT.url.addPayrollTaxComponent} className="btn btn-label-brand btn-bold">Add tax component</a>
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { list, showLoader, page, limit } = this.state;
        let lcList, scList = undefined;

        if (list !== undefined) {
            lcList = list.filter((i: any) => i.isCompliance === true);
            scList = list.filter((i: any) => i.isCompliance === false);
        }
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Legal compliance
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    {/* <th scope="col">Type</th>
                                                     <th scope="col">Amount</th> */}
                                                    {/* <th scope="col">Is active?</th> */}
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    lcList === undefined && <tr>
                                                        <td className="text-center" colSpan={2}>Loading...</td>
                                                    </tr>
                                                }
                                                {
                                                    lcList !== undefined && lcList.length === 0 && <tr>
                                                        <td className="text-center" colSpan={2}>No records found</td>
                                                    </tr>
                                                }
                                                {
                                                    lcList !== undefined && lcList.map((l: any, i: any) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{l.name}<br /><small>{l.description}</small></td>
                                                                <td className="float-right">
                                                                    <CustomInput data-id={l.id} onClick={this.toggleStatus} type="switch" id={`clCustomSwitchId-${i}`} name={`clCustomSwitch-${i}`} checked={l.isActive} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            {/* <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Is mandatory</th>
                                                    <th scope="col">Modified by</th>
                                                    <th scope="col">Modified on</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list === undefined && <tr>
                                                        <td className="text-center" colSpan={4}>Loading...</td>
                                                    </tr>
                                                }
                                                {
                                                    list !== undefined && list.length === 0 && <tr>
                                                        <td className="text-center" colSpan={4}>No records found</td>
                                                    </tr>
                                                }
                                                {
                                                    list !== undefined && list.map((item: any, index: number) => {
                                                        const modOn = getDateParts(item.modifiedOn);
                                                        const url = CONSTANT.url.editDocumentType.replace(':id', item.id);
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.name}</td>
                                                                <td>{item.isMandatory ? 'Yes' : 'No'}</td>
                                                                <td>{`${item.createdByName}`}</td>
                                                                <td>{`${modOn.relativeTime}`}</td>
                                                                <td>
                                                                    <a href={url}>
                                                                        <i className="fas fa-edit" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody> */}
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end::Section--> */}
                            </div>
                            {/* <!--end::Form--> */}
                        </div>
                    </div>


                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Salary component
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Percentage</th>
                                                    <th scope="col">Modified by</th>
                                                    <th scope="col">Modified on</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    scList === undefined && <tr>
                                                        <td className="text-center" colSpan={7}>Loading...</td>
                                                    </tr>
                                                }
                                                {
                                                    scList !== undefined && scList.length === 0 && <tr>
                                                        <td className="text-center" colSpan={7}>No records found</td>
                                                    </tr>
                                                }
                                                {
                                                    scList !== undefined && scList.map((l: any, i: any) => {
                                                        const detailPageURL = CONSTANT.url.editPayrollTaxComponent.replace(':id', l.id);
                                                        const modOn = getDateParts(l.modifiedOn);
                                                        return (
                                                            <tr key={i}>
                                                                <td>{l.name}<br /><small>{l.description}</small></td>
                                                                <td>{l.amount !== null ? parseInt(l.amount, 10).toLocaleString('en-IN') : '-'}</td>
                                                                <td>{l.percentage !== null ? parseInt(l.percentage, 10).toLocaleString('en-IN') : '-'}</td>
                                                                <td>{`${l.modifiedByName}`}</td>
                                                                <td>{`${modOn.relativeTime}`}</td>
                                                                <td className="text-right"><CustomInput data-id={l.id} onClick={this.toggleStatus} type="switch" id={`scCustomSwitchId-${i}`} name={`scCustomSwitch`} checked={l.isActive} /></td>
                                                                <td className="text-right"><a href={detailPageURL}><i className="fas fa-edit"></i></a></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    loadList = () => {
        const { page, limit } = this.state;
        getSalaryComponentList(page, limit).then((res: any) => {
            if (res.status) {
                this.setState({ list: res.result });
            } else {
                alert(res.error);
            }
        })
    }

    toggleStatus = (e: any) => {
        const id = parseInt(e.target.dataset.id, 10);
        const isActive = e.target.checked;
        this.setState({ showLoader: true });
        toggleIsActiveStatus({ id, isActive }).then((res: any) => {
            const updatedList = this.state.list.map((i: any) => {
                if (i.id === id) {
                    i.isActive = res.result.isActive;
                }
                return i;
            });
            this.setState({
                list: updatedList,
                showLoader: false
            });
        });
    }
}

export default ComponentName;
