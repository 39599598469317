import * as axios from 'axios';
import CONFIG from './../config';
import { getAuthHeader, getUploadFileHeader } from '../utils';

export const getEmployeeList = (page: number, limit: number, sort: number, type: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/list?p=${page}&l=${limit}&s=${sort}&t=${type}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const searchEmployeeList = (keyword: string, page: number, limit: number, sort: number, type: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/search?q=${keyword}&p=${page}&l=${limit}&s=${sort}&t=${type}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const getAllEmployeeList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/all`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

// export const getEmployeeCount = () => {
//     const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/count`;
//     return axios.get(url, getAuthHeader()).then((response: any) => {
//         return response.data;
//     });
// }



export const getMasterDetail = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/master/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const getBasicDetail = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/basic/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const getAddressDetail = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/address/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const getBankDetail = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/bank/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const getWorkDetail = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/work/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const getSalaryDetail = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/salary/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}


// employee summary
export const getEmployeeSummary = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/summary?id=${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
// employee summary

// document detail
// export const getSettingDocument = (userId: number) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/document/${userId}`;
//     return axios.get(url, getAuthHeader())
//         .then((response: any) => {
//             return response.data;
//         });
// }


export const getDocumentDetail = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/document/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const updateDocumentDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/document`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const deleteDocumentDetail = (id: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/document/${id}`;
    return axios.delete(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
// document detail

// experience detail
export const getExperienceDetail = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/experience/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const updateExperienceDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/experience`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const deleteExperienceDetail = (id: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/experience/${id}`;
    return axios.delete(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
// experience detail

// family detail
export const getFamilyDetail = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/family/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const updateFamilyDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/family`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const deleteFamilyDetail = (id: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/family/${id}`;
    return axios.delete(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
// family detail

// contact detail
export const getContactList = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/contact/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const updateContactList = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/contact`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
// contact detail


// education detail
export const getEducationDetail = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/education/${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

// export const addEmployeeEducation = (model: any) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/v1/add/employee/educationDetail`;
//     return axios.post(url, model, getAuthHeader())
//         .then((response: any) => {
//             return response.data;
//         });
// }

export const updateEducationDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/education`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const deleteEducationDetail = (id: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/education/${id}`;
    return axios.delete(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
// education detail


// attendance detail

// attendance detail

// export const getEmployeeListSummary = () => {
//     const url = `${process.env.REACT_APP_BASE_URL}/v1/get/employee/summary`;
//     return axios.get(url, getAuthHeader())
//         .then((response: any) => {
//             return response.data;
//         });
// }




// post calls
export const addEmployeeMaster = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/master`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}


// put calls
export const updateEmployeeMaster = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/master`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const updateSalaryDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/salary`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const updateAddressDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/address`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const updateWorkDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/work`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const updateBankDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/bank`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const updateBasicDetail = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/basic`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}







export const updateProfilePicture = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/profile-picture`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

// leave balance
export const getLeaveBalanceDetail = (userId: number, page: number, limit: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/leave-balance?u=${userId}&p=${page}&l=${limit}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const updateLeaveBalance = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/leave-balance`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const deleteLeaveBalance = (id: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/leave-balance/${id}`;
    return axios.delete(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
// leave balance

// update password
export const updatePassword = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/change-password`;
    return axios.put(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
// update password

// location detail
export const getLocationDetail = (userId: number, page: number, limit: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/location-detail?u=${userId}&p=${page}&l=${limit}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
// location detail

// call detail
export const getCallDetail = (userId: number, page: number, limit: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/call-detail?u=${userId}&p=${page}&l=${limit}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
// call detail


// device detail
export const getDeviceDetail = (userId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/device-detail?u=${userId}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
// device detail

// session history
export const getSessionHistory = (userId: number, page: number, limit: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/session-history?u=${userId}&p=${page}&l=${limit}`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
// session history


export const getOrgHeirarchy = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employee/heirarchy`;
    return axios.get(url, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}