import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { onChange, setError, setOptions, setRequired, validateForm } from './../../utils';
import { getAllGeofenceGroup } from '../../action/SettingsActions';
import { getAllEmployeeList } from './../../action/EmployeeAction';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            name: { name: 'name', value: detail ? detail.name : '', error: '', isRequired: true },
            isGeofenced: { name: 'isGeofenced', value: detail ? detail.isGeofenced : false, error: '', isRequired: true },
            geofenceGroupId: { name: 'geofenceGroupId', value: detail ? detail.geofenceGroupId : '', error: '', isRequired: false, options: [] },
            description: { name: 'description', value: detail ? detail.description : '', error: '', isRequired: false },
            employeeIds: { name: 'employeeIds', value: detail ? detail.members : [], error: '', isRequired: true, options: [] },
        }
    }

    componentDidMount() {
        getAllEmployeeList().then((res: any) => {
            setOptions(this, this.state.employeeIds.name, res.result);
        });
    }

    public render() {
        const { name, employeeIds, description } = this.state;
        return (
            <form onSubmit={this.onSubmit} className="row">
                <div className="col-lg-12 form-group">
                    <label>Name *</label>
                    <input
                        type="text"
                        className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Name of the client"
                        name={name.name}
                        value={name.value}
                        onChange={this.onChange}
                    />
                </div>
                <div className="col-lg-12 form-group">
                    <label>Tagline *</label>
                    <input
                        type="text"
                        className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Tagline of the brand"
                        name={name.name}
                        value={name.value}
                        onChange={this.onChange}
                    />
                </div>
                <div className="col-lg-12 form-group">
                    <label>Description</label>
                    <textarea
                        rows={3}
                        className={description.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Write description"
                        name={description.name}
                        value={description.value}
                        onChange={this.onChange}
                    />
                </div>

                <div className="col-lg-6 form-group">
                    <label>Project start date *</label>
                    <input
                        type="date"
                        className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Tagline of the brand"
                        name={name.name}
                        value={name.value}
                        onChange={this.onChange}
                    />
                </div>
                <div className="col-lg-6 form-group">
                    <label>Project end date *</label>
                    <input
                        type="date"
                        className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Tagline of the brand"
                        name={name.name}
                        value={name.value}
                        onChange={this.onChange}
                    />
                </div>
                <div className="col-lg-12 form-group">
                    <label>Team members</label>
                    <Typeahead
                        id="ta-employee-ids"
                        allowNew={false}
                        labelKey={(e: any) => `${e.name} ${e.designation ? `- ${e.designation}` : ''}`}
                        name={employeeIds.name}
                        selected={employeeIds.value}
                        multiple={true}
                        options={employeeIds.options}
                        onChange={(e: any) => this.typeaheadOnChange(employeeIds.name, e)}
                        placeholder="Employee working on this project"
                        isInvalid={employeeIds.error.length > 0} />
                </div>

                <div className="col-md-12"><small>* Indicates required fields</small></div>
                <div className="col-md-3 mt-3">
                    <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                    <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                </div>
            </form>
        )
    }
    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    typeaheadOnChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const model: any = {
                name: this.state.name.value,
                description: this.state.description.value,
                isGeofenced: this.state.isGeofenced.value,
                geofenceGroupId: this.state.geofenceGroupId.value
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }
}

export default AddComponent;
