import React, { Component } from 'react'
import Chart from "chart.js";
// import classes from "./LineGraph.module.css";

export default class BarGraph extends Component<any, any> {
    chartRef: any = React.createRef();

    componentDidMount() {
        const myChartRef = this.chartRef.current.getContext("2d");

        new Chart(myChartRef, {
            type: "bar",
            data: {
                datasets: [{
                    backgroundColor: [
                        "#2ecc71",
                        "#3498db",
                        "#95a5a6",
                        "#9b59b6",
                        "#f1c40f",
                        "#e74c3c",
                        "#34495e"
                    ],
                    data: [10, 20, 120, 70, 90, 100],
                    label: 'Cost'
                }],
                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: [
                    'Apr 2019',
                    'May 2019',
                    'Jun 2019',
                    'Jul 2019',
                    'Aug 2019',
                    'Sep 2019',
                    'Oct 2019',
                    'Nov 2019',
                    'Dec 2019',
                    'Jan 2020',
                    'Feb 2020',
                    'Mar 2020'
                ]
            },
        });
    }
    render() {
        return (
            <div>
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        )
    }
}