import * as React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
// import UserDefaultPIC from './../../assets/media/users/default.jpg'
import CONSTANT from './../../constant';
import { getEmployeeList } from '../../action/EmployeeAction';
import StackedBarGraph from '../../component/chart/StackedBarChart';
import BOT from './../../component/chat-bot/Index';

class HelpdeskDashboard extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 20,
            sort: 1,
            count: 0,
            showLoader: false
        }
    }

    componentDidMount() {

    }

    public render() {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <React.Fragment>
                {/* <BOT /> */}
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Helpdesk Dashboard
                        </h3>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <div className="kt-subheader__wrapper row">

                            <div className="kt-subheader__toolbar">
                                {/* <button type="button" className="btn btn-primary btn-upper" onClick={this.onStarted}>
                                    Getting Started
                                </button> */}
                                <a href={CONSTANT.url.helpdesk} className="btn btn-primary btn-upper">
                                    View All Tickets
			                    </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="kt-portlet">
                    <div className="kt-portlet__body  kt-portlet__body--fit">
                        <div className="row row-no-padding row-col-separator-lg">

                            <div className="col-md-12 col-lg-6 col-xl-4">

                                <div className="kt-widget24">
                                    <div className="kt-widget24__details">
                                        <div className="kt-widget24__info">
                                            <h4 className="kt-widget24__title">
                                                Total New Tickets Request
                                            </h4>
                                            {/* <span className="kt-widget24__desc">
                                                All Customs Value
                                            </span> */}
                                        </div>
                                        <span className="kt-widget24__stats kt-font-brand">
                                            181
                                        </span>
                                    </div>

                                    {/* <div className="progress progress--sm">
                                        <div className="progress-bar kt-bg-brand" role="progressbar"></div>
                                    </div> */}

                                    {/* <div className="kt-widget24__action">
                                        <span className="kt-widget24__change">
                                            Total Tickets Pending
                                        </span>
                                        <span className="kt-widget24__number">
                                            78%
                                        </span>
                                    </div> */}
                                </div>

                            </div>

                            <div className="col-md-12 col-lg-6 col-xl-4">

                                <div className="kt-widget24">
                                    <div className="kt-widget24__details">
                                        <div className="kt-widget24__info">
                                            <h4 className="kt-widget24__title">
                                                Total Tickets Resolved
                                            </h4>
                                            {/* <span className="kt-widget24__desc">
                                                Customer Review
                                            </span> */}
                                        </div>

                                        <span className="kt-widget24__stats kt-font-warning">
                                            34
                                        </span>
                                    </div>

                                    {/* <div className="progress progress--sm">
                                        <div className="progress-bar kt-bg-warning" role="progressbar"></div>
                                    </div>

                                    <div className="kt-widget24__action">
                                        <span className="kt-widget24__change">
                                            Change
                                        </span>
                                        <span className="kt-widget24__number">
                                            84%
                                        </span>
                                    </div> */}
                                </div>

                            </div>

                            <div className="col-md-12 col-lg-6 col-xl-4">

                                <div className="kt-widget24">
                                    <div className="kt-widget24__details">
                                        <div className="kt-widget24__info">
                                            <h4 className="kt-widget24__title">
                                                Total Tickets Pending
                                            </h4>
                                            {/* <span className="kt-widget24__desc">
                                                Fresh Order Amount
                                            </span> */}
                                        </div>

                                        <span className="kt-widget24__stats kt-font-danger">
                                            147
                                        </span>
                                    </div>

                                    {/* <div className="progress progress--sm">
                                        <div className="progress-bar kt-bg-danger" role="progressbar"></div>
                                    </div>

                                    <div className="kt-widget24__action">
                                        <span className="kt-widget24__change">
                                            Change
                                        </span>
                                        <span className="kt-widget24__number">
                                            69%
                                        </span>
                                    </div> */}
                                </div>

                            </div>

                            {/* <div className="col-md-12 col-lg-6 col-xl-3">

                                <div className="kt-widget24">
                                    <div className="kt-widget24__details">
                                        <div className="kt-widget24__info">
                                            <h4 className="kt-widget24__title">
                                                New Users
                                            </h4>
                                            <span className="kt-widget24__desc">
                                                Joined New User
                                            </span>
                                        </div>

                                        <span className="kt-widget24__stats kt-font-success">
                                            276
                                        </span>
                                    </div>

                                    <div className="progress progress--sm">
                                        <div className="progress-bar kt-bg-success" role="progressbar" ></div>
                                    </div>

                                    <div className="kt-widget24__action">
                                        <span className="kt-widget24__change">
                                            Change
                                        </span>
                                        <span className="kt-widget24__number">
                                            90%
                                        </span>
                                    </div>
                                </div>

                            </div> */}

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6">
                        <div className="kt-portlet kt-portlet--height-fluid">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Recent Tickets
                                    </h3>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <a href={CONSTANT.url.helpdesk} className="btn btn-label-brand btn-bold btn-sm">
                                        View All
			                        </a>
                                </div>
                                {/* <div className="kt-portlet__head-toolbar">
                                    <div className="dropdown dropdown-inline">
                                        <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="flaticon-more-1"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <ul className="kt-nav">
                                                <li className="kt-nav__item">
                                                    <a href="#" className="kt-nav__link">
                                                        <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                        <span className="kt-nav__link-text">Today</span>
                                                    </a>
                                                </li>
                                                <li className="kt-nav__item">
                                                    <a href="#" className="kt-nav__link">
                                                        <i className="kt-nav__link-icon flaticon2-send"></i>
                                                        <span className="kt-nav__link-text">Monthly</span>
                                                    </a>
                                                </li>
                                                <li className="kt-nav__item">
                                                    <a href="#" className="kt-nav__link">
                                                        <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                        <span className="kt-nav__link-text">Yearly</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                             */}
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-widget6">
                                    <div className="kt-widget6__head">
                                        <div className="kt-widget6__item">
                                            <span>Ticket Id</span>
                                            <span>Subject</span>
                                            <span>Category</span>
                                            <span>Raised by</span>
                                            <span>Date</span>
                                        </div>
                                    </div>
                                    <div className="kt-widget6__body">
                                        <div className="kt-widget6__item">
                                            <span>122</span>
                                            <span>Missing punch...</span>
                                            <span>Attendance</span>
                                            <span>Nitesh</span>
                                            <span>03 April 2020</span>
                                        </div>
                                        <div className="kt-widget6__item">
                                            <span>121</span>
                                            <span>need payslip</span>
                                            <span>Payroll</span>
                                            <span>Abhishek</span>
                                            <span>03 April 2020</span>
                                        </div>
                                        <div className="kt-widget6__item">
                                            <span>120</span>
                                            <span>salary issue</span>
                                            <span>Attendance</span>
                                            <span>Saif</span>
                                            <span>02 April 2020</span>
                                        </div>
                                        <div className="kt-widget6__item">
                                            <span>99</span>
                                            <span>need payslip</span>
                                            <span>Payroll</span>
                                            <span>Nitesh</span>
                                            <span>02 April 2020</span>
                                        </div>
                                        <div className="kt-widget6__item">
                                            <span>98</span>
                                            <span>need payslip</span>
                                            <span>Payroll</span>
                                            <span>Satendra</span>
                                            <span>01 April 2020</span>
                                        </div>

                                    </div>
                                    {/* <div className="kt-widget6__foot">
                                        <div className="kt-widget6__action kt-align-right">
                                            <a href="#" className="btn btn-label-brand btn-sm btn-bold">View All</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <span className="kt-portlet__head-icon kt-hidden">
                                        <i className="la la-gear"></i>
                                    </span>
                                    <h3 className="kt-portlet__head-title">
                                        Tickets Summary
                                    </h3>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    <div className="kt-portlet__head-wrapper">
                                        <div className="kt-portlet__head-actions">
                                            <div className="dropdown dropdown-inline">
                                                <button type="button" className="btn btn-default btn-icon-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    This Month
			                                    </button>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <ul className="kt-nav">
                                                        <li className="kt-nav__section kt-nav__section--first">
                                                            <a href="#" className="kt-nav__link">
                                                                <span className="kt-nav__section-text">This Month</span>
                                                            </a>
                                                        </li>

                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <span className="kt-nav__link-text">This Year</span>
                                                            </a>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="kt-portlet__body">
                                <StackedBarGraph />
                            </div>
                        </div>
                    </div>

                </div>

                {/* <Pagination /> */}
            </div >
        )
    }

    onStarted = () => {
        // window.location.href = CONSTANT.url.paySchedule;
    }

}

export default HelpdeskDashboard;
