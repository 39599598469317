import * as React from 'react';
import { Link } from 'react-router-dom';
import CONSTANT from './../../constant';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import AddProjectDetail from './../../component/project/AddProjectDetail';
import { getGeofenceGroupList } from '../../action/SettingsActions'
import { getDateParts } from '../../utils';

class AddProject extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 20,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    public render() {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Project detail
                        </h3>

                        {/* <span className="kt-subheader__separator kt-subheader__separator--v"></span> */}

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            {/* <span className="kt-subheader__desc" id="kt_subheader_total">
                                {`${currMonthName} ${currYear}`}
                            </span> */}
                        </div>

                    </div>
                    <div className="kt-subheader__toolbar">
                        {/* <a href={CONSTANT.url.addGeofenceGroup} className="btn btn-label-brand btn-bold">Add geofence group</a> */}
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                {/* <!--begin::Section--> */}
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <AddProjectDetail
                                            detail={{}}
                                            onSubmit={this.onSubmit}
                                            onCancel={this.onCancel}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onSubmit = () => {
        const { page, limit } = this.state;
        getGeofenceGroupList(page, limit).then((res: any) => {
            this.setState({
                list: res.result
            })
        }).catch((err: any) => {
            console.log(err);
        })
    }

    onCancel = () => {
        window.location.href = CONSTANT.url.projectList;
    }
}

export default AddProject;
