import * as React from 'react';

import CONSTANT from './../../constant';
import AddDocumentType from '../../component/document-type/Add'
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from '../../component/common/Loader';
import { getDocumentTypeDetail, updateDocumentType } from '../../action/SettingsActions'

class AddAttendance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: props.match.params.id,
            detail: undefined
        }
    }

    componentDidMount() {
        getDocumentTypeDetail(this.state.id).then((res: any) => {
            this.setState({
                detail: res.result
            });
        }).catch((err: any) => {
            console.log(err);
        })
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Document type
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    public renderBody() {
        const { detail } = this.state;

        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            {/* <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Time log
					                                </h3>
                                </div>
                            </div> */}
                            <div className="kt-portlet__body">
                                {/* <!--begin::Section--> */}
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        {
                                            detail === undefined && <div>Loading...</div>
                                        }
                                        {
                                            detail && <AddDocumentType
                                                detail={detail}
                                                onSubmit={this.onSubmit}
                                                onCancel={this.onCancel}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onSubmit = (model: any) => {
        updateDocumentType(model).then((res: any) => {
            // this.props.history.push("CONSTANT.url.attendanceTypeList")
            window.location.href = CONSTANT.url.attendanceType;
        }).catch((err: any) => {
            alert(err)
        })
    }

    onCancel = () => {
        // this.props.history.push("CONSTANT.url.attendanceTypeList")
        window.location.href = CONSTANT.url.attendanceType;
    }
}

export default AddAttendance;
