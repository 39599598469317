import React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import BOT from './../../component/chat-bot/Index';

class ComponentName extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            questions: undefined,
            cateogories: undefined,
            activeCategoryId: undefined
        }
    }


    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Exit Process
                        </h3>
                    </div>
                    <div className="kt-subheader__toolbar">
                        {/* <div className="kt-subheader__wrapper row">

                        <div className="kt-subheader__toolbar">
                            <button type="button" className="btn btn-primary btn-upper">
                                Getting Started
                            </button>
                        </div>

                    </div> */}
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { questions, categories, activeCategoryId } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <BOT />
                <div className="kt-portlet kt-faq-v1">
                    <div className="kt-portlet__body">
                        <div className="row">
                            <div className="col-md-12">
                                The Process includes
                                
                                <ul className="mt-3">
                                    <li>Submitting your Resignation letter</li>
                                    <li>Clearance from Reporting Manager</li>
                                    <li>Clearance from IT/Asset Department</li>
                                    <li>Clearance from HR Department</li>
                                    <li>Clearance from Accounts Department</li>
                                </ul>
                            </div>
                            <div className="col-md-10"></div>
                            <div className="col-md-2">
                                <button onClick={this.onSubmit} className="btn btn-sm btn-primary btn-block">Start the Exit process</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    onSubmit = (e: any) => {
        const isSure = window.confirm('Are you sure, you want to initate the exit process?');
    }
}

export default ComponentName;
