import * as React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
// import UserDefaultPIC from './../../assets/media/users/default.jpg'
import CONSTANT from '../../constant';
import { getReimbursementDetail } from '../../action/ReimbursementAction';
import AddReimbursement from '../../component/reimbursement/Add';
import Loader from '../../component/common/Loader';
import { getDateParts } from '../../utils';

class AssetHome extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: props.match.params.id,
            detail: undefined
        }
    }

    componentDidMount() {
        getReimbursementDetail(this.state.id).then((res: any) => {
            this.setState({
                detail: res.result
            });
        }).catch((err: any) => {
            console.log(err);
        })
    }

    public render() {

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">

                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Reimbursement Detail
                        </h3>
                        {/* <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <form className="kt-margin-l-20" id="kt_subheader_search_form">
                                <div className="kt-input-icon kt-input-icon--right kt-subheader__search">
                                    <input type="text" className="form-control" placeholder="Search..." id="generalSearch" />
                                    <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                        <span>
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                    <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </span>
                                </div>
                            </form>
                        </div> */}
                    </div>
                    {/* <div className="kt-subheader__toolbar">
                        <div className="kt-subheader__wrapper row">

                            <div className="kt-subheader__toolbar">
                                <a href={CONSTANT.url.assetAdd} className="btn btn-brand btn-elevate btn-icon-sm">
                                    <i className="la la-plus"></i>
                                    Add Declaration
                                </a>
                            </div>

                        </div>
                    </div> */}
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { detail } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                {
                    detail === undefined && <Loader />
                }
                <div className="kt-portlet kt-portlet--mobile">
                    {/* <div className="kt-portlet__head kt-portlet__head--lg">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Asset
			                </h3>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <a href="#" className="btn btn-brand btn-elevate btn-icon-sm">
                                        <i className="la la-plus"></i>
                                        Add Record
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="">
                        <div id="kt_table_1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="row">
                                <div className="col-sm-12">
                                    {
                                        detail !== undefined && <div className="kt-form kt-form--label-right">
                                            <div className="kt-portlet__body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            {detail.detail.name}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        {this.renderClaim()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                {/* <Pagination /> */}
            </div >
        )
    }

    renderClaim = () => {
        const { claim } = this.state.detail;
        let totalAmount = claim.reduce(function (accumulator: any, currentValue: any) {
            return accumulator + parseFloat(currentValue.amount);
        }, 0);
        return (
            <table style={{ width: '100%' }} className="table table-bordered table-hover">
                <tbody>
                    <tr className="active">
                        <th style={{ width: '3%' }}>#</th>
                        <th>Type</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Bill Number</th>
                        <th>Comments</th>
                        <th>Bills</th>
                        <th>Amount</th>
                    </tr>
                    {
                        claim.length === 0 && <tr>
                            <td colSpan={8} className="text-center">No records found</td>
                        </tr>
                    }
                    {
                        claim.map((item: any, index: number) => {
                            const fdt = getDateParts(item.startDate);
                            const tdt = getDateParts(item.endDate);
                            const bills = this.state.detail.attachment;
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.reimbursementTypeName}</td>
                                    <td>{`${fdt.date} ${fdt.monthName} ${fdt.year}`}</td>
                                    <td>{`${tdt.date} ${tdt.monthName} ${tdt.year}`}</td>
                                    <td>{item.billNumber}</td>
                                    <td>{item.comment}</td>
                                    <td><button onClick={() => this.downloadBills(bills)} className="btn btn-link btn-sm">{bills.length > 0 ? `Download ${bills.length} Files` : '-'}</button></td>
                                    <td>{parseFloat(item.amount)}</td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <td colSpan={7}>Total</td>
                        <td colSpan={1} className="font-weight-bold">{parseFloat(totalAmount)}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    downloadBills = (bills: any) => {
        for (let i = 0; i < bills.length; i++) {
            const file = bills[i];
            const fileURL = `${process.env.REACT_APP_BASE_URL}/reimbursement/${file.reimbursementId}/${file.fileName}`;
            window.open(fileURL);
        }
    }
}

export default AssetHome;
