import * as React from 'react';
import { onChange, setError, setOptions, setRequired, validateForm } from './../../utils';
import { getAllGeofenceGroup } from '../../action/SettingsActions';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            name: { name: 'name', value: detail ? detail.name : '', error: '', isRequired: true },
            isGeofenced: { name: 'isGeofenced', value: detail && detail.isGeofenced ? detail.isGeofenced : false, error: '', isRequired: true },
            geofenceGroupId: { name: 'geofenceGroupId', value: detail && detail.geofenceGroupId ? detail.geofenceGroupId : '', error: '', isRequired: false, options: [] },
            description: { name: 'description', value: detail && detail.description ? detail.description : '', error: '', isRequired: false },
        }
    }

    componentDidMount() {
        getAllGeofenceGroup().then((res: any) => {
            setOptions(this, this.state.geofenceGroupId.name, res.result);
        });
    }

    public render() {
        const { name, isGeofenced, geofenceGroupId, description } = this.state;
        return (
            <form onSubmit={this.onSubmit} className="row">
                <div className="col-lg-12 form-group">
                    <label>Name *</label>
                    <input
                        type="text"
                        className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Name of the attendance"
                        name={name.name}
                        value={name.value}
                        onChange={this.onChange}
                    />
                </div>
                <div className="col-lg-12 form-group">
                    <label>Description</label>
                    <textarea
                        rows={3}
                        className={description.error.length > 0 ? "form-control is-invalid" : "form-control"}
                        placeholder="Write description"
                        name={description.name}
                        value={description.value}
                        onChange={this.onChange}
                    />
                </div>
                <div className="col-lg-12 form-group">
                    <label>Is Geofenced ?</label>
                    <div className="kt-checkbox-list">
                        <label className="kt-checkbox">
                            <input
                                name={isGeofenced.name}
                                checked={isGeofenced.value}
                                onChange={this.onChange}
                                type="checkbox" /> Do you want to Geofence this attendance type?
								<span></span>
                        </label>

                    </div>
                </div>
                {
                    isGeofenced.value && <div className="col-lg-12 form-group">
                        <label>Geofence Group *</label>
                        <select
                            className={geofenceGroupId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                            name={geofenceGroupId.name}
                            value={geofenceGroupId.value}
                            onChange={this.onChange}
                        >
                            <option value="">Select a group</option>
                            {
                                geofenceGroupId.options.map(function (item: any, index: number) {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                }

                <div className="col-md-12"><small>* Indicates required fields</small></div>
                <div className="col-md-3 mt-3">
                    <button type="button" onClick={this.props.onCancel} className="btn btn-sm btn-secondary btn-block">Cancel</button>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                    <button type="submit" className="btn btn-sm btn-primary btn-block">Submit</button>
                </div>
            </form>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === this.state.isGeofenced.name) {
            value = e.target.checked;
            setRequired(this, this.state.geofenceGroupId.name, value);
            if (!value) {
                onChange(this, this.state.geofenceGroupId.name, '');
            }
        }
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const model: any = {
                name: this.state.name.value,
                description: this.state.description.value,
                isGeofenced: this.state.isGeofenced.value,
                geofenceGroupId: this.state.geofenceGroupId.value
            };
            if (this.state.id) {
                model.id = this.state.id
            }
            this.props.onSubmit(model);
        }
    }
}

export default AddComponent;
