import * as React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from './../../component/common/Loader';

import CONSTANT from '../../constant';
import { getCurrencyList, getLanguageList, getTimezoneList } from './../../action/PublicActions';
import { getCompanyConfiguration, updateCompanyConfiguration, updateCompanyLogo } from '../../action/CompanyConfigurationAction';
import { onChange, setOptions, validateForm, setLocalStorageItem } from '../../utils';

class ListAttendance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            detail: undefined,
            showLoader: false,
            timezoneId: { name: 'timezoneId', value: '', options: [], isRequired: true, error: '' },
            currencyId: { name: 'currencyId', value: '', options: [], isRequired: true, error: '' },
            // languageId: { name: 'languageId', value: '', options: [], isRequired: true, error: '' },
            logoFile: { name: 'logoFile', value: '', isRequired: false, error: '' },
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        getCurrencyList().then((res: any) => {
            setOptions(this, this.state.currencyId.name, res.result);
        })
        // getLanguageList().then((res: any) => {
        //     setOptions(this, this.state.languageId.name, res.result);
        // })
        getTimezoneList().then((res: any) => {
            setOptions(this, this.state.timezoneId.name, res.result);
        });

        getCompanyConfiguration().then((res: any) => {
            if (res.status) {
                this.setState({ detail: res.result });
                onChange(this, this.state.timezoneId.name, res.result.timezoneId);
                onChange(this, this.state.currencyId.name, res.result.currencyId);
                // onChange(this, this.state.languageId.name, res.result.languageId);
            }
        });
    }

    public render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Company Configuration
                        </h3>

                        {/* <span className="kt-subheader__separator kt-subheader__separator--v"></span> */}

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            {/* <span className="kt-subheader__desc" id="kt_subheader_total">
                                {`${currMonthName} ${currYear}`}
                            </span> */}
                        </div>

                    </div>
                    {/* <div className="kt-subheader__toolbar">
                        <a href={CONSTANT.url.addCompanyLocation} className="btn btn-label-brand btn-bold">Add company location</a>
                    </div> */}
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { timezoneId, logoFile, detail, currencyId, showLoader } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                {
                    detail === undefined && <Loader />
                }
                {
                    showLoader && <Loader />
                }
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <form onSubmit={this.onConfigurationSubmit}>
                            <div className="card">
                                <div className="card-header">Company Settings</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Timezone *</label>
                                                <select
                                                    className={timezoneId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    name={timezoneId.name}
                                                    value={timezoneId.value}
                                                    onChange={this.onChange}
                                                >
                                                    <option value="">Select Timezone</option>
                                                    {
                                                        timezoneId.options.map(function (item: any, ind: number) {
                                                            return (
                                                                <option key={ind} value={item.id}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Currency *</label>
                                                <select
                                                    className={currencyId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    name={currencyId.name}
                                                    value={currencyId.value}
                                                    onChange={this.onChange}
                                                >
                                                    <option value="">Select Currency</option>
                                                    {
                                                        currencyId.options.map(function (item: any, ind: number) {
                                                            return (
                                                                <option key={ind} value={item.id}>{item.name} {item.symbol}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Language *</label>
                                                <select
                                                    className={languageId.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    name={languageId.name}
                                                    value={languageId.value}
                                                    onChange={this.onChange}
                                                >
                                                    <option value="">Select Lanauge</option>
                                                    {
                                                        languageId.options.map(function (item: any, ind: number) {
                                                            return (
                                                                <option key={ind} value={item.id}>{item.name} {item.localName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-md-9"></div>
                                        <div className="col-md-3">
                                            <button className="btn btn-primary btn-block btn-sm">Save Changes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-12 mb-3">
                        <form onSubmit={this.onLogoSubmit}>
                            <div className="card">
                                <div className="card-header">Company Logo</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                detail !== undefined && detail.logoFileName !== undefined && <img
                                                    src={`${process.env.REACT_APP_BASE_URL}/logo/${detail.logoFileName}`}
                                                    style={{ height: 30 }} />
                                            }
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Logo file *</label>
                                                <input
                                                    name={logoFile.name}
                                                    type="file"
                                                    className="form-control-file"
                                                    onChange={this.onFileChange}
                                                />
                                                <small>* max-height:30px</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-md-9"></div>
                                        <div className="col-md-3">
                                            <button className="btn btn-primary btn-block btn-sm">Save Changes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    private onFileChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.files[0];
        onChange(this, name, value);
    }

    onConfigurationSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model = {
                currencyId: st.currencyId.value,
                timezoneId: st.timezoneId.value
            }

            this.setState({ showLoader: true });
            updateCompanyConfiguration(model).then((res: any) => {
                debugger;
                if (res.status) {
                    alert('Changes were saved successfully');
                    setLocalStorageItem(CONSTANT.keys.configuration, res.result);
                } else {
                    alert(res.error);
                }
                this.setState({ showLoader: false });
            })
        }
    }

    onLogoSubmit = (e: any) => {
        e.preventDefault();
        const st = this.state;
        if (st.logoFile.value === undefined) {
            alert('Please select a file');
            return;
        }

        let model = new FormData();
        model.append('file', st.logoFile.value);

        this.setState({ showLoader: true });
        updateCompanyLogo(model).then((res: any) => {
            if (res.status) {
                alert('Changes were saved successfully. Please refresh the page to view the changes');
                setLocalStorageItem(CONSTANT.keys.configuration, res.result);
            } else {
                alert(res.error);
            }
            this.setState({ showLoader: false });
        })
    }
}

export default ListAttendance;
