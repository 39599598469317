import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import CONSTANT from './constant';
import { getCookie, getStorage } from './utils';

// auth routes
import Login from './container/auth/Login';
import Logout from './container/auth/Logout';
import Register from './container/auth/Register';
import ChangePassword from './container/auth/ChangePassword';
import ForgotPassword from './container/auth/ForgotPassword';
import Language from './container/misc/PublicChangeLanguage';
// auth routes

// company dashboard
import CompanyDashboard from './container/company/EmployeeDashboard';
// company dashboard

// exit page
import Exit from './container/exit/Index'
// exit page

// employee routes
import EmployeeDashboard from './container/employee/Dashboard';
import EmployeeList from './container/employee/List';
import EmployeeSearch from './container/employee/Search';
import EmployeeBulkUpload from './container/employee/BulkUpload';
import AddEmployeeMaster from './container/employee/AddMaster';
import EmployeeOverview from './container/employee/Overview';
import EmployeeMasterDetail from './container/employee/EditMaster';
import EmployeeBasicDetail from './container/employee/EditBasic';

import EmployeeReibursementDetail from './container/employee/EditReimbursement';
import EmployeeReibursementListDetail from './container/employee/EditListReimbursement';

import EmployeeAddressDetail from './container/employee/EditAddress';
import EmployeeFamilyDetail from './container/employee/EditFamily';
import EmployeeBankDetail from './container/employee/EditBank';
import EmployeeEducationDetail from './container/employee/EditEducation';
import EmployeeExperienceDetail from './container/employee/EditExperience';
import EmployeeDocumentDetail from './container/employee/EditDocument';
import EmployeeWorkDetail from './container/employee/EditWork';
import EmployeeSalaryDetail from './container/employee/EditSalary';
import EmployeeProfilePicture from './container/employee/EditProfilePicture';
import EmployeeLeaveBalance from './container/employee/EditLeaveBalance';
import EmployeeNotificationDetail from './container/employee/EditNotification';
import EmployeeChangePassword from './container/employee/EditPassword';
import EmployeeTaxDeclarationDetail from './container/employee/EditTaxDeclaration';
import EmployeeLocationDetail from './container/employee/EditLocation';
import EmployeeCallDetail from './container/employee/EditCall';
import EmployeeSessionHistory from './container/employee/SessionHistory';
import AddEmployeeExit from './container/employee/AddExit';
import EmployeeExitList from './container/employee/ExitList';
import EmployeeExitDetail from './container/employee/ExitDetail';

// employee routes

// payroll route
import PayrollSummary from './container/payroll/Summary';
import PayrollDetail from './container/payroll/Detail';
import PayrollPayRuns from './container/payroll/PayRuns';
import PayrollPaySchedule from './container/payroll/PaySchedule';
import PayrollPayRunSubmit from './container/payroll/PayRunSubmit';
import PayrollDashboard from './container/payroll/Dashboard';
import PayrollExpense from './container/payroll/Expense';
import EmployeeInvestmentDeclaration from './container/payroll/InvestmentDeclaration';
import EmployeeSalarySlips from './container/payroll/SalarySlips';
import EmployeeForm16 from './container/payroll/Form16';
// payroll route

// attendance routes
import AttendanceDashboard from './container/attendance/Dashboard';
import EmployeeAttendance from './container/attendance/Employee';
import TeamAttendance from './container/attendance/Team';
import TeamAttendanceDashboard from './container/attendance/TeamDashboard';
import CompanyAttendanceList from './container/attendance/CompanyList';
import CompanyAttendanceMap from './container/attendance/CompanyMap';
import CompanyAttendanceDashboard from './container/attendance/CompanyDashboard';
import ConsolidatedAttendanceDashboard from './container/attendance/ConsolidatedDashboard';
import CompanyProxyAttendance from './container/attendance/ProxyAttendance';
import Shift from './container/attendance/Shift';
import ShiftCalendar from './container/attendance/ShiftMapping';
// attendance routes

// attendance regularization
import TeamAttendanceRegularization from './container/attendance-regularization/Team'
import AttendanceRegularizationList from './container/attendance-regularization/List'
import AddAttendanceRegularization from './container/attendance-regularization/Add'
import AttendanceRegularizationDetail from './container/attendance-regularization/Detail'
// attendance regularization

// compensatory off

import TeamCompensatoryOffRequest from './container/compensatory-off/Team'
import CompensatoryOffList from './container/compensatory-off/List'
import AddCompensatoryOff from './container/compensatory-off/Add'
import CompensatoryOffDetail from './container/compensatory-off/Detail'
// compensatory off

// leave
import LeaveDashboard from './container/leave/Dashboard';
import LeaveRequest from './container/leave/Request';
import EmployeeLeave from './container/leave/Employee';
import LeaveDetail from './container/leave/Detail';
import TeamLeave from './container/leave/Team';
import TeamLeaveDashboard from './container/leave/TeamDashboard';
import CompanyLeaveDashboard from './container/leave/CompanyDashboard';
import ConsolidatedLeaveDashboard from './container/leave/ConsolidatedDashboard';
import CompanyLeaveList from './container/leave/CompanyList';
// leave

// pms-kra
import KRADashboard from './container/pms-kra/Dashboard';
import KRAList from './container/pms-kra/List';
import AddKRA from './container/pms-kra/Add';
import EditKRA from './container/pms-kra/Edit';
// pms-kra

// attendance type route
import AttendanceTypeList from './container/attendance-type/List';
import AddAttendanceType from './container/attendance-type/Add';
import EditAttendanceType from './container/attendance-type/Edit';
// attendance type route

// designation route
import DesignationList from './container/designation/List';
import AddDesignation from './container/designation/Add';
import EditDesignation from './container/designation/Edit';
// designation route

// geofencing group route
import GeofenceGroupList from './container/geofence-group/List';
import AddGeofenceGroup from './container/geofence-group/Add';
import EditGeofenceGroup from './container/geofence-group/Edit';
// geofencing group route

// leave type route
import LeaveTypeList from './container/leave-type/List';
import AddLeaveType from './container/leave-type/Add';
import EditLeaveType from './container/leave-type/Edit';
// leave type route

// document type route
import DocumentTypeList from './container/document-type/List';
import AddDocumentType from './container/document-type/Add';
import EditDocumentType from './container/document-type/Edit';
// document type route

// company location route
import CompanyLocationList from './container/company-location/List';
import AddCompanyLocation from './container/company-location/Add';
import EditCompanyLocation from './container/company-location/Edit';
// company location route

// company configuration
import CompanyConfiguration from './container/company-configuration/Detail';
// company configuration

// company calendar route
import CompanyCalendarList from './container/company-calendar/List';
import AddCompanyCalendar from './container/company-calendar/Add';
import EditCompanyCalendar from './container/company-calendar/Edit';
// company calendar route

// company branch route
import PayrollTaxComponentList from './container/payroll-tax-component/List';
import AddPayrollTaxComponent from './container/payroll-tax-component/Add';
import EditPayrollTaxComponent from './container/payroll-tax-component/Edit';
// company branch route

// project route
import ProjectList from './container/project/List';
import AddProjectDetail from './container/project/Add';
import EditProjectDetail from './container/project/Edit';
import ProjectDetail from './container/project/Detail';
// project route

// project route
import TaskList from './container/task/List';
// project route

// ats route
import ATSDashboard from './container/ats/Dashboard';

import ATSCandidateDashboard from './container/ats-candidate/List';
import ATSAddCandidate from './container/ats-candidate/Add';
import ATSEditCandidate from './container/ats-candidate/Edit';
import ATSCandidateDetail from './container/ats-candidate/Detail';

import ATSPositionDashboard from './container/ats-position/Dashboard';
import ATSPositionList from './container/ats-position/List';
import ATSAddPosition from './container/ats-position/Add';
import ATSEditPosition from './container/ats-position/Edit';
import ATSPositionDetail from './container/ats-position/Detail';
// ats route

// misc route
import FourZeroFour from './container/error/FourZeroFour';
import ChangeLanguage from './container/misc/ChangeLanguage';
// misc route

// reimbursement route
import AddReimbursement from './container/reimbursement/Add';
import ReimbursementList from './container/reimbursement/List';
import TeamReimbursementList from './container/reimbursement/Team';
import ReimbursementDashboard from './container/reimbursement/Dashboard';
import ReimbursementDetail from './container/reimbursement/Detail';
import ConsolidateReimbursementDashboard from './container/reimbursement/Consolidated';
// reimbursement route

// asset route
import AssetDashboard from './container/asset-management/Dashboard';
import AssetList from './container/asset-management/List';
import AssetAdd from './container/asset-management/Add';
import EmployeeAssets from './container/asset-management/Employee';
// asset route

// FAQ route
import FAQ from './container/faq/index';
// FAQ route

// chat bot route
import BOT from './container/chat-bot/BOT';
// chat bot route

// PMS route
import PMSDashboard from './container/pms/Dashboard';
import PMSRating from './container/pms/Rating';
import PMSRatingDetail from './container/pms/RatingDetail';
import PMSCycle from './container/pms-cycle/List';
import PMSRoleCompetency from './container/pms-role-competency/List';
import AddPMSRoleCompetency from './container/pms-role-competency/Add';
import EditPMSRoleCompetency from './container/pms-role-competency/Edit';
// PMS route

// helpdesk route
import HelpdeskDashboard from './container/helpdesk/Dashboard';
import HelpdeskCategory from './container/helpdesk-category/List';
import HelpdeskAddCategory from './container/helpdesk-category/AddCategory';
import HelpdeskEditCategory from './container/helpdesk-category/EditCategory';
import HelpdeskAddSubCategory from './container/helpdesk-category/AddSubCategory';
import HelpdeskList from './container/helpdesk/List';
import HelpdeskTimeline from './container/helpdesk/Timeline';
import HelpdeskListEmp from './container/helpdesk/ListEmp';
import HelpdeskTimelineEmp from './container/helpdesk/TimelineEmp';
// helpdesk route

// holiday type route
import HolidayTypeList from './container/holiday-type/List'
import AddHolidayType from './container/holiday-type/Add'
import EditHolidayType from './container/holiday-type/Edit'
// holiday type route

// LMS
import LMSList from './container/lms/Index';
import LMSDetail from './container/lms/Detail';
import LMSIndividualReport from './container/lms/IndividualReport';
import LMSTeamReport from './container/lms/TeamReport';
import LMSTaskBasedReport from './container/lms/TaskBasedReport';
//LMS

const AdminRoute = (props: any) => {
    const token = getCookie(CONSTANT.keys.token);
    const ud = getStorage(CONSTANT.keys.userDetail);
    if (token && ud.isu) {
        return (
            <Route exact={true} path={props.path} component={props.component} />
        )
    } else {
        return (
            <Login />
        )
    }
}

const PrivateRoute = (props: any) => {
    const token = getCookie(CONSTANT.keys.token);
    if (token) {
        return (
            <Route exact={true} path={props.path} component={props.component} />
        )
    } else {
        return (
            <Login />
        )
    }
}

const Router = (props: any) => {
    return (
        <Switch>
            {/* auth routes */}
            <Route exact={true} path={CONSTANT.url.login} component={Login} />
            <Route exact={true} path={CONSTANT.url.logout} component={Logout} />
            <Route exact={true} path={CONSTANT.url.register} component={Register} />
            <Route exact={true} path={CONSTANT.url.changePassword} component={ChangePassword} />
            <Route exact={true} path={CONSTANT.url.forgotPassword} component={ForgotPassword} />
            <Route exact={true} path={CONSTANT.url.language} component={Language} />
            {/* auth routes */}

            {/* company routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.companyDashboard} component={CompanyDashboard} />
            {/* company routes */}

            {/* exit routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.exit} component={Exit} />
            {/* exit routes */}

            {/* misc routes */}
            <PrivateRoute exact={true} path={CONSTANT.url.changeLanguage} component={ChangeLanguage} />
            {/* misc routes */}

            {/* attendacne route */}
            <PrivateRoute exact={true} path={CONSTANT.url.attendanceDashboard} component={AttendanceDashboard} />
            <PrivateRoute exact={true} path={CONSTANT.url.employeeAttendance} component={EmployeeAttendance} />
            <PrivateRoute exact={true} path={CONSTANT.url.teamAttendance} component={TeamAttendance} />
            <PrivateRoute exact={true} path={CONSTANT.url.teamAttendanceDashboard} component={TeamAttendanceDashboard} />
            <PrivateRoute exact={true} path={CONSTANT.url.companyAttendanceList} component={CompanyAttendanceList} />
            <PrivateRoute exact={true} path={CONSTANT.url.companyAttendanceMap} component={CompanyAttendanceMap} />
            <PrivateRoute exact={true} path={CONSTANT.url.companyAttendanceDashboard} component={CompanyAttendanceDashboard} />
            <PrivateRoute exact={true} path={CONSTANT.url.consolidatedAttendanceDashboard} component={ConsolidatedAttendanceDashboard} />
            <PrivateRoute exact={true} path={CONSTANT.url.companyProxyAttendance} component={CompanyProxyAttendance} />

            <PrivateRoute exact={true} path={CONSTANT.url.attendenceShift} component={Shift} />
            <PrivateRoute exact={true} path={CONSTANT.url.attendenceShifCalendar} component={ShiftCalendar} />
            {/* attendacne route */}

            {/* attendance regularization */}
            <PrivateRoute exact={true} path={CONSTANT.url.attendanceRegularizationList} component={AttendanceRegularizationList} />
            <PrivateRoute exact={true} path={CONSTANT.url.addAttendanceRegularization} component={AddAttendanceRegularization} />
            <PrivateRoute exact={true} path={CONSTANT.url.attendanceRegularizationDetail} component={AttendanceRegularizationDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.teamAttendanceRegularizationRequest} component={TeamAttendanceRegularization} />
            {/* attendance regularization */}

            {/* compensatory off */}
            <PrivateRoute exact={true} path={CONSTANT.url.compensatoryOffList} component={CompensatoryOffList} />
            <PrivateRoute exact={true} path={CONSTANT.url.addCompensatoryOff} component={AddCompensatoryOff} />
            <PrivateRoute exact={true} path={CONSTANT.url.compensatoryOffDetail} component={CompensatoryOffDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.teamCompensatoryOffRequest} component={TeamCompensatoryOffRequest} />
            {/* compensatory off */}

            {/*Leave*/}
            <PrivateRoute exact={true} path={CONSTANT.url.leaveDashboard} component={LeaveDashboard} />
            <PrivateRoute exact={true} path={CONSTANT.url.leaveRequest} component={LeaveRequest} />
            <PrivateRoute exact={true} path={CONSTANT.url.employeeLeave} component={EmployeeLeave} />
            <PrivateRoute exact={true} path={CONSTANT.url.leaveDetail} component={LeaveDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.teamLeave} component={TeamLeave} />
            <PrivateRoute exact={true} path={CONSTANT.url.teamLeaveDashboard} component={TeamLeaveDashboard} />

            <PrivateRoute exact={true} path={CONSTANT.url.companyLeaveList} component={CompanyLeaveList} />
            <PrivateRoute exact={true} path={CONSTANT.url.companyLeaveDashboard} component={CompanyLeaveDashboard} />
            <PrivateRoute exact={true} path={CONSTANT.url.consolidatedLeaveDashboard} component={ConsolidatedLeaveDashboard} />

            {/*Leave*/}

            {/* payroll */}
            <PrivateRoute exact={true} path={CONSTANT.url.payrollSummary} component={PayrollSummary} />
            <PrivateRoute exact={true} path={CONSTANT.url.payrollDetail} component={PayrollDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.payRuns} component={PayrollPayRuns} />
            <PrivateRoute exact={true} path={CONSTANT.url.paySchedule} component={PayrollPaySchedule} />
            <PrivateRoute exact={true} path={CONSTANT.url.payRunSubmit} component={PayrollPayRunSubmit} />
            <PrivateRoute exact={true} path={CONSTANT.url.payrollDashboard} component={PayrollDashboard} />
            <PrivateRoute exact={true} path={CONSTANT.url.payrollExpense} component={PayrollExpense} />
            <PrivateRoute exact={true} path={CONSTANT.url.employeeInvestmentDeclaration} component={EmployeeInvestmentDeclaration} />
            <PrivateRoute exact={true} path={CONSTANT.url.employeeSalarySlips} component={EmployeeSalarySlips} />
            <PrivateRoute exact={true} path={CONSTANT.url.employeeForm16} component={EmployeeForm16} />
            {/* payroll */}


            {/* reimbursement route */}
            <PrivateRoute exact={true} path={CONSTANT.url.addReimbursement} component={AddReimbursement} />
            <PrivateRoute exact={true} path={CONSTANT.url.reimbursementList} component={ReimbursementList} />
            <PrivateRoute exact={true} path={CONSTANT.url.reimbursementDetail} component={ReimbursementDetail} />

            <PrivateRoute exact={true} path={CONSTANT.url.reimbursementDashboard} component={ReimbursementDashboard} />
            <PrivateRoute exact={true} path={CONSTANT.url.consolidatedReimbursementDashboard} component={ConsolidateReimbursementDashboard} />
            <PrivateRoute exact={true} path={CONSTANT.url.teamReimbursement} component={TeamReimbursementList} />

            {/* reimbursement route */}

            {/* asset route */}
            <AdminRoute exact={true} path={CONSTANT.url.assetDashboard} component={AssetDashboard} />
            <AdminRoute exact={true} path={CONSTANT.url.assetList} component={AssetList} />
            <AdminRoute exact={true} path={CONSTANT.url.assetAdd} component={AssetAdd} />
            <PrivateRoute exact={true} path={CONSTANT.url.employeeAssets} component={EmployeeAssets} />

            {/* asset route */}

            {/* employee dashboard route */}
            <PrivateRoute exact={true} path={CONSTANT.url.dashboard} component={EmployeeDashboard} />
            {/* employee dashboard route */}

            {/* employee route */}
            <AdminRoute exact={true} path={CONSTANT.url.employeeList} component={EmployeeList} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeSearch} component={EmployeeSearch} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeBulkUpload} component={EmployeeBulkUpload} />
            <AdminRoute exact={true} path={CONSTANT.url.addEmployeeMaster} component={AddEmployeeMaster} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeExitList} component={EmployeeExitList} />
            <AdminRoute exact={true} path={CONSTANT.url.addEmployeeExit} component={AddEmployeeExit} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeExitDetail} component={EmployeeExitDetail} />

            <AdminRoute exact={true} path={CONSTANT.url.employeeOverview} component={EmployeeOverview} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeMasterDetail} component={EmployeeMasterDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeBasicDetail} component={EmployeeBasicDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeBasicDetail} component={EmployeeBasicDetail} />

            <AdminRoute exact={true} path={CONSTANT.url.employeeReimbursementDetail} component={EmployeeReibursementDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeReimbursementListDetail} component={EmployeeReibursementListDetail} />

            <AdminRoute exact={true} path={CONSTANT.url.employeeAddressDetail} component={EmployeeAddressDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeFamilyDetail} component={EmployeeFamilyDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeBankDetail} component={EmployeeBankDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeEducationDetail} component={EmployeeEducationDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeExperienceDetail} component={EmployeeExperienceDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeDocumentDetail} component={EmployeeDocumentDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeWorkDetail} component={EmployeeWorkDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeSalaryDetail} component={EmployeeSalaryDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeProfilePicture} component={EmployeeProfilePicture} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeTaxDeclarationDetail} component={EmployeeTaxDeclarationDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeNotificationDetail} component={EmployeeNotificationDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeLeaveBalance} component={EmployeeLeaveBalance} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeChangePassword} component={EmployeeChangePassword} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeLocationDetail} component={EmployeeLocationDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeCallDetail} component={EmployeeCallDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.employeeSessionHistory} component={EmployeeSessionHistory} />

            <PrivateRoute exact={true} path={CONSTANT.url.profile} component={EmployeeOverview} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileOverview} component={EmployeeOverview} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileMasterDetail} component={EmployeeMasterDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileBasicDetail} component={EmployeeBasicDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileReimbursementDetail} component={EmployeeReibursementDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileReimbursementListDetail} component={EmployeeReibursementListDetail} />


            <PrivateRoute exact={true} path={CONSTANT.url.profileAddressDetail} component={EmployeeAddressDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileFamilyDetail} component={EmployeeFamilyDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileBankDetail} component={EmployeeBankDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileEducationDetail} component={EmployeeEducationDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileExperienceDetail} component={EmployeeExperienceDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileDocumentDetail} component={EmployeeDocumentDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileWorkDetail} component={EmployeeWorkDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileSalaryDetail} component={EmployeeSalaryDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileProfilePicture} component={EmployeeProfilePicture} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileTaxDeclaration} component={EmployeeTaxDeclarationDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileNotificationDetail} component={EmployeeNotificationDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileLeaveBalance} component={EmployeeLeaveBalance} />
            <PrivateRoute exact={true} path={CONSTANT.url.profileChangePassword} component={EmployeeChangePassword} />


            {/* employee route */}

            {/*holiday route*/}
            {/* <PrivateRoute exact={true} path={CONSTANT.url.holidayList} component={HolidayList} />
            <AdminRoute exact={true} path={CONSTANT.url.addHoliday} component={AddHoliday} />
            <AdminRoute exact={true} path={CONSTANT.url.editHoliday} component={EditHoliday} /> */}
            {/*holiday route*/}

            {/*holiday route*/}
            <AdminRoute exact={true} path={CONSTANT.url.holidayTypeList} component={HolidayTypeList} />
            <AdminRoute exact={true} path={CONSTANT.url.addHolidayType} component={AddHolidayType} />
            <AdminRoute exact={true} path={CONSTANT.url.editHolidayType} component={EditHolidayType} />
            {/*holiday route*/}

            {/* attendance type route */}
            <AdminRoute exact={true} path={CONSTANT.url.attendanceType} component={AttendanceTypeList} />
            <AdminRoute exact={true} path={CONSTANT.url.addAttendanceType} component={AddAttendanceType} />
            <AdminRoute exact={true} path={CONSTANT.url.editAttendanceType} component={EditAttendanceType} />
            {/* attendance type route */}

            {/* designation route */}
            <AdminRoute exact={true} path={CONSTANT.url.designationList} component={DesignationList} />
            <AdminRoute exact={true} path={CONSTANT.url.addDesignation} component={AddDesignation} />
            <AdminRoute exact={true} path={CONSTANT.url.editDesignation} component={EditDesignation} />
            {/* designation route */}

            {/* leave type route */}
            <AdminRoute exact={true} path={CONSTANT.url.leaveType} component={LeaveTypeList} />
            <AdminRoute exact={true} path={CONSTANT.url.addLeaveType} component={AddLeaveType} />
            <AdminRoute exact={true} path={CONSTANT.url.editLeaveType} component={EditLeaveType} />
            {/* leave type route */}

            {/* document type route */}
            <AdminRoute exact={true} path={CONSTANT.url.documentType} component={DocumentTypeList} />
            <AdminRoute exact={true} path={CONSTANT.url.addDocumentType} component={AddDocumentType} />
            <AdminRoute exact={true} path={CONSTANT.url.editDocumentType} component={EditDocumentType} />
            {/* document type route */}

            {/* company location route */}
            <AdminRoute exact={true} path={CONSTANT.url.companyLocation} component={CompanyLocationList} />
            <AdminRoute exact={true} path={CONSTANT.url.addCompanyLocation} component={AddCompanyLocation} />
            <AdminRoute exact={true} path={CONSTANT.url.editCompanyLocation} component={EditCompanyLocation} />
            {/* company location route */}

            {/* company branch route */}
            {/* <AdminRoute exact={true} path={CONSTANT.url.companyBranch} component={CompanyBranchList} />
            <AdminRoute exact={true} path={CONSTANT.url.addCompanyBranch} component={AddCompanyBranch} />
            <AdminRoute exact={true} path={CONSTANT.url.editCompanyBranch} component={EditCompanyBranch} /> */}
            {/* company branch route */}

            {/* CompanyConfiguration */}
            <AdminRoute exact={true} path={CONSTANT.url.companyConfiguration} component={CompanyConfiguration} />
            {/* CompanyConfiguration */}

            {/* company branch route */}
            <AdminRoute exact={true} path={CONSTANT.url.companyCalendar} component={CompanyCalendarList} />
            <AdminRoute exact={true} path={CONSTANT.url.addCompanyCalendar} component={AddCompanyCalendar} />
            <AdminRoute exact={true} path={CONSTANT.url.editCompanyCalendar} component={EditCompanyCalendar} />
            {/* company branch route */}

            {/* geofence route */}
            <AdminRoute exact={true} path={CONSTANT.url.geofenceGroup} component={GeofenceGroupList} />
            <AdminRoute exact={true} path={CONSTANT.url.addGeofenceGroup} component={AddGeofenceGroup} />
            <AdminRoute exact={true} path={CONSTANT.url.editGeofenceGroup} component={EditGeofenceGroup} />
            {/* geofence route */}

            {/* payroll component route */}
            <AdminRoute exact={true} path={CONSTANT.url.payrollTaxComponent} component={PayrollTaxComponentList} />
            <AdminRoute exact={true} path={CONSTANT.url.addPayrollTaxComponent} component={AddPayrollTaxComponent} />
            <AdminRoute exact={true} path={CONSTANT.url.editPayrollTaxComponent} component={EditPayrollTaxComponent} />
            {/* payroll component route */}

            {/* project route */}
            <PrivateRoute exact={true} path={CONSTANT.url.projectList} component={ProjectList} />
            <PrivateRoute exact={true} path={CONSTANT.url.addProjectDetail} component={AddProjectDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.editProjectDetail} component={EditProjectDetail} />
            <PrivateRoute exact={true} path={CONSTANT.url.projectDetail} component={ProjectDetail} />
            {/* project route */}

            {/* project route */}
            <PrivateRoute exact={true} path={CONSTANT.url.taskList} component={TaskList} />
            {/* project route */}

            {/* FAQs route */}
            <PrivateRoute exact={true} path={CONSTANT.url.faq} component={FAQ} />
            {/* FAQs route */}

            {/* bot route */}
            <Route exact={true} path={CONSTANT.url.chatBot} component={BOT} />
            {/* bot route */}

            {/* PMS route */}
            <PrivateRoute exact={true} path={CONSTANT.url.pmsDashboard} component={PMSDashboard} />
            <PrivateRoute exact={true} path={CONSTANT.url.pmsRating} component={PMSRating} />
            <PrivateRoute exact={true} path={CONSTANT.url.pmsRatingDetail} component={PMSRatingDetail} />
            <AdminRoute exact={true} path={CONSTANT.url.pmsCycle} component={PMSCycle} />
            <AdminRoute exact={true} path={CONSTANT.url.pmsRoleCompetency} component={PMSRoleCompetency} />
            <AdminRoute exact={true} path={CONSTANT.url.addPMSRoleCompetency} component={AddPMSRoleCompetency} />
            <AdminRoute exact={true} path={CONSTANT.url.editPMSRoleCompetency} component={EditPMSRoleCompetency} />
            {/* PMS route */}

            {/* kra route */}
            <PrivateRoute exact={true} path={CONSTANT.url.kraDashboard} component={KRADashboard} />
            <PrivateRoute exact={true} path={CONSTANT.url.kraList} component={KRAList} />
            <PrivateRoute exact={true} path={CONSTANT.url.addKRA} component={AddKRA} />
            <PrivateRoute exact={true} path={CONSTANT.url.editKRA} component={EditKRA} />
            {/* kra route */}

            {/* ATS route */}
            <AdminRoute exact={true} path={CONSTANT.url.atsDashboard} component={ATSDashboard} />

            <AdminRoute exact={true} path={CONSTANT.url.atsPositionDashboard} component={ATSPositionDashboard} />
            <AdminRoute exact={true} path={CONSTANT.url.atsPositionList} component={ATSPositionList} />
            <AdminRoute exact={true} path={CONSTANT.url.atsAddPosition} component={ATSAddPosition} />
            <AdminRoute exact={true} path={CONSTANT.url.atsEditPosition} component={ATSEditPosition} />
            <AdminRoute exact={true} path={CONSTANT.url.atsPositionDetail} component={ATSPositionDetail} />

            <AdminRoute exact={true} path={CONSTANT.url.atsCandidate} component={ATSCandidateDashboard} />
            <AdminRoute exact={true} path={CONSTANT.url.atsAddCandidate} component={ATSAddCandidate} />
            <AdminRoute exact={true} path={CONSTANT.url.atsEditCandidate} component={ATSEditCandidate} />
            <AdminRoute exact={true} path={CONSTANT.url.atsCandidateDetail} component={ATSCandidateDetail} />
            {/* ATS route */}

            {/* helpdesk route */}
            <AdminRoute exact={true} path={CONSTANT.url.helpdeskDashboard} component={HelpdeskDashboard} />
            <AdminRoute exact={true} path={CONSTANT.url.helpdeskCategoryList} component={HelpdeskCategory} />
            <AdminRoute exact={true} path={CONSTANT.url.addHelpdeskCategory} component={HelpdeskAddCategory} />
            <AdminRoute exact={true} path={CONSTANT.url.editHelpdeskCategory} component={HelpdeskEditCategory} />
            <AdminRoute exact={true} path={CONSTANT.url.addHelpdeskSubCategory} component={HelpdeskAddSubCategory} />

            <Route exact={true} path={CONSTANT.url.helpdesk} component={HelpdeskList} />
            <Route exact={true} path={CONSTANT.url.helpdeskTimeline} component={HelpdeskTimeline} />

            <Route exact={true} path={CONSTANT.url.helpdeskEmp} component={HelpdeskListEmp} />
            <Route exact={true} path={CONSTANT.url.helpdeskTimelineEmp} component={HelpdeskTimelineEmp} />
            {/* helpdesk route */}

            {/*LMS Route*/}
            <Route exact={true} path={CONSTANT.url.lms} component={LMSList} />
            <Route exact={true} path={CONSTANT.url.lmsDetail} component={LMSDetail} />
            <Route exact={true} path={CONSTANT.url.lmsIndividualReport} component={LMSIndividualReport} />
            <Route exact={true} path={CONSTANT.url.lmsTaskReport} component={LMSTaskBasedReport} />
            <Route exact={true} path={CONSTANT.url.lmsTeamReport} component={LMSTeamReport} />
            {/*LMS Route*/}

            <Route component={FourZeroFour} />
        </Switch>
    );
}

export default Router;
