import * as React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import UserMenu from '../../component/employee/Menu';
import Footer from '../../component/common/Footer';
import MasterDetail from './../../component/employee/MasterDetail'
import Loader from '../../component/common/Loader';

import CONSTANT from '../../constant';
import { addEmployeeMaster } from '../../action/EmployeeAction';



class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false
        }
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Employee Detail
                        </h3>
                        {/* <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                450 Total </span>
                            <form className="kt-margin-l-20" id="kt_subheader_search_form">
                                <div className="kt-input-icon kt-input-icon--right kt-subheader__search">
                                    <input type="text" className="form-control" placeholder="Search..." id="generalSearch" />
                                    <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                    <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero" />
                                                </g>
                                            </svg>
                                        </span>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="kt-subheader__group kt-hidden" id="kt_subheader_group_actions">
                            <div className="kt-subheader__desc"><span id="kt_subheader_group_selected_rows"></span> Selected:</div>
                            <div className="btn-toolbar kt-margin-l-20">
                                <div className="dropdown" id="kt_subheader_group_actions_status_change">
                                    <button type="button" className="btn btn-label-brand btn-bold btn-sm dropdown-toggle" data-toggle="dropdown">
                                        Update Status
                    </button>
                                    <div className="dropdown-menu">
                                        <ul className="kt-nav">
                                            <li className="kt-nav__section kt-nav__section--first">
                                                <span className="kt-nav__section-text">Change status to:</span>
                                            </li>
                                            <li className="kt-nav__item">
                                                <a href="#" className="kt-nav__link" data-toggle="status-change" data-status="1">
                                                    <span className="kt-nav__link-text"><span className="kt-badge kt-badge--unified-success kt-badge--inline kt-badge--bold">Approved</span></span>
                                                </a>
                                            </li>
                                            <li className="kt-nav__item">
                                                <a href="#" className="kt-nav__link" data-toggle="status-change" data-status="2">
                                                    <span className="kt-nav__link-text"><span className="kt-badge kt-badge--unified-danger kt-badge--inline kt-badge--bold">Rejected</span></span>
                                                </a>
                                            </li>
                                            <li className="kt-nav__item">
                                                <a href="#" className="kt-nav__link" data-toggle="status-change" data-status="3">
                                                    <span className="kt-nav__link-text"><span className="kt-badge kt-badge--unified-warning kt-badge--inline kt-badge--bold">Pending</span></span>
                                                </a>
                                            </li>
                                            <li className="kt-nav__item">
                                                <a href="#" className="kt-nav__link" data-toggle="status-change" data-status="4">
                                                    <span className="kt-nav__link-text"><span className="kt-badge kt-badge--unified-info kt-badge--inline kt-badge--bold">On Hold</span></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <button className="btn btn-label-success btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" data-toggle="modal" data-target="#kt_datatable_records_fetch_modal">
                                    Fetch Selected
                </button>
                                <button className="btn btn-label-danger btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_delete_all">
                                    Delete All
                </button>
                            </div>
                        </div>
                     */}
                    </div>
                    {/* <div className="kt-subheader__toolbar">
                        <a href="#" className="">
                        </a>
                        <a href="custom/apps/user/add-user.html" className="btn btn-label-brand btn-bold">
                            Add User </a>
                        <div className="kt-subheader__wrapper">
                            <div className="dropdown dropdown-inline" data-toggle="kt-tooltip-" title="Quick actions" data-placement="left">
                                <a href="#" className="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--success kt-svg-icon--md">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                            <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                            <path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="#000000" />
                                        </g>
                                    </svg>

                                </a>
                                <div className="dropdown-menu dropdown-menu-fit dropdown-menu-md dropdown-menu-right">

                                    <ul className="kt-nav">
                                        <li className="kt-nav__head">
                                            Add New:
                            <i className="flaticon2-information" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Click to learn more..."></i>
                                        </li>
                                        <li className="kt-nav__separator"></li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-drop"></i>
                                                <span className="kt-nav__link-text">Orders</span>
                                            </a>
                                        </li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-new-email"></i>
                                                <span className="kt-nav__link-text">Members</span>
                                                <span className="kt-nav__link-badge">
                                                    <span className="kt-badge kt-badge--brand kt-badge--rounded">15</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-calendar-8"></i>
                                                <span className="kt-nav__link-text">Reports</span>
                                            </a>
                                        </li>
                                        <li className="kt-nav__item">
                                            <a href="#" className="kt-nav__link">
                                                <i className="kt-nav__link-icon flaticon2-link"></i>
                                                <span className="kt-nav__link-text">Finance</span>
                                            </a>
                                        </li>
                                        <li className="kt-nav__separator"></li>
                                        <li className="kt-nav__foot">
                                            <a className="btn btn-label-brand btn-bold btn-sm" href="#">More options</a>
                                            <a className="btn btn-clean btn-bold btn-sm kt-hidden" href="#" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Click to learn more...">Learn more</a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { userId } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
                    <button className="kt-app__aside-close" id="kt_user_profile_aside_close">
                        <i className="la la-close"></i>
                    </button>
                    <UserMenu userId={userId} isSelf={false} />
                    <MasterDetail
                        onSubmit={this.onSubmit}
                    />
                </div>
            </div>
        )
    }

    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        addEmployeeMaster(model).then((response: any) => {
            this.setState({ showLoader: false });
            if (response.status) {
                alert('Changes were saved successfully');
                window.location.href = CONSTANT.url.employeeBasicDetail.replace(':id', response.result.id);
            } else {
                alert(response.error);
            }
        }, (error: any) => {
            alert(error.message);
        });
    }
}

export default EmployeeMaster;
