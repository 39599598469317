import React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import AddPosition from './../../component/ats-position/Add';

import CONSTANT from './../../constant';

class ComponentName extends React.Component<any, any> {
    public render() {
        return (
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <Sidebar />
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                        <Header />
                        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                            {this.renderHeader()}
                            {this.renderBody()}
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Position detail
                            </h3>

                        {/* <span className="kt-subheader__separator kt-subheader__separator--v"></span> */}

                        {/* <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {count} Total
                            </span>
                            <SearchForm />
                        </div> */}

                    </div>
                    <div className="kt-subheader__toolbar">
                        {/* <a href={CONSTANT.url.atsAddCandidate} className="btn btn-label-brand btn-bold">
                            Add New Candidate
                        </a> */}

                        {/* <a href={CONSTANT.url.employeeBulkUpload} className="btn btn-label-brand btn-bold">
                            Bulk Upload
                            </a> */}
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                <AddPosition />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ComponentName;
