import * as React from 'react';
import $ from 'jquery';
import moment from 'moment';
import XLSX from 'xlsx';
import Chart from "chart.js";
import { Link } from 'react-router-dom';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from './../../component/common/Loader';
import Shimmer from './../../component/common/Loader';
import PieChart from './../../component/chart/PieChart';
import CONSTANT from './../../constant';

import { getCompanyDailyHistory } from '../../action/AttendanceActions';
import { onChange } from '../../utils';
import { getCategoryList, updateCategory } from '../../action/HelpdeskActions';
import AddCategoryCard from './../../component/setting/helpdesk-category/AddCategory';
// import { updateRole } from './../../action/SettingsActions';

class EditCategory extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            detail: props.location.state.detail
        }
    }

    public componentDidMount() {
        getCategoryList().then((response: any) => {
            if (response.status) {
                this.setState({ data: response.result });
            }
        });
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Helpdesk Category/Sub-Category
                        </h3>

                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                    </div>

                </div>
            </div>

        )
    }

    renderBody = () => {
        const { detail } = this.state;

        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="col-lg-12">
                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <h5 className="heading-h1">Edit Role</h5>
                        </div>
                    </div>
                    {
                        detail === undefined && <Shimmer />
                    }
                    {
                        detail !== undefined && <AddCategoryCard
                            detail={detail}
                            onSubmit={this.onSubmit}
                            onCancel={this.onCancel}
                        />
                    }
                </div>

            </div>
        )
    }

    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        updateCategory(model).then((res: any) => {
            this.setState({ showLoader: false });
            if (res.result) {
                window.location.href = CONSTANT.url.helpdeskCategoryList
            }
        });
    }

    onCancel = () => {
        window.location.href = CONSTANT.url.helpdeskCategoryList;
    }
}

export default EditCategory;
