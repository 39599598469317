import React from 'react';
import { Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import CONSTANT from './../../constant';
import { onChange, setOptions, validateForm, getStorage } from './../../utils';
// import { getSkillList, getLocationList, getQualificationList, getJobType } from './../../actions/MasterAction';
// import { getUserListSummary } from './../../actions/UserAction';
// import { getClientListSummary } from './../../actions/ClientAction';
import NumberFormat from 'react-number-format';

class ComponentName extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        const detail: any = props.detail;

        this.state = {
            code: detail ? detail.code : '',
            title: { name: 'title', value: detail ? detail.title : '', error: '', isRequired: true },
            description: { name: 'description', value: detail ? detail.description : '', error: '', isRequired: true },
            role: { name: 'role', value: detail ? detail.role : '', options: [], error: '', isRequired: true },
            jobType: { name: 'jobType', value: detail ? detail.jobType : '', options: [], error: '', isRequired: true },
            industry: { name: 'industry', value: detail ? detail.industry : '', options: [], error: '', isRequired: true },
            skill: { name: 'skill', value: detail ? detail.skill : [], options: [], error: '', isRequired: true },
            qualification: { name: 'qualification', value: detail ? detail.qualification : [], options: [], error: '', isRequired: true },
            location: { name: 'location', value: detail ? detail.location : [], options: [], error: '', isRequired: true },
            vacancy: { name: 'vacancy', value: detail ? detail.vacancy.open : '', error: '', isRequired: true },

            minSalary: { name: 'minSalary', value: detail ? detail.minSalary.toString() : '', error: '', isRequired: true },
            maxSalary: { name: 'maxSalary', value: detail ? detail.maxSalary.toString() : '', error: '', isRequired: true },
            minExperience: { name: 'minExperience', value: detail ? detail.minExperience.toString() : '', error: '', isRequired: true },
            maxExperience: { name: 'maxExperience', value: detail ? detail.maxExperience.toString() : '', error: '', isRequired: true },

            client: { name: 'client', value: detail ? detail.client.code : '', error: '', options: [], isRequired: true },
            recruiter: { name: 'recruiter', value: detail ? detail.recruiter : [], error: '', options: [], isRequired: true },
            startDate: { name: 'startDate', value: detail ? detail.startDate : '', error: '', isRequired: true },
            endDate: { name: 'endDate', value: detail ? detail.endDate : '', error: '', isRequired: true },
            isOpen: { name: 'isOpen', value: detail ? detail.isOpen : 'open', error: '', isRequired: true, options: [{ code: 'open', name: 'Open' }, { code: 'closed', name: 'Closed' }] }
        }
    }

    componentDidMount() {
        // getUserListSummary().then((res: any) => {
        //     const heirarchy = getStorage(CONSTANT.keys.heirarchy);
        //     const list = [];

        //     for (let i = 0; i < heirarchy.length; i++) {
        //         const userObj = res.result.filter((j: any) => j.code === heirarchy[i].code);
        //         list.push(...userObj);
        //     }

        //     setOptions(this, this.state.recruiter.name, list);
        // })

        // getClientListSummary().then((res: any) => {
        //     setOptions(this, this.state.client.name, res.result);
        // })

        // getSkillList().then((res: any) => {
        //     setOptions(this, this.state.skill.name, res.result);
        // })

        // getLocationList().then((res: any) => {
        //     setOptions(this, this.state.location.name, res.result);
        // })

        // getQualificationList().then((res: any) => {
        //     setOptions(this, this.state.qualification.name, res.result);
        // })

        // getJobType().then((res: any) => {
        //     setOptions(this, this.state.jobType.name, res.result);
        // })
    }

    render() {
        const { code, title, description, location, role, industry, skill, jobType, qualification,
            vacancy, minSalary, maxSalary, minExperience, maxExperience, client,
            startDate, endDate, isOpen, recruiter
        } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input
                                                type="text"
                                                className={title.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Name of the Position"
                                                name={title.name}
                                                value={title.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea
                                                rows={3}
                                                className={description.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Description of the position"
                                                name={description.name}
                                                value={description.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Client</label>

                                            <select
                                                className={client.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                name={client.name}
                                                value={client.value}
                                                onChange={this.onChange}
                                            >
                                                <option>Select a Client</option>
                                                {
                                                    client.options.map(function (item: any, index: number) {
                                                        return (
                                                            <option key={index} value={item.code}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Role</label>
                                            <input
                                                type="text"
                                                className={role.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Role"
                                                name={role.name}
                                                value={role.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Industry</label>
                                            <input
                                                type="text"
                                                className={industry.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Industry type"
                                                name={industry.name}
                                                value={industry.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Vacancy</label>
                                            <NumberFormat
                                                allowNegative={false}
                                                className={vacancy.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="# of vacancy"
                                                name={vacancy.name}
                                                value={vacancy.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Location</label>
                                            <Typeahead
                                                id="ta-location"
                                                allowNew={true}
                                                labelKey='name'
                                                name={location.name}
                                                selected={location.value}
                                                multiple={true}
                                                options={location.options}
                                                onChange={(e: any) => this.typeaheadOnChange(location.name, e)}
                                                placeholder="Location of the position"
                                                isInvalid={location.error.length > 0}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Qualification</label>
                                            <Typeahead
                                                id="ta-qualification"
                                                allowNew={true}
                                                labelKey='name'
                                                name={qualification.name}
                                                selected={qualification.value}
                                                multiple={true}
                                                options={qualification.options}
                                                onChange={(e: any) => this.typeaheadOnChange(qualification.name, e)}
                                                placeholder="Qualification required for the position"
                                                isInvalid={qualification.error.length > 0}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Job type</label>
                                            <select
                                                className={jobType.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                name={jobType.name}
                                                value={jobType.value}
                                                onChange={this.onChange}
                                            >
                                                <option>Select job type</option>
                                                {
                                                    jobType.options.map(function (item: any, index: number) {
                                                        return (
                                                            <option key={index} value={item.code}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Skills</label>
                                            <Typeahead
                                                id="ta-skills"
                                                allowNew={true}
                                                labelKey='name'
                                                name={skill.name}
                                                selected={skill.value}
                                                multiple={true}
                                                options={skill.options}
                                                onChange={(e: any) => this.typeaheadOnChange(skill.name, e)}
                                                placeholder="Suitable skills for the position"
                                                isInvalid={skill.error.length > 0}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Min. Experience</label>
                                            <NumberFormat
                                                allowNegative={false}
                                                className={minExperience.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Minimum experience in years"
                                                name={minExperience.name}
                                                value={minExperience.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Max. Experience</label>
                                            <NumberFormat
                                                allowNegative={false}
                                                className={maxExperience.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Maximum experience in years"
                                                name={maxExperience.name}
                                                value={maxExperience.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Min. Salary</label>
                                            <NumberFormat
                                                allowNegative={false}
                                                thousandSeparator={true}
                                                className={minSalary.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Minimum salary"
                                                name={minSalary.name}
                                                value={minSalary.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Max. Salary</label>
                                            <NumberFormat
                                                allowNegative={false}
                                                thousandSeparator={true}
                                                className={maxSalary.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Maximum salary"
                                                name={maxSalary.name}
                                                value={maxSalary.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Start date</label>
                                            <input
                                                type="date"
                                                className={startDate.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Start date"
                                                name={startDate.name}
                                                value={startDate.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>End date</label>
                                            <input
                                                type="date"
                                                className={endDate.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="End date"
                                                name={endDate.name}
                                                value={endDate.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Is position still open?</label>
                                            <select
                                                className={isOpen.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                name={isOpen.name}
                                                value={isOpen.value}
                                                onChange={this.onChange}
                                            >
                                                <option>Select an option</option>
                                                {
                                                    isOpen.options.map(function (item: any, index: number) {
                                                        return (
                                                            <option key={index} value={item.code}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Assigned to</label>
                                            <Typeahead
                                                id="ta-recipient"
                                                labelKey={(u: any) => `${u.name} (${u.role.name})`}
                                                name={recruiter.name}
                                                selected={recruiter.value}
                                                multiple={true}
                                                options={recruiter.options}
                                                onChange={(e: any) => this.typeaheadOnChange(recruiter.name, e)}
                                                placeholder="Assign this position to"
                                                isInvalid={recruiter.error.length > 0}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="row my-3">
                                    <div className="col-md-3">
                                        <Link to={this.props.onCancel} className="btn btn-block btn-secondary">Cancel</Link>
                                    </div>
                                    <div className="col-md-6">
                                        &nbsp;
                                                </div>
                                    <div className="col-md-3">
                                        <button type="submit" className="btn btn-block btn-info">Save & Continue</button>
                                    </div>
                                </div>
                            

            </form >
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    typeaheadOnChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();

        if (validateForm(this)) {
            this.setState({ showLoader: true });
            const st = this.state;
            const client = st.client.options.filter((i: any) => i.code === st.client.value)[0];
            const jobType = st.jobType.options.filter((i: any) => i.code === st.jobType.value)[0];
            const model = {
                code: st.code,
                title: st.title.value,
                description: st.description.value,
                client: client,
                jobType: jobType,
                role: st.role.value,
                industry: st.industry.value,
                vacancy: parseInt(st.vacancy.value, 10),
                location: st.location.value,
                qualification: st.qualification.value,
                skill: st.skill.value,
                minExperience: parseInt(st.minExperience.value, 10),
                maxExperience: parseInt(st.maxExperience.value, 10),
                minSalary: parseInt(st.minSalary.value.replace(/,/g, ''), 10),
                maxSalary: parseInt(st.maxSalary.value.replace(/,/g, ''), 10),
                startDate: st.startDate.value,
                endDate: st.endDate.value,
                recruiter: st.recruiter.value,
                isOpen: st.isOpen.value
            };

            this.props.onSubmit(model);
        }
    }
}

export default ComponentName;