import * as axios from 'axios';
import CONFIG from './../config';
import { getAuthHeader, getUploadFileHeader } from '../utils';

// recent changes 04 APR 2020
export const addLeave = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getLeaveList = (year: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave/list?y=${year}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getTeamLeaveList = (year: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave/team?y=${year}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getCompanyLeaveList = (year: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave/company?y=${year}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateLeaveStatus = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave/status`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getLeaveComment = (leaveId: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave/comment?lid=${leaveId}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getLeaveDetail = (id: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave/detail?id=${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const addComment = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave/comment`;
    return axios.post(url, model, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

// recent changes 04 APR 2020


// export const getLeaves = (page: any, limit: any) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/v1/leave?p=${page}&l=${limit}`;
//     return axios.get(url, getAuthHeader())
//         .then((response: any) => {
//             return response.data;
//         });
// }

// export const getLeaveStatusProgress = (leaveId: any) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/v1/leave/progress?leaveId=${leaveId}`;
//     return axios.get(url, getAuthHeader())
//         .then((response: any) => {
//             return response.data;
//         });
// }

// export const getLeavesCount = () => {
//     const url = `${process.env.REACT_APP_BASE_URL}/v1/leave-count`;
//     return axios.get(url, getAuthHeader())
//         .then((response: any) => {
//             return response.data;
//         });
// }

// export const getAllLeaves = (empCode: any, page: any, limit: any) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/v1/all-leaves?empCode=${empCode}&p=${page}&l=${limit}`;
//     return axios.get(url, getAuthHeader())
//         .then((response: any) => {
//             return response.data;
//         });
// }

// export const getAllLeavesCount = (empCode: any) => {
//     const url = `${process.env.REACT_APP_BASE_URL}/v1/all-leaves-count?empCode=${empCode}`;
//     return axios.get(url, getAuthHeader())
//         .then((response: any) => {
//             return response.data;
//         });
// }



export const updateLeave = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const approveLeave = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/approve-leave`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}