import * as React from 'react';
import NumberFormat from 'react-number-format';
import { getLoginStatusList, getRoleList } from './../../action/ConfigAction';
import { onChange, validateForm, setOptions } from '../../utils';

class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            userId: props.userId,
            accountNumber: { value: detail ? detail.accountNumber : '', name: 'accountNumber', error: '', isRequired: true },
            accountType: { value: detail ? detail.accountType : '', name: 'accountType', error: '', isRequired: true },
            bankName: { value: detail ? detail.bankName : '', name: 'bankName', error: '', isRequired: true },
            branchName: { value: detail ? detail.branchName : '', name: 'branchName', error: '', isRequired: true },
            branchCode: { value: detail ? detail.branchCode : '', name: 'branchCode', error: '', isRequired: true },
        }
    }

    public render() {
        const { accountNumber, accountType, bankName, branchName, branchCode } = this.state;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Bank Detail
                                    </h3>
                                </div>
                            </div>

                            <form className="kt-form kt-form--label-right" onSubmit={this.onSubmit}>
                                <div className="kt-portlet__body">
                                    <div className="row">
                                        <div className="col-lg-6 form-group">
                                            <label>Account Number* </label>
                                            <input
                                                type="text"
                                                className={accountNumber.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter account number"
                                                name={accountNumber.name}
                                                value={accountNumber.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <label>Account Type*</label>
                                            <input
                                                type="text"
                                                className={accountType.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter account type"
                                                name={accountType.name}
                                                value={accountType.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <label>Bank Name*</label>
                                            <input
                                                type="text"
                                                className={bankName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter bank name"
                                                name={bankName.name}
                                                value={bankName.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <label>Branch Name* </label>
                                            <input
                                                type="text"
                                                className={branchName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter branch name"
                                                name={branchName.name}
                                                value={branchName.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <label>Branch Code* </label>
                                            <input
                                                type="text"
                                                className={branchCode.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter branch code"
                                                name={branchCode.name}
                                                value={branchCode.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>

                                </div>
                                {
                                    !this.props.isSelf && <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-md-9"></div>
                                                <div className="col-md-3">
                                                    <button type="submit" className="btn btn-brand btn-bold btn-block">Save Changes</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model = {
                userId: st.userId,
                accountNumber: st.accountNumber.value,
                accountType: st.accountType.value,
                bankName: st.bankName.value,
                branchName: st.branchName.value,
                branchCode: st.branchCode.value,
            };
            this.props.onSubmit(model);
        }
    }
}

export default EmployeeMaster;
