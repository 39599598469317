import * as React from 'react';
import { onChange, validateForm } from '../../utils';

class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            userId: props.userId,
            isSelf: props.isSelf,
            oldPassword: { value: '', name: 'oldPassword', error: '', isRequired: props.isSelf },
            newPassword: { value: '', name: 'newPassword', error: '', isRequired: true },
        }
    }

    public render() {
        const { oldPassword, newPassword } = this.state;
        const { isSelf } = this.props;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Change password
                                    </h3>
                                </div>
                            </div>

                            <form className="kt-form kt-form--label-right" onSubmit={this.onSubmit}>
                                <div className="kt-portlet__body">
                                    <div className="row">
                                        {
                                            isSelf && <div className="col-lg-12 form-group">
                                                <label>Current password * </label>
                                                <input
                                                    type="password"
                                                    className={oldPassword.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    placeholder="Enter your current password"
                                                    name={oldPassword.name}
                                                    value={oldPassword.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        }
                                        <div className="col-lg-12 form-group">
                                            <label>New password * </label>
                                            <input
                                                type="password"
                                                className={newPassword.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter your new password"
                                                name={newPassword.name}
                                                value={newPassword.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <div className="row">
                                            <div className="col-md-9"></div>
                                            <div className="col-md-3">
                                                <button type="submit" className="btn btn-brand btn-bold btn-block">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model: any = {
                userId: parseInt(st.userId, 10),
                isSelf: st.isSelf,
                newPassword: st.newPassword.value,
            };
            if (st.isSelf) {
                model.oldPassword = st.oldPassword.value;
            }
            this.props.onSubmit(model);
        }
    }
}

export default EmployeeMaster;
