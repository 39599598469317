import * as axios from 'axios';
import { getAuthHeader } from '../utils';

// leave type
export const getAvailableLeaveType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave-type/available`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getApprovalBasedLeaveType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave-type/approval-based`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getLeaveTypeList = (page: number, limit: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave-type/list?p=${page}&l=${limit}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const addLeaveType = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave-type`;
    return axios.post(url, model, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateLeaveType = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave-type`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getLeaveTypeDetail = (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave-type/detail/${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// leave type

// employment type
export const getAvailableEmploymentType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employment-type/available`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getEmploymentTypeList = (page: number, limit: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employment-type/list?p=${page}&l=${limit}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const addEmploymentType = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employment-type`;
    return axios.post(url, model, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateEmploymentType = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employment-type`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getEmploymentTypeDetail = (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/employment-type/detail/${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// employment type

// attendance regularization status
export const getAvailableARStatusList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/ar-status/available`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// attendance regularization status

// leave status
export const getAvailableLeaveStatusList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave-status/available`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getLeaveStatusList = (page: number, limit: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave-status/list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const addLeaveStatus = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave-status`;
    return axios.post(url, model, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateLeaveStatus = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave-status`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getLeaveStatusDetail = (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/leave-status/detail?id=${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// leave status

// holiday type
export const getAvailableHolidayType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/holiday-type/available`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
export const getHolidayTypeList = (page: number, limit: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/holiday-type/list?p=${page}&l=${limit}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const addHolidayType = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/holiday-type`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateHolidayType = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/holiday-type`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getHolidayTypeDetail = (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/holiday-type/detail/${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// holiday type

// holiday
export const getAllHolidays = (year: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/holiday/all?y=${year}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// holiday

//attendanceType
export const getAllAttendanceType = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/attendance-type/all`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getAttendanceTypeList = (page: number, limit: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/attendance-type/list?p=${page}&l=${limit}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const addAttendanceType = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/attendance-type`;
    return axios.post(url, model, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateAttendanceType = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/attendance-type`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getAttendanceTypeDetail = (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/attendance-type/detail/${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
//attendanceType

// location
export const addLocation = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/location`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateLocation = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/location`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getLocationList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/location/list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getLocationDetail = (id: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/location/detail?id=${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getAllLocationList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/location/all`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// location

// gender
export const addGender = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/gender`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateGender = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/gender`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getGenderList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/gender/list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getGenderDetail = (id: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/gender/detail?id=${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getAllGenderList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/gender/all`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// gender

// marital status
export const addMaritalStatus = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/marital-status`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateMaritalStatus = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/marital-status`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getMaritalStatusList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/marital-status/list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getMaritalStatusDetail = (id: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/marital-status/detail?id=${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getAllMaritalStatusList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/marital-status/all`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// marital status

// designation
export const addDesignation = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/designation`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateDesignation = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/designation`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getDesignationList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/designation/list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getDesignationDetail = (id: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/designation/detail?id=${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getAllDesignationList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/designation/all`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// designation

// department
export const addDepartment = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/department`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateDepartment = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/department`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getDepartmentList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/department/list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getDepartmentDetail = (id: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/department/detail?id=${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getAllDepartmentList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/department/all`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// department

// document type
export const getDocumentTypeList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/document-type/list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const addDocumentType = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/document-type`;
    return axios.post(url, model, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateDocumentType = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/document-type`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getDocumentTypeDetail = (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/document-type/detail/${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// document type

// geofencing group
export const getAllGeofenceGroup = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/geofence-group/all`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getGeofenceGroupList = (page: number, limit: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/geofence-group/list?p=${page}&l=${limit}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const addGeofenceGroup = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/geofence-group`;
    return axios.post(url, model, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateGeofenceGroup = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/geofence-group`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getGeofenceGroupDetail = (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/geofence-group/detail/${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// geofencing group

// role
export const addRole = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/role/add`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const updateRole = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/role/update`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getRoleList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/role/list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getRoleDetail = (id: number) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/role/detail?id=${id}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getAvailableRole = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/role/available`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const getPermissionList = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/role/permission`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}
// role