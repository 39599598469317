import React from 'react';

function ProgressBar(props: any) {
    const { percentage } = props;
    let className = 'kt-bg-success'
    if (percentage < 50) {
        className = 'kt-bg-warning';
    } else if (percentage > 50 && percentage < 90) {
        className = 'kt-bg-primary'
    }
    return (
        // <div className="kt-widget__progress d-flex  align-items-center">
        //     <div className="progress" style={{ height: '5px', width: '100%' }}>
        //         <div className="progress-bar kt-bg-success" role="progressbar" style={{ width: `${percentage}%` }}></div></div>
        //     <span className="kt-widget__stat">{percentage}%</span>
        // </div>
        <div className="kt-widget__progress d-flex align-items-center flex-fill">
            <div className="progress" style={{ height: '5px', width: '100%' }}>
                <div className={`progress-bar ${className}`} role="progressbar" style={{ width: `${percentage}%` }}></div>
            </div>
            <span className="kt-widget__stat">
                {percentage}%
            </span>
        </div>
    )
}

export default ProgressBar;