import React from 'react';
import moment from 'moment';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import UserMenu from '../../component/employee/Menu';
import Footer from '../../component/common/Footer';
import ModalWindow from '../../component/common/ModalWindow';
import ExperienceDetail from '../../component/employee/ExperienceDetail';
import Loader from '../../component/common/Loader';
import Shimmer from '../../component/common/Shimmer';

import CONSTANT from '../../constant';
import { getExperienceDetail, updateExperienceDetail, deleteExperienceDetail } from '../../action/EmployeeAction';
import { getCookie, getStorage } from '../../utils';

class EmployeeFamily extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const isSelf = props.match.params.id ? false : true;
        const userId = props.match.params.id ? props.match.params.id : getStorage(CONSTANT.keys.userDetail).id;
        this.state = {
            userId,
            isSelf,
            list: undefined,
            showLoader: false,
            showModal: false,
            detail: undefined
        }
    }

    componentDidMount() {
        this.getExperienceDetail();
    }

    public render() {
        const { showLoader, showModal, userId, detail } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                {
                    showModal && <ModalWindow
                        className="modal-lg"
                        title="Experience detail"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <ExperienceDetail
                            userId={userId}
                            detail={detail}
                            onSubmit={this.onSubmit}
                            onCancel={this.onCancel}
                            onDelete={this.onDelete}
                        />
                    </ModalWindow>
                }
            </React.Fragment >
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Experience Detail
                        </h3>

                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href='javascript:;' onClick={this.showModal} className="btn btn-label-brand btn-bold">
                            Add Experience Detail
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { userId, isSelf, list, detail } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
                    <button className="kt-app__aside-close" id="kt_user_profile_aside_close">
                        <i className="la la-close"></i>
                    </button>
                    <UserMenu userId={userId} isSelf={isSelf} />

                    <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="kt-portlet">
                                    <form className="kt-form kt-form--label-right">
                                        <div className="kt-portlet__body">
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '3%' }} scope="col">#</th>
                                                        <th scope="col">Job Profile</th>
                                                        <th scope="col">Company Name</th>
                                                        <th scope="col">From</th>
                                                        <th scope="col">To</th>
                                                        <th scope="col">Summary</th>
                                                        <th style={{ width: '3%' }} scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        list === undefined && <tr>
                                                            <td className="text-center" colSpan={7}>Loading...</td>
                                                        </tr>
                                                    }
                                                    {
                                                        list !== undefined && list.length === 0 && <tr>
                                                            <td className="text-center" colSpan={7}>No records found</td>
                                                        </tr>
                                                    }
                                                    {
                                                        list !== undefined && list.map((item: any, ind: number) => {

                                                            return (
                                                                <tr key={ind}>
                                                                    <td>{ind + 1}</td>
                                                                    <td>{item.jobProfile}</td>
                                                                    <td>{item.companyName}</td>
                                                                    <td>{moment(item.from).format("DD MMM YYYY")}</td>
                                                                    <td>{moment(item.to).format("DD MMM YYYY")}</td>
                                                                    <td>{item.remarks}</td>
                                                                    <th scope="col">
                                                                        <a href="#" onClick={(e: any) => { this.setState({ detail: item, showModal: true }) }} >
                                                                            <i className="fas fa-pencil-alt"></i>
                                                                        </a>
                                                                    </th>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }

    getExperienceDetail = () => {
        const { userId } = this.state;
        getExperienceDetail(userId).then((response: any) => {
            this.setState({
                list: response.result,
                showLoader: false
            });
        }, (error: any) => {
            alert(error.Message);
            this.setState({ showLoader: false });
        });
    }

    onSubmit = (model: any) => {
        updateExperienceDetail(model).then((res: any) => {
            alert('Changes were saved successfully');
            this.setState({ showLoader: false, showModal: false });
            this.getExperienceDetail();
        }).catch((err: any) => {
            alert(err)
        });
    }

    onDelete = (id: any) => {
        deleteExperienceDetail(id).then((res: any) => {
            alert('Changes were saved successfully');
            this.setState({ showLoader: false, showModal: false });
            this.getExperienceDetail();
        }).catch((err: any) => {
            alert(err)
        });
    }

    showModal = () => {
        this.setState({ showModal: true, detail: null });
    }

    onCancel = () => {
        this.setState({ showModal: false });
    }
}

export default EmployeeFamily;
