import * as React from 'react';
// import { Link } from 'react-router-dom';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
// import UserDefaultPIC from './../../assets/media/users/default.jpg'
import CONSTANT from '../../constant';
import { getEmployeeList } from '../../action/EmployeeAction';
import AddAssetModal from '../../component/asset-management/AddAssetModal';

class ExitDetail extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 20,
            sort: 1,
            count: 0,
            showLoader: false
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                                {this.renderChatDiv()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <AddAssetModal />
            </React.Fragment>
        )
    }

    renderHeader = () => {

        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Exit Details
                        </h3>
                    </div>
                    {/* <div className="kt-subheader__toolbar">
                        <div className="kt-subheader__wrapper row">

                            <div className="kt-subheader__toolbar">
                                <a href="#" className="btn btn-brand btn-elevate btn-icon-sm">
                                    <i className="la la-plus"></i>
                                    Add Asset
                                </a>
                            </div>

                        </div>
                    </div> */}
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="kt-portlet">
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Seperation
					        </h3>
                        </div>
                    </div>
                    <form className="kt-form kt-form--label-right">
                        <div className="kt-portlet__body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Employee ID</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm form-control form-control-sm">
                                                    <option value="10">Select Employees</option>
                                                    {
                                                        list !== undefined && list.map((item: any, index: any) => {
                                                            return (
                                                                <option value={item.name}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Seperation Date</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="date" className="form-control" placeholder="Select date" id="kt_datepicker_2" />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">

                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Interviewer</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm form-control form-control-sm">
                                                    <option value="10">Select Employees</option>
                                                    {
                                                        list !== undefined && list.map((item: any, index: any) => {
                                                            return (
                                                                <option value={item.name}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Reason for leaving</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm form-control form-control-sm">
                                                    <option value="10">Select</option>
                                                    <option value="25">Better Employment Condition</option>
                                                    <option value="50">Career Prospect</option>
                                                    <option value="100">Death</option>
                                                    <option value="100">Dessertion</option>
                                                    <option value="100">Dismissed</option>
                                                    <option value="100">Dissatisfaction with the job</option>
                                                    <option value="100">Emigrating</option>
                                                    <option value="100">Health</option>
                                                    <option value="100">Higher Pay</option>
                                                    <option value="100">Personality Conflicts</option>
                                                    <option value="100">Retirement</option>
                                                    <option value="100">Retrenchment</option>
                                                </select>
                                                <div className="input-group-append ml-3">
                                                    <span className="input-group-text btn" data-toggle="modal" data-target="#kt_modal_1_2">
                                                        <i className="la la-plus-square"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Added By</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="text" disabled className="form-control" id="exampleFormControlTextarea4"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Modified By</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="text" disabled className="form-control" id="exampleFormControlTextarea4"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Added Time</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="text" disabled className="form-control" id="exampleFormControlTextarea4"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Modified Time</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="text" disabled className="form-control" id="exampleFormControlTextarea4"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        {/* <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <button type="reset" className="btn btn-primary" onClick={this.onSubmit}>Submit</button>
                                        <button type="reset" className="btn btn-secondary ml-1">Submit and New</button>
                                        <button type="reset" className="btn btn-secondary ml-1" onClick={this.onCancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </form>

                </div>

                <div className="kt-portlet">
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Questionairre
					        </h3>
                        </div>
                    </div>
                    <form className="kt-form kt-form--label-right">
                        <div className="kt-portlet__body">

                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Working for this organization again</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm form-control form-control-sm">
                                                    <option value="10">Select</option>
                                                    <option value="25">Yes</option>
                                                    <option value="50">No</option>
                                                </select>
                                                <div className="input-group-append ml-3">
                                                    <span className="input-group-text btn" data-toggle="modal" data-target="#kt_modal_1_2">
                                                        <i className="la la-plus-square"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">What did you like the most of the organization</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <textarea className="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Think the organization do to improve staff welfare</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <textarea className="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Anything you wish to share with us</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <textarea className="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        
                    </form>

                </div>
                
                <div className="kt-portlet">
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Checklist for Exit Interview
					        </h3>
                        </div>
                    </div>
                    <form className="kt-form kt-form--label-right">
                        <div className="kt-portlet__body">
                            
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Company Vehicle handed in</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="text" className="form-control" id="exampleFormControlTextarea4"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">All equipments handed in</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="text" className="form-control" id="exampleFormControlTextarea4"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">All library books submitted</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="text" className="form-control" id="exampleFormControlTextarea4"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Security</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="text" className="form-control" id="exampleFormControlTextarea4"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Exit interview conducted</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="text" className="form-control" id="exampleFormControlTextarea4"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Notice period followed</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="text" className="form-control" id="exampleFormControlTextarea4"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Resignation letter submitted</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="text" className="form-control" id="exampleFormControlTextarea4"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="col-form-label col-lg-3 col-sm-12">Manager/Supervisor clearance</label>
                                        <div className="col-lg-8 col-md-9 col-sm-12">
                                            <div className="input-group date">
                                                <input type="text" className="form-control" id="exampleFormControlTextarea4"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <button type="reset" className="btn btn-primary" onClick={this.onSubmit}>Submit</button>
                                        <button type="reset" className="btn btn-secondary ml-1">Submit and New</button>
                                        <button type="reset" className="btn btn-secondary ml-1" onClick={this.onCancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>

                </div>


                {/* <Pagination /> */}
            </div >
        )
    }

    renderChatDiv = () => {
        return (

            <div className="modal modal-hide fade- modal-sticky-bottom-right" id="kt_chat_modal" role="dialog" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="kt-chat">
                            <div className="kt-portlet kt-portlet--last">
                                <div className="kt-portlet__head">
                                    <div className="kt-chat__head ">
                                        <div className="kt-chat__left">
                                            <div className="kt-chat__label">
                                                <a href="#" className="kt-chat__title">Jason Muller</a>
                                                <span className="kt-chat__status">
                                                    <span className="kt-badge kt-badge--dot kt-badge--success"></span> Active
                                                </span>
                                            </div>
                                        </div>
                                        <div className="kt-chat__right">
                                            <div className="dropdown dropdown-inline">
                                                <button type="button" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="flaticon-more-1"></i>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-md">

                                                    <ul className="kt-nav">
                                                        <li className="kt-nav__head">
                                                            Messaging
                                                            <i className="flaticon2-information" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more..."></i>
                                                        </li>
                                                        <li className="kt-nav__separator"></li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-group"></i>
                                                                <span className="kt-nav__link-text">New Group</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-open-text-book"></i>
                                                                <span className="kt-nav__link-text">Contacts</span>
                                                                <span className="kt-nav__link-badge">
                                                                    <span className="kt-badge kt-badge--brand  kt-badge--rounded-">5</span>
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-bell-2"></i>
                                                                <span className="kt-nav__link-text">Calls</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-dashboard"></i>
                                                                <span className="kt-nav__link-text">Settings</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-protected"></i>
                                                                <span className="kt-nav__link-text">Help</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__separator"></li>
                                                        <li className="kt-nav__foot">
                                                            <a className="btn btn-label-brand btn-bold btn-sm" href="#">Upgrade plan</a>
                                                            <a className="btn btn-clean btn-bold btn-sm" href="#" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more...">Learn more</a>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>

                                            <button type="button" className="btn btn-clean btn-sm btn-icon" data-dismiss="modal">
                                                <i className="flaticon2-cross"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="kt-portlet__body">
                                    <div className="kt-scroll kt-scroll--pull" data-height="410" data-mobile-height="225">
                                        <div className="kt-chat__messages kt-chat__messages--solid">
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">2 Hours</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    How likely are you to recommend our company<br /> to your friends and family?
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Hey there, we’re just writing to let you know that you’ve<br /> been subscribed to a repository on GitHub.
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok, Understood!
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">Just Now</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    You’ll receive notifications for all issues, pull requests!
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">2 Hours</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    You were automatically <b className="kt-font-brand">subscribed</b> <br />because you’ve been given access to the repository
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>

                                                <div className="kt-chat__text">
                                                    You can unwatch this repository immediately <br />by clicking here: <a href="#" className="kt-font-bold kt-link"></a>
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok!
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">Just Now</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-chat__input">
                                        <div className="kt-chat__editor">
                                            <textarea placeholder="Type here..." style={{ height: 50 }}></textarea>
                                        </div>
                                        <div className="kt-chat__toolbar">
                                            <div className="kt_chat__tools">
                                                <a href="#"><i className="flaticon2-link"></i></a>
                                                <a href="#"><i className="flaticon2-photograph"></i></a>
                                                <a href="#"><i className="flaticon2-photo-camera"></i></a>
                                            </div>
                                            <div className="kt_chat__actions">
                                                <button type="button" className="btn btn-brand btn-md  btn-font-sm btn-upper btn-bold kt-chat__reply">reply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onSubmit = () => {
        window.location.href = CONSTANT.url.employeeExitList;
    }

    onCancel = () => {
        window.location.href = CONSTANT.url.employeeExitList;
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { page, limit, sort } = this.state;
        getEmployeeList(page, limit, sort, "*").then((response: any) => {
            this.setState({
                list: response.result.list,
                count: response.result.count,
            });
        }, (error: any) => {
            alert(error.message);
        });
    }

}

export default ExitDetail;
