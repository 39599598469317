import * as React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
// import UserDefaultPIC from './../../assets/media/users/default.jpg'
import CONSTANT from '../../constant';
import { getEmployeeList } from '../../action/EmployeeAction';
import BarGraph from '../../component/chart/BarGraph';

class AssetHome extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 20,
            sort: 1,
            count: 0,
            showLoader: false
        }
    }

    public render() {

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">

                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Investment Declaration
                        </h3>
                        {/* <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <form className="kt-margin-l-20" id="kt_subheader_search_form">
                                <div className="kt-input-icon kt-input-icon--right kt-subheader__search">
                                    <input type="text" className="form-control" placeholder="Search..." id="generalSearch" />
                                    <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                        <span>
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                    <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </span>
                                </div>
                            </form>
                        </div> */}

                        <div className="kt-subheader__group kt-hidden" id="kt_subheader_group_actions">

                            <div className="kt-subheader__desc"><span id="kt_subheader_group_selected_rows">0</span> Selected:</div>

                            <div className="btn-toolbar kt-margin-l-20">
                                <div className="dropdown" id="kt_subheader_group_actions_status_change">
                                    <button type="button" className="btn btn-label-brand btn-bold btn-sm dropdown-toggle" data-toggle="dropdown">
                                        Update Status
                                    </button>
                                    <div className="dropdown-menu">
                                        <ul className="kt-nav">
                                            <li className="kt-nav__section kt-nav__section--first">
                                                <span className="kt-nav__section-text">Change status to:</span>
                                            </li>
                                            <li className="kt-nav__item">
                                                <a href="#" className="kt-nav__link" data-toggle="status-change" data-status="1">
                                                    <span className="kt-nav__link-text"><span className="kt-badge kt-badge--unified-success kt-badge--inline kt-badge--bold">Approved</span></span>
                                                </a>
                                            </li>
                                            <li className="kt-nav__item">
                                                <a href="#" className="kt-nav__link" data-toggle="status-change" data-status="2">
                                                    <span className="kt-nav__link-text"><span className="kt-badge kt-badge--unified-danger kt-badge--inline kt-badge--bold">Rejected</span></span>
                                                </a>
                                            </li>
                                            <li className="kt-nav__item">
                                                <a href="#" className="kt-nav__link" data-toggle="status-change" data-status="3">
                                                    <span className="kt-nav__link-text"><span className="kt-badge kt-badge--unified-warning kt-badge--inline kt-badge--bold">Pending</span></span>
                                                </a>
                                            </li>
                                            <li className="kt-nav__item">
                                                <a href="#" className="kt-nav__link" data-toggle="status-change" data-status="4">
                                                    <span className="kt-nav__link-text"><span className="kt-badge kt-badge--unified-info kt-badge--inline kt-badge--bold">On Hold</span></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <button className="btn btn-label-success btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" data-toggle="modal" data-target="#kt_datatable_records_fetch_modal">
                                    Fetch Selected
                        </button>
                                <button className="btn btn-label-danger btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_delete_all">
                                    Delete All
                        </button>
                            </div>
                        </div>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <div className="kt-subheader__wrapper row">

                            <div className="kt-subheader__toolbar">
                                <a href="#" className="btn btn-brand btn-elevate btn-icon-sm">
                                    <i className="la la-plus"></i>
                                    Add Declaration
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="kt-portlet kt-portlet--mobile">
                    {/* <div className="kt-portlet__head kt-portlet__head--lg">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Asset
			                </h3>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <a href="#" className="btn btn-brand btn-elevate btn-icon-sm">
                                        <i className="la la-plus"></i>
                                        Add Record
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="kt-portlet__body">
                        <div id="kt_table_1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '3%' }}>#</th>
                                                <th>Year</th>
                                                <th>House rent</th>
                                                <th>80CCC</th>
                                                <th>80D</th>
                                                <th>80C</th>
                                                <th>80E</th>
                                                <th>80DD</th>
                                                <th>80DDB</th>
                                                <th>80G</th>
                                                <th title="Previous employment PT+PF+IT+HRA">Prev. employment</th>
                                                <th title="Home loan">Section 24</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={13} className="text-center">
                                                    No records found.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                {/* <Pagination /> */}
            </div >
        )
    }

}

export default AssetHome;
