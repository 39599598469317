
import * as React from 'react';
import { Link } from 'react-router-dom';
import CONSTANT from './../../constant';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import SearchForm from './../../component/project/SearchForm';
import ProjectSummary from './../../component/project/ProjectSummary';


import { getProjectList } from '../../action/ProjectAction';
import { getDateParts } from '../../utils';
import Shimmer from '../../component/common/Shimmer';

class ListAttendance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            detail: undefined,
            page: 1,
            limit: 20,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadList()
    }

    public render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { detail } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Projects
                        </h3>

                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {detail && detail.count} Total
                            </span>

                            <SearchForm />
                        </div>

                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href={CONSTANT.url.addProjectDetail} className="btn btn-label-brand btn-bold">Add New Project</a>
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { detail, count } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    {
                        detail === undefined && <tr>
                            <Shimmer />
                        </tr>
                    }
                    {
                        detail !== undefined && detail.list.length === 0 && <div>
                            No records found
                        </div>
                    }
                    {
                        detail !== undefined && detail.list.map((item: any, index: number) => {
                            return (
                                <div className="col-md-4" key={index}>
                                    <ProjectSummary detail={item} />
                                </div>
                            )
                        })
                    }
                </div>
                {/* <Pagination /> */}
            </div>
        )
    }

    loadList = () => {
        const { page, limit } = this.state;
        getProjectList(page, limit).then((res: any) => {
            this.setState({
                detail: res
            })
        }).catch((err: any) => {
            console.log(err);
        })
    }
}

export default ListAttendance;
