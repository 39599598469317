import * as React from 'react';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import UserMenu from '../../component/employee/Menu';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
// import Shimmer from '../../component/common/Shimmer';
// import TaskList from './../task/List';
import PieChart from './../../component/chart/PieChart';
import LeaveBalance from './../../component/chart/BarGraph';

import CONSTANT from '../../constant';
// import { getMasterDetail, updateEmployeeMaster, getEmployeeSummary } from '../../action/EmployeeAction';
import { getCookie, getStorage } from '../../utils';

class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const isSelf = props.match.params.id ? false : true;
        const userId = props.match.params.id ? props.match.params.id : getStorage(CONSTANT.keys.userDetail).id;
        this.state = {
            userId,
            isSelf,
            detail: undefined,
            showLoader: false
        }
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Employee Detail
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { userId, isSelf, detail } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
                    <button className="kt-app__aside-close" id="kt_user_profile_aside_close">
                        <i className="la la-close"></i>
                    </button>
                    <UserMenu userId={userId} isSelf={isSelf} />
                    <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
                                    <div className="kt-portlet__head">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">
                                                Tasks
			                                </h3>
                                        </div>
                                        <div className="kt-portlet__head-toolbar">
                                            <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-brand" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" data-toggle="tab" href="#kt_widget2_tab1_content" role="tab">
                                                        Today
					</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#kt_widget2_tab2_content" role="tab">
                                                        Week
					</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#kt_widget2_tab3_content" role="tab">
                                                        Month
					</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__body">
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="kt_widget2_tab1_content">
                                                <div className="kt-widget2">
                                                    <div className="kt-widget2__item kt-widget2__item--primary">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Make Metronic Great  Again.Lorem Ipsum Amet
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Bob
							</a>
                                                        </div>
                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--warning">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>

                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Prepare Docs For Metting On Monday
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Sean
							</a>
                                                        </div>

                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--brand">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>

                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Make Widgets Development. Estudiat Communy Elit
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Aziko
							</a>
                                                        </div>

                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--success">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>

                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Make Metronic Development. Lorem Ipsum
							</a>
                                                            <a className="kt-widget2__username">
                                                                By James
							</a>
                                                        </div>
                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--danger">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>


                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Completa Financial Report For Emirates Airlines
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Bob
							</a>
                                                        </div>

                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--info">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>

                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Completa Financial Report For Emirates Airlines
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Sean
							</a>
                                                        </div>

                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="kt_widget2_tab2_content">
                                                <div className="kt-widget2">
                                                    <div className="kt-widget2__item kt-widget2__item--success">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>

                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Make Metronic Development.Lorem Ipsum
							</a>
                                                            <a className="kt-widget2__username">
                                                                By James
							</a>
                                                        </div>
                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--warning">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>

                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Prepare Docs For Metting On Monday
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Sean
							</a>
                                                        </div>

                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--danger">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>


                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Completa Financial Report For Emirates Airlines
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Bob
							</a>
                                                        </div>

                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--primary">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>

                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Make Metronic Great  Again.Lorem Ipsum Amet
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Bob
							</a>
                                                        </div>

                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--info">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Completa Financial Report For Emirates Airlines
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Sean
							</a>
                                                        </div>
                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--brand">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Make Widgets Development.Estudiat Communy Elit
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Aziko
							</a>
                                                        </div>
                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="kt_widget2_tab3_content">
                                                <div className="kt-widget2">

                                                    <div className="kt-widget2__item kt-widget2__item--warning">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Make Metronic Development. Lorem Ipsum
							</a>
                                                            <a className="kt-widget2__username">
                                                                By James
							</a>
                                                        </div>
                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--danger">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Completa Financial Report For Emirates Airlines
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Bob
							</a>
                                                        </div>
                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--brand">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Make Widgets Development.Estudiat Communy Elit
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Aziko
							</a>
                                                        </div>
                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--info">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Completa Financial Report For Emirates Airlines
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Sean
							</a>
                                                        </div>
                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--success">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Completa Financial Report For Emirates Airlines
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Bob
							</a>
                                                        </div>
                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                    <div className="kt-widget2__item kt-widget2__item--primary">
                                                        <div className="kt-widget2__checkbox">
                                                            <label className="kt-checkbox kt-checkbox--solid kt-checkbox--single">
                                                                <input type="checkbox" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <div className="kt-widget2__info">
                                                            <a href="#" className="kt-widget2__title">
                                                                Make Metronic Great  Again.Lorem Ipsum Amet
							</a>
                                                            <a href="#" className="kt-widget2__username">
                                                                By Bob
							</a>
                                                        </div>
                                                        <div className="kt-widget2__actions">
                                                            <a href="#" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown">
                                                                <i className="flaticon-more-1"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                                <ul className="kt-nav">
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-line-chart"></i>
                                                                            <span className="kt-nav__link-text">Reports</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-send"></i>
                                                                            <span className="kt-nav__link-text">Messages</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-pie-chart-1"></i>
                                                                            <span className="kt-nav__link-text">Charts</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-avatar"></i>
                                                                            <span className="kt-nav__link-text">Members</span>
                                                                        </a>
                                                                    </li>
                                                                    <li className="kt-nav__item">
                                                                        <a href="#" className="kt-nav__link">
                                                                            <i className="kt-nav__link-icon flaticon2-settings"></i>
                                                                            <span className="kt-nav__link-text">Settings</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>							</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="kt-portlet kt-portlet--height-fluid">
                                    <div className="kt-portlet__head">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">
                                                Notifications
			</h3>
                                        </div>
                                        <div className="kt-portlet__head-toolbar">
                                            <ul className="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" data-toggle="tab" href="#kt_widget6_tab1_content" role="tab">
                                                        Latest
					</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#kt_widget6_tab2_content" role="tab">
                                                        Week
					</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#kt_widget6_tab3_content" role="tab">
                                                        Month
					</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__body">
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="kt_widget6_tab1_content" aria-expanded="true">
                                                <div className="kt-notification">
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"></path>
                                                                    <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) " x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                New order has been received.
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                2 hrs ago
                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.98630124,11 4.48466491,11.0516454 4,11.1500272 L4,7 C4,5.8954305 4.8954305,5 6,5 L20,5 C21.1045695,5 22,5.8954305 22,7 L22,16 C22,17.1045695 21.1045695,18 20,18 L12.9835977,18 Z M19.1444251,6.83964668 L13,10.1481833 L6.85557487,6.83964668 C6.4908718,6.6432681 6.03602525,6.77972206 5.83964668,7.14442513 C5.6432681,7.5091282 5.77972206,7.96397475 6.14442513,8.16035332 L12.6444251,11.6603533 C12.8664074,11.7798822 13.1335926,11.7798822 13.3555749,11.6603533 L19.8555749,8.16035332 C20.2202779,7.96397475 20.3567319,7.5091282 20.1603533,7.14442513 C19.9639747,6.77972206 19.5091282,6.6432681 19.1444251,6.83964668 Z" fill="#000000"></path>
                                                                    <path d="M8.4472136,18.1055728 C8.94119209,18.3525621 9.14141644,18.9532351 8.89442719,19.4472136 C8.64743794,19.9411921 8.0467649,20.1414164 7.5527864,19.8944272 L5,18.618034 L5,14.5 C5,13.9477153 5.44771525,13.5 6,13.5 C6.55228475,13.5 7,13.9477153 7,14.5 L7,17.381966 L8.4472136,18.1055728 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                New member is registered and pending for approval.
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                3 hrs ago
                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M12,10.9996338 C12.8356605,10.3719448 13.8743941,10 15,10 C17.7614237,10 20,12.2385763 20,15 C20,17.7614237 17.7614237,20 15,20 C13.8743941,20 12.8356605,19.6280552 12,19.0003662 C11.1643395,19.6280552 10.1256059,20 9,20 C6.23857625,20 4,17.7614237 4,15 C4,12.2385763 6.23857625,10 9,10 C10.1256059,10 11.1643395,10.3719448 12,10.9996338 Z M13.3336047,12.504354 C13.757474,13.2388026 14,14.0910788 14,15 C14,15.9088933 13.7574889,16.761145 13.3336438,17.4955783 C13.8188886,17.8206693 14.3938466,18 15,18 C16.6568542,18 18,16.6568542 18,15 C18,13.3431458 16.6568542,12 15,12 C14.3930587,12 13.8175971,12.18044 13.3336047,12.504354 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                                    <circle fill="#000000" cx="12" cy="9" r="5"></circle>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                Membership application has been added.
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                3 hrs ago
                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                    <path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000"></path>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                New report file has been uploaded.
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                5 hrs ago
                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M16.3740377,19.9389434 L22.2226499,11.1660251 C22.4524142,10.8213786 22.3592838,10.3557266 22.0146373,10.1259623 C21.8914367,10.0438285 21.7466809,10 21.5986122,10 L17,10 L17,4.47708173 C17,4.06286817 16.6642136,3.72708173 16.25,3.72708173 C15.9992351,3.72708173 15.7650616,3.85240758 15.6259623,4.06105658 L9.7773501,12.8339749 C9.54758575,13.1786214 9.64071616,13.6442734 9.98536267,13.8740377 C10.1085633,13.9561715 10.2533191,14 10.4013878,14 L15,14 L15,19.5229183 C15,19.9371318 15.3357864,20.2729183 15.75,20.2729183 C16.0007649,20.2729183 16.2349384,20.1475924 16.3740377,19.9389434 Z" fill="#000000"></path>
                                                                    <path d="M4.5,5 L9.5,5 C10.3284271,5 11,5.67157288 11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L4.5,8 C3.67157288,8 3,7.32842712 3,6.5 C3,5.67157288 3.67157288,5 4.5,5 Z M4.5,17 L9.5,17 C10.3284271,17 11,17.6715729 11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L4.5,20 C3.67157288,20 3,19.3284271 3,18.5 C3,17.6715729 3.67157288,17 4.5,17 Z M2.5,11 L6.5,11 C7.32842712,11 8,11.6715729 8,12.5 C8,13.3284271 7.32842712,14 6.5,14 L2.5,14 C1.67157288,14 1,13.3284271 1,12.5 C1,11.6715729 1.67157288,11 2.5,11 Z" fill="#000000" opacity="0.3"></path>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                New user feedback received and pending for review.
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                8 hrs ago
                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z" fill="#000000"></path>
                                                                    <circle fill="#000000" opacity="0.3" cx="18.5" cy="5.5" r="2.5"></circle>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                Database sever #2 has been fully restarted.
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                23 hrs ago
                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="kt_widget6_tab2_content" aria-expanded="false">
                                                <div className="kt-notification">
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--success">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M9.35321926,16.3736278 L16.3544311,10.3706602 C16.5640654,10.1909158 16.5882961,9.87526197 16.4085517,9.66562759 C16.3922584,9.64662485 16.3745611,9.62887247 16.3556091,9.6125202 L9.35439731,3.57169798 C9.14532254,3.39130299 8.82959492,3.41455255 8.64919993,3.62362732 C8.5708616,3.71442013 8.52776329,3.83034375 8.52776329,3.95026134 L8.52776329,15.9940512 C8.52776329,16.2701936 8.75162092,16.4940512 9.02776329,16.4940512 C9.14714624,16.4940512 9.2625893,16.4513356 9.35321926,16.3736278 Z" fill="#000000" transform="translate(12.398118, 9.870355) rotate(-450.000000) translate(-12.398118, -9.870355) "></path>
                                                                    <rect fill="#000000" opacity="0.3" transform="translate(12.500000, 17.500000) scale(-1, 1) rotate(-270.000000) translate(-12.500000, -17.500000) " x="11" y="11" width="3" height="13" rx="0.5"></rect>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                New company application has been approved.
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                3 hrs ago
                            </div>
                                                        </div>
                                                    </a>

                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <rect fill="#000000" opacity="0.3" x="12" y="4" width="3" height="13" rx="1.5"></rect>
                                                                    <rect fill="#000000" opacity="0.3" x="7" y="9" width="3" height="8" rx="1.5"></rect>
                                                                    <path d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z" fill="#000000" fill-rule="nonzero"></path>
                                                                    <rect fill="#000000" opacity="0.3" x="17" y="11" width="3" height="6" rx="1.5"></rect>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                New report has been received.
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                23 hrs ago
                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--danger">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"></path>
                                                                    <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) " x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                New file has been uploaded and pending for review.
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                5 hrs ago
                            </div>
                                                        </div>
                                                    </a>

                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M12,10.9996338 C12.8356605,10.3719448 13.8743941,10 15,10 C17.7614237,10 20,12.2385763 20,15 C20,17.7614237 17.7614237,20 15,20 C13.8743941,20 12.8356605,19.6280552 12,19.0003662 C11.1643395,19.6280552 10.1256059,20 9,20 C6.23857625,20 4,17.7614237 4,15 C4,12.2385763 6.23857625,10 9,10 C10.1256059,10 11.1643395,10.3719448 12,10.9996338 Z M13.3336047,12.504354 C13.757474,13.2388026 14,14.0910788 14,15 C14,15.9088933 13.7574889,16.761145 13.3336438,17.4955783 C13.8188886,17.8206693 14.3938466,18 15,18 C16.6568542,18 18,16.6568542 18,15 C18,13.3431458 16.6568542,12 15,12 C14.3930587,12 13.8175971,12.18044 13.3336047,12.504354 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                                    <circle fill="#000000" cx="12" cy="9" r="5"></circle>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                Membership application has been added.
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                3 hrs ago
                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--info">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                    <path d="M12,4.25932872 C12.1488635,4.25921584 12.3000368,4.29247316 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 L12,4.25932872 Z" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M12,4.25932872 L12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.277344,4.464261 11.6315987,4.25960807 12,4.25932872 Z" fill="#000000"></path>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                New customer is registered.
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                3 days ago
                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="kt_widget6_tab3_content" aria-expanded="false">
                                                <div className="kt-notification">
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--warning">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M14.5,11 C15.0522847,11 15.5,11.4477153 15.5,12 L15.5,15 C15.5,15.5522847 15.0522847,16 14.5,16 L9.5,16 C8.94771525,16 8.5,15.5522847 8.5,15 L8.5,12 C8.5,11.4477153 8.94771525,11 9.5,11 L9.5,10.5 C9.5,9.11928813 10.6192881,8 12,8 C13.3807119,8 14.5,9.11928813 14.5,10.5 L14.5,11 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 L10.5,11 L13.5,11 L13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z" fill="#000000"></path>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                New order has been received.
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                2 hrs ago
                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--success">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M13,5.06189375 C12.6724058,5.02104333 12.3386603,5 12,5 C11.6613397,5 11.3275942,5.02104333 11,5.06189375 L11,4 L10,4 C9.44771525,4 9,3.55228475 9,3 C9,2.44771525 9.44771525,2 10,2 L14,2 C14.5522847,2 15,2.44771525 15,3 C15,3.55228475 14.5522847,4 14,4 L13,4 L13,5.06189375 Z" fill="#000000"></path>
                                                                    <path d="M16.7099142,6.53272645 L17.5355339,5.70710678 C17.9260582,5.31658249 18.5592232,5.31658249 18.9497475,5.70710678 C19.3402718,6.09763107 19.3402718,6.73079605 18.9497475,7.12132034 L18.1671361,7.90393167 C17.7407802,7.38854954 17.251061,6.92750259 16.7099142,6.53272645 Z" fill="#000000"></path>
                                                                    <path d="M11.9630156,7.5 L12.0369844,7.5 C12.2982526,7.5 12.5154733,7.70115317 12.5355117,7.96165175 L12.9585886,13.4616518 C12.9797677,13.7369807 12.7737386,13.9773481 12.4984096,13.9985272 C12.4856504,13.9995087 12.4728582,14 12.4600614,14 L11.5399386,14 C11.2637963,14 11.0399386,13.7761424 11.0399386,13.5 C11.0399386,13.4872031 11.0404299,13.4744109 11.0414114,13.4616518 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000"></path>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                New customer is registered
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                3 hrs ago
                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <rect fill="#000000" opacity="0.3" x="2" y="9" width="20" height="13" rx="2"></rect>
                                                                    <rect fill="#000000" opacity="0.3" x="5" y="5" width="14" height="2" rx="0.5"></rect>
                                                                    <rect fill="#000000" opacity="0.3" x="7" y="1" width="10" height="2" rx="0.5"></rect>
                                                                    <path d="M10.8333333,20 C9.82081129,20 9,19.3159906 9,18.4722222 C9,17.6284539 9.82081129,16.9444444 10.8333333,16.9444444 C11.0476105,16.9444444 11.2533018,16.9750785 11.4444444,17.0313779 L11.4444444,12.7916011 C11.4444444,12.4782408 11.6398662,12.2012404 11.9268804,12.1077729 L15.4407693,11.0331119 C15.8834716,10.8889438 16.3333333,11.2336005 16.3333333,11.7169402 L16.3333333,12.7916011 C16.3333333,13.1498215 15.9979332,13.3786009 15.7222222,13.4444444 C15.3255297,13.53918 14.3070112,13.7428837 12.6666667,14.0555556 L12.6666667,18.5035214 C12.6666667,18.5583862 12.6622174,18.6091837 12.6535404,18.6559869 C12.5446237,19.4131089 11.771224,20 10.8333333,20 Z" fill="#000000"></path>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                Application has been approved
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                3 hrs ago
                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--warning">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect opacity="0.200000003" x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M4.5,7 L9.5,7 C10.3284271,7 11,7.67157288 11,8.5 C11,9.32842712 10.3284271,10 9.5,10 L4.5,10 C3.67157288,10 3,9.32842712 3,8.5 C3,7.67157288 3.67157288,7 4.5,7 Z M13.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L13.5,18 C12.6715729,18 12,17.3284271 12,16.5 C12,15.6715729 12.6715729,15 13.5,15 Z" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M17,11 C15.3431458,11 14,9.65685425 14,8 C14,6.34314575 15.3431458,5 17,5 C18.6568542,5 20,6.34314575 20,8 C20,9.65685425 18.6568542,11 17,11 Z M6,19 C4.34314575,19 3,17.6568542 3,16 C3,14.3431458 4.34314575,13 6,13 C7.65685425,13 9,14.3431458 9,16 C9,17.6568542 7.65685425,19 6,19 Z" fill="#000000"></path>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                New customer comment recieved
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                2 days ago
                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="kt-notification__item">
                                                        <div className="kt-notification__item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--danger">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"></path>
                                                                    <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) " x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
                                                                </g>
                                                            </svg>                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title">
                                                                New customer is registered
                            </div>
                                                            <div className="kt-notification__item-time">
                                                                3 days ago
                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="kt-portlet">
                                    <div className="kt-portlet__head">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">
                                                Check in pattern
                                    </h3>
                                        </div>
                                    </div>
                                    <PieChart data={[
                                        { name: '8:00 - 9:00', value: 10 },
                                        { name: '9:00 - 10:00', value: 5 },
                                        { name: '10:00 - 11:00', value: 2 }]} />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="kt-portlet">
                                    <div className="kt-portlet__head">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">
                                                Leave Balance
					                                </h3>
                                        </div>
                                    </div>
                                    <LeaveBalance title="Medical leave" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default EmployeeMaster;
