import * as React from 'react';
import NumberFormat from 'react-number-format';
import { getLoginStatusList, getRoleList } from './../../action/ConfigAction';
import { onChange, validateForm, setOptions } from '../../utils';

class EmployeeMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            userId: props.userId,
            isSelf: props.isSelf,
            ctc: { value: detail ? detail.ctc : '', name: 'ctc', error: '', isRequired: true },
            variable: { value: detail ? detail.variable : '', name: 'variable', error: '', isRequired: true },
        }
    }

    public render() {
        const { ctc, variable, isSelf } = this.state;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Salary Profile
                                    </h3>
                                </div>
                            </div>

                            <form className="kt-form kt-form--label-right" onSubmit={this.onSubmit}>
                                <div className="kt-portlet__body">
                                    <div className="row">
                                        <div className="col-lg-6 form-group">
                                            <label>CTC* </label>
                                            <NumberFormat
                                                allowLeadingZeros={false}
                                                allowNegative={false}
                                                thousandSeparator={true}
                                                className={ctc.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter CTC"
                                                name={ctc.name}
                                                value={ctc.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <label >Variable*</label>
                                            <NumberFormat
                                                allowLeadingZeros={false}
                                                allowNegative={false}
                                                thousandSeparator={true}
                                                className={variable.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                placeholder="Enter variable"
                                                name={variable.name}
                                                value={variable.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    !isSelf && <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-md-9"></div>
                                                <div className="col-md-3">
                                                    <button type="submit" className="btn btn-brand btn-bold btn-block">Save & Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model = {
                userId: parseInt(st.userId, 10),
                ctc: parseInt(st.ctc.value.replace(/,/g, ''), 10),
                variable: parseInt(st.variable.value.replace(/,/g, ''), 10)
            };
            this.props.onSubmit(model);
        }
    }
}

export default EmployeeMaster;
