
import * as React from 'react';
import queryString from 'query-string';

import Shimmer from '../../component/common/Shimmer';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import ProgressBar from './../../component/common/ProgressBar';
import AddTaskDetail from './../../component/task/AddTaskDetail';

import CONSTANT from './../../constant';
import { getProjectDetail } from '../../action/ProjectAction';
import { getMembersList } from '../../action/ProjectMemberAction';
import { getTaskList } from '../../action/TaskAction';
import { getCommentList } from '../../action/TaskCommentAction';
import { getDateParts } from '../../utils';
import ModalWindow from '../../component/common/ModalWindow';


class ListAttendance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const param: any = queryString.parse(props.location.search);
        this.state = {
            id: param.id,
            detail: undefined,
            task: undefined,
            members: undefined,
            comments: undefined,

            showTaskModal: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadDetail()
    }

    public render() {
        const { showTaskModal } = this.state;
        return (
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <Sidebar />
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                        <Header />
                        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                            {this.renderHeader()}
                            {this.renderBody()}
                        </div>
                        <Footer />
                    </div>
                </div>
                {
                    showTaskModal && <ModalWindow
                        title="Task detail"
                        backdrop="static"
                        toggleModal={this.onTaskCancel}>
                        <AddTaskDetail
                            onSubmit={this.onTaskSubmit}
                            onCancel={this.onTaskCancel} />
                    </ModalWindow>
                }
            </div>
        )
    }

    renderHeader = () => {
        const { detail } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Project detail
                        </h3>

                        {/* <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {detail && detail.count} Total
                            </span>

                            <SearchForm />
                        </div> */}

                    </div>
                    <div className="kt-subheader__toolbar">
                        <a onClick={this.onAddTask} href="javascript:;" className="btn btn-label-brand btn-bold">Add New Task</a>
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { detail, task, comment } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">


                <div className="kt-portlet ">
                    <div className="kt-portlet__body">
                        {
                            detail === undefined && <Shimmer />
                        }
                        {
                            detail !== undefined && this.renderProjectDetail()
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        {
                            task === undefined && <Shimmer />
                        }
                        {
                            task !== undefined && this.renderTaskList()
                        }
                    </div>
                    <div className="col-md-6">
                        {
                            comment === undefined && <Shimmer />
                        }
                        {
                            comment !== undefined && this.renderCommentList()
                        }
                    </div>
                </div>
            </div>
        )
    }

    renderProjectDetail = () => {
        const { detail, count } = this.state;
        const editPageURL = CONSTANT.url.editProjectDetail.replace(':id', detail.id);
        const addUserURL = CONSTANT.url.addEmployeeMaster;
        const addTaskURL = CONSTANT.url.addTaskDetail;
        const sd = getDateParts(detail.startDate);
        const ed = getDateParts(detail.endDate);
        return (
            <div className="kt-widget kt-widget--user-profile-3">
                <div className="kt-widget__top">
                    <div className="kt-widget__content pl-0">
                        <div className="kt-widget__head">
                            <a href="#" className="kt-widget__title">{detail.name}</a>

                            <div className="kt-widget__action">
                                <a href={editPageURL} className="btn btn-sm btn-upper mr-1" style={{ background: "#edeff6" }}>edit</a>
                                <a href={editPageURL} className="btn btn-success btn-sm btn-upper mr-1">add user</a>
                                <a onClick={this.onAddTask} href="javascript:;" className="btn btn-brand btn-sm btn-upper">new task</a>
                            </div>
                        </div>

                        <div className="kt-widget__info">
                            <div className="kt-widget__desc">
                                {detail.description}
                            </div>

                            <div className="kt-widget__stats d-flex align-items-center flex-fill">
                                <div className="kt-widget__item">
                                    <span className="kt-widget__date">
                                        Start Date
                                </span>
                                    <div className="kt-widget__label">
                                        <span className="btn btn-label-brand btn-sm btn-bold btn-upper">{`${sd.date} ${sd.monthName} ${sd.year}`}</span>
                                    </div>
                                </div>

                                <div className="kt-widget__item">
                                    <span className="kt-widget__date">
                                        Due Date
                                </span>
                                    <div className="kt-widget__label">
                                        <span className="btn btn-label-danger btn-sm btn-bold btn-upper">{`${ed.date} ${ed.monthName} ${ed.year}`}</span>
                                    </div>
                                </div>

                                <div className="kt-widget__item flex-fill">
                                    <span className="kt-widget__subtitel">Progress</span>
                                    <ProgressBar percentage={78} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-widget__bottom">
                    <div className="kt-widget__item">
                        <div className="kt-widget__icon">
                            <i className="flaticon-file-2"></i>
                        </div>
                        <div className="kt-widget__details">
                            <span className="kt-widget__title">{detail.taskCount} Tasks</span>
                            <a href="#" className="kt-widget__value kt-font-brand">View</a>
                        </div>
                    </div>

                    <div className="kt-widget__item">
                        <div className="kt-widget__icon">
                            <i className="flaticon-chat-1"></i>
                        </div>
                        <div className="kt-widget__details">
                            <span className="kt-widget__title">{detail.commentCount} Comments</span>
                            <a href="#" className="kt-widget__value kt-font-brand">View</a>
                        </div>
                    </div>

                    <div className="kt-widget__item">
                        <div className="kt-widget__icon">
                            <i className="flaticon-network"></i>
                        </div>
                        <div className="kt-widget__details">
                            <div className="kt-section__content kt-section__content--solid">
                                <div className="kt-media-group">
                                    <a href="#" className="kt-media kt-media--sm kt-media--circle" data-toggle="kt-tooltip" data-skin="brand" data-placement="top" title="" data-original-title="John Myer">
                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_7.jpg" alt="image" />
                                    </a>
                                    <a href="#" className="kt-media kt-media--sm kt-media--circle" data-toggle="kt-tooltip" data-skin="brand" data-placement="top" title="" data-original-title="Alison Brandy">
                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_3.jpg" alt="image" />
                                    </a>
                                    <a href="#" className="kt-media kt-media--sm kt-media--circle" data-toggle="kt-tooltip" data-skin="brand" data-placement="top" title="" data-original-title="Selina Cranson">
                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_2.jpg" alt="image" />
                                    </a>
                                    <a href="#" className="kt-media kt-media--sm kt-media--circle" data-toggle="kt-tooltip" data-skin="brand" data-placement="top" title="" data-original-title="Luke Walls">
                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_13.jpg" alt="image" />
                                    </a>
                                    <a href="#" className="kt-media kt-media--sm kt-media--circle" data-toggle="kt-tooltip" data-skin="brand" data-placement="top" title="" data-original-title="Micheal York">
                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_4.jpg" alt="image" />
                                    </a>
                                    <a href="#" className="kt-media kt-media--sm kt-media--circle" data-toggle="kt-tooltip" data-skin="brand" data-placement="top" title="" data-original-title="Micheal York">
                                        <span>+3</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderTaskList = () => {
        const { task } = this.state;
        return (
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Task list
                        </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                        <div className="dropdown dropdown-inline">
                            <button type="button" className="btn btn-clean btn-sm btn-icon-md btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="flaticon-more-1"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-fit dropdown-menu-md">
                                <ul className="kt-nav">
                                    {/* <li className="kt-nav__head">
                                    Export Options
<span data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Click to learn more...">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--brand kt-svg-icon--md1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                                                <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"></rect>
                                                <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"></rect>
                                            </g>
                                        </svg>        </span>
                                </li>
                                <li className="kt-nav__separator"></li> */}
                                    <li className="kt-nav__item">
                                        <a href="#" className="kt-nav__link">
                                            <i className="kt-nav__link-icon flaticon2-drop"></i>
                                            <span className="kt-nav__link-text">View All</span>
                                        </a>
                                    </li>
                                    <li className="kt-nav__item">
                                        <a href="#" className="kt-nav__link">
                                            <i className="kt-nav__link-icon flaticon2-calendar-8"></i>
                                            <span className="kt-nav__link-text">Add Task</span>
                                        </a>
                                    </li>
                                    {/* <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link">
                                        <i className="kt-nav__link-icon flaticon2-telegram-logo"></i>
                                        <span className="kt-nav__link-text">Settings</span>
                                    </a>
                                </li>
                                <li className="kt-nav__item">
                                    <a href="#" className="kt-nav__link">
                                        <i className="kt-nav__link-icon flaticon2-new-email"></i>
                                        <span className="kt-nav__link-text">Support</span>
                                        <span className="kt-nav__link-badge">
                                            <span className="kt-badge kt-badge--success kt-badge--rounded">5</span>
                                        </span>
                                    </a>
                                </li>
                                <li className="kt-nav__separator"></li>
                                <li className="kt-nav__foot">
                                    <a className="btn btn-label-danger btn-bold btn-sm" href="#">Upgrade plan</a>
                                    <a className="btn btn-clean btn-bold btn-sm" href="#" data-toggle="kt-tooltip" data-placement="right" title="" data-original-title="Click to learn more...">Learn more</a>
                                </li> */}
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    <div className="tab-content">
                        <div className="tab-pane active" id="kt_widget2_tab1_content">
                            <div className="kt-widget3">
                                {
                                    task.list.map((item: any, index: number) => {
                                        const mo = getDateParts(item.modifiedOn);
                                        const className = item.statusId === 1 ? "kt-widget3__status kt-font-info" : (
                                            item.statusId === 2 ? "kt-widget3__status kt-font-warning" : "kt-widget3__status kt-font-success"
                                        )
                                        return (
                                            <div className="kt-widget3__item" key={index}>
                                                <div className="kt-widget3__header">
                                                    <div className="kt-widget3__user-img">
                                                        <img className="kt-widget3__img" src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/user1.jpg" alt="" />
                                                    </div>
                                                    <div className="kt-widget3__info">
                                                        <a href="#" className="kt-widget3__username">
                                                            {item.assignedToName}
                                                        </a><br />
                                                        <span className="kt-widget3__time">
                                                            {mo.relativeTime}
                                                        </span>
                                                    </div>
                                                    <span className={className}>
                                                        {item.statusName}
                                                    </span>
                                                </div>
                                                <div className="kt-widget3__body">
                                                    <p className="kt-widget3__text">
                                                        {item.title}
                                                    </p>
                                                </div>
                                                {
                                                    item.percentageComplete !== 0 && <div className="kt-widget__item flex-fill">
                                                        <span style={{ color: '#595d6e', fontWeight: 500, display: 'block' }} className="kt-widget__subtitel">Progress</span>
                                                        <div className="kt-widget__progress d-flex align-items-center flex-fill" style={{ width: '100%', margin: '1.4rem 0 1.4rem 0' }}>
                                                            <div className="progress" style={{ height: "5px", width: "100%" }}>
                                                                <div className="progress-bar kt-bg-primary" role="progressbar" style={{ width: `${item.percentageComplete}%` }}></div>
                                                            </div>
                                                            <span style={{ paddingLeft: '0.7rem', color: '#48465b', fontWeight: 600 }} className="kt-widget__stat">{item.percentageComplete}%</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>



                        </div>
                    </div>
                </div>
            </div >

        )
    }

    renderCommentList = () => {
        const { comment } = this.state;
        return (
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Recent Comments
                        </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                        <div className="dropdown dropdown-inline">
                            <button type="button" className="btn btn-clean btn-sm btn-icon-md btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="flaticon-more-1"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-fit dropdown-menu-md">
                                <ul className="kt-nav">
                                    <li className="kt-nav__item">
                                        <a href="#" className="kt-nav__link">
                                            <i className="kt-nav__link-icon flaticon2-drop"></i>
                                            <span className="kt-nav__link-text">View All</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    <div className="kt-widget3">
                        {
                            comment.list.map((item: any, index: number) => {
                                const co = getDateParts(item.createdOn);
                                return (
                                    <div className="kt-widget3__item" key={index}>
                                        <div className="kt-widget3__header">
                                            <div className="kt-widget3__user-img">
                                                <img className="kt-widget3__img" src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/user1.jpg" alt="" />
                                            </div>
                                            <div className="kt-widget3__info">
                                                <a href="#" className="kt-widget3__username">
                                                    {item.createdByName}
                                                </a><br />
                                                <span className="kt-widget3__time">
                                                    {co.relativeTime}
                                                </span>
                                            </div>
                                            <span className="kt-widget3__status kt-font-info">
                                                {/* Pending */}
                                            </span>
                                        </div>
                                        <div className="kt-widget3__body">
                                            <p className="kt-widget3__text">
                                                {item.comment}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        )
    }

    loadDetail = () => {
        const { id } = this.state;
        getProjectDetail(id).then((res: any) => {
            this.setState({ detail: res });
        })

        getTaskList(id, 1, 10).then((res: any) => {
            this.setState({ task: res });
        })

        getCommentList(id, 1, 10).then((res: any) => {
            this.setState({ comment: res });
        })
    }

    onAddTask = () => {
        this.setState({ showTaskModal: true });
    }

    onTaskSubmit = () => {
        this.setState({ showTaskModal: false });
    }

    onTaskCancel = () => {
        this.setState({ showTaskModal: false });
    }
}

export default ListAttendance;
