import * as React from 'react';
import $ from 'jquery';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import PieChart from './../../component/chart/PieChart';
import CONSTANT from './../../constant';
import LogAttendance from './../../component/attendance/LogAttendance';

import { getAllEmployeeList } from '../../action/EmployeeAction';
import { getEmployeeMonthlyHistory } from '../../action/AttendanceActions';
import { getCookie, getStorage } from '../../utils';

class EmployeeList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            date: moment(),
            list: undefined,
            uniqueRecords: undefined,
            showLoader: false,
            employeeList: []
        }
    }

    componentDidMount() {
        this.loadList();
        getAllEmployeeList().then((res: any) => {
            this.setState({ employeeList: res.result });
        })
    }

    public render() {

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { date, employeeList } = this.state;

        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Attendance
                        </h3>

                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {date.format('dddd DD MMM YYYY')}
                            </span>
                        </div>

                    </div>
                    <div id="proxy-ta" className="kt-subheader__toolbar" style={{ width: '700px' }}>
                        <Typeahead
                            id="ta-location"
                            allowNew={true}
                            labelKey={(e: any) => `${e.name} ${e.employeeId ? e.employeeId : ''}`}
                            options={employeeList}
                            // onChange={(e: any) => this.typeaheadOnChange(location.name, e)}
                            placeholder="Search employee by employee code"
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { list, uniqueRecords } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-4">
                        {/* <!--Begin::Portlet--> */}
                        <div className="kt-portlet kt-portlet--height-fluid">
                            <div className="kt-portlet__head kt-portlet__head--noborder">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title"></h3>
                                </div>
                            </div>


                            <div className="kt-portlet__body">
                                {/* <!--begin::Widget --> */}
                                <div className="kt-widget kt-widget--user-profile-2">
                                    <div className="kt-widget__head">
                                        <div className="kt-widget__media">
                                            <img className="kt-widget__img kt-hidden-" src="https://devapi.rozgar.com/profile-picture/2/1585746724383.jpg" alt="image" />
                                        </div>

                                        <div className="kt-widget__info">
                                            <a href="#" className="kt-widget__username">
                                                Amit Sharma
                                            </a>

                                            <span className="kt-widget__desc">
                                                Supervisor
                                            </span>
                                            Planning Department
                                        </div>
                                    </div>

                                    <div className="kt-widget__body">
                                        <div className="kt-widget__item">
                                            <div className="kt-widget__contact">
                                                <span className="kt-widget__label">Email:</span>
                                                <a href={`mailto:`} className="kt-widget__data">amit.sharma@mounttalent.com</a>
                                            </div>
                                            <div className="kt-widget__contact">
                                                <span className="kt-widget__label">Phone:</span>
                                                <a href={`tel:`} className="kt-widget__data">7838920181</a>
                                            </div>
                                            <div className="kt-widget__contact">
                                                <span className="kt-widget__label">Is onboard:</span>
                                                <span className="kt-widget__data"><span className="text-success font-weight-bold">Yes</span></span>
                                            </div>
                                            <div className="kt-widget__contact">
                                                <span className="kt-widget__label">Login status:</span>
                                                <span className="kt-widget__data font-weight-bold text-danger">Active</span>
                                            </div>
                                            {/* <div className="kt-widget__contact">
                                                                <span className="kt-widget__label">Administrator:</span>
                                                                <span className="kt-widget__data">{item.isSystemUser ? 'Yes' : 'No'}</span>
                                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-4">
                        <div className="kt-portlet mb-3">
                            <div className="kt-portlet__body kt-portlet__body--fit">
                                <div className="row row-no-padding row-col-separator-xl">
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                        <div className="kt-widget1">
                                            <LogAttendance />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="kt-portlet mb-3">
                            <div className="kt-portlet__body kt-portlet__body--fit">
                                <div className="row row-no-padding row-col-separator-xl">
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                        {/* <!--begin:: Widgets/Stats2-1 --> */}
                                        <div className="kt-widget1">

                                            <div className="kt-widget1__item">
                                                <div className="kt-widget1__info">
                                                    <h3 className="kt-widget1__title">Present</h3>
                                                    <span className="kt-widget1__desc">Total no of days you've worked</span>
                                                </div>
                                                <span className="kt-widget1__number kt-font-success">18</span>
                                            </div>

                                            <div className="kt-widget1__item">
                                                <div className="kt-widget1__info">
                                                    <h3 className="kt-widget1__title">Absent</h3>
                                                    <span className="kt-widget1__desc">Total no of days your were absent</span>
                                                </div>
                                                <span className="kt-widget1__number kt-font-danger">2</span>
                                            </div>

                                            <div className="kt-widget1__item">
                                                <div className="kt-widget1__info">
                                                    <h3 className="kt-widget1__title">Leave</h3>
                                                    <span className="kt-widget1__desc">Total no of days you were on leave</span>
                                                </div>
                                                <span className="kt-widget1__number kt-font-primary">1</span>
                                            </div>

                                        </div>
                                        {/* <!--end:: Widgets/Stats2-1 -->             */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* <Pagination /> */}
            </div>
        )
    }

    loadList = () => {
        const ud = getStorage(CONSTANT.keys.userDetail);
        const date = this.state.date;
        const monthName = date.format('MMMM');
        const month = date.format('MM');
        const year = date.format('YYYY');
        const userId = ud.id;
        getEmployeeMonthlyHistory(userId, month, year).then((res: any) => {
            let uniqueRecords = new Set(res.result.map((item: any) => item.inTimeDay));
            this.setState({
                uniqueRecords: Array.from(uniqueRecords),
                list: res.result
            });
        })
    }

    changeDate = (e: any) => {
        const month = e.target.dataset.month;
        const year = e.target.dataset.year;

        this.setState({ date: moment(`${year}-${month}-01`) }, () => {
            this.loadList();
        });
    }

    expandDetail = (e: any) => {
        const detailId = e.target.dataset.detailId;
        const innerText = e.target.innerText;
        $(e.target).html(innerText === '+' ? '-' : '+');
        $(detailId).slideToggle();
    }

}

export default EmployeeList;