import React from 'react'
import moment from 'moment';
import Chart from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import BarGraph from './../../component/chart/BarGraph';

import { getTeamLeaveList } from '../../action/LeaveActions'

export default class Leave extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            leaveList: undefined,
            showLoader: false,
            showManageLeaveModal: false,
            leaveDetail: undefined,
            comments: [],
            loadingComments: false,
            balance: null,
            date: moment()
        }
    }

    componentDidMount() {
        this.loadList();
        this.bindCharts();
    }

    public render() {
        const { showLoader, showManageLeaveModal, leaveDetail } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                {this.contentHeader()}
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    {this.renderBody()}
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment >
        )
    }

    contentHeader = () => {
        const { date } = this.state;
        const currYear = date.format('YYYY');

        const prevDate = moment(date.format('YYYY-MM-DD')).subtract(1, 'year');
        const prevYear = prevDate.format('YYYY');

        const nextDate = moment(date.format('YYYY-MM-DD')).add(1, 'year');
        const nextYear = nextDate.format('YYYY');

        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Reimbursement
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {currYear} </span>
                        </div>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href="javascript:;" onClick={this.changeDate} data-year={prevYear} className="btn btn-label-brand btn-bold"><i className="fas fa-angle-double-left mr-1 pe-none" aria-hidden="true"></i>{prevYear}</a>
                        <a href="javascript:;" onClick={this.changeDate} data-year={nextYear} className="btn btn-label-brand btn-bold">{nextYear}<i className="fas fa-angle-double-right ml-1 pe-none" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div >
        )
    }


    chart1Ref: any = React.createRef();
    chart2Ref: any = React.createRef();
    renderBody = () => {
        const { leaveList, loadingComments } = this.state;
        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            India
                        </div>
                        <div className="card-body">
                            <canvas
                                // style={{ height: 80 }}
                                ref={this.chart1Ref}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            France
                        </div>
                        <div className="card-body">
                            <canvas
                                // style={{ height: 80 }}
                                ref={this.chart2Ref}
                            />
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    bindCharts = () => {
        // leave approval 
        const chart1 = this.chart1Ref.current.getContext("2d");
        new Chart(chart1, {
            plugins: [ChartDataLabels],
            type: 'pie',
            data: {
                labels: ['Claimed', 'Approved', 'Rejected', 'Pending'],
                datasets: [{
                    data: [23000, 18000, 2000, 3000],
                    backgroundColor: [
                        'rgba(54, 162, 235)',
                        'rgba(255, 99, 132)',
                        'rgba(255, 206, 86)',
                        'rgba(255, 114, 86)',
                    ],
                    // datalabels: {
                    //     color: '#000'
                    // }
                }]
            },
            // options: {
            //     legend: {
            //         display: false
            //     },
            //     scales: {
            //         yAxes: [{
            //             scaleLabel: {
            //                 display: true,
            //                 labelString: 'Days'
            //             },
            //             ticks: {
            //                 beginAtZero: true
            //             }
            //         }]
            //     }
            // }
        });

        const chart2 = this.chart2Ref.current.getContext("2d");
        new Chart(chart2, {
            plugins: [ChartDataLabels],
            type: 'pie',
            data: {
                labels: ['Claimed', 'Approved', 'Rejected', 'Pending'],
                datasets: [{
                    data: [32000, 17000, 12000, 3000],
                    backgroundColor: [
                        'rgba(54, 162, 235)',
                        'rgba(255, 99, 132)',
                        'rgba(255, 206, 86)',
                        'rgba(255, 114, 86)',
                    ],
                    // datalabels: {
                    //     color: '#000'
                    // }
                }]
            },
            // options: {
            //     legend: {
            //         display: false
            //     },
            //     scales: {
            //         yAxes: [{
            //             scaleLabel: {
            //                 display: true,
            //                 labelString: 'Days'
            //             },
            //             ticks: {
            //                 beginAtZero: true
            //             }
            //         }]
            //     }
            // }
        });
    }

    loadList = () => {
        const { date } = this.state;
        const year = date.format('YYYY');
        getTeamLeaveList(year).then((res: any) => {
            this.setState({ leaveList: res.result });
        })
    }

    changeDate = (e: any) => {
        const year = e.target.dataset.year;
        this.setState({ date: moment(`${year}-01-01`) }, () => {
            this.loadList();
        });
    }
}
