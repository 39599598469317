import * as axios from 'axios';
import CONFIG from './../config';
import { getAuthHeader, getUploadFileHeader } from '../utils';

export const uploadEmployee = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/bulk/employee`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const uploadReportingManager = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/bulk/reporting-manager`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}

export const uploadLeaveBalance = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/bulk/leave-balance`;
    return axios.post(url, model, getAuthHeader()).then((response: any) => {
        return response.data;
    });
}
