import * as React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import UserDefaultPIC from './../../assets/media/users/default.jpg'
import CONSTANT from '../../constant';
import { getEmployeeList } from '../../action/EmployeeAction';

class PayrollExpense extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 20,
            sort: 1,
            count: 0,
            showLoader: false
        }
    }

    componentDidMount() {
        // this.loadList();
    }

    public render() {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                                {this.renderChatDiv()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
             <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Expense List
                        </h3>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <div className="kt-subheader__wrapper row">

                            

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
		<div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="kt-portlet kt-portlet--mobile">
                    {/* <div className="kt-portlet__head kt-portlet__head--lg">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Asset
			                </h3>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <a href="#" className="btn btn-brand btn-elevate btn-icon-sm">
                                        <i className="la la-plus"></i>
                                        Add Record
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
		
		
		
	
           

                                            <div className="row">
                                            <div className="col-sm-12">
                                            <div className="modal fade show" id="kt_modal_1"  role="dialog" aria-labelledby="exampleModalLabel"  aria-modal="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Expense Edit</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div className="modal-body">
			<label>Reimbursement Name</label>
                <input className="form-control"  type="text"    placeholder="Hmc Meeting"  /> 
				<label>Bill Number</label>
                <input className="form-control"  type="text"    placeholder="1234"  />
				<label>Approved Amount</label>
                <input className="form-control"  type="text"    placeholder="1000"  /> 
                                                    
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>

<table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1" role="grid" aria-describedby="kt_table_1_info">
                                        <thead>
                                            <tr role="row">
                                                <th className="sorting_desc" aria-controls="kt_table_1" aria-sort="descending" aria-label="Order ID: activate to sort column ascending">Claim Number</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Country: activate to sort column ascending">Claim Date</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Reimbursement Name</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship Address: activate to sort column ascending">Bill Date</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Company Agent: activate to sort column ascending">Bill Number</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Company Name: activate to sort column ascending">Claimed Amount</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship Date: activate to sort column ascending">Approved Amount</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Status: activate to sort column ascending">Comment</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Status: activate to sort column ascending">Edit</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr role="row" className="odd active">
                                                <td className="sorting_1">1</td>
                                                <td>05-Mar-2020</td>
                                                <td>Hmc Meeting</td>
                                                <td>28-Mar-2020</td>
                                                <td>1234</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>Correct</td>
												<td><button type="button" className="btn btn-bold btn-label-brand btn-sm" data-toggle="modal" data-target="#kt_modal_1">Click To Edit</button></td>
                                              
                                            </tr>
                                            <tr role="row" className="odd active">
                                                <td className="sorting_1">2</td>
                                                <td>05-Mar-2020</td>
                                                <td>Hmc Meeting</td>
                                                <td>28-Mar-2020</td>
                                                <td>1234</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>Correct</td>
												<td><button type="button" className="btn btn-bold btn-label-brand btn-sm" data-toggle="modal" data-target="#kt_modal_1">Click To Edit</button></td>
                                              
                                            </tr>
                                            <tr role="row" className="odd active">
                                                <td className="sorting_1">3</td>
                                                <td>05-Mar-2020</td>
                                                <td>Hmc Meeting</td>
                                                <td>28-Mar-2020</td>
                                                <td>1234</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>Correct</td>
												<td><button type="button" className="btn btn-bold btn-label-brand btn-sm" data-toggle="modal" data-target="#kt_modal_1">Click To Edit</button></td>
                                              
                                            </tr>
                                           <tr role="row" className="odd active">
                                                <td className="sorting_1">4</td>
                                                <td>05-Mar-2020</td>
                                                <td>Hmc Meeting</td>
                                                <td>28-Mar-2020</td>
                                                <td>1234</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>Correct</td>
												<td><button type="button" className="btn btn-bold btn-label-brand btn-sm" data-toggle="modal" data-target="#kt_modal_1">Click To Edit</button></td>
                                              
                                            </tr>
                                            <tr role="row" className="odd active">
                                                <td className="sorting_1">5</td>
                                                <td>05-Mar-2020</td>
                                                <td>Hmc Meeting</td>
                                                <td>28-Mar-2020</td>
                                                <td>1234</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>Correct</td>
												<td><button type="button" className="btn btn-bold btn-label-brand btn-sm" data-toggle="modal" data-target="#kt_modal_1">Click To Edit</button></td>
                                              
                                            </tr>
                                            <tr role="row" className="odd active">
                                                <td className="sorting_1">6</td>
                                                <td>05-Mar-2020</td>
                                                <td>Hmc Meeting</td>
                                                <td>28-Mar-2020</td>
                                                <td>1234</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>Correct</td>
												<td><button type="button" className="btn btn-bold btn-label-brand btn-sm" data-toggle="modal" data-target="#kt_modal_1">Click To Edit</button></td>
                                              
                                            </tr>
                                            <tr role="row" className="odd active">
                                                <td className="sorting_1">7</td>
                                                <td>05-Mar-2020</td>
                                                <td>Hmc Meeting</td>
                                                <td>28-Mar-2020</td>
                                                <td>1234</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>Correct</td>
												<td><button type="button" className="btn btn-bold btn-label-brand btn-sm" data-toggle="modal" data-target="#kt_modal_1">Click To Edit</button></td>
                                              
                                            </tr>
                                            <tr role="row" className="odd active">
                                                <td className="sorting_1">8</td>
                                                <td>05-Mar-2020</td>
                                                <td>Hmc Meeting</td>
                                                <td>28-Mar-2020</td>
                                                <td>1234</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>Correct</td>
												<td><button type="button" className="btn btn-bold btn-label-brand btn-sm" data-toggle="modal" data-target="#kt_modal_1">Click To Edit</button></td>
                                              
                                            </tr>
											<tr role="row" className="odd active">
                                                <td className="sorting_1">9</td>
                                                <td>05-Mar-2020</td>
                                                <td>Hmc Meeting</td>
                                                <td>28-Mar-2020</td>
                                                <td>1234</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>Correct</td>
												<td><button type="button" className="btn btn-bold btn-label-brand btn-sm" data-toggle="modal" data-target="#kt_modal_1">Click To Edit</button></td>
                                              
                                            </tr>
                                            <tr role="row" className="odd active">
                                                <td className="sorting_1">10</td>
                                                <td>05-Mar-2020</td>
                                                <td>Hmc Meeting</td>
                                                <td>28-Mar-2020</td>
                                                <td>1234</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>Correct</td>
												<td><button type="button" className="btn btn-bold btn-label-brand btn-sm" data-toggle="modal" data-target="#kt_modal_1">Click To Edit</button></td>
                                              
                                            </tr>
                                            
                                        </tbody>

                                    </table>
                              </div>
                            </div>
                        </div>


                

                {/* <Pagination /> */}
            </div >
        )
    }

    renderChatDiv = () => {
        return (

            <div className="modal modal-hide fade- modal-sticky-bottom-right" id="kt_chat_modal" role="dialog" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="kt-chat">
                            <div className="kt-portlet kt-portlet--last">
                                <div className="kt-portlet__head">
                                    <div className="kt-chat__head ">
                                        <div className="kt-chat__left">
                                            <div className="kt-chat__label">
                                                <a href="#" className="kt-chat__title">Jason Muller</a>
                                                <span className="kt-chat__status">
                                                    <span className="kt-badge kt-badge--dot kt-badge--success"></span> Active
                                                </span>
                                            </div>
                                        </div>
                                        <div className="kt-chat__right">
                                            <div className="dropdown dropdown-inline">
                                                <button type="button" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="flaticon-more-1"></i>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-md">

                                                    <ul className="kt-nav">
                                                        <li className="kt-nav__head">
                                                            Messaging
                                                <i className="flaticon2-information" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more..."></i>
                                                        </li>
                                                        <li className="kt-nav__separator"></li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-group"></i>
                                                                <span className="kt-nav__link-text">New Group</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-open-text-book"></i>
                                                                <span className="kt-nav__link-text">Contacts</span>
                                                                <span className="kt-nav__link-badge">
                                                                    <span className="kt-badge kt-badge--brand  kt-badge--rounded-">5</span>
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-bell-2"></i>
                                                                <span className="kt-nav__link-text">Calls</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-dashboard"></i>
                                                                <span className="kt-nav__link-text">Settings</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-protected"></i>
                                                                <span className="kt-nav__link-text">Help</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__separator"></li>
                                                        <li className="kt-nav__foot">
                                                            <a className="btn btn-label-brand btn-bold btn-sm" href="#">Upgrade plan</a>
                                                            <a className="btn btn-clean btn-bold btn-sm" href="#" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more...">Learn more</a>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>

                                            <button type="button" className="btn btn-clean btn-sm btn-icon" data-dismiss="modal">
                                                <i className="flaticon2-cross"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__body">
                                    <div className="kt-scroll kt-scroll--pull" data-height="410" data-mobile-height="225">
                                        <div className="kt-chat__messages kt-chat__messages--solid">
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">2 Hours</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    How likely are you to recommend our company<br /> to your friends and family?
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Hey there, we’re just writing to let you know that you’ve<br /> been subscribed to a repository on GitHub.
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok, Understood!
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">Just Now</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    You’ll receive notifications for all issues, pull requests!
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">2 Hours</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    You were automatically <b className="kt-font-brand">subscribed</b> <br />because you’ve been given access to the repository
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>

                                                <div className="kt-chat__text">
                                                    You can unwatch this repository immediately <br />by clicking here: <a href="#" className="kt-font-bold kt-link"></a>
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok!
                                    </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">Just Now</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok!
                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-chat__input">
                                        <div className="kt-chat__editor">
                                            <textarea placeholder="Type here..." style={{ height: 50 }}></textarea>
                                        </div>
                                        <div className="kt-chat__toolbar">
                                            <div className="kt_chat__tools">
                                                <a href="#"><i className="flaticon2-link"></i></a>
                                                <a href="#"><i className="flaticon2-photograph"></i></a>
                                                <a href="#"><i className="flaticon2-photo-camera"></i></a>
                                            </div>
                                            <div className="kt_chat__actions">
                                                <button type="button" className="btn btn-brand btn-md  btn-font-sm btn-upper btn-bold kt-chat__reply">reply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { page, limit, sort } = this.state;
        getEmployeeList(page, limit, sort, "*").then((response: any) => {
            this.setState({
                list: response.result.list,
                count: response.result.count,
            });
        }, (error: any) => {
            alert(error.message);
        });
    }

}

export default PayrollExpense;
