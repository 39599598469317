import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class TDSModal extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            modal: true,
            message: props.message
        };
    }

    public render() {
        const { className } = this.props;
        const { modal } = this.state;
        const backdrop = this.props.backdrop ? this.props.backdrop : true;
        return (

            <React.Fragment>
                <div className="modal" id="kt_modal_KTDatatable_local">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content payslip-container">

                            <button type="button" className="close popup-close" data-dismiss="modal">&times;</button>

                            <div className="modal-body">
                                <h4 className="d-block">MounTalent</h4>
                                <span>A521 Delhi 201301 India</span>
                            </div>
                            <div className="payslip-info">
                                <h4 className="d-block">TDS WORK SHEET - <small> For the month 2020</small></h4>
                                <span>A521 Delhi 201301 India</span>

                                <div className="mt-3 mb-2">
                                    <div className="row">
                                        <div className="col-lg-6 form-group mb-0">
                                            <label>EMPLOYEE NAME</label>
                                            <div><b>Ranjan Sharma</b></div>
                                        </div>
                                        <div className="col-lg-6 form-group  mb-0">
                                            <label >Designations</label>
                                            <div>
                                                <b>React Developer</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="payslip-info">
                                <b className="mb-2 d-block">Details of salary paid and any income and tax deducted</b>
                                <table className="table table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Particulars</th>
                                            <th scope="col">Actual</th>
                                            <th scope="col">Projection </th>
                                            <th scope="col">Total </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row"><b>1) Gross Earning </b></td>
                                            <td>&#x20B9;3000 </td>
                                            <td>0.00</td>
                                            <td>&#x20B9;4000.00</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">Basic </td>
                                            <td>&#x20B9;3000 </td>
                                            <td>0.00</td>
                                            <td>&#x20B9;4000.00</td>
                                        </tr>
                                        <tr>
                                            <tr>
                                                <td scope="row">HRA </td>
                                                <td>&#x20B9;3000 </td>
                                                <td>0.00</td>
                                                <td>&#x20B9;4000.00</td>
                                            </tr>
                                            <tr>
                                                <tr>
                                                    <td scope="row"><a><b>Total Income</b></a> </td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td><b>&#x20B9;4000.00</b></td>
                                                </tr>
                                                <tr>
                                                </tr>
                                            </tr>
                                        </tr>
                                    </tbody>
                                </table>

                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td scope="row"><b>2) Allowance  </b></td>
                                            <td>&#x20B9;3000 </td>
                                            <td>0.00</td>
                                            <td>&#x20B9;4000.00</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">Basic </td>
                                            <td>&#x20B9;3000 </td>
                                            <td>0.00</td>
                                            <td>&#x20B9;4000.00</td>
                                        </tr>
                                        <tr>
                                            <tr>
                                                <td scope="row">HRA </td>
                                                <td>&#x20B9;3000 </td>
                                                <td>0.00</td>
                                                <td>&#x20B9;4000.00</td>
                                            </tr>
                                            <tr>
                                                <tr>
                                                    <td scope="row"><a><b>Total Income</b></a> </td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td><b>&#x20B9;4000.00</b></td>
                                                </tr>
                                                <tr>
                                                </tr>
                                            </tr>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>


                    </div>
                </div>

            </React.Fragment >


            // <div id="kt_modal_KTDatatable_local" className="modal fade" role="dialog" aria-hidden="true">
            //     <div className="modal-dialog modal-xl">
            //         <div className="modal-content" >
            //             <div className="modal-header">
            //                 {/* <h5 className="modal-title">
            //                     Datatable
            // 		            <small>local data source</small>
            //                 </h5> */}
            //                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            //                     <span aria-hidden="true">×</span>
            //                 </button>
            //             </div>
            //             <div className="modal-body">
            //                 <div className="kt-portlet kt-portlet--mobile">

            //                     <div className="kt-portlet__body">
            //                         <div id="kt_table_1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">

            //                             <div className="row"><div className="col-sm-12">
            //                                 <div className="dataTables_scroll">

            //                                     <div className="dataTables_scrollBody" >
            //                                         <table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer" id="kt_table_1" role="grid" aria-describedby="kt_table_1_info">
            //                                             <thead>
            //                                                 <tr role="row" >
            //                                                     <th className="sorting" aria-controls="kt_table_1" >
            //                                                         <div className="dataTables_sizing" >Particulars</div></th><th className="sorting" aria-controls="kt_table_1" >
            //                                                         <div className="dataTables_sizing">Actual</div></th><th className="sorting" aria-controls="kt_table_1" >
            //                                                         <div className="dataTables_sizing">Projection</div></th><th className="sorting" aria-controls="kt_table_1">
            //                                                         <div className="dataTables_sizing">Total</div></th><th className="sorting" aria-controls="kt_table_1">
            //                                                     </th>
            //                                                 </tr>
            //                                             </thead>

            //                                             <tbody>
            //                                                 <tr role="row" className="odd">
            //                                                     <td className="sorting_1">1</td>
            //                                                     <td>61715-075</td>
            //                                                     <td>China</td>
            //                                                     <td>Tieba</td>
            //                                                 </tr>
            //                                             </tbody>

            //                                         </table>
            //                                     </div>
            //                                 </div>
            //                             </div>
            //                             </div>

            //                         </div>

            //                     </div>
            //                 </div>

            //             </div>
            //             {/* <div className="modal-body modal-body-fit">

            //             </div> */}
            //             {/* <div className="modal-footer kt-hidden">
            //                 <button type="button" className="btn btn-clean btn-bold btn-upper btn-font-md" data-dismiss="modal">Close</button>
            //                 <button type="button" className="btn btn-default btn-bold btn-upper btn-font-md">Submit</button>
            //             </div> */}
            //         </div>
            //     </div>
            // </div>

        );
    }

    toggleModal = () => {
        this.setState((prevState: any) => ({ modal: !prevState.modal }));
        this.props.toggleModal();
    }
}

export default TDSModal;