import * as React from 'react';
import ModalWindow from '../../component/common/ModalWindow'
import Header from './../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import CONSTANT from './../../constant';
import { getSalaryComponentList, toggleIsActiveStatus } from './../../action/PayrollTaxComponentActions';
import { getDateParts } from '../../utils';
import AddCycle from './../../component/pms/AddCycle';
import AddSubCycle from './../../component/pms/AddSubCycle';

class ComponentName extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 10,
            showLoader: false,
            showCycleModal: false,
            showSubCycleModal: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = CONSTANT.title.locationList;
    }

    public render() {
        const { showCycleModal, showSubCycleModal } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                    {
                        showCycleModal && <ModalWindow
                            title="Appraisal Cycle detail"
                            backdrop="static"
                            toggleModal={this.onCycleCancel}>
                            <AddCycle
                                onSubmit={this.onCycleSubmit}
                                onCancel={this.onCycleCancel}
                            />
                        </ModalWindow>
                    }
                    {
                        showSubCycleModal && <ModalWindow
                            title="Appraisal Sub-Cycle detail"
                            backdrop="static"
                            toggleModal={this.onSubCycleCancel}>
                            <AddSubCycle
                                onSubmit={this.onSubCycleSubmit}
                                onCancel={this.onSubCycleCancel}
                            />
                        </ModalWindow>
                    }
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            PMS Appraisal Cycle
                        </h3>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                        </div>

                    </div>
                    <div className="kt-subheader__toolbar">
                        <a href="javascript:;" onClick={this.onAddCycle} className="btn btn-label-brand btn-bold">Add Appraisal cycle</a>
                        <a href="javascript:;" onClick={this.onAddSubCycle} className="btn btn-label-brand btn-bold">Add sub-cycle</a>
                    </div>
                </div>
            </div>

        )
    }

    renderBody = () => {
        const { list, showLoader, page, limit } = this.state;
        let lcList, scList = undefined;

        if (list !== undefined) {
            lcList = list.filter((i: any) => i.isCompliance === true);
            scList = list.filter((i: any) => i.isCompliance === false);
        }
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Primary Cycle
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Starts on</th>
                                                    <th scope="col">Ends on</th>
                                                    <th scope="col">Modified by</th>
                                                    <th scope="col">Modified on</th>
                                                    <th scope="col" style={{ width: "3%" }}></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>Yearly Cycle 20-21</td>
                                                    <td>01 Apr 2020</td>
                                                    <td>31 Mar 2021</td>
                                                    <td>Ranjan Sharma</td>
                                                    <td>2 Days ago</td>
                                                    <td className="text-right"><a href="#"><i className="fas fa-edit"></i></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end::Section--> */}
                            </div>
                            {/* <!--end::Form--> */}
                        </div>
                    </div>


                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Sub-cycle
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Primary Cycle</th>
                                                    <th scope="col">Starts on</th>
                                                    <th scope="col">Ends on</th>
                                                    <th scope="col">Modified by</th>
                                                    <th scope="col">Modified on</th>
                                                    <th scope="col"></th>
                                                    <th scope="col" style={{ width: "3%" }}></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>1st Quarter 2020</td>
                                                    <td>Yearly Cycle 20-21</td>
                                                    <td>01 Apr 2020</td>
                                                    <td>30 Jun 2020</td>
                                                    <td>Ranjan Sharma</td>
                                                    <td>2 Days ago</td>
                                                    <td><a href="javascript:;" className="btn btn-primary btn-sm">Initiate Appraisal</a></td>
                                                    <td className="text-right"><a href="#"><i className="fas fa-edit"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td>2nd Quarter 2020</td>
                                                    <td>Yearly Cycle 20-21</td>
                                                    <td>01 Jul 2020</td>
                                                    <td>31 Sept 2020</td>

                                                    <td>Ranjan Sharma</td>
                                                    <td>2 Days ago</td>
                                                    <td><a href="javascript:;" className="btn btn-primary btn-sm">Initiate Appraisal</a></td>
                                                    <td className="text-right"><a href="#"><i className="fas fa-edit"></i></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onAddCycle = () => {
        this.setState({
            showCycleModal: true
        });
    }

    onCycleSubmit = () => {
        this.setState({
            showCycleModal: false
        });
    }

    onCycleCancel = () => {
        this.setState({
            showCycleModal: false
        });
    }

    onAddSubCycle = () => {
        this.setState({
            showSubCycleModal: true
        });
    }

    onSubCycleSubmit = () => {
        this.setState({
            showSubCycleModal: false
        });
    }

    onSubCycleCancel = () => {
        this.setState({
            showSubCycleModal: false
        });
    }
}

export default ComponentName;
