import React from 'react';
import CONSTANT from './../../constant';
import ProgressBar from './../common/ProgressBar';
import { getDateParts } from '../../utils';

const ComponentName = (props: any) => {
    const detail = props.detail;
    const detailPageURL = CONSTANT.url.projectDetail.replace(':id', detail.id);
    const sd = getDateParts(detail.startDate);
    const ed = getDateParts(detail.endDate);
    return (
        <div className="kt-portlet kt-portlet--height-fluid">
            <div className="kt-portlet__body kt-portlet__body--fit">
                <div className="kt-widget kt-widget--project-1">
                    <div className="kt-widget__head d-flex">
                        <div className="kt-widget__label">

                            <div className="kt-widget__info kt-padding-0">
                                <a href={detailPageURL} className="kt-widget__title">
                                    {detail.name}
                                </a>
                                <span className="kt-widget__desc">
                                    {detail.tagLine}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="kt-widget__body">
                        <span className="kt-widget__text kt-margin-t-0 kt-padding-t-5">
                            {detail.description}
                        </span>

                        <div className="kt-widget__stats kt-margin-t-20">
                            <div className="kt-widget__item d-flex align-items-center kt-margin-r-30">
                                <span className="kt-widget__date kt-padding-0 kt-margin-r-10">
                                    Start
                                </span>
                                <div className="kt-widget__label">
                                    <span className="btn btn-label-brand btn-sm btn-bold btn-upper">{`${sd.date} ${sd.monthName} ${sd.year}`}</span>
                                </div>
                            </div>
                            <div className="kt-widget__item d-flex align-items-center kt-padding-l-0">
                                <span className="kt-widget__date kt-padding-0 kt-margin-r-10 ">
                                    Due
                                </span>
                                <div className="kt-widget__label">
                                    <span className="btn btn-label-danger btn-sm btn-bold btn-upper">{`${ed.date} ${ed.monthName} ${ed.year}`}</span>
                                </div>
                            </div>
                        </div>
                        <div className="kt-widget__container">
                            <span className="kt-widget__subtitel">Progress</span>
                            <ProgressBar percentage={detail.progressInPercentage} />
                            {/* <div className="kt-widget__progress d-flex align-items-center flex-fill">
                                <div className="progress" style={{ height: '5px', width: '100%' }}>
                                    <div className="progress-bar kt-bg-success" role="progressbar" style={{ width: `${detail.progressInPercentage}%` }}></div>
                                </div>
                                <span className="kt-widget__stat">
                                    {detail.progressInPercentage}%
                                </span>
                            </div> */}
                        </div>
                    </div>
                    <div className="kt-widget__footer">
                        <div className="kt-widget__wrapper">
                            <div className="kt-widget__section">
                                <div className="kt-widget__blog">
                                    <i className="flaticon2-list-1"></i>
                                    <a href="#" className="kt-widget__value kt-font-brand">{detail.taskCount} Tasks</a>
                                </div>
                                <div className="kt-widget__blog">
                                    <i className="flaticon2-talk"></i>
                                    <a href="#" className="kt-widget__value kt-font-brand">{detail.commentCount} Comments</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ComponentName;