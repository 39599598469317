import * as React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
// import UserDefaultPIC from './../../assets/media/users/default.jpg'
import CONSTANT from '../../constant';
import { getEmployeeList } from '../../action/EmployeeAction';
// import BarGraph from '../../component/chart/BarGraph';

class ExitList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 20,
            sort: 1,
            count: 0,
            showLoader: false
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                                {this.renderChatDiv()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderHeader = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">

                    <div className="kt-subheader__main">

                        <h3 className="kt-subheader__title">
                            Exit Details
                        </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <form className="kt-margin-l-20" id="kt_subheader_search_form">
                                <div className="kt-input-icon kt-input-icon--right kt-subheader__search">
                                    <input type="text" className="form-control" placeholder="Search..." id="generalSearch" />
                                    <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                        <span>
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                    <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </span>
                                </div>
                            </form>
                        </div>

                    </div>
                    <div className="kt-subheader__toolbar">
                        <div className="kt-subheader__wrapper row">

                            <div className="kt-subheader__toolbar">
                                <a href={CONSTANT.url.addEmployeeExit} className="btn btn-brand btn-elevate btn-icon-sm">
                                    <i className="la la-plus"></i>Add Record
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { list, page, limit, sort, showLoader, count } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="kt-portlet kt-portlet--mobile">
                    {/* <div className="kt-portlet__head kt-portlet__head--lg">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Asset
			                </h3>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <a href="#" className="btn btn-brand btn-elevate btn-icon-sm">
                                        <i className="la la-plus"></i>
                                        Add Record
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="kt-portlet__body">

                        <div className="kt-portlet">
                            <div className="kt-portlet__body">
                                <div className="kt-widget kt-widget--user-profile-3">
                                    <div className="kt-widget__top">
                                        <div className="kt-widget__media kt-hidden-">
                                            <img src="./../../assets/media/users/100_1.jpg" alt="image" />
                                        </div>
                                        <div className="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-boldest kt-font-light kt-hidden">
                                            JM
                                        </div>
                                        <div className="kt-widget__content">
                                            <div className="kt-widget__head">
                                                <a className="kt-widget__username" href={CONSTANT.url.employeeExitDetail.replace(':id', '1')}>
                                                    Keshav Gupta
                                                    {/* <i className="flaticon2-correct kt-font-success"></i> */}
                                                </a>
                                                <div className="kt-widget__action">
                                                    {/* <button type="button" className="btn btn-label-success btn-sm btn-upper">ask</button>&nbsp; */}
                                                    <a className="kt-widget__username" href={CONSTANT.url.employeeExitDetail.replace(':id', '1')}>
                                                        <button type="button" className="btn btn-brand btn-sm btn-upper">Edit</button>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="kt-widget__subhead">
                                                <a href="#"><i className="flaticon2-new-email"></i>keshav.gupta@mounttalent.com</a>
                                                <a href="#"><i className="flaticon2-calendar-3"></i>PR Manager </a>
                                                <a href="#"><i className="flaticon2-placeholder"></i>Noida</a>
                                            </div>

                                            <div className="kt-widget__info">
                                                <div className="kt-widget__desc">
                                                    I distinguish three main text objektive could be merely to inform people.
                                                    <br /> A second could be persuade people.You want people to bay objective
                                                </div>
                                                {/* <div className="kt-widget__progress">
                                                    <div className="kt-widget__text">
                                                        Progress
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* <div id="kt_table_1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1" role="grid" aria-describedby="kt_table_1_info">
                                        <thead>
                                            <tr role="row">
                                                <th className="sorting_desc" aria-controls="kt_table_1" aria-sort="descending" aria-label="Order ID: activate to sort column ascending">Employee ID</th>
                                                <th className="sorting_desc" aria-controls="kt_table_1" aria-sort="descending" aria-label="Order ID: activate to sort column ascending">Interviewer</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship Address: activate to sort column ascending">Separation Date</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Reason For Leaving</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Working for this organization again</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Think the organization do to improve staff welfare</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">What did you like the most of the organization</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Anything you wish to share with us</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Company Vehicle handed in</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">All library books submittedn</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Exit interview conducted</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Resignation letter submitted</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">All equipments handed in</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Security</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Notice period followed	</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Manager/Supervisor clearance	</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Added By</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Added time</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Modified By</th>
                                                <th className="sorting" aria-controls="kt_table_1" aria-label="Ship City: activate to sort column ascending">Modified time</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr role="row" className="odd active">
                                                <td className="sorting_1">MTC101</td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                                <td className="sorting_1"></td>
                                            </tr>
                                            
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div> */}

                    </div>

                </div>

                {/* <Pagination /> */}
            </div >
        )
    }

    renderChatDiv = () => {
        return (

            <div className="modal modal-hide fade- modal-sticky-bottom-right" id="kt_chat_modal" role="dialog" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="kt-chat">
                            <div className="kt-portlet kt-portlet--last">
                                <div className="kt-portlet__head">
                                    <div className="kt-chat__head ">
                                        <div className="kt-chat__left">
                                            <div className="kt-chat__label">
                                                <a href="#" className="kt-chat__title">Jason Muller</a>
                                                <span className="kt-chat__status">
                                                    <span className="kt-badge kt-badge--dot kt-badge--success"></span> Active
                                                </span>
                                            </div>
                                        </div>
                                        <div className="kt-chat__right">
                                            <div className="dropdown dropdown-inline">
                                                <button type="button" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="flaticon-more-1"></i>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-md">

                                                    <ul className="kt-nav">
                                                        <li className="kt-nav__head">
                                                            Messaging
                                                            <i className="flaticon2-information" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more..."></i>
                                                        </li>
                                                        <li className="kt-nav__separator"></li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-group"></i>
                                                                <span className="kt-nav__link-text">New Group</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-open-text-book"></i>
                                                                <span className="kt-nav__link-text">Contacts</span>
                                                                <span className="kt-nav__link-badge">
                                                                    <span className="kt-badge kt-badge--brand  kt-badge--rounded-">5</span>
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-bell-2"></i>
                                                                <span className="kt-nav__link-text">Calls</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-dashboard"></i>
                                                                <span className="kt-nav__link-text">Settings</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-protected"></i>
                                                                <span className="kt-nav__link-text">Help</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__separator"></li>
                                                        <li className="kt-nav__foot">
                                                            <a className="btn btn-label-brand btn-bold btn-sm" href="#">Upgrade plan</a>
                                                            <a className="btn btn-clean btn-bold btn-sm" href="#" data-toggle="kt-tooltip" data-placement="right" title="Click to learn more...">Learn more</a>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>

                                            <button type="button" className="btn btn-clean btn-sm btn-icon" data-dismiss="modal">
                                                <i className="flaticon2-cross"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="kt-portlet__body">
                                    <div className="kt-scroll kt-scroll--pull" data-height="410" data-mobile-height="225">
                                        <div className="kt-chat__messages kt-chat__messages--solid">
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">2 Hours</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    How likely are you to recommend our company<br /> to your friends and family?
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Hey there, we’re just writing to let you know that you’ve<br /> been subscribed to a repository on GitHub.
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok, Understood!
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">Just Now</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    You’ll receive notifications for all issues, pull requests!
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">2 Hours</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    You were automatically <b className="kt-font-brand">subscribed</b> <br />because you’ve been given access to the repository
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>

                                                <div className="kt-chat__text">
                                                    You can unwatch this repository immediately <br />by clicking here: <a href="#" className="kt-font-bold kt-link"></a>
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--success">
                                                <div className="kt-chat__user">
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/100_12.jpg" alt="image" />
                                                    </span>
                                                    <a href="#" className="kt-chat__username">Jason Muller</a>
                                                    <span className="kt-chat__datetime">30 Seconds</span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok!
                                                </div>
                                            </div>
                                            <div className="kt-chat__message kt-chat__message--right kt-chat__message--brand">
                                                <div className="kt-chat__user">
                                                    <span className="kt-chat__datetime">Just Now</span>
                                                    <a href="#" className="kt-chat__username">You</a>
                                                    <span className="kt-media kt-media--circle kt-media--sm">
                                                        <img src="https://keenthemes.com/metronic/themes/metronic/theme/default/demo1/dist/assets/media/users/300_21.jpg" alt="image" />
                                                    </span>
                                                </div>
                                                <div className="kt-chat__text">
                                                    Ok!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-chat__input">
                                        <div className="kt-chat__editor">
                                            <textarea placeholder="Type here..." style={{ height: 50 }}></textarea>
                                        </div>
                                        <div className="kt-chat__toolbar">
                                            <div className="kt_chat__tools">
                                                <a href="#"><i className="flaticon2-link"></i></a>
                                                <a href="#"><i className="flaticon2-photograph"></i></a>
                                                <a href="#"><i className="flaticon2-photo-camera"></i></a>
                                            </div>
                                            <div className="kt_chat__actions">
                                                <button type="button" className="btn btn-brand btn-md  btn-font-sm btn-upper btn-bold kt-chat__reply">reply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onStarted = () => {
        this.props.history.push(CONSTANT.url.paySchedule);
    }

    loadList = () => {
        this.setState({ showLoader: true });
        const { page, limit, sort } = this.state;
        getEmployeeList(page, limit, sort, "*").then((response: any) => {
            this.setState({
                list: response.result.list,
                count: response.result.count,
            });
        }, (error: any) => {
            alert(error.message);
        });
    }

}

export default ExitList;
